import { Table } from "@ttc3k/trekker";
import { Box } from "styled-system/jsx";
import { Amenity } from "gql/generated";
import { ConsumingOverlayPageProps, OverlayPage } from "../OverlayPage";

type AmenitiesPageProps = ConsumingOverlayPageProps & {
  amenities: Amenity[];
};

export const AmenitiesPage = ({
  amenities,
  onClose,
  ...rest
}: AmenitiesPageProps) => {
  return (
    <OverlayPage
      title={"Amenities"}
      subtitle={
        "Below is a list of all our amenities. If you have questions about any of these, please don’t hesitate to get in touch."
      }
      onButtonClick={onClose}
      onClose={onClose}
      {...rest}
    >
      <Box mt={"400"}>
        <Table.Root>
          <Table.Body>
            {amenities.map(amenity => (
              <Table.Row key={amenity._id}>
                <Table.Header>{amenity.name.en}</Table.Header>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </Box>
    </OverlayPage>
  );
};
