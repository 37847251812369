import { z } from "zod";

export const captionLanguageSchema = z.object({
  en: z.string().max(300),
  fr: z.string(),
  es: z.string()
});

export type CaptionLanguageSchema = z.infer<typeof captionLanguageSchema>;

export const captionInitialValue: CaptionLanguageSchema = {
  en: "",
  fr: "",
  es: ""
};

export const imageInfoSchema = z.object({
  caption: captionLanguageSchema,
});

export type ImageInfoSchema = z.infer<typeof imageInfoSchema>;

export const imageInfoInitialValues: ImageInfoSchema = {
  caption: captionInitialValue,
};
