import { useElements, CardElement, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { useCartContext } from "apps/custom/ttc/booker/contexts";

export const usePaymentMethod = () => {
  const { updateStripeCardElement, stripeCardElement, updatePaymentMethodID } =
    useCartContext();
  const elements = useElements();
  const stripe = useStripe();

  const handleCardElementChange = async (e: StripeCardElementChangeEvent) => {
    if (e.complete) {
      if (elements && stripe) {
        const cardElement = elements.getElement(CardElement) ?? undefined;
        updateStripeCardElement(cardElement);

        if (cardElement) {
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
          });
          if (paymentMethod && !error) updatePaymentMethodID(paymentMethod.id);
        }
      }
    } else {
      updateStripeCardElement(undefined);
      updatePaymentMethodID("");
    }
  };

  return { onChange: handleCardElementChange, isComplete: !!stripeCardElement };
};
