import { useFormHandler } from "core/hooks/useFormHandler";
import { useCallback } from "react";
import { z } from "zod";

export const guestSchema = z
  .object({
    label: z.string(),
    type: z.string().min(1, "Please select a type"),
    _id: z.string(),
  })

export type AddEditGuestDataFormValues = z.infer<typeof guestSchema>;

interface AddEditGuestDataFormOptions {
  onSuccess?: (v: AddEditGuestDataFormValues) => void;
  defaultValues: AddEditGuestDataFormValues;
}

export const useAddEditGuestDataForm = ({
  onSuccess,
  defaultValues,
}: AddEditGuestDataFormOptions) => {
  return useFormHandler<AddEditGuestDataFormValues>(
    useCallback(
      async data => {
        onSuccess?.(data as AddEditGuestDataFormValues);
      },
      [onSuccess],
    ),
    defaultValues,
    { schema: guestSchema },
  );
};
