import { ReactNode, useCallback, useId, useMemo, useState } from "react";
import { Combobox } from "@headlessui/react";
import { Xmark } from "iconoir-react";
import { nanoid } from "nanoid";
import { cn } from "../../../utils/cn";
import { Label } from "../Label";
import { HelperText } from "../HelperText";
import { Tag, TagProps } from "../Tag";

export type Option = {
  value: string;
  label: string;
};

export type AutocompleteProps = {
  label?: string;
  tagPropsVariants?: TagProps["variant"];
  onChange?: (value: string[]) => void;
  value?: string[];
  options?: Option[];
  helperText?: ReactNode;
  placeholder?: string;
  tagsOutside?: boolean;
};

export const Autocomplete = ({
  onChange,
  value = [],
  options = [],
  label,
  tagPropsVariants = "info",
  helperText,
  placeholder = "Search",
  tagsOutside = false,
}: AutocompleteProps) => {
  const [query, setQuery] = useState("");
  const id = useId();

  const handleRemove = useCallback(
    (option: string) => {
      const newArrayValue = [...value];
      const found = newArrayValue.findIndex(item => item === option);
      newArrayValue.splice(found, 1);
      if (found >= 0) onChange?.(newArrayValue);
    },
    [onChange, value],
  );

  const filteredOption =
    query === ""
      ? options
      : options.filter(option => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  const arrToObjOptions = useMemo(
    () =>
      options.reduce<Record<string, string>>(
        (acc, item) => ({ ...acc, [item.value]: item.label }),
        {},
      ),
    [options],
  );

  return (
    <Combobox
      value={value}
      onChange={e => {
        onChange?.(e);
        setQuery("");
      }}
      multiple
      immediate
    >
      <div className="tw-relative tw-w-full tw-flex tw-flex-col tw-gap-[4px]">
        {label && <Label id={id} label={label} />}
        <div
          className={cn(
            "tw-w-full tw-gap-[8px] tw-font-figtree tw-border-solid tw-flex tw-items-center tw-bg-white tw-py-[12px] tw-px-[16px] tw-rounded-[8px]  focus-within:tw-border-[#2E92EF] tw-border-border tw-border-[1px] tw-flex-wrap",
          )}
        >
          {/* Selected values */}
          {!tagsOutside &&
            value?.map(val => (
              <Tag
                key={val}
                label={arrToObjOptions[val]}
                variant={tagPropsVariants}
                endIcon={
                  <Xmark
                    fontSize={12}
                    className="tw-cursor-pointer tw-opacity-50"
                    onClick={() => handleRemove(val)}
                  />
                }
              />
            ))}
          {/* Input */}
          <Combobox.Input
            className="tw-grow tw-bg-transparent tw-border-none tw-text-[#27272A] tw-outline-none tw-font-[400] tw-text-[16px] placeholder:tw-text-[#A1A1A9] placeholder:tw-font-thin"
            placeholder={placeholder}
            onChange={e => setQuery(e.target.value)}
            value={query}
          />
        </div>
        {/* Options List */}
        <div className="tw-relative tw-text-left">
          {filteredOption.length > 0 && (
            <Combobox.Options className="tw-absolute tw-top-[0px] tw-max-h-48 tw-overflow-auto tw-bg-white tw-w-full tw-rounded-[8px] tw-mt-2 tw-flex tw-flex-col tw-gap-[14px] tw-border-border tw-border-[1px] tw-border-solid tw-p-[14px] tw-z-10">
              {filteredOption.map(option => (
                <Combobox.Option
                  key={nanoid()}
                  value={option.value}
                  className={({ active, selected }) =>
                    cn("tw-cursor-pointer tw-text-left tw-font-figtree", {
                      "tw-text-[#2E92EF95]":
                        active && tagPropsVariants === "info",
                      "tw-text-[#2E92EF]":
                        selected && tagPropsVariants === "info",
                      "tw-text-[#E08D1195]":
                        active && tagPropsVariants === "warning",
                      "tw-text-[#E08D11]":
                        selected && tagPropsVariants === "warning",
                    })
                  }
                >
                  {option.label}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
        {helperText && typeof helperText === "string" ? (
          <HelperText text={helperText} />
        ) : (
          helperText
        )}
        {tagsOutside && (
          <div className="tw-space-y-3 tw-mt-2">
            {value?.map(val => (
              <Tag
                key={val}
                label={arrToObjOptions[val]}
                variant={tagPropsVariants}
                endIcon={
                  <Xmark
                    fontSize={12}
                    className="tw-cursor-pointer tw-opacity-50"
                    onClick={() => handleRemove(val)}
                  />
                }
              />
            ))}
          </div>
        )}
      </div>
    </Combobox>
  );
};
