import { useEffect, useState } from "react";
import { Icon, LinkButton } from "@ttc3k/trekker";
import { NavArrowRight } from "iconoir-react";
import { useBookerContext } from "booker/contexts";
import {
  MatomoEvent,
  useMatomoAnalyticsContext,
} from "@ttc3k/ttc-bkr-analytics";
import { AmenitiesPage } from "../OverlayPages";

export const ShowAmenities = () => {
  const { product } = useBookerContext();
  const [openAmenitiesPage, setOpenAmenitiesPage] = useState(false);
  const { pushMatomoEvent } = useMatomoAnalyticsContext();

  useEffect(() => {
    if (openAmenitiesPage) {
      pushMatomoEvent({
        event: MatomoEvent.AmenitiesViewed,
        productName: product?.name.en ?? "Unknown product name",
      });
    }
  }, [product?.name.en, openAmenitiesPage, pushMatomoEvent]);

  if (
    !product ||
    !product.accommodationMetadata?.amenities ||
    product.accommodationMetadata.amenities.length < 1
  ) {
    return null;
  }
  return (
    <>
      <LinkButton
        justifyContent="flex-start"
        onClick={() => setOpenAmenitiesPage(true)}
      >
        Show amenities <Icon Element={NavArrowRight} />
      </LinkButton>
      <AmenitiesPage
        amenities={product.accommodationMetadata.amenities}
        open={openAmenitiesPage}
        onClose={() => setOpenAmenitiesPage(false)}
      />
    </>
  );
};
