import { Textarea } from "@ttc3k/trekker";
import { ComponentProps } from "react";
import { useController, useFormContext } from "react-hook-form";

type ControlledTextareaProps = ComponentProps<typeof Textarea> & { name: string };

export const ControlledTextarea = ({
  name,
  hint,
  ...rest
}: ControlledTextareaProps) => {
  const { register, control } = useFormContext();
  const { fieldState } = useController({ name, control });

  const props = {
    ...rest,
    bg: "white",
    ...register(name),
    hint: fieldState.error?.message ?? hint,
    ...(fieldState.error ? { "data-invalid": true, "aria-invalid": true } : {}),
  };

  return <Textarea {...props} />;
};
