import {
  Sizes,
  LegacyButton,
  ButtonTagVariant,
  LegacyTypography,
  TypographyVariant,
  LegacyLoadingSpinner,
} from "@ttc3k/ttc-design-system";

type NoteProps = {
  text: string;
  by: string;
  date: string;
  onClick?: () => void;
  loading?: boolean;
};

export const Note = (props: NoteProps) => {
  const { text, by, date, onClick, loading } = props;

  const handleClick = () => {
    onClick?.();
  };

  return (
    <div className="tw-bg-[#FFF0A1] tw-shadow-[4px_4px_0_0_#CCBF7E] tw-p-space-300 tw-w-full tw-space-y-space-300">
      <LegacyTypography>{text}</LegacyTypography>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-flex-col tw-opacity-50">
          <a href={`mailto:${by}`}>
            <LegacyTypography variant={TypographyVariant.secondaryBold}>
              {by}
            </LegacyTypography>
          </a>
          <LegacyTypography variant={TypographyVariant.secondaryMedium}>
            {date}
          </LegacyTypography>
        </div>
        <LegacyButton
          size={Sizes.Small}
          variant={ButtonTagVariant.Secondary}
          className="tw-rounded-full tw-bg-white tw-border-gray-200 tw-border-[1px] tw-px-2 tw-py-1 tw-flex tw-justify-center tw-items-center"
          onClick={handleClick}
        >
          {loading ? <LegacyLoadingSpinner size={14} /> : "Resolve"}
        </LegacyButton>
      </div>
    </div>
  );
};
