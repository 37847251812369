import { BookerLeafHeader } from "core/components/shared/BookerLeafHeader";
import { Flex } from "styled-system/jsx";
import {
  CreateAccomForm,
  UpdateAccomDetailsForm,
} from "./components";

type AccommodationDetailsLeafProps = {
  isUpdating: boolean;
  productID: string;
};

export const AccommodationDetailsLeaf = ({
  isUpdating,
  productID,
}: AccommodationDetailsLeafProps) => {
  return (
    <Flex flexDir={"column"} gap={"300"} width={"full"}>
      <BookerLeafHeader
        title={"Product Info"}
        description={
          "Start by capturing the key information for your product here."
        }
      />
      {!isUpdating ? (
        <CreateAccomForm />
      ) : (
        <UpdateAccomDetailsForm productID={productID} />
      )}
    </Flex>
  );
};
