import { ProductToggleCard } from "booker/shared";
import { useCartContext } from "booker/contexts";
import { formatPrice } from "core/utils/formatPrice";
import {
  MatomoEvent,
  useMatomoAnalyticsContext,
} from "@ttc3k/ttc-bkr-analytics";
import { Product } from "gql/generated";

type AddOnItemProps = {
  addOn: Product | undefined;
};

export const AddOnItem = ({ addOn }: AddOnItemProps) => {
  const { cart, addCartItem, removeCartItem } = useCartContext();
  const { pushMatomoEvent } = useMatomoAnalyticsContext();

  if (!addOn) {
    return null;
  }

  const isSelected = !!cart.find(item => item.productID === addOn._id);

  const handleToggleAddOn = (checked: boolean) => {
    if (checked) {
      addCartItem({
        productID: addOn._id,
        productName: addOn.name.en ?? "",
        productType: addOn.type,
        endDateISO: "",
        startDateISO: "",
        guestsPerTier: [],
        total: addOn.pricing.tiers[0]?.price ?? 0,
      });
      pushMatomoEvent({
        event: MatomoEvent.AddOnAdded,
        productName: addOn.name.en ?? "Unknown product name",
      });
    } else {
      removeCartItem(addOn._id);
      pushMatomoEvent({
        event: MatomoEvent.AddOnRemoved,
        productName: addOn.name.en ?? "Unknown product name",
      });
    }
  };
  return (
    <ProductToggleCard
      key={addOn._id}
      mainText={addOn.name.en ?? ""}
      subText={`$${formatPrice(addOn.pricing.tiers[0]?.price ?? 0)}`}
      checked={isSelected}
      onCheckedChange={v => handleToggleAddOn(v)}
    />
  );
};
