import { ProgressNavigation } from "@ttc3k/trekker";
import { ComponentProps, PropsWithChildren } from "react";
import { Box, Flex } from "styled-system/jsx";

type BookerLeafNavLayoutProps = PropsWithChildren &
  ComponentProps<typeof ProgressNavigation>;

export const BookerLeafNavLayout = ({
  children,
  ...rest
}: BookerLeafNavLayoutProps) => {
  return (
    <Box>
      <ProgressNavigation
        px={{ base: "400", lg: "1000" }}
        py={"250"}
        borderBottomColor={"border.light"}
        borderBottomWidth={"1px"}
        overflowX={"auto"}
        {...rest}
      />
      <Flex
        px={{ base: "400", lg: "1000" }}
        pt={"3.75rem"}
        pb={"7.5rem"}
        gap={"600"}
        justifyContent={"space-between"}
        flexDir={{ base: "column-reverse", lg: "row" }}
        position={"relative"}
      >
        {children}
      </Flex>
    </Box>
  );
};
