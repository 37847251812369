const radioOptions = [
  {
    value: "none",
    helperText: "Customer will not be refunded",
    label: "Issue no refund",
  },
  {
    value: "full",
    helperText: "Customer will be refunded",
    label: "Issue full refund",
  },
  {
    value: "partial",
    helperText: "Partial refund amount",
    label: "Issue partial refund",
  },
];

export { radioOptions };
