import { WhereaboutsModal } from "core/components/shared/WhereaboutsModal";
import { PlusCircle } from "iconoir-react";
import { toastFactory, Text } from "@ttc3k/trekker";
import { FormProvider } from "react-hook-form";
import { useState } from "react";
import { Box, Flex } from "styled-system/jsx";
import { ControlledInput } from "apps/custom/ttc/booker/shared";
import {
  ProductManyDocument,
  ProductManyQuery,
  useProductUpdateMutation,
} from "gql/generated";
import { handleAsyncError } from "core/utils/alert";
import { ControlledPriceInput } from "core/components/shared/ControlledPriceInput";
import { useCreateEditFeeForm } from "./useCreateEditFeeForm";

type CreateEditUpsellModalProps = {
  open: boolean;
  onClose: () => void;
  operatorID: string;
  product?: ProductManyQuery["productMany"][0];
  onSuccess?: (productID: string) => void;
};

export const CreateEditUpsellModal = ({
  open,
  onClose,
  operatorID,
  product,
  onSuccess,
}: CreateEditUpsellModalProps) => {
  const isEditing = !!product?._id

  const [formError, setFormError] = useState("");
  const { form, defaultValues, onSubmit } = useCreateEditFeeForm({
    onSuccess: data => {
      form.reset(defaultValues);
      setFormError("");
      onClose();
      toastFactory.create({
        title: "Success!",
        description: `${data?.name} upsell was ${
          isEditing ? "updated" : "created"
        } successfully`,
      });
      onSuccess?.(data.productID);
    },
    onError: m => setFormError(m),
    operatorID,
    isUpsell: true,
    product: product,
  });
  const [updateProduct] = useProductUpdateMutation();

  const handleDeleteUpsell = async () => {
    if (product) {
      try {
        await updateProduct({
          variables: {
            id: product._id,
            record: {
              isActive: false,
            },
          },
          refetchQueries: [ProductManyDocument],
        }).then(res => {
          onClose();
          toastFactory.create({
            title: "Success!",
            description: `${
              res?.data?.productUpdate?.record?.name.en ?? ""
            } upsell was removed successfully`,
          });
        });
      } catch (e) {
        handleAsyncError();
      }
    }
  };

  const formID = "whereabouts-create-edit-upsell-form";

  return (
    <WhereaboutsModal
      isOpen={open}
      onClose={onClose}
      cancelButtonProps={{
        visual: isEditing ? "destructive" : "outline",
        onClick: isEditing ? handleDeleteUpsell : onClose,
      }}
      cancelButtonCopy={isEditing ? "Delete" : "Cancel"}
      saveButtonProps={{
        type: "submit",
        form: formID,
        disabled: !form.formState.isDirty,
      }}
      HeaderIcon={PlusCircle}
      title={isEditing ? "Edit Upsell" : "Create Upsell"}
      description="An upsell is a basic, fee-based product that can be added to any accommodation or event product as an add-on."
    >
      <FormProvider {...form}>
        <form onSubmit={onSubmit} id={formID}>
          <Flex flexDir={"column"} gap={"400"}>
            {!isEditing && (
              <Box py={"200"} px={"300"} bg={"bg.wa.dark"} borderRadius={"100"}>
                <Text color={"text.dark"}>
                  Keep in mind that upsells are simple! They are charged once
                  per booking, and have no related inventory.{" "}
                </Text>
              </Box>
            )}
            <Flex gap={"200"} width={"full"}>
              <ControlledInput name={"name"} label={"Name"} width={"full"} />
              <ControlledPriceInput name={"price"} label={"Price"} />
            </Flex>
          </Flex>
          {formError.length > 0 && (
            <Text color={"text.error.mid"} visual={"smallMedium"}>
              {formError}
            </Text>
          )}
        </form>
      </FormProvider>
    </WhereaboutsModal>
  );
};
