import {
  Button,
  Input,
  LinkButton,
  Select,
  Text,
  toastFactory,
} from "@ttc3k/trekker";
import { RefreshDouble, Trash } from "iconoir-react";
import { useFormContext, useWatch } from "react-hook-form";
import { Box, Flex } from "styled-system/jsx";
import { useIntegrateProductCalendarLinksByProductIdsMutation } from "gql/generated";
import { SettingsFormValues } from "../hooks";
import { transformCalendarLinksByProduct } from "../utils";

type IntegrateExternalCalendarProps = {
  productOptions: {
    value: string;
    label: string;
  }[];
};

export const IntegrateExternalCalendar = ({
  productOptions,
}: IntegrateExternalCalendarProps) => {
  const { control, setValue, formState, trigger } =
    useFormContext<SettingsFormValues>();

  const watchedCalendarLinksByProduct = useWatch({
    control,
    name: "calendarLinksByProduct",
  });

  const [integrateExternalProductCalendarLinks] =
    useIntegrateProductCalendarLinksByProductIdsMutation();

  const errors = formState.errors["calendarLinksByProduct"];

  const handleManualSync = async () => {
    const updatedProductCalendarLinks = transformCalendarLinksByProduct(
      watchedCalendarLinksByProduct,
    );

    try {
      await integrateExternalProductCalendarLinks({
        variables: { calendarLinksByProductIDs: updatedProductCalendarLinks },
      });

      toastFactory.create({
        title: "Success!",
        description:
          "Your product(s) have synced successfully with your external calendar link(s)",
      });
    } catch (e) {
      trigger("calendarLinksByProduct");
      toastFactory.create({
        title: "Error",
        description:
          "Could not sync all of the calendar links - check if all the links are valid .ics links",
      });
    }
  };

  const handleIntegrateCalendarLinkUpdate = (
    value: string,
    index: number,
    field: "_id" | "link",
  ) => {
    const newCalendarLinks = [...watchedCalendarLinksByProduct];
    newCalendarLinks[index][field] = value;
    setValue("calendarLinksByProduct", newCalendarLinks);
  };

  const handleRemoveCalendarLink = (index: number) => {
    const newCalendarLinks = [...watchedCalendarLinksByProduct];
    newCalendarLinks.splice(index, 1);
    setValue("calendarLinksByProduct", newCalendarLinks);
  };

  const handleNewCalendarLinkClick = () => {
    setValue("calendarLinksByProduct", [
      ...watchedCalendarLinksByProduct,
      { _id: "", link: "" },
    ]);
  };
  return (
    <>
      <Box>
        <Text visual={"bodySemiBold"} color={"text.dark"}>
          Integrate Your External Calendar With Booker
        </Text>
        <Text color={"text.mid"} mt={"100"}>
          Copy and paste your calendar’s .ics URL (from Google, Airbnb, etc.)
          here to sync your bookings from elsewhere into Booker.
        </Text>
      </Box>
      {watchedCalendarLinksByProduct.map((v, i) => {
        return (
          <Flex
            key={i}
            gap={"150"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Select.Root
              items={productOptions}
              value={[v._id]}
              placeholder={"Select product"}
              maxW={"240px"}
              css={{
                "& [data-scope='select'][data-part='trigger']": {
                  bg: "white",
                },
              }}
              onValueChange={({ value }: { value: string[] }) =>
                handleIntegrateCalendarLinkUpdate(value[0], i, "_id")
              }
            >
              {productOptions.map(op => (
                <Select.Item key={op.value} item={op} />
              ))}
            </Select.Root>
            <Input
              value={v.link}
              bg={"white"}
              onChange={e =>
                handleIntegrateCalendarLinkUpdate(
                  e.currentTarget.value,
                  i,
                  "link",
                )
              }
            />
            <Button
              Icon={Trash}
              visual={"outline"}
              type={"button"}
              disabled={i === 0}
              onClick={() => handleRemoveCalendarLink(i)}
            />
          </Flex>
        );
      })}
      <LinkButton
        width={"max-content"}
        onClick={handleNewCalendarLinkClick}
        type={"button"}
      >
        + Add external calendar link
      </LinkButton>

      {errors && errors.length && errors.length > 0 && (
        <Text visual={"smallSemiBold"} color={"text.error.mid"}>
          One of these links is not a valid .ics link. Please check them and
          retry
        </Text>
      )}

      <Button
        Icon={RefreshDouble}
        width={"max-content"}
        visual={"outline"}
        size={"lg"}
        type={"button"}
        onClick={handleManualSync}
      >
        Sync calendars
      </Button>
    </>
  );
};
