import { Accordion, Link, SectionHeader, Text } from "@ttc3k/trekker";
import { WhereaboutsModal } from "core/components/shared/WhereaboutsModal";
import { HelpCircle, RefreshDouble } from "iconoir-react";
import { Flex, styled } from "styled-system/jsx";

type CalendarIntegrationHelpModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CalendarIntegrationHelpModal = ({
  isOpen,
  onClose,
}: CalendarIntegrationHelpModalProps) => {
  return (
    <WhereaboutsModal
      isOpen={isOpen}
      onClose={onClose}
      title={"Calendar Integration Help Docs"}
      HeaderIcon={HelpCircle}
      cancelButtonProps={{ display: "none" }}
      saveButtonCopy="Close"
      saveButtonProps={{ onClick: onClose }}
    >
      <Flex flexDir={"column"} gap={"200"}>
        <SectionHeader
          text={"Sync the Booker calendar with an external calendar"}
          Icon={RefreshDouble}
          divider
        />
        <Accordion.Root collapsible borderTop={0} borderBottom={0}>
          <Accordion.Item value={"airbnb"} title={"Sync Booker with Airbnb"}>
            To learn how to import your Booker calendar links into your Airbnb
            calendars, follow&nbsp;
            <Link
              href={"https://www.airbnb.ca/help/article/99#section-heading-5-0"}
              target={"_blank"}
            >
              the instructions here
            </Link>
          </Accordion.Item>
          <Accordion.Item value={"google"} title={"Sync with Google Calendar"}>
            <styled.ol listStyle={"inside"} listStyleType={"decimal"}>
              <li>
                From Google Calendar, click the '+' next to the 'Other
                Calendars' field.
              </li>
              <li>Select 'From URL' in the menu.</li>
              <li>
                Paste the subscribe to booker calendar URL and click 'Add
                Calendar'.
              </li>
            </styled.ol>
          </Accordion.Item>
          <Accordion.Item value={"apple"} title={"Sync with Apple Calendar"}>
            <styled.ol listStyle={"inside"} listStyleType={"decimal"}>
              <li>Choose File &gt; New Calendar Subscription.</li>
              <li>
                Paste the subscribe to booker calendar URL and click Subscribe.
              </li>
              <li>
                In the next info dialog box, in the Auto-refresh dropdown menu,
                select Every Hour in order to keep the calendar up-to-date.
              </li>
            </styled.ol>
          </Accordion.Item>
          <Accordion.Item
            value={"microsoft"}
            title={"Sync with Microsoft Outlook"}
          >
            <Flex flexDir={"column"} gap={"100"}>
              <Text visual={"smallSemiBold"} color={"text.dark"}>
                Web Application
              </Text>
              <styled.ol listStyle={"inside"} listStyleType={"decimal"}>
                <li>
                  Open Outlook & click on the Calendar Tab on the bottom left
                  corner of the screen.
                </li>
                <li>Click Add Calendar.</li>
                <li>
                  Paste the subscribe to booker calendar URL in the field and
                  click Import.
                </li>
              </styled.ol>

              <Text visual={"smallSemiBold"} color={"text.dark"}>
                Desktop Application
              </Text>
              <styled.ol listStyle={"inside"} listStyleType={"decimal"}>
                <li>
                  Open Outlook & click on the Calendar Tab on the bottom left
                  corner of the screen.
                </li>
                <li>
                  Right-click on My Calendars (or any other calendar group's
                  heading) on the left-hand side of the screen to display the
                  options for that group.
                </li>
                <li>
                  Hover over Add Calendar and select From Internet in the
                  sub-menu.
                </li>
                <li>
                  Paste the subscribe to booker calendar URL in the "New
                  Internet Calendar Subscription" window that appears and click
                  OK. Click Yes to subscribe to this internet calendar and add
                  it to your list of calendars.
                </li>
                <li>
                  Click Yes to subscribe to this internet calendar and add it to
                  your list of calendars.
                </li>
              </styled.ol>
            </Flex>
          </Accordion.Item>
        </Accordion.Root>

        <SectionHeader
          text={"Sync an external calendar with Booker"}
          Icon={RefreshDouble}
          divider
        />
        <Accordion.Root collapsible borderTop={0} borderBottom={0}>
          <Accordion.Item
            value={"airbnb"}
            title={"How to export your Airbnb product link"}
          >
            To learn how to export your Airbnb calendar per product,
            follow&nbsp;
            <Link
              href={"https://www.airbnb.ca/help/article/99#section-heading-1-0"}
              target={"_blank"}
            >
              the instructions here
            </Link>
          </Accordion.Item>
          <Accordion.Item
            value={"google"}
            title={"How to export your Google calendar link"}
          >
            <styled.ol listStyle={"inside"} listStyleType={"decimal"}>
              <li>
                Login to your google account. In the top right, click Settings
                gear and then Settings.
              </li>
              <li>
                On the left panel, under “Settings for my calendars,” click the
                name of the calendar you want to use.
              </li>
              <li>Click "Access permissions for events".</li>
              <li>
                Check the box to "Make available to the public" & ensure "See
                all event details" is the selected option
              </li>
              <li>Scroll further down to "Integrate calendar".</li>
              <li>
                Find the link under the "Public address in iCal format" &gt;
                this link should end in ".ics".
              </li>
              <li>
                Copy this link & paste it under the "Integrate Your Google
                Calendar With Booker" section &gt; click "Sync"
              </li>
            </styled.ol>
          </Accordion.Item>
        </Accordion.Root>
      </Flex>
    </WhereaboutsModal>
  );
};
