import { Flag, Input, Text, toastFactory } from "@ttc3k/trekker";
import { WhereaboutsModal } from "core/components/shared/WhereaboutsModal";
import { useProductByIdQuery, useProductUpdateMutation } from "gql/generated";
import { InfoCircle, Trash } from "iconoir-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex } from "styled-system/jsx";

type DeleteProductModalProps = {
  isOpen: boolean;
  onClose: () => void;
  productID: string;
};

export const DeleteProductModal = ({
  isOpen,
  onClose,
  productID,
}: DeleteProductModalProps) => {
  const [confirmationName, setConfirmationName] = useState("");
  const { data } = useProductByIdQuery({ variables: { id: productID } });
  const [updateProduct, { loading }] = useProductUpdateMutation();
  const navigate = useNavigate();

  const productName =
    data && data.productById && data.productById.name.en
      ? data.productById.name.en
      : "Product";

  const handleClose = () => {
    onClose();
    setConfirmationName("");
  };

  const handleDeleteProduct = async () => {
    try {
      await updateProduct({
        variables: { id: productID, record: { isActive: false } },
      });
      handleClose();
      navigate(`/app/booker/${data?.productById?.operator}/products`);
      toastFactory.create({
        title: "Success!",
        description: `${productName} was deleted successfully`,
      });
    } catch {
      handleClose();
      toastFactory.create({
        title: "Error",
        description: `There has been an error trying to delete ${productName}. Please try again`,
      });
    }
  };
  return (
    <WhereaboutsModal
      isOpen={isOpen}
      onClose={handleClose}
      HeaderIcon={Trash}
      title={`Delete ${productName}`}
      cancelButtonProps={{ onClick: handleClose }}
      saveButtonCopy="Delete product"
      saveButtonProps={{
        visual: "destructive",
        isLoading: loading,
        onClick: handleDeleteProduct,
        disabled: confirmationName !== productName,
      }}
    >
      <Flex flexDir={"column"} gap={"400"}>
        <Flag
          Icon={InfoCircle}
          mainText={"Important"}
          subText={
            "Deleting this product will de-activate and remove it from your account. Its reservation and order history will be preserved, however."
          }
          visual={"highlight"}
          hideCloseTrigger
        />
        <Flex flexDir={"column"} gap={"250"}>
          <Text as={"label"} for={"productName"} color={"text.dark"}>
            Please confirm the deletion of this product by typing in its full
            name in the field below.
          </Text>
          <Input
            name={"productName"}
            value={confirmationName}
            placeholder={productName}
            onChange={e => setConfirmationName(e.currentTarget.value)}
            bg={"white"}
          />
        </Flex>
      </Flex>
    </WhereaboutsModal>
  );
};
