import { Calendar } from "@ttc3k/trekker";
import { add, endOfYear } from "date-fns";
import { useBookerContext } from "booker/contexts";
import { Box } from "styled-system/jsx";
import { useInventoryByProductBookedDatesQuery } from "gql/generated";
import { DateRange } from "../../Steps/components/DatesAndGuests/useDatesAndGuests";
import { ConsumingOverlayPageProps, OverlayPage } from "../OverlayPage";
import { convertDateRangeToCalendarValue } from "./helpers";

type DatesPageProps = ConsumingOverlayPageProps & {
  selectedDates: DateRange;
  onUpdateDates: (dateRange: Partial<DateRange>) => void;
  completeDates: () => void;
  resetDates: () => void;
};

export const DatesPage = ({
  selectedDates,
  onUpdateDates,
  completeDates,
  resetDates,
  onClose,
  ...rest
}: DatesPageProps) => {
  const { product, loading: contextLoading } = useBookerContext();

  if (!product && !contextLoading) {
    throw new Error("Error loading accommodation calendar");
  }

  const { data, error, loading } = useInventoryByProductBookedDatesQuery({
    variables: { productId: product?._id ?? "" },
  });

  if ((!data && !loading) || error) {
    throw new Error("Error loading inventory booked dates");
  }

  const isDatesPageValid = Object.values(selectedDates).every(
    v => v.length > 0,
  );

  const handleButtonClick = () => {
    completeDates();
    onClose();
  };

  const handleBackClick = () => {
    resetDates();
    onClose();
  };

  const handleValueChange = (dates: string[]) => {
    if (dates.length > 1) {
      onUpdateDates({ startDateISO: dates[0], endDateISO: dates[1] });
    } else if (dates.length === 1) {
      onUpdateDates({ startDateISO: dates[0], endDateISO: "" });
    }
  };

  const handleClearDates = () => {
    onUpdateDates({ startDateISO: "", endDateISO: "" });
  };

  return (
    <OverlayPage
      title={"Travel Dates"}
      subtitle={"Select your check in and check out dates to continue."}
      onButtonClick={handleButtonClick}
      onClose={handleBackClick}
      buttonDisabled={!isDatesPageValid}
      {...rest}
    >
      <Box width={"full"} height={{ base: "420px", md: "382px" }} mt={"400"}>
        <Calendar
          unavailableDatesISO={data?.inventoryDatesByProduct?.bookedDatesISO}
          checkOutOnlyDatesISO={
            data?.inventoryDatesByProduct?.checkOutOnlyDatesISO
          }
          lastCalendarDateISO={endOfYear(
            add(new Date(), { years: 3 }),
          ).toISOString()}
          minNights={product?.accommodationMetadata?.minimumNights}
          maxNights={product?.accommodationMetadata?.maximumNights}
          onValueChange={d => handleValueChange(d.valueAsString)}
          value={convertDateRangeToCalendarValue(selectedDates)}
          onClearDatesClick={handleClearDates}
          displayJumpToToday
        />
      </Box>
    </OverlayPage>
  );
};
