import { InfoTemplate } from "./templates/InfoTemplate.tsx";
import { VideoTemplate } from "./templates/VideoTemplate.tsx";
import { AdditionalInfoTemplate } from "./templates/AdditionalInfoTemplate.tsx";
import Onboarding404 from "./Onboarding404.tsx";
import { HelpTemplate } from "./templates/HelpTemplate.tsx";
import { StartClaimTemplate } from "./templates/StartClaimTemplate.tsx";
import { SuccessTemplate } from "./templates/SuccessTemplate.tsx";
import { ApproveTemplate } from "./templates/ApproveTemplate.tsx";
import { ChoiceTemplate } from "./templates/ChoiceTemplate.tsx";

export default function TemplateLoader({ type, baseURL, next, stepData }) {
  switch (type) {
    case "INFO":
      return <InfoTemplate next={next} baseURL={baseURL} stepData={stepData} />;
    case "VIDEO":
      return (
        <VideoTemplate next={next} baseURL={baseURL} stepData={stepData} />
      );
    case "ADDITIONAL_DATA":
      return (
        <AdditionalInfoTemplate
          next={next}
          baseURL={baseURL}
          stepData={stepData}
        />
      );
    case "CHOICE":
      return (
        <ChoiceTemplate baseURL={baseURL} stepData={stepData} next={next} />
      );
    case "CLAIM":
      return (
        <StartClaimTemplate baseURL={baseURL} stepData={stepData} next={next} />
      );
    case "APPROVE":
      return (
        <ApproveTemplate baseURL={baseURL} stepData={stepData} next={next} />
      );
    case "SUCCESS":
      return (
        <SuccessTemplate baseURL={baseURL} stepData={stepData} next={next} />
      );
    case "HELP":
      return <HelpTemplate next={next} baseURL={baseURL} stepData={stepData} />;
    default:
      return <Onboarding404 />;
  }
}
