import { isEqual } from "lodash";
import { DependencyList, EffectCallback, useEffect, useRef } from "react";

/**
 * Same as useEffect but does a deep comparison
 */
export function useDeepEffect(
  func: EffectCallback,
  dependencies: DependencyList,
) {
  const dependenciesRef = useRef(dependencies);

  useEffect(() => {
    if (!isEqual(dependenciesRef.current, dependencies)) {
      // Execute the function
      func();

      // Update the dependencies
      dependenciesRef.current = dependencies;
    }
  }, [func, dependencies]);
}
