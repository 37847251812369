import { ProductManyQuery } from "gql/generated";
import _ from "lodash";
import {
  getUniqueOptionsWithCount,
  OptionWithCount,
} from "./getUniqueOptionsWithCount";

export const getProductOptions = (
  products: ProductManyQuery["productMany"],
): OptionWithCount[] => {
  const formattedArray = products.map(prod => {
    if (prod.isUpsellOnly) {
      return { value: "UPSELL", label: "Upsells" };
    }
    return {
      value: prod.type,
      label: _.capitalize(prod.type) + "s",
    };
  });
  return getUniqueOptionsWithCount(formattedArray);
};
