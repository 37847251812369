import { gql, useQuery } from "@apollo/client";
import React, { createContext, useContext, useEffect, useState } from "react";
import { SiteSettings } from "./apps/library/directory/api/typeDefs.ts";
import { useAuth0 } from "@auth0/auth0-react";

type Action = { type: "increment" } | { type: "decrement" };
type Dispatch = (action: Action) => void;
type State = { count: number };
const SiteSettingsContext = React.createContext();

const GET_SITE_SETTINGS = gql`
  query GetSiteSettings {
    getSiteSettings {
      maintenanceMode {
        banner {
          isLive
          message
        }
        doorstop {
          isLive
          message
        }
      }
    }
  }
`;

function SiteSettingsProvider({ children }: { children: JSX.Element }) {
  const { data, loading } = useQuery<SiteSettings>(GET_SITE_SETTINGS);
  const { user } = useAuth0();
  if (loading) {
    return;
  }

  const allowedDomains = [
    "thenew.business",
    "thenewbusiness.ca",
    "tourism.tech",
  ];

  const siteSettings =
    allowedDomains.some(el => user?.email.includes(el)) &&
    data?.getSiteSettings.maintenanceMode.doorstop.isLive == true
      ? {
          maintenanceMode: {
            banner: {
              message: "This site is in maintenance mode.",
              isLive: true,
            },
            doorstop: {
              message: "",
              isLive: false,
            },
          },
        }
      : data?.getSiteSettings;

  return (
    <SiteSettingsContext.Provider value={siteSettings}>
      {children}
    </SiteSettingsContext.Provider>
  );
}

function useSiteSettings() {
  const context = React.useContext(SiteSettingsContext);
  if (context === undefined) {
    throw new Error(
      "useSiteSettings must be used within a SiteSettingsProvider",
    );
  }
  return context;
}

export { SiteSettingsProvider, useSiteSettings };
