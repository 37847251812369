import {  Order } from "gql/generated";
import { compareAsc, isSameDay, isWithinInterval } from "date-fns";
import { parseToUTCDate } from "core/utils/parseToUTCDate";
import { OrderManyItems } from "core/types";

type filterOrdersByDateProps = {
  orders: OrderManyItems;
  selectedDate: string;
};

export const getMainProductDateRangeISO = (order: OrderManyItems[0]) => {
  const mainProduct = order.purchasedProducts[0];
  const mainProductInventoryClaim = mainProduct?.inventoryClaims[0];
  return mainProductInventoryClaim?.claimedDateRangeISO;
};

export const filterOrdersByDate = ({
  selectedDate,
  orders,
}: filterOrdersByDateProps): Order[] => {
  if (selectedDate && selectedDate.length > 0) {
    // Filter the orders by the main product's start & end date range
    // Sort them in ascending order by the start date
    const filteredOrders = orders
      .filter(order => {
        const mainProductDateRangeISO = getMainProductDateRangeISO(order);

        if (
          mainProductDateRangeISO &&
          mainProductDateRangeISO.start.length > 0 &&
          mainProductDateRangeISO.end.length > 0 &&
          selectedDate &&
          !isNaN(parseToUTCDate(mainProductDateRangeISO.start).getTime()) &&
          !isNaN(parseToUTCDate(mainProductDateRangeISO.end).getTime())
        ) {
          return (
            isWithinInterval(parseToUTCDate(selectedDate), {
              start: parseToUTCDate(mainProductDateRangeISO.start),
              end: parseToUTCDate(mainProductDateRangeISO.end),
            }) ||
            isSameDay(
              parseToUTCDate(selectedDate),
              parseToUTCDate(mainProductDateRangeISO.start),
            )
          );
        } else return false;
      })
      .sort((a, b) => {
        const aMainProductDateRangeISO = getMainProductDateRangeISO(a);
        const bMainProductDateRangeISO = getMainProductDateRangeISO(b);

        const aDate = aMainProductDateRangeISO?.start
          ? parseToUTCDate(aMainProductDateRangeISO.start)
          : new Date(0);
        const bDate = bMainProductDateRangeISO?.start
          ? parseToUTCDate(bMainProductDateRangeISO.start)
          : new Date(0);

        return compareAsc(aDate, bDate);
      });

    return filteredOrders as Order[];
  } else return [];
};
