import _ from "lodash";
import { eachDayOfInterval } from "date-fns";
import { parseToUTCDate } from "core/utils/parseToUTCDate";
import { OrderManyItems } from "core/types";
import { getMainProductDateRangeISO } from "./filterOrdersByDate";

type getBookedDatesByOrdersProps = {
  orders: OrderManyItems;
};

function getBookedDatesByOrders({ orders }: getBookedDatesByOrdersProps) {
  const allBookedDates = orders.reduce((acc, currentOrder) => {
    const mainProductDateRangeISO = getMainProductDateRangeISO(currentOrder);

    const orderDates = mainProductDateRangeISO
      ? eachDayOfInterval({
          start: parseToUTCDate(mainProductDateRangeISO.start),
          end: parseToUTCDate(mainProductDateRangeISO.end),
        }).map(d => d.toISOString())
      : [];

    return [...acc, ...orderDates];
  }, [] as string[]);

  return _.sortBy(_.uniq(allBookedDates));
}

export { getBookedDatesByOrders };
