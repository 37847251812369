import { useEffect, useState } from "react";
import { Blurhash } from "react-blurhash";

interface ImageLazyLoadProps {
  src: string | undefined;
  alt: string;
  blurhash: string | undefined;
}

export const ImageLazyLoad = ({ src, alt, blurhash }: ImageLazyLoadProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    if (src) {
      img.src = src;
    }
  }, [src]);

  return (
    <>
      <div
        style={{
          display: imageLoaded ? "none" : "inline",
        }}
        className={
          "tw-aspect-square tw-relative tw-overflow-hidden tw-w-full tw-h-full tw-rounded-[8px]"
        }
      >
        {blurhash && blurhash.length >= 6 && (
          <Blurhash
            hash={blurhash}
            width={"100%"}
            height={"100%"}
            resolutionX={32}
            resolutionY={32}
            punch={1}
          />
        )}
      </div>
      <img
        src={src}
        alt={alt}
        style={{ display: !imageLoaded ? "none" : "inline" }}
        className={
          "tw-aspect-square tw-object-cover hover:tw-object-contain hover:tw-bg-[#333333] tw-w-full tw-rounded-[8px] tw-flex-grow"
        }
        loading={"lazy"}
      />
    </>
  );
};
