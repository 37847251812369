import {
  tokens,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";

type HelperTextProps = {
  text: string;
  error?: boolean;
  className?: string;
};

export const HelperText = ({ text, error, className }: HelperTextProps) => {
  return (
    <LegacyTypography
      variant={TypographyVariant.secondaryRegular}
      color={error ? tokens.semantic.textError : tokens.semantic.textSubtle}
      className={className ?? ""}
    >
      {text}
    </LegacyTypography>
  );
};
