import { OTPInput } from "input-otp";
import {
  LegacyTypography,
  TypographyVariant,
  tokens,
} from "@ttc3k/ttc-design-system";
import { useId } from "react";

export type OtpInputProps = {
  value: string;
  onChange?: (val: string) => void;
};

export const OtpInput = (props: OtpInputProps) => {
  const id = useId();
  const { value, onChange } = props;
  return (
    <div>
      <OTPInput
        autoFocus
        value={value}
        onChange={val => onChange?.(val)}
        maxLength={6}
        containerClassName="group tw-flex tw-justify-evenly tw-items-center"
        render={({ slots }) =>
          slots.map((slot, i) => (
            <div
              key={id + "_" + i}
              className="tw-w-[56px] tw-h-[80px] tw-bg-white tw-border-[1px] tw-border-[#D4D4D8] tw-rounded-lg tw-flex tw-justify-center tw-items-center tw-uppercase"
            >
              <LegacyTypography
                variant={TypographyVariant.h2}
                color={tokens.semantic.colorText}
              >
                {slot.char}
              </LegacyTypography>
            </div>
          ))
        }
      />
    </div>
  );
};
