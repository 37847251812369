import {
  MatomoEvent,
  useMatomoAnalyticsContext,
} from "@ttc3k/ttc-bkr-analytics";
import { useBookerContext, useCartContext } from "booker/contexts";
import { getDatesSummaryString, getTotalGuestCount } from "booker/shared/utils";
import { PurchasedProductGuestsPerTier } from "gql/generated";
import { useEffect, useState } from "react";

export type DateRange = {
  startDateISO: string;
  endDateISO: string;
};

export const useDatesAndGuests = (isGuestPageOpen: boolean) => {
  const { pushMatomoEvent } = useMatomoAnalyticsContext();
  const { product } = useBookerContext();
  const [dates, setDates] = useState<DateRange>({
    startDateISO: "",
    endDateISO: "",
  });
  const [guests, setGuests] = useState<PurchasedProductGuestsPerTier[]>([]);
  const [guestNames, setGuestNames] = useState<string[]>([""]);
  const [complete, setComplete] = useState({
    dates: false,
    guests: false,
    guestNames: product?.accommodationMetadata?.guestMetadata.nameEntryRequired
      ? false
      : true,
  });
  const {
    updateCartItem,
    cart,
    updateGuestNames,
    guestNames: cartGuestNames,
  } = useCartContext();

  function handleUpdateDates(date: Partial<DateRange>) {
    setDates(prev => ({ ...prev, ...date }));

    pushMatomoEvent({
      event: MatomoEvent.DatesSelected,
      datesSelected: getDatesSummaryString({...dates, ...date}),
    });
  }

  function completeDates() {
    if (product) {
      updateCartItem(product._id, dates);
      setComplete(prev => ({ ...prev, dates: true }));
    }
  }

  function resetDates() {
    const cartItem =
      product && cart.find(item => item.productID === product?._id);
    if (cartItem) {
      setDates({
        startDateISO: cartItem.startDateISO,
        endDateISO: cartItem.endDateISO,
      });
    }
  }

  function handleUpdateGuests(g: PurchasedProductGuestsPerTier) {
    const newGuestTiers = guests.map(tier => {
      if (tier.tierId === g.tierId) {
        return g;
      } else return tier;
    });

    setGuests(newGuestTiers);

    pushMatomoEvent({
      event: MatomoEvent.GuestsAdded,
      guestType: g.tierId,
      guestCt: g.count,
    });

    const newGuestCount = getTotalGuestCount(newGuestTiers);

    if (newGuestCount > guestNames.length) {
      const addition: string[] = new Array(
        newGuestCount - guestNames.length,
      ).fill("");
      const newGuestNames = [...guestNames, ...addition];
      setGuestNames(newGuestNames);
    } else if (newGuestCount < guestNames.length) {
      const newGuestNames = guestNames.filter((_, i) => i <= newGuestCount - 1);
      setGuestNames(newGuestNames);
    }
  }

  function completeGuests() {
    if (product) {
      updateCartItem(product._id, {
        guestsPerTier: guests,
      });
      updateGuestNames(guestNames);
      setComplete(prev => ({ ...prev, guests: true, guestNames: true }));
    }
  }

  function handleUpdateGuestNames(name: string, index: number) {
    const newGuestNames = [...guestNames];
    newGuestNames[index] = name;

    setGuestNames(newGuestNames);
  }

  function resetGuests() {
    const cartItem =
      product && cart.find(item => item.productID === product?._id);
    if (cartItem) {
      setGuests(cartItem.guestsPerTier);
    }
    setGuestNames(cartGuestNames);
  }

  useEffect(() => {
    const cartItem =
      product && cart.find(item => item.productID === product?._id);
    if (cartItem) {
      setDates({
        startDateISO: cartItem.startDateISO,
        endDateISO: cartItem.endDateISO,
      });
      if (isGuestPageOpen) setGuests(cartItem.guestsPerTier);
    }
    setGuestNames(cartGuestNames);
  }, [cart, product, cartGuestNames, isGuestPageOpen]);

  return {
    complete,
    onUpdateDates: handleUpdateDates,
    onUpdateGuests: handleUpdateGuests,
    onUpdateGuestNames: handleUpdateGuestNames,
    dates,
    guests,
    guestNames,
    completeGuests,
    completeDates,
    resetGuests,
    resetDates,
  };
};
