import { Input } from "@ttc3k/trekker";
import { ComponentProps } from "react";
import { useController, useFormContext } from "react-hook-form";

type ControlledInputProps = ComponentProps<typeof Input> & { name: string };

export const ControlledInput = ({ name, hint, ...rest }: ControlledInputProps) => {
  const { register, control } = useFormContext();
  const { fieldState } = useController({ name, control });

  const props = {
    ...rest,
    bg: "white",
    ...register(name),
    hint: fieldState.error?.message ?? hint,
    ...(fieldState.error ? { "data-invalid": true, "aria-invalid": true } : {}),
  };

  return <Input {...props} />;
};
