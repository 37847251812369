import { Button, Heading, Icon, Text } from "@ttc3k/trekker";
import { PlusCircle } from "iconoir-react";
import { Box, Center } from "styled-system/jsx";

type NoProductsFoundProps = {
  onCreateProductClick: () => void;
};

export const NoProductsFound = ({
  onCreateProductClick,
}: NoProductsFoundProps) => {
  return (
    <Box
      pt={"3.75rem"}
      pb={"7.5rem"}
      px={{ base: "400", lg: "1000" }}
      width={"full"}
    >
      <Center
        flexDir={"column"}
        p={"600"}
        width={"full"}
        height={"full"}
        minH={"400px"}
        bg={"white"}
        gap={"300"}
        borderRadius={"100"}
        borderWidth={"1px"}
        borderStyle={"dashed"}
        borderColor={"border.light"}
      >
        <Center
          flexDir={"column"}
          gap={"150"}
          maxW={"500px"}
          textAlign={"center"}
        >
          <Icon Element={PlusCircle} size={"lg"} color={"icon.accent.mid"} />
          <Heading size={"h5"} color={"text.accent.dark"}>
            Create a New Product
          </Heading>
          <Text color={"text.mid"}>
            Create a sellable product, get it embedded on your website, and
            start collecting payments — all in just a few minutes!
          </Text>
        </Center>
        <Button visual={"accent"} size={"lg"} onClick={onCreateProductClick}>
          Create
        </Button>
      </Center>
    </Box>
  );
};
