import { ButtonTagVariant, LegacyTag } from "@ttc3k/ttc-design-system";
import { useAuth0 } from "@auth0/auth0-react";

export const EnvLabel = () => {
  const { user } = useAuth0();
  const isDemoAccount = user?.whereaboutsOnboarding?.demoAccount;
  const isDev = import.meta.env.VITE_ENVIRONMENT === "development";

  return (
    <>
      {isDev && (
        <LegacyTag variant={ButtonTagVariant.WarningBold}>Dev</LegacyTag>
      )}
      {isDemoAccount && (
        <LegacyTag variant={ButtonTagVariant.SuccessBold}>Demo</LegacyTag>
      )}
    </>
  );
};
