import { forwardRef, ReactNode } from "react";
import { tokens, LegacyTypography } from "@ttc3k/ttc-design-system";
import { cn } from "../../../utils/cn";
import { HelperText } from "../HelperText";

type RadioProps = {
  label?: ReactNode;
  value?: string;
  onChange?: (value: string) => void;
  helperText?: string;
  error?: boolean;
};

type TextFieldMainProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "ref" | "value" | "onChange"
>;

export type Ref = HTMLInputElement;

export const Radio = forwardRef<Ref, RadioProps & TextFieldMainProps>(
  (props, ref) => {
    const { label, value, onChange, helperText, error, ...textFieldMainProps } =
      props;

    return (
      <label
        className={cn(
          "tw-flex tw-flex-row tw-gap-x-space-200 tw-cursor-pointer",
        )}
      >
        <input
          {...textFieldMainProps}
          ref={ref}
          type="radio"
          className="tw-peer tw-sr-only"
          value={value}
          onChange={e => onChange?.(e.target.value)}
        />
        <div className="tw-w-[20px] tw-h-[20px] tw-rounded-full tw-border-border tw-border-[1px] tw-border-solid peer-checked:tw-border-[5.2px] tw-inset-0 peer-checked:tw-border-[#000000]" />
        <div className="tw-flex tw-flex-col tw-flex-1">
          <LegacyTypography
            color={
              helperText
                ? tokens.semantic.colorText
                : tokens.semantic.textSubtle
            }
          >
            {label}
          </LegacyTypography>
          {helperText && <HelperText text={helperText} error={error} />}
        </div>
      </label>
    );
  },
);
