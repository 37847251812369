export default function PoweredByWhereabouts() {
  return (
    <div className="tw-w-28 tw-h-9 tw-flex-col tw-justify-start tw-items-center tw-gap-1 tw-inline-flex">
      <div className="tw-opacity-50 tw-text-zinc-800 tw-text-xs tw-font-semibold tw-font-['Figtree'] tw-leading-none tw-tracking-tight">
        <a target="_blank" href="https://tourism.tech/whereabouts">
          Powered by
        </a>
      </div>
      <div className="tw-text-zinc-800 tw-text-sm tw-font-bold tw-font-['Figtree'] tw-uppercase tw-leading-none tw-tracking-wide">
        <a target="_blank" href="https://tourism.tech/whereabouts">
          whereabouts
        </a>
      </div>
    </div>
  );
}
