import { ReactNode, forwardRef, useId } from "react";
import { Label } from "../Label";
import { HelperText } from "../HelperText";
import "./text-field.scss";

type TextFieldProps = {
  label?: string;
  error?: boolean;
  helperText?: string;
  helperComponent?: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
};

type TextFieldMainProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "ref"
>;

export type Ref = HTMLInputElement;

export const TextField = forwardRef<Ref, TextFieldProps & TextFieldMainProps>(
  (props, ref) => {
    const {
      label,
      error,
      helperText,
      helperComponent,
      startIcon,
      endIcon,
      disabled,
      ...textFieldMainProps
    } = props;
    const id = useId();
    // {
    //     "tw-border-[#DB330D] focus:tw-border-[#DB330D]": error,
    //     "tw-bg-white/60 tw-opacity-50": disabled,
    // },
    return (
      <div className="tw-w-full tw-gap-[4px] tw-flex tw-flex-col input-styles">
        {label && <Label id={id} label={label} />}
        {/* TODO: the cn function isnt merging them properly and taking conditional cn in precedence */}
        <div
          className={`tw-flex tw-items-center tw-w-full tw-gap-x-[8px] tw-bg-white tw-border-solid tw-py-[12px] tw-px-[16px] tw-rounded-[8px] tw-border-[1px] focus-within:tw-border-[#2E92EF] ${
            error
              ? "tw-border-[#DB330D] tw-outline tw-outline-1 tw-outline-[#DB330D] focus:tw-border-[#DB330D]"
              : "tw-border-border"
          }`}
        >
          {startIcon}
          <input
            ref={ref}
            id={id}
            className="tw-grow tw-bg-transparent tw-border-none tw-outline-none"
            {...textFieldMainProps}
            disabled={disabled}
          />
          {endIcon}
        </div>
        {helperText && <HelperText text={helperText} error={error} />}
        {helperComponent && helperComponent}
      </div>
    );
  },
);
