import { useFormHandler } from "core/hooks/useFormHandler";
import {
  ProductByIdDocument,
  useProductByIdQuery,
  useProductUpdateMutation,
} from "gql/generated";
import { useCallback } from "react";
import { z } from "zod";

const updateAccomFormSchema = z.object({
  name: z.string().min(1, "Please enter a name"),
  description: z.string(),
  shortDescription: z.string(),
});

export type UpdateAccomFormValues = z.infer<typeof updateAccomFormSchema>;

interface UpdateAccomFormOptions {
  onSuccess?: (v: UpdateAccomFormValues | undefined) => void;
  onError?: (message: string) => void;
  productID: string;
}

export const useUpdateAccomDetailsForm = ({
  onSuccess,
  onError,
  productID,
}: UpdateAccomFormOptions) => {
  const { data: productData } = useProductByIdQuery({
    variables: { id: productID },
  });

  const defaultValues: UpdateAccomFormValues = {
    name: productData?.productById?.name.en ?? "",
    description: productData?.productById?.description?.en ?? "",
    shortDescription: productData?.productById?.shortDescription?.en ?? "",
  };

  const [updateProduct] = useProductUpdateMutation();
  return useFormHandler<UpdateAccomFormValues>(
    useCallback(
      async data => {
        const payload = { ...data };
        const { shortDescription, name, description } = payload;

        const formErrorMessage =
          "Could not update this product, please try again later";
        if (productID && productData && productData?.productById) {
          updateProduct({
            variables: {
              id: productID,
              record: {
                name: {
                  ...productData?.productById?.name,
                  en: name,
                },
                description: {
                  ...productData?.productById?.description,
                  en: description,
                },
                shortDescription: {
                  ...productData?.productById?.shortDescription,
                  en: shortDescription,
                },
              },
            },
            refetchQueries: [ProductByIdDocument],
          })
            .then(() => onSuccess?.(data as UpdateAccomFormValues))
            .catch(() => onError?.(formErrorMessage));
        } else {
          onError?.(formErrorMessage);
        }
      },
      [productData, onError, onSuccess, productID, updateProduct],
    ),
    defaultValues,
    { schema: updateAccomFormSchema },
  );
};
