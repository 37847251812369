const tokens = {
  "aspectRatios.square": {
    "value": "1 / 1",
    "variable": "var(--aspect-ratios-square)"
  },
  "aspectRatios.landscape": {
    "value": "4 / 3",
    "variable": "var(--aspect-ratios-landscape)"
  },
  "aspectRatios.portrait": {
    "value": "3 / 4",
    "variable": "var(--aspect-ratios-portrait)"
  },
  "aspectRatios.wide": {
    "value": "16 / 9",
    "variable": "var(--aspect-ratios-wide)"
  },
  "aspectRatios.ultrawide": {
    "value": "18 / 5",
    "variable": "var(--aspect-ratios-ultrawide)"
  },
  "aspectRatios.golden": {
    "value": "1.618 / 1",
    "variable": "var(--aspect-ratios-golden)"
  },
  "borders.none": {
    "value": "none",
    "variable": "var(--borders-none)"
  },
  "blurs.sm": {
    "value": "4px",
    "variable": "var(--blurs-sm)"
  },
  "blurs.base": {
    "value": "8px",
    "variable": "var(--blurs-base)"
  },
  "blurs.md": {
    "value": "12px",
    "variable": "var(--blurs-md)"
  },
  "blurs.lg": {
    "value": "16px",
    "variable": "var(--blurs-lg)"
  },
  "blurs.xl": {
    "value": "24px",
    "variable": "var(--blurs-xl)"
  },
  "blurs.2xl": {
    "value": "40px",
    "variable": "var(--blurs-2xl)"
  },
  "blurs.3xl": {
    "value": "64px",
    "variable": "var(--blurs-3xl)"
  },
  "animations.spin": {
    "value": "spin 1s linear infinite",
    "variable": "var(--animations-spin)"
  },
  "animations.ping": {
    "value": "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite",
    "variable": "var(--animations-ping)"
  },
  "animations.pulse": {
    "value": "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
    "variable": "var(--animations-pulse)"
  },
  "animations.bounce": {
    "value": "bounce 1s infinite",
    "variable": "var(--animations-bounce)"
  },
  "colors.current": {
    "value": "currentColor",
    "variable": "var(--colors-current)"
  },
  "colors.black": {
    "value": "#000",
    "variable": "var(--colors-black)"
  },
  "colors.white": {
    "value": "#fff",
    "variable": "var(--colors-white)"
  },
  "colors.transparent": {
    "value": "rgb(0 0 0 / 0)",
    "variable": "var(--colors-transparent)"
  },
  "colors.rose.50": {
    "value": "#fff1f2",
    "variable": "var(--colors-rose-50)"
  },
  "colors.rose.100": {
    "value": "#ffe4e6",
    "variable": "var(--colors-rose-100)"
  },
  "colors.rose.200": {
    "value": "#fecdd3",
    "variable": "var(--colors-rose-200)"
  },
  "colors.rose.300": {
    "value": "#fda4af",
    "variable": "var(--colors-rose-300)"
  },
  "colors.rose.400": {
    "value": "#fb7185",
    "variable": "var(--colors-rose-400)"
  },
  "colors.rose.500": {
    "value": "#f43f5e",
    "variable": "var(--colors-rose-500)"
  },
  "colors.rose.600": {
    "value": "#e11d48",
    "variable": "var(--colors-rose-600)"
  },
  "colors.rose.700": {
    "value": "#be123c",
    "variable": "var(--colors-rose-700)"
  },
  "colors.rose.800": {
    "value": "#9f1239",
    "variable": "var(--colors-rose-800)"
  },
  "colors.rose.900": {
    "value": "#881337",
    "variable": "var(--colors-rose-900)"
  },
  "colors.rose.950": {
    "value": "#4c0519",
    "variable": "var(--colors-rose-950)"
  },
  "colors.pink.50": {
    "value": "#fdf2f8",
    "variable": "var(--colors-pink-50)"
  },
  "colors.pink.100": {
    "value": "#fce7f3",
    "variable": "var(--colors-pink-100)"
  },
  "colors.pink.200": {
    "value": "#fbcfe8",
    "variable": "var(--colors-pink-200)"
  },
  "colors.pink.300": {
    "value": "#f9a8d4",
    "variable": "var(--colors-pink-300)"
  },
  "colors.pink.400": {
    "value": "#f472b6",
    "variable": "var(--colors-pink-400)"
  },
  "colors.pink.500": {
    "value": "#ec4899",
    "variable": "var(--colors-pink-500)"
  },
  "colors.pink.600": {
    "value": "#db2777",
    "variable": "var(--colors-pink-600)"
  },
  "colors.pink.700": {
    "value": "#be185d",
    "variable": "var(--colors-pink-700)"
  },
  "colors.pink.800": {
    "value": "#9d174d",
    "variable": "var(--colors-pink-800)"
  },
  "colors.pink.900": {
    "value": "#831843",
    "variable": "var(--colors-pink-900)"
  },
  "colors.pink.950": {
    "value": "#500724",
    "variable": "var(--colors-pink-950)"
  },
  "colors.fuchsia.50": {
    "value": "#fdf4ff",
    "variable": "var(--colors-fuchsia-50)"
  },
  "colors.fuchsia.100": {
    "value": "#fae8ff",
    "variable": "var(--colors-fuchsia-100)"
  },
  "colors.fuchsia.200": {
    "value": "#f5d0fe",
    "variable": "var(--colors-fuchsia-200)"
  },
  "colors.fuchsia.300": {
    "value": "#f0abfc",
    "variable": "var(--colors-fuchsia-300)"
  },
  "colors.fuchsia.400": {
    "value": "#e879f9",
    "variable": "var(--colors-fuchsia-400)"
  },
  "colors.fuchsia.500": {
    "value": "#d946ef",
    "variable": "var(--colors-fuchsia-500)"
  },
  "colors.fuchsia.600": {
    "value": "#c026d3",
    "variable": "var(--colors-fuchsia-600)"
  },
  "colors.fuchsia.700": {
    "value": "#a21caf",
    "variable": "var(--colors-fuchsia-700)"
  },
  "colors.fuchsia.800": {
    "value": "#86198f",
    "variable": "var(--colors-fuchsia-800)"
  },
  "colors.fuchsia.900": {
    "value": "#701a75",
    "variable": "var(--colors-fuchsia-900)"
  },
  "colors.fuchsia.950": {
    "value": "#4a044e",
    "variable": "var(--colors-fuchsia-950)"
  },
  "colors.purple.50": {
    "value": "#faf5ff",
    "variable": "var(--colors-purple-50)"
  },
  "colors.purple.100": {
    "value": "#f3e8ff",
    "variable": "var(--colors-purple-100)"
  },
  "colors.purple.200": {
    "value": "#e9d5ff",
    "variable": "var(--colors-purple-200)"
  },
  "colors.purple.300": {
    "value": "#d8b4fe",
    "variable": "var(--colors-purple-300)"
  },
  "colors.purple.400": {
    "value": "#c084fc",
    "variable": "var(--colors-purple-400)"
  },
  "colors.purple.500": {
    "value": "#a855f7",
    "variable": "var(--colors-purple-500)"
  },
  "colors.purple.600": {
    "value": "#9333ea",
    "variable": "var(--colors-purple-600)"
  },
  "colors.purple.700": {
    "value": "#7e22ce",
    "variable": "var(--colors-purple-700)"
  },
  "colors.purple.800": {
    "value": "#6b21a8",
    "variable": "var(--colors-purple-800)"
  },
  "colors.purple.900": {
    "value": "#581c87",
    "variable": "var(--colors-purple-900)"
  },
  "colors.purple.950": {
    "value": "#3b0764",
    "variable": "var(--colors-purple-950)"
  },
  "colors.violet.50": {
    "value": "#f5f3ff",
    "variable": "var(--colors-violet-50)"
  },
  "colors.violet.100": {
    "value": "#ede9fe",
    "variable": "var(--colors-violet-100)"
  },
  "colors.violet.200": {
    "value": "#ddd6fe",
    "variable": "var(--colors-violet-200)"
  },
  "colors.violet.300": {
    "value": "#c4b5fd",
    "variable": "var(--colors-violet-300)"
  },
  "colors.violet.400": {
    "value": "#a78bfa",
    "variable": "var(--colors-violet-400)"
  },
  "colors.violet.500": {
    "value": "#8b5cf6",
    "variable": "var(--colors-violet-500)"
  },
  "colors.violet.600": {
    "value": "#7c3aed",
    "variable": "var(--colors-violet-600)"
  },
  "colors.violet.700": {
    "value": "#6d28d9",
    "variable": "var(--colors-violet-700)"
  },
  "colors.violet.800": {
    "value": "#5b21b6",
    "variable": "var(--colors-violet-800)"
  },
  "colors.violet.900": {
    "value": "#4c1d95",
    "variable": "var(--colors-violet-900)"
  },
  "colors.violet.950": {
    "value": "#2e1065",
    "variable": "var(--colors-violet-950)"
  },
  "colors.indigo.50": {
    "value": "#eef2ff",
    "variable": "var(--colors-indigo-50)"
  },
  "colors.indigo.100": {
    "value": "#e0e7ff",
    "variable": "var(--colors-indigo-100)"
  },
  "colors.indigo.200": {
    "value": "#c7d2fe",
    "variable": "var(--colors-indigo-200)"
  },
  "colors.indigo.300": {
    "value": "#a5b4fc",
    "variable": "var(--colors-indigo-300)"
  },
  "colors.indigo.400": {
    "value": "#818cf8",
    "variable": "var(--colors-indigo-400)"
  },
  "colors.indigo.500": {
    "value": "#6366f1",
    "variable": "var(--colors-indigo-500)"
  },
  "colors.indigo.600": {
    "value": "#4f46e5",
    "variable": "var(--colors-indigo-600)"
  },
  "colors.indigo.700": {
    "value": "#4338ca",
    "variable": "var(--colors-indigo-700)"
  },
  "colors.indigo.800": {
    "value": "#3730a3",
    "variable": "var(--colors-indigo-800)"
  },
  "colors.indigo.900": {
    "value": "#312e81",
    "variable": "var(--colors-indigo-900)"
  },
  "colors.indigo.950": {
    "value": "#1e1b4b",
    "variable": "var(--colors-indigo-950)"
  },
  "colors.sky.50": {
    "value": "#f0f9ff",
    "variable": "var(--colors-sky-50)"
  },
  "colors.sky.100": {
    "value": "#e0f2fe",
    "variable": "var(--colors-sky-100)"
  },
  "colors.sky.200": {
    "value": "#bae6fd",
    "variable": "var(--colors-sky-200)"
  },
  "colors.sky.300": {
    "value": "#7dd3fc",
    "variable": "var(--colors-sky-300)"
  },
  "colors.sky.400": {
    "value": "#38bdf8",
    "variable": "var(--colors-sky-400)"
  },
  "colors.sky.500": {
    "value": "#0ea5e9",
    "variable": "var(--colors-sky-500)"
  },
  "colors.sky.600": {
    "value": "#0284c7",
    "variable": "var(--colors-sky-600)"
  },
  "colors.sky.700": {
    "value": "#0369a1",
    "variable": "var(--colors-sky-700)"
  },
  "colors.sky.800": {
    "value": "#075985",
    "variable": "var(--colors-sky-800)"
  },
  "colors.sky.900": {
    "value": "#0c4a6e",
    "variable": "var(--colors-sky-900)"
  },
  "colors.sky.950": {
    "value": "#082f49",
    "variable": "var(--colors-sky-950)"
  },
  "colors.cyan.50": {
    "value": "#ecfeff",
    "variable": "var(--colors-cyan-50)"
  },
  "colors.cyan.100": {
    "value": "#cffafe",
    "variable": "var(--colors-cyan-100)"
  },
  "colors.cyan.200": {
    "value": "#a5f3fc",
    "variable": "var(--colors-cyan-200)"
  },
  "colors.cyan.300": {
    "value": "#67e8f9",
    "variable": "var(--colors-cyan-300)"
  },
  "colors.cyan.400": {
    "value": "#22d3ee",
    "variable": "var(--colors-cyan-400)"
  },
  "colors.cyan.500": {
    "value": "#06b6d4",
    "variable": "var(--colors-cyan-500)"
  },
  "colors.cyan.600": {
    "value": "#0891b2",
    "variable": "var(--colors-cyan-600)"
  },
  "colors.cyan.700": {
    "value": "#0e7490",
    "variable": "var(--colors-cyan-700)"
  },
  "colors.cyan.800": {
    "value": "#155e75",
    "variable": "var(--colors-cyan-800)"
  },
  "colors.cyan.900": {
    "value": "#164e63",
    "variable": "var(--colors-cyan-900)"
  },
  "colors.cyan.950": {
    "value": "#083344",
    "variable": "var(--colors-cyan-950)"
  },
  "colors.teal.50": {
    "value": "#f0fdfa",
    "variable": "var(--colors-teal-50)"
  },
  "colors.teal.100": {
    "value": "#ccfbf1",
    "variable": "var(--colors-teal-100)"
  },
  "colors.teal.200": {
    "value": "#99f6e4",
    "variable": "var(--colors-teal-200)"
  },
  "colors.teal.300": {
    "value": "#5eead4",
    "variable": "var(--colors-teal-300)"
  },
  "colors.teal.400": {
    "value": "#2dd4bf",
    "variable": "var(--colors-teal-400)"
  },
  "colors.teal.500": {
    "value": "#14b8a6",
    "variable": "var(--colors-teal-500)"
  },
  "colors.teal.600": {
    "value": "#0d9488",
    "variable": "var(--colors-teal-600)"
  },
  "colors.teal.700": {
    "value": "#0f766e",
    "variable": "var(--colors-teal-700)"
  },
  "colors.teal.800": {
    "value": "#115e59",
    "variable": "var(--colors-teal-800)"
  },
  "colors.teal.900": {
    "value": "#134e4a",
    "variable": "var(--colors-teal-900)"
  },
  "colors.teal.950": {
    "value": "#042f2e",
    "variable": "var(--colors-teal-950)"
  },
  "colors.emerald.50": {
    "value": "#ecfdf5",
    "variable": "var(--colors-emerald-50)"
  },
  "colors.emerald.100": {
    "value": "#d1fae5",
    "variable": "var(--colors-emerald-100)"
  },
  "colors.emerald.200": {
    "value": "#a7f3d0",
    "variable": "var(--colors-emerald-200)"
  },
  "colors.emerald.300": {
    "value": "#6ee7b7",
    "variable": "var(--colors-emerald-300)"
  },
  "colors.emerald.400": {
    "value": "#34d399",
    "variable": "var(--colors-emerald-400)"
  },
  "colors.emerald.500": {
    "value": "#10b981",
    "variable": "var(--colors-emerald-500)"
  },
  "colors.emerald.600": {
    "value": "#059669",
    "variable": "var(--colors-emerald-600)"
  },
  "colors.emerald.700": {
    "value": "#047857",
    "variable": "var(--colors-emerald-700)"
  },
  "colors.emerald.800": {
    "value": "#065f46",
    "variable": "var(--colors-emerald-800)"
  },
  "colors.emerald.900": {
    "value": "#064e3b",
    "variable": "var(--colors-emerald-900)"
  },
  "colors.emerald.950": {
    "value": "#022c22",
    "variable": "var(--colors-emerald-950)"
  },
  "colors.lime.50": {
    "value": "#f7fee7",
    "variable": "var(--colors-lime-50)"
  },
  "colors.lime.100": {
    "value": "#ecfccb",
    "variable": "var(--colors-lime-100)"
  },
  "colors.lime.200": {
    "value": "#d9f99d",
    "variable": "var(--colors-lime-200)"
  },
  "colors.lime.300": {
    "value": "#bef264",
    "variable": "var(--colors-lime-300)"
  },
  "colors.lime.400": {
    "value": "#a3e635",
    "variable": "var(--colors-lime-400)"
  },
  "colors.lime.500": {
    "value": "#84cc16",
    "variable": "var(--colors-lime-500)"
  },
  "colors.lime.600": {
    "value": "#65a30d",
    "variable": "var(--colors-lime-600)"
  },
  "colors.lime.700": {
    "value": "#4d7c0f",
    "variable": "var(--colors-lime-700)"
  },
  "colors.lime.800": {
    "value": "#3f6212",
    "variable": "var(--colors-lime-800)"
  },
  "colors.lime.900": {
    "value": "#365314",
    "variable": "var(--colors-lime-900)"
  },
  "colors.lime.950": {
    "value": "#1a2e05",
    "variable": "var(--colors-lime-950)"
  },
  "colors.amber.50": {
    "value": "#fffbeb",
    "variable": "var(--colors-amber-50)"
  },
  "colors.amber.100": {
    "value": "#fef3c7",
    "variable": "var(--colors-amber-100)"
  },
  "colors.amber.200": {
    "value": "#fde68a",
    "variable": "var(--colors-amber-200)"
  },
  "colors.amber.300": {
    "value": "#fcd34d",
    "variable": "var(--colors-amber-300)"
  },
  "colors.amber.400": {
    "value": "#fbbf24",
    "variable": "var(--colors-amber-400)"
  },
  "colors.amber.500": {
    "value": "#f59e0b",
    "variable": "var(--colors-amber-500)"
  },
  "colors.amber.600": {
    "value": "#d97706",
    "variable": "var(--colors-amber-600)"
  },
  "colors.amber.700": {
    "value": "#b45309",
    "variable": "var(--colors-amber-700)"
  },
  "colors.amber.800": {
    "value": "#92400e",
    "variable": "var(--colors-amber-800)"
  },
  "colors.amber.900": {
    "value": "#78350f",
    "variable": "var(--colors-amber-900)"
  },
  "colors.amber.950": {
    "value": "#451a03",
    "variable": "var(--colors-amber-950)"
  },
  "colors.neutral.50": {
    "value": "#fafafa",
    "variable": "var(--colors-neutral-50)"
  },
  "colors.neutral.100": {
    "value": "#f5f5f5",
    "variable": "var(--colors-neutral-100)"
  },
  "colors.neutral.200": {
    "value": "#e5e5e5",
    "variable": "var(--colors-neutral-200)"
  },
  "colors.neutral.300": {
    "value": "#d4d4d4",
    "variable": "var(--colors-neutral-300)"
  },
  "colors.neutral.400": {
    "value": "#a3a3a3",
    "variable": "var(--colors-neutral-400)"
  },
  "colors.neutral.500": {
    "value": "#737373",
    "variable": "var(--colors-neutral-500)"
  },
  "colors.neutral.600": {
    "value": "#525252",
    "variable": "var(--colors-neutral-600)"
  },
  "colors.neutral.700": {
    "value": "#404040",
    "variable": "var(--colors-neutral-700)"
  },
  "colors.neutral.800": {
    "value": "#262626",
    "variable": "var(--colors-neutral-800)"
  },
  "colors.neutral.900": {
    "value": "#171717",
    "variable": "var(--colors-neutral-900)"
  },
  "colors.neutral.950": {
    "value": "#0a0a0a",
    "variable": "var(--colors-neutral-950)"
  },
  "colors.stone.50": {
    "value": "#fafaf9",
    "variable": "var(--colors-stone-50)"
  },
  "colors.stone.100": {
    "value": "#f5f5f4",
    "variable": "var(--colors-stone-100)"
  },
  "colors.stone.200": {
    "value": "#e7e5e4",
    "variable": "var(--colors-stone-200)"
  },
  "colors.stone.300": {
    "value": "#d6d3d1",
    "variable": "var(--colors-stone-300)"
  },
  "colors.stone.400": {
    "value": "#a8a29e",
    "variable": "var(--colors-stone-400)"
  },
  "colors.stone.500": {
    "value": "#78716c",
    "variable": "var(--colors-stone-500)"
  },
  "colors.stone.600": {
    "value": "#57534e",
    "variable": "var(--colors-stone-600)"
  },
  "colors.stone.700": {
    "value": "#44403c",
    "variable": "var(--colors-stone-700)"
  },
  "colors.stone.800": {
    "value": "#292524",
    "variable": "var(--colors-stone-800)"
  },
  "colors.stone.900": {
    "value": "#1c1917",
    "variable": "var(--colors-stone-900)"
  },
  "colors.stone.950": {
    "value": "#0c0a09",
    "variable": "var(--colors-stone-950)"
  },
  "colors.zinc.50": {
    "value": "#fafafa",
    "variable": "var(--colors-zinc-50)"
  },
  "colors.zinc.100": {
    "value": "#f4f4f5",
    "variable": "var(--colors-zinc-100)"
  },
  "colors.zinc.200": {
    "value": "#e4e4e7",
    "variable": "var(--colors-zinc-200)"
  },
  "colors.zinc.300": {
    "value": "#d4d4d8",
    "variable": "var(--colors-zinc-300)"
  },
  "colors.zinc.400": {
    "value": "#a1a1aa",
    "variable": "var(--colors-zinc-400)"
  },
  "colors.zinc.500": {
    "value": "#71717a",
    "variable": "var(--colors-zinc-500)"
  },
  "colors.zinc.600": {
    "value": "#52525b",
    "variable": "var(--colors-zinc-600)"
  },
  "colors.zinc.700": {
    "value": "#3f3f46",
    "variable": "var(--colors-zinc-700)"
  },
  "colors.zinc.800": {
    "value": "#27272a",
    "variable": "var(--colors-zinc-800)"
  },
  "colors.zinc.900": {
    "value": "#18181b",
    "variable": "var(--colors-zinc-900)"
  },
  "colors.zinc.950": {
    "value": "#09090b",
    "variable": "var(--colors-zinc-950)"
  },
  "colors.gray.50": {
    "value": "#f9fafb",
    "variable": "var(--colors-gray-50)"
  },
  "colors.gray.100": {
    "value": "#f3f4f6",
    "variable": "var(--colors-gray-100)"
  },
  "colors.gray.200": {
    "value": "#e5e7eb",
    "variable": "var(--colors-gray-200)"
  },
  "colors.gray.300": {
    "value": "#d1d5db",
    "variable": "var(--colors-gray-300)"
  },
  "colors.gray.400": {
    "value": "#9ca3af",
    "variable": "var(--colors-gray-400)"
  },
  "colors.gray.500": {
    "value": "#6b7280",
    "variable": "var(--colors-gray-500)"
  },
  "colors.gray.600": {
    "value": "#4b5563",
    "variable": "var(--colors-gray-600)"
  },
  "colors.gray.700": {
    "value": "#374151",
    "variable": "var(--colors-gray-700)"
  },
  "colors.gray.800": {
    "value": "#1f2937",
    "variable": "var(--colors-gray-800)"
  },
  "colors.gray.900": {
    "value": "#111827",
    "variable": "var(--colors-gray-900)"
  },
  "colors.gray.950": {
    "value": "#030712",
    "variable": "var(--colors-gray-950)"
  },
  "colors.slate.50": {
    "value": "#f8fafc",
    "variable": "var(--colors-slate-50)"
  },
  "colors.slate.100": {
    "value": "#f1f5f9",
    "variable": "var(--colors-slate-100)"
  },
  "colors.slate.200": {
    "value": "#e2e8f0",
    "variable": "var(--colors-slate-200)"
  },
  "colors.slate.300": {
    "value": "#cbd5e1",
    "variable": "var(--colors-slate-300)"
  },
  "colors.slate.400": {
    "value": "#94a3b8",
    "variable": "var(--colors-slate-400)"
  },
  "colors.slate.500": {
    "value": "#64748b",
    "variable": "var(--colors-slate-500)"
  },
  "colors.slate.600": {
    "value": "#475569",
    "variable": "var(--colors-slate-600)"
  },
  "colors.slate.700": {
    "value": "#334155",
    "variable": "var(--colors-slate-700)"
  },
  "colors.slate.800": {
    "value": "#1e293b",
    "variable": "var(--colors-slate-800)"
  },
  "colors.slate.900": {
    "value": "#0f172a",
    "variable": "var(--colors-slate-900)"
  },
  "colors.slate.950": {
    "value": "#020617",
    "variable": "var(--colors-slate-950)"
  },
  "colors.ink.50": {
    "value": "#FAFAFA",
    "variable": "var(--colors-ink-50)"
  },
  "colors.ink.100": {
    "value": "#F4F4F5",
    "variable": "var(--colors-ink-100)"
  },
  "colors.ink.200": {
    "value": "#EDEDEE",
    "variable": "var(--colors-ink-200)"
  },
  "colors.ink.300": {
    "value": "#D4D4D8",
    "variable": "var(--colors-ink-300)"
  },
  "colors.ink.400": {
    "value": "#A1A1AA",
    "variable": "var(--colors-ink-400)"
  },
  "colors.ink.500": {
    "value": "#71717A",
    "variable": "var(--colors-ink-500)"
  },
  "colors.ink.600": {
    "value": "#52525B",
    "variable": "var(--colors-ink-600)"
  },
  "colors.ink.700": {
    "value": "#3F3F46",
    "variable": "var(--colors-ink-700)"
  },
  "colors.ink.800": {
    "value": "#27272A",
    "variable": "var(--colors-ink-800)"
  },
  "colors.red.50": {
    "value": "#FCECE9",
    "variable": "var(--colors-red-50)"
  },
  "colors.red.100": {
    "value": "#F9D9D2",
    "variable": "var(--colors-red-100)"
  },
  "colors.red.200": {
    "value": "#fecaca",
    "variable": "var(--colors-red-200)"
  },
  "colors.red.300": {
    "value": "#EE9D8A",
    "variable": "var(--colors-red-300)"
  },
  "colors.red.400": {
    "value": "#f87171",
    "variable": "var(--colors-red-400)"
  },
  "colors.red.500": {
    "value": "#E14F2F",
    "variable": "var(--colors-red-500)"
  },
  "colors.red.600": {
    "value": "#dc2626",
    "variable": "var(--colors-red-600)"
  },
  "colors.red.700": {
    "value": "#AC2506",
    "variable": "var(--colors-red-700)"
  },
  "colors.red.800": {
    "value": "#991b1b",
    "variable": "var(--colors-red-800)"
  },
  "colors.red.900": {
    "value": "#7f1d1d",
    "variable": "var(--colors-red-900)"
  },
  "colors.red.950": {
    "value": "#450a0a",
    "variable": "var(--colors-red-950)"
  },
  "colors.orange.50": {
    "value": "#FBF0E0",
    "variable": "var(--colors-orange-50)"
  },
  "colors.orange.100": {
    "value": "#FFE5BF",
    "variable": "var(--colors-orange-100)"
  },
  "colors.orange.200": {
    "value": "#fed7aa",
    "variable": "var(--colors-orange-200)"
  },
  "colors.orange.300": {
    "value": "#FFCE84",
    "variable": "var(--colors-orange-300)"
  },
  "colors.orange.400": {
    "value": "#fb923c",
    "variable": "var(--colors-orange-400)"
  },
  "colors.orange.500": {
    "value": "#E08D11",
    "variable": "var(--colors-orange-500)"
  },
  "colors.orange.600": {
    "value": "#ea580c",
    "variable": "var(--colors-orange-600)"
  },
  "colors.orange.700": {
    "value": "#8A5402",
    "variable": "var(--colors-orange-700)"
  },
  "colors.orange.800": {
    "value": "#9a3412",
    "variable": "var(--colors-orange-800)"
  },
  "colors.orange.900": {
    "value": "#7c2d12",
    "variable": "var(--colors-orange-900)"
  },
  "colors.orange.950": {
    "value": "#431407",
    "variable": "var(--colors-orange-950)"
  },
  "colors.yellow.50": {
    "value": "#FFFAEB",
    "variable": "var(--colors-yellow-50)"
  },
  "colors.yellow.100": {
    "value": "#FFF0B8",
    "variable": "var(--colors-yellow-100)"
  },
  "colors.yellow.200": {
    "value": "#fef08a",
    "variable": "var(--colors-yellow-200)"
  },
  "colors.yellow.300": {
    "value": "#F8D75F",
    "variable": "var(--colors-yellow-300)"
  },
  "colors.yellow.400": {
    "value": "#facc15",
    "variable": "var(--colors-yellow-400)"
  },
  "colors.yellow.500": {
    "value": "#CA9307",
    "variable": "var(--colors-yellow-500)"
  },
  "colors.yellow.600": {
    "value": "#ca8a04",
    "variable": "var(--colors-yellow-600)"
  },
  "colors.yellow.700": {
    "value": "#8B6502",
    "variable": "var(--colors-yellow-700)"
  },
  "colors.yellow.800": {
    "value": "#854d0e",
    "variable": "var(--colors-yellow-800)"
  },
  "colors.yellow.900": {
    "value": "#713f12",
    "variable": "var(--colors-yellow-900)"
  },
  "colors.yellow.950": {
    "value": "#422006",
    "variable": "var(--colors-yellow-950)"
  },
  "colors.green.50": {
    "value": "#EDFAF5",
    "variable": "var(--colors-green-50)"
  },
  "colors.green.100": {
    "value": "#D5F5E8",
    "variable": "var(--colors-green-100)"
  },
  "colors.green.200": {
    "value": "#bbf7d0",
    "variable": "var(--colors-green-200)"
  },
  "colors.green.300": {
    "value": "#8ECDB3",
    "variable": "var(--colors-green-300)"
  },
  "colors.green.400": {
    "value": "#4ade80",
    "variable": "var(--colors-green-400)"
  },
  "colors.green.500": {
    "value": "#429F72",
    "variable": "var(--colors-green-500)"
  },
  "colors.green.600": {
    "value": "#16a34a",
    "variable": "var(--colors-green-600)"
  },
  "colors.green.700": {
    "value": "#27704C",
    "variable": "var(--colors-green-700)"
  },
  "colors.green.800": {
    "value": "#166534",
    "variable": "var(--colors-green-800)"
  },
  "colors.green.900": {
    "value": "#14532d",
    "variable": "var(--colors-green-900)"
  },
  "colors.green.950": {
    "value": "#052e16",
    "variable": "var(--colors-green-950)"
  },
  "colors.blue.50": {
    "value": "#EBF2FF",
    "variable": "var(--colors-blue-50)"
  },
  "colors.blue.100": {
    "value": "#D1E0FF",
    "variable": "var(--colors-blue-100)"
  },
  "colors.blue.200": {
    "value": "#bfdbfe",
    "variable": "var(--colors-blue-200)"
  },
  "colors.blue.300": {
    "value": "#7B9AFF",
    "variable": "var(--colors-blue-300)"
  },
  "colors.blue.400": {
    "value": "#60a5fa",
    "variable": "var(--colors-blue-400)"
  },
  "colors.blue.500": {
    "value": "#2E5BEF",
    "variable": "var(--colors-blue-500)"
  },
  "colors.blue.600": {
    "value": "#2563eb",
    "variable": "var(--colors-blue-600)"
  },
  "colors.blue.700": {
    "value": "#0634CC",
    "variable": "var(--colors-blue-700)"
  },
  "colors.blue.800": {
    "value": "#1e40af",
    "variable": "var(--colors-blue-800)"
  },
  "colors.blue.900": {
    "value": "#1e3a8a",
    "variable": "var(--colors-blue-900)"
  },
  "colors.blue.950": {
    "value": "#172554",
    "variable": "var(--colors-blue-950)"
  },
  "colors.cream.50": {
    "value": "#FAF7F3",
    "variable": "var(--colors-cream-50)"
  },
  "colors.cream.100": {
    "value": "#F1EDE7",
    "variable": "var(--colors-cream-100)"
  },
  "colors.cream.200": {
    "value": "#FBF0E0",
    "variable": "var(--colors-cream-200)"
  },
  "colors.cream.300": {
    "value": "#DEDAD3",
    "variable": "var(--colors-cream-300)"
  },
  "radii.0": {
    "value": "0",
    "variable": "var(--radii-0)"
  },
  "radii.25": {
    "value": "0.125rem",
    "variable": "var(--radii-25)"
  },
  "radii.50": {
    "value": "0.25rem",
    "variable": "var(--radii-50)"
  },
  "radii.75": {
    "value": "0.375rem",
    "variable": "var(--radii-75)"
  },
  "radii.100": {
    "value": "0.5rem",
    "variable": "var(--radii-100)"
  },
  "radii.150": {
    "value": "0.75rem",
    "variable": "var(--radii-150)"
  },
  "radii.200": {
    "value": "1rem",
    "variable": "var(--radii-200)"
  },
  "radii.250": {
    "value": "1.25rem",
    "variable": "var(--radii-250)"
  },
  "radii.300": {
    "value": "1.5rem",
    "variable": "var(--radii-300)"
  },
  "radii.400": {
    "value": "2rem",
    "variable": "var(--radii-400)"
  },
  "radii.xs": {
    "value": "0.125rem",
    "variable": "var(--radii-xs)"
  },
  "radii.sm": {
    "value": "0.25rem",
    "variable": "var(--radii-sm)"
  },
  "radii.md": {
    "value": "0.375rem",
    "variable": "var(--radii-md)"
  },
  "radii.lg": {
    "value": "0.5rem",
    "variable": "var(--radii-lg)"
  },
  "radii.xl": {
    "value": "0.75rem",
    "variable": "var(--radii-xl)"
  },
  "radii.2xl": {
    "value": "1rem",
    "variable": "var(--radii-2xl)"
  },
  "radii.3xl": {
    "value": "1.5rem",
    "variable": "var(--radii-3xl)"
  },
  "radii.full": {
    "value": "9999px",
    "variable": "var(--radii-full)"
  },
  "shadows.25": {
    "value": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    "variable": "var(--shadows-25)"
  },
  "shadows.50": {
    "value": "0 1px 4px 0 rgba(0, 0, 0, 0.08);",
    "variable": "var(--shadows-50)"
  },
  "shadows.100": {
    "value": "0 2px 8px 0 rgba(0, 0, 0, 0.08);",
    "variable": "var(--shadows-100)"
  },
  "shadows.200": {
    "value": "0 4px 12px 0 rgba(0, 0, 0, 0.08)",
    "variable": "var(--shadows-200)"
  },
  "shadows.300": {
    "value": "0 8px 20px -3px rgba(0, 0, 0, 0.15)",
    "variable": "var(--shadows-300)"
  },
  "shadows.xs": {
    "value": "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    "variable": "var(--shadows-xs)"
  },
  "shadows.sm": {
    "value": "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-sm)"
  },
  "shadows.md": {
    "value": "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-md)"
  },
  "shadows.lg": {
    "value": "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-lg)"
  },
  "shadows.xl": {
    "value": "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-xl)"
  },
  "shadows.2xl": {
    "value": "0 25px 50px -12px rgb(0 0 0 / 0.25)",
    "variable": "var(--shadows-2xl)"
  },
  "shadows.inner": {
    "value": "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
    "variable": "var(--shadows-inner)"
  },
  "sizes.0": {
    "value": "0rem",
    "variable": "var(--sizes-0)"
  },
  "sizes.1": {
    "value": "0.25rem",
    "variable": "var(--sizes-1)"
  },
  "sizes.2": {
    "value": "0.5rem",
    "variable": "var(--sizes-2)"
  },
  "sizes.3": {
    "value": "0.75rem",
    "variable": "var(--sizes-3)"
  },
  "sizes.4": {
    "value": "1rem",
    "variable": "var(--sizes-4)"
  },
  "sizes.5": {
    "value": "1.25rem",
    "variable": "var(--sizes-5)"
  },
  "sizes.6": {
    "value": "1.5rem",
    "variable": "var(--sizes-6)"
  },
  "sizes.7": {
    "value": "1.75rem",
    "variable": "var(--sizes-7)"
  },
  "sizes.8": {
    "value": "2rem",
    "variable": "var(--sizes-8)"
  },
  "sizes.9": {
    "value": "2.25rem",
    "variable": "var(--sizes-9)"
  },
  "sizes.10": {
    "value": "2.5rem",
    "variable": "var(--sizes-10)"
  },
  "sizes.11": {
    "value": "2.75rem",
    "variable": "var(--sizes-11)"
  },
  "sizes.12": {
    "value": "3rem",
    "variable": "var(--sizes-12)"
  },
  "sizes.14": {
    "value": "3.5rem",
    "variable": "var(--sizes-14)"
  },
  "sizes.16": {
    "value": "4rem",
    "variable": "var(--sizes-16)"
  },
  "sizes.20": {
    "value": "5rem",
    "variable": "var(--sizes-20)"
  },
  "sizes.24": {
    "value": "6rem",
    "variable": "var(--sizes-24)"
  },
  "sizes.28": {
    "value": "7rem",
    "variable": "var(--sizes-28)"
  },
  "sizes.32": {
    "value": "8rem",
    "variable": "var(--sizes-32)"
  },
  "sizes.36": {
    "value": "9rem",
    "variable": "var(--sizes-36)"
  },
  "sizes.40": {
    "value": "10rem",
    "variable": "var(--sizes-40)"
  },
  "sizes.44": {
    "value": "11rem",
    "variable": "var(--sizes-44)"
  },
  "sizes.48": {
    "value": "12rem",
    "variable": "var(--sizes-48)"
  },
  "sizes.52": {
    "value": "13rem",
    "variable": "var(--sizes-52)"
  },
  "sizes.56": {
    "value": "14rem",
    "variable": "var(--sizes-56)"
  },
  "sizes.60": {
    "value": "15rem",
    "variable": "var(--sizes-60)"
  },
  "sizes.64": {
    "value": "16rem",
    "variable": "var(--sizes-64)"
  },
  "sizes.72": {
    "value": "18rem",
    "variable": "var(--sizes-72)"
  },
  "sizes.80": {
    "value": "20rem",
    "variable": "var(--sizes-80)"
  },
  "sizes.96": {
    "value": "24rem",
    "variable": "var(--sizes-96)"
  },
  "sizes.0.5": {
    "value": "0.125rem",
    "variable": "var(--sizes-0\\.5)"
  },
  "sizes.1.5": {
    "value": "0.375rem",
    "variable": "var(--sizes-1\\.5)"
  },
  "sizes.2.5": {
    "value": "0.625rem",
    "variable": "var(--sizes-2\\.5)"
  },
  "sizes.3.5": {
    "value": "0.875rem",
    "variable": "var(--sizes-3\\.5)"
  },
  "sizes.xs": {
    "value": "20rem",
    "variable": "var(--sizes-xs)"
  },
  "sizes.sm": {
    "value": "24rem",
    "variable": "var(--sizes-sm)"
  },
  "sizes.md": {
    "value": "28rem",
    "variable": "var(--sizes-md)"
  },
  "sizes.lg": {
    "value": "32rem",
    "variable": "var(--sizes-lg)"
  },
  "sizes.xl": {
    "value": "36rem",
    "variable": "var(--sizes-xl)"
  },
  "sizes.2xl": {
    "value": "42rem",
    "variable": "var(--sizes-2xl)"
  },
  "sizes.3xl": {
    "value": "48rem",
    "variable": "var(--sizes-3xl)"
  },
  "sizes.4xl": {
    "value": "56rem",
    "variable": "var(--sizes-4xl)"
  },
  "sizes.5xl": {
    "value": "64rem",
    "variable": "var(--sizes-5xl)"
  },
  "sizes.6xl": {
    "value": "72rem",
    "variable": "var(--sizes-6xl)"
  },
  "sizes.7xl": {
    "value": "80rem",
    "variable": "var(--sizes-7xl)"
  },
  "sizes.8xl": {
    "value": "90rem",
    "variable": "var(--sizes-8xl)"
  },
  "sizes.prose": {
    "value": "65ch",
    "variable": "var(--sizes-prose)"
  },
  "sizes.full": {
    "value": "100%",
    "variable": "var(--sizes-full)"
  },
  "sizes.min": {
    "value": "min-content",
    "variable": "var(--sizes-min)"
  },
  "sizes.max": {
    "value": "max-content",
    "variable": "var(--sizes-max)"
  },
  "sizes.fit": {
    "value": "fit-content",
    "variable": "var(--sizes-fit)"
  },
  "sizes.icons.sm": {
    "value": "1rem",
    "variable": "var(--sizes-icons-sm)"
  },
  "sizes.icons.md": {
    "value": "1.25rem",
    "variable": "var(--sizes-icons-md)"
  },
  "sizes.icons.lg": {
    "value": "1.5rem",
    "variable": "var(--sizes-icons-lg)"
  },
  "sizes.icons.xl": {
    "value": "2rem",
    "variable": "var(--sizes-icons-xl)"
  },
  "sizes.modals.pane": {
    "value": "460px",
    "variable": "var(--sizes-modals-pane)"
  },
  "sizes.modals.center": {
    "value": "600px",
    "variable": "var(--sizes-modals-center)"
  },
  "sizes.breakpoint-sm": {
    "value": "640px",
    "variable": "var(--sizes-breakpoint-sm)"
  },
  "sizes.breakpoint-md": {
    "value": "768px",
    "variable": "var(--sizes-breakpoint-md)"
  },
  "sizes.breakpoint-lg": {
    "value": "1024px",
    "variable": "var(--sizes-breakpoint-lg)"
  },
  "sizes.breakpoint-xl": {
    "value": "1280px",
    "variable": "var(--sizes-breakpoint-xl)"
  },
  "sizes.breakpoint-2xl": {
    "value": "1536px",
    "variable": "var(--sizes-breakpoint-2xl)"
  },
  "spacing.0": {
    "value": "0rem",
    "variable": "var(--spacing-0)"
  },
  "spacing.1": {
    "value": "0.25rem",
    "variable": "var(--spacing-1)"
  },
  "spacing.2": {
    "value": "0.5rem",
    "variable": "var(--spacing-2)"
  },
  "spacing.3": {
    "value": "0.75rem",
    "variable": "var(--spacing-3)"
  },
  "spacing.4": {
    "value": "1rem",
    "variable": "var(--spacing-4)"
  },
  "spacing.5": {
    "value": "1.25rem",
    "variable": "var(--spacing-5)"
  },
  "spacing.6": {
    "value": "1.5rem",
    "variable": "var(--spacing-6)"
  },
  "spacing.7": {
    "value": "1.75rem",
    "variable": "var(--spacing-7)"
  },
  "spacing.8": {
    "value": "2rem",
    "variable": "var(--spacing-8)"
  },
  "spacing.9": {
    "value": "2.25rem",
    "variable": "var(--spacing-9)"
  },
  "spacing.10": {
    "value": "2.5rem",
    "variable": "var(--spacing-10)"
  },
  "spacing.11": {
    "value": "2.75rem",
    "variable": "var(--spacing-11)"
  },
  "spacing.12": {
    "value": "3rem",
    "variable": "var(--spacing-12)"
  },
  "spacing.14": {
    "value": "3.5rem",
    "variable": "var(--spacing-14)"
  },
  "spacing.16": {
    "value": "4rem",
    "variable": "var(--spacing-16)"
  },
  "spacing.20": {
    "value": "5rem",
    "variable": "var(--spacing-20)"
  },
  "spacing.24": {
    "value": "6rem",
    "variable": "var(--spacing-24)"
  },
  "spacing.25": {
    "value": "0.125rem",
    "variable": "var(--spacing-25)"
  },
  "spacing.28": {
    "value": "7rem",
    "variable": "var(--spacing-28)"
  },
  "spacing.32": {
    "value": "8rem",
    "variable": "var(--spacing-32)"
  },
  "spacing.36": {
    "value": "9rem",
    "variable": "var(--spacing-36)"
  },
  "spacing.40": {
    "value": "10rem",
    "variable": "var(--spacing-40)"
  },
  "spacing.44": {
    "value": "11rem",
    "variable": "var(--spacing-44)"
  },
  "spacing.48": {
    "value": "12rem",
    "variable": "var(--spacing-48)"
  },
  "spacing.50": {
    "value": "0.25rem",
    "variable": "var(--spacing-50)"
  },
  "spacing.52": {
    "value": "13rem",
    "variable": "var(--spacing-52)"
  },
  "spacing.56": {
    "value": "14rem",
    "variable": "var(--spacing-56)"
  },
  "spacing.60": {
    "value": "15rem",
    "variable": "var(--spacing-60)"
  },
  "spacing.64": {
    "value": "16rem",
    "variable": "var(--spacing-64)"
  },
  "spacing.72": {
    "value": "18rem",
    "variable": "var(--spacing-72)"
  },
  "spacing.75": {
    "value": "0.375rem",
    "variable": "var(--spacing-75)"
  },
  "spacing.80": {
    "value": "20rem",
    "variable": "var(--spacing-80)"
  },
  "spacing.96": {
    "value": "24rem",
    "variable": "var(--spacing-96)"
  },
  "spacing.100": {
    "value": "0.5rem",
    "variable": "var(--spacing-100)"
  },
  "spacing.150": {
    "value": "0.75rem",
    "variable": "var(--spacing-150)"
  },
  "spacing.200": {
    "value": "1rem",
    "variable": "var(--spacing-200)"
  },
  "spacing.250": {
    "value": "1.25rem",
    "variable": "var(--spacing-250)"
  },
  "spacing.300": {
    "value": "1.5rem",
    "variable": "var(--spacing-300)"
  },
  "spacing.400": {
    "value": "2rem",
    "variable": "var(--spacing-400)"
  },
  "spacing.500": {
    "value": "2.5rem",
    "variable": "var(--spacing-500)"
  },
  "spacing.600": {
    "value": "3rem",
    "variable": "var(--spacing-600)"
  },
  "spacing.800": {
    "value": "4rem",
    "variable": "var(--spacing-800)"
  },
  "spacing.1000": {
    "value": "5rem",
    "variable": "var(--spacing-1000)"
  },
  "spacing.0.5": {
    "value": "0.125rem",
    "variable": "var(--spacing-0\\.5)"
  },
  "spacing.1.5": {
    "value": "0.375rem",
    "variable": "var(--spacing-1\\.5)"
  },
  "spacing.2.5": {
    "value": "0.625rem",
    "variable": "var(--spacing-2\\.5)"
  },
  "spacing.3.5": {
    "value": "0.875rem",
    "variable": "var(--spacing-3\\.5)"
  },
  "zIndex.toast": {
    "value": 2147483647,
    "variable": "var(--z-index-toast)"
  },
  "zIndex.modal": {
    "value": 2147483646,
    "variable": "var(--z-index-modal)"
  },
  "easings.default": {
    "value": "cubic-bezier(0.4, 0, 0.2, 1)",
    "variable": "var(--easings-default)"
  },
  "easings.linear": {
    "value": "linear",
    "variable": "var(--easings-linear)"
  },
  "easings.in": {
    "value": "cubic-bezier(0.4, 0, 1, 1)",
    "variable": "var(--easings-in)"
  },
  "easings.out": {
    "value": "cubic-bezier(0, 0, 0.2, 1)",
    "variable": "var(--easings-out)"
  },
  "easings.in-out": {
    "value": "cubic-bezier(0.4, 0, 0.2, 1)",
    "variable": "var(--easings-in-out)"
  },
  "easings.standard.productive": {
    "value": "cubic-bezier(0.2, 0, 0.38, 0.9)",
    "variable": "var(--easings-standard-productive)"
  },
  "easings.standard.expressive": {
    "value": "cubic-bezier(0.4, 0.14, 0.3, 1)",
    "variable": "var(--easings-standard-expressive)"
  },
  "easings.entrance.productive": {
    "value": "cubic-bezier(0, 0, 0.38, 0.9)",
    "variable": "var(--easings-entrance-productive)"
  },
  "easings.entrance.expressive": {
    "value": "cubic-bezier(0, 0, 0.3, 1)",
    "variable": "var(--easings-entrance-expressive)"
  },
  "easings.exit.productive": {
    "value": "cubic-bezier(0.2, 0, 1, 0.9)",
    "variable": "var(--easings-exit-productive)"
  },
  "easings.exit.expressive": {
    "value": "cubic-bezier(0.4, 0.14, 1, 1)",
    "variable": "var(--easings-exit-expressive)"
  },
  "durations.fastest": {
    "value": "50ms",
    "variable": "var(--durations-fastest)"
  },
  "durations.faster": {
    "value": "100ms",
    "variable": "var(--durations-faster)"
  },
  "durations.fast": {
    "value": "150ms",
    "variable": "var(--durations-fast)"
  },
  "durations.normal": {
    "value": "200ms",
    "variable": "var(--durations-normal)"
  },
  "durations.slow": {
    "value": "300ms",
    "variable": "var(--durations-slow)"
  },
  "durations.slower": {
    "value": "400ms",
    "variable": "var(--durations-slower)"
  },
  "durations.slowest": {
    "value": "500ms",
    "variable": "var(--durations-slowest)"
  },
  "durations.fast1": {
    "value": "70ms",
    "variable": "var(--durations-fast1)"
  },
  "durations.fast2": {
    "value": "110ms",
    "variable": "var(--durations-fast2)"
  },
  "durations.moderate1": {
    "value": "150ms",
    "variable": "var(--durations-moderate1)"
  },
  "durations.moderate2": {
    "value": "240ms",
    "variable": "var(--durations-moderate2)"
  },
  "durations.slow1": {
    "value": "400ms",
    "variable": "var(--durations-slow1)"
  },
  "durations.slow2": {
    "value": "700ms",
    "variable": "var(--durations-slow2)"
  },
  "letterSpacings.tightest": {
    "value": "-0.01em",
    "variable": "var(--letter-spacings-tightest)"
  },
  "letterSpacings.tighter": {
    "value": "-0.05em",
    "variable": "var(--letter-spacings-tighter)"
  },
  "letterSpacings.tight": {
    "value": "-0.025em",
    "variable": "var(--letter-spacings-tight)"
  },
  "letterSpacings.normal": {
    "value": "0",
    "variable": "var(--letter-spacings-normal)"
  },
  "letterSpacings.wide": {
    "value": "0.008em",
    "variable": "var(--letter-spacings-wide)"
  },
  "letterSpacings.wider": {
    "value": "0.024em",
    "variable": "var(--letter-spacings-wider)"
  },
  "letterSpacings.widest": {
    "value": "0.1em",
    "variable": "var(--letter-spacings-widest)"
  },
  "lineHeights.none": {
    "value": "1",
    "variable": "var(--line-heights-none)"
  },
  "lineHeights.tight": {
    "value": "1.25",
    "variable": "var(--line-heights-tight)"
  },
  "lineHeights.snug": {
    "value": "1.375",
    "variable": "var(--line-heights-snug)"
  },
  "lineHeights.normal": {
    "value": "1.5",
    "variable": "var(--line-heights-normal)"
  },
  "lineHeights.relaxed": {
    "value": "1.625",
    "variable": "var(--line-heights-relaxed)"
  },
  "lineHeights.loose": {
    "value": "2",
    "variable": "var(--line-heights-loose)"
  },
  "lineHeights.2xs": {
    "value": "1rem",
    "variable": "var(--line-heights-2xs)"
  },
  "lineHeights.xs": {
    "value": "1.375rem",
    "variable": "var(--line-heights-xs)"
  },
  "lineHeights.sm": {
    "value": "1.5rem",
    "variable": "var(--line-heights-sm)"
  },
  "lineHeights.md": {
    "value": "1.75rem",
    "variable": "var(--line-heights-md)"
  },
  "lineHeights.lg": {
    "value": "2rem",
    "variable": "var(--line-heights-lg)"
  },
  "lineHeights.xl": {
    "value": "2.25rem",
    "variable": "var(--line-heights-xl)"
  },
  "lineHeights.2xl": {
    "value": "2.5rem",
    "variable": "var(--line-heights-2xl)"
  },
  "lineHeights.3xl": {
    "value": "3rem",
    "variable": "var(--line-heights-3xl)"
  },
  "lineHeights.4xl": {
    "value": "3.75rem",
    "variable": "var(--line-heights-4xl)"
  },
  "fontWeights.extralight": {
    "value": "200",
    "variable": "var(--font-weights-extralight)"
  },
  "fontWeights.semibold": {
    "value": "600",
    "variable": "var(--font-weights-semibold)"
  },
  "fontWeights.extrabold": {
    "value": "800",
    "variable": "var(--font-weights-extrabold)"
  },
  "fontWeights.thin": {
    "value": 200,
    "variable": "var(--font-weights-thin)"
  },
  "fontWeights.light": {
    "value": 300,
    "variable": "var(--font-weights-light)"
  },
  "fontWeights.normal": {
    "value": 400,
    "variable": "var(--font-weights-normal)"
  },
  "fontWeights.medium": {
    "value": 500,
    "variable": "var(--font-weights-medium)"
  },
  "fontWeights.semiBold": {
    "value": 600,
    "variable": "var(--font-weights-semi-bold)"
  },
  "fontWeights.bold": {
    "value": 700,
    "variable": "var(--font-weights-bold)"
  },
  "fontWeights.extraBold": {
    "value": 800,
    "variable": "var(--font-weights-extra-bold)"
  },
  "fontWeights.black": {
    "value": 900,
    "variable": "var(--font-weights-black)"
  },
  "fonts.sans": {
    "value": "ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
    "variable": "var(--fonts-sans)"
  },
  "fonts.serif": {
    "value": "ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif",
    "variable": "var(--fonts-serif)"
  },
  "fonts.heading": {
    "value": "'Figtree Variable', 'Figtree', -apple-system, BlinkMacSystemFont, \"Segoe UI\", Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
    "variable": "var(--fonts-heading)"
  },
  "fonts.body": {
    "value": "'Figtree Variable', 'Figtree', -apple-system, BlinkMacSystemFont, \"Segoe UI\", Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
    "variable": "var(--fonts-body)"
  },
  "fonts.mono": {
    "value": "'IBM Plex Mono', SFMono-Regular,Menlo,Monaco,Consolas,\"Liberation Mono\",\"Courier New\",monospace",
    "variable": "var(--fonts-mono)"
  },
  "fontSizes.6xl": {
    "value": "3.75rem",
    "variable": "var(--font-sizes-6xl)"
  },
  "fontSizes.7xl": {
    "value": "4.5rem",
    "variable": "var(--font-sizes-7xl)"
  },
  "fontSizes.8xl": {
    "value": "6rem",
    "variable": "var(--font-sizes-8xl)"
  },
  "fontSizes.9xl": {
    "value": "8rem",
    "variable": "var(--font-sizes-9xl)"
  },
  "fontSizes.2xs": {
    "value": "0.813rem",
    "variable": "var(--font-sizes-2xs)"
  },
  "fontSizes.xs": {
    "value": "0.875rem",
    "variable": "var(--font-sizes-xs)"
  },
  "fontSizes.sm": {
    "value": "0.938rem",
    "variable": "var(--font-sizes-sm)"
  },
  "fontSizes.md": {
    "value": "1rem",
    "variable": "var(--font-sizes-md)"
  },
  "fontSizes.lg": {
    "value": "1.125rem",
    "variable": "var(--font-sizes-lg)"
  },
  "fontSizes.xl": {
    "value": "1.25rem",
    "variable": "var(--font-sizes-xl)"
  },
  "fontSizes.2xl": {
    "value": "1.5rem",
    "variable": "var(--font-sizes-2xl)"
  },
  "fontSizes.3xl": {
    "value": "2rem",
    "variable": "var(--font-sizes-3xl)"
  },
  "fontSizes.4xl": {
    "value": "2.25rem",
    "variable": "var(--font-sizes-4xl)"
  },
  "fontSizes.5xl": {
    "value": "3rem",
    "variable": "var(--font-sizes-5xl)"
  },
  "breakpoints.sm": {
    "value": "640px",
    "variable": "var(--breakpoints-sm)"
  },
  "breakpoints.md": {
    "value": "768px",
    "variable": "var(--breakpoints-md)"
  },
  "breakpoints.lg": {
    "value": "1024px",
    "variable": "var(--breakpoints-lg)"
  },
  "breakpoints.xl": {
    "value": "1280px",
    "variable": "var(--breakpoints-xl)"
  },
  "breakpoints.2xl": {
    "value": "1536px",
    "variable": "var(--breakpoints-2xl)"
  },
  "colors.text.dark": {
    "value": "var(--colors-ink-800)",
    "variable": "var(--colors-text-dark)"
  },
  "colors.text.mid": {
    "value": "var(--colors-ink-500)",
    "variable": "var(--colors-text-mid)"
  },
  "colors.text.light": {
    "value": "var(--colors-ink-400)",
    "variable": "var(--colors-text-light)"
  },
  "colors.text.disabled": {
    "value": "var(--colors-ink-300)",
    "variable": "var(--colors-text-disabled)"
  },
  "colors.text.inverse": {
    "value": "white",
    "variable": "var(--colors-text-inverse)"
  },
  "colors.text.accent.dark": {
    "value": "var(--colors-blue-700)",
    "variable": "var(--colors-text-accent-dark)"
  },
  "colors.text.accent.mid": {
    "value": "var(--colors-blue-500)",
    "variable": "var(--colors-text-accent-mid)"
  },
  "colors.text.accent.light": {
    "value": "var(--colors-blue-300)",
    "variable": "var(--colors-text-accent-light)"
  },
  "colors.text.success.dark": {
    "value": "var(--colors-green-700)",
    "variable": "var(--colors-text-success-dark)"
  },
  "colors.text.success.mid": {
    "value": "var(--colors-green-500)",
    "variable": "var(--colors-text-success-mid)"
  },
  "colors.text.success.light": {
    "value": "var(--colors-green-300)",
    "variable": "var(--colors-text-success-light)"
  },
  "colors.text.warning.dark": {
    "value": "var(--colors-orange-700)",
    "variable": "var(--colors-text-warning-dark)"
  },
  "colors.text.warning.mid": {
    "value": "var(--colors-orange-500)",
    "variable": "var(--colors-text-warning-mid)"
  },
  "colors.text.warning.light": {
    "value": "var(--colors-orange-300)",
    "variable": "var(--colors-text-warning-light)"
  },
  "colors.text.error.dark": {
    "value": "var(--colors-red-700)",
    "variable": "var(--colors-text-error-dark)"
  },
  "colors.text.error.mid": {
    "value": "var(--colors-red-500)",
    "variable": "var(--colors-text-error-mid)"
  },
  "colors.text.error.light": {
    "value": "var(--colors-red-300)",
    "variable": "var(--colors-text-error-light)"
  },
  "colors.text.highlight.dark": {
    "value": "var(--colors-yellow-700)",
    "variable": "var(--colors-text-highlight-dark)"
  },
  "colors.text.highlight.mid": {
    "value": "var(--colors-yellow-500)",
    "variable": "var(--colors-text-highlight-mid)"
  },
  "colors.text.highlight.light": {
    "value": "var(--colors-yellow-300)",
    "variable": "var(--colors-text-highlight-light)"
  },
  "colors.bg.darkest": {
    "value": "var(--colors-ink-800)",
    "variable": "var(--colors-bg-darkest)"
  },
  "colors.bg.dark": {
    "value": "var(--colors-ink-500)",
    "variable": "var(--colors-bg-dark)"
  },
  "colors.bg.mid": {
    "value": "var(--colors-ink-400)",
    "variable": "var(--colors-bg-mid)"
  },
  "colors.bg.lighter": {
    "value": "var(--colors-ink-300)",
    "variable": "var(--colors-bg-lighter)"
  },
  "colors.bg.lightest": {
    "value": "var(--colors-ink-200)",
    "variable": "var(--colors-bg-lightest)"
  },
  "colors.bg.accent.darkest": {
    "value": "var(--colors-blue-700)",
    "variable": "var(--colors-bg-accent-darkest)"
  },
  "colors.bg.accent.dark": {
    "value": "var(--colors-blue-500)",
    "variable": "var(--colors-bg-accent-dark)"
  },
  "colors.bg.accent.mid": {
    "value": "var(--colors-blue-300)",
    "variable": "var(--colors-bg-accent-mid)"
  },
  "colors.bg.accent.light": {
    "value": "var(--colors-blue-100)",
    "variable": "var(--colors-bg-accent-light)"
  },
  "colors.bg.accent.lightest": {
    "value": "var(--colors-blue-50)",
    "variable": "var(--colors-bg-accent-lightest)"
  },
  "colors.bg.success.darkest": {
    "value": "var(--colors-green-700)",
    "variable": "var(--colors-bg-success-darkest)"
  },
  "colors.bg.success.dark": {
    "value": "var(--colors-green-500)",
    "variable": "var(--colors-bg-success-dark)"
  },
  "colors.bg.success.mid": {
    "value": "var(--colors-green-300)",
    "variable": "var(--colors-bg-success-mid)"
  },
  "colors.bg.success.light": {
    "value": "var(--colors-green-100)",
    "variable": "var(--colors-bg-success-light)"
  },
  "colors.bg.success.lightest": {
    "value": "var(--colors-green-50)",
    "variable": "var(--colors-bg-success-lightest)"
  },
  "colors.bg.warning.darkest": {
    "value": "var(--colors-orange-700)",
    "variable": "var(--colors-bg-warning-darkest)"
  },
  "colors.bg.warning.dark": {
    "value": "var(--colors-orange-500)",
    "variable": "var(--colors-bg-warning-dark)"
  },
  "colors.bg.warning.mid": {
    "value": "var(--colors-orange-300)",
    "variable": "var(--colors-bg-warning-mid)"
  },
  "colors.bg.warning.light": {
    "value": "var(--colors-orange-100)",
    "variable": "var(--colors-bg-warning-light)"
  },
  "colors.bg.warning.lightest": {
    "value": "var(--colors-orange-50)",
    "variable": "var(--colors-bg-warning-lightest)"
  },
  "colors.bg.error.darkest": {
    "value": "var(--colors-red-700)",
    "variable": "var(--colors-bg-error-darkest)"
  },
  "colors.bg.error.dark": {
    "value": "var(--colors-red-500)",
    "variable": "var(--colors-bg-error-dark)"
  },
  "colors.bg.error.mid": {
    "value": "var(--colors-red-300)",
    "variable": "var(--colors-bg-error-mid)"
  },
  "colors.bg.error.light": {
    "value": "var(--colors-red-100)",
    "variable": "var(--colors-bg-error-light)"
  },
  "colors.bg.error.lightest": {
    "value": "var(--colors-red-50)",
    "variable": "var(--colors-bg-error-lightest)"
  },
  "colors.bg.highlight.darkest": {
    "value": "var(--colors-yellow-700)",
    "variable": "var(--colors-bg-highlight-darkest)"
  },
  "colors.bg.highlight.dark": {
    "value": "var(--colors-yellow-500)",
    "variable": "var(--colors-bg-highlight-dark)"
  },
  "colors.bg.highlight.mid": {
    "value": "var(--colors-yellow-300)",
    "variable": "var(--colors-bg-highlight-mid)"
  },
  "colors.bg.highlight.light": {
    "value": "var(--colors-yellow-100)",
    "variable": "var(--colors-bg-highlight-light)"
  },
  "colors.bg.highlight.lightest": {
    "value": "var(--colors-yellow-50)",
    "variable": "var(--colors-bg-highlight-lightest)"
  },
  "colors.bg.wa.dark": {
    "value": "var(--colors-cream-300)",
    "variable": "var(--colors-bg-wa-dark)"
  },
  "colors.bg.wa.mid": {
    "value": "var(--colors-cream-200)",
    "variable": "var(--colors-bg-wa-mid)"
  },
  "colors.bg.wa.light": {
    "value": "var(--colors-cream-100)",
    "variable": "var(--colors-bg-wa-light)"
  },
  "colors.bg.wa.lightest": {
    "value": "var(--colors-cream-50)",
    "variable": "var(--colors-bg-wa-lightest)"
  },
  "colors.icon.dark": {
    "value": "var(--colors-ink-800)",
    "variable": "var(--colors-icon-dark)"
  },
  "colors.icon.mid": {
    "value": "var(--colors-ink-500)",
    "variable": "var(--colors-icon-mid)"
  },
  "colors.icon.light": {
    "value": "var(--colors-ink-400)",
    "variable": "var(--colors-icon-light)"
  },
  "colors.icon.disabled": {
    "value": "var(--colors-ink-300)",
    "variable": "var(--colors-icon-disabled)"
  },
  "colors.icon.inverse": {
    "value": "white",
    "variable": "var(--colors-icon-inverse)"
  },
  "colors.icon.accent.dark": {
    "value": "var(--colors-blue-700)",
    "variable": "var(--colors-icon-accent-dark)"
  },
  "colors.icon.accent.mid": {
    "value": "var(--colors-blue-500)",
    "variable": "var(--colors-icon-accent-mid)"
  },
  "colors.icon.accent.light": {
    "value": "var(--colors-blue-300)",
    "variable": "var(--colors-icon-accent-light)"
  },
  "colors.icon.success.dark": {
    "value": "var(--colors-green-700)",
    "variable": "var(--colors-icon-success-dark)"
  },
  "colors.icon.success.mid": {
    "value": "var(--colors-green-500)",
    "variable": "var(--colors-icon-success-mid)"
  },
  "colors.icon.success.light": {
    "value": "var(--colors-green-300)",
    "variable": "var(--colors-icon-success-light)"
  },
  "colors.icon.warning.dark": {
    "value": "var(--colors-orange-700)",
    "variable": "var(--colors-icon-warning-dark)"
  },
  "colors.icon.warning.mid": {
    "value": "var(--colors-orange-500)",
    "variable": "var(--colors-icon-warning-mid)"
  },
  "colors.icon.warning.light": {
    "value": "var(--colors-orange-300)",
    "variable": "var(--colors-icon-warning-light)"
  },
  "colors.icon.error.dark": {
    "value": "var(--colors-red-700)",
    "variable": "var(--colors-icon-error-dark)"
  },
  "colors.icon.error.mid": {
    "value": "var(--colors-red-500)",
    "variable": "var(--colors-icon-error-mid)"
  },
  "colors.icon.error.light": {
    "value": "var(--colors-red-300)",
    "variable": "var(--colors-icon-error-light)"
  },
  "colors.icon.highlight.dark": {
    "value": "var(--colors-yellow-700)",
    "variable": "var(--colors-icon-highlight-dark)"
  },
  "colors.icon.highlight.mid": {
    "value": "var(--colors-yellow-500)",
    "variable": "var(--colors-icon-highlight-mid)"
  },
  "colors.icon.highlight.light": {
    "value": "var(--colors-yellow-300)",
    "variable": "var(--colors-icon-highlight-light)"
  },
  "colors.border.dark": {
    "value": "var(--colors-ink-800)",
    "variable": "var(--colors-border-dark)"
  },
  "colors.border.mid": {
    "value": "var(--colors-ink-400)",
    "variable": "var(--colors-border-mid)"
  },
  "colors.border.light": {
    "value": "var(--colors-ink-300)",
    "variable": "var(--colors-border-light)"
  },
  "colors.border.disabled": {
    "value": "var(--colors-ink-200)",
    "variable": "var(--colors-border-disabled)"
  },
  "colors.border.accent": {
    "value": "var(--colors-blue-500)",
    "variable": "var(--colors-border-accent)"
  },
  "colors.border.success": {
    "value": "var(--colors-green-500)",
    "variable": "var(--colors-border-success)"
  },
  "colors.border.warning": {
    "value": "var(--colors-orange-500)",
    "variable": "var(--colors-border-warning)"
  },
  "colors.border.error": {
    "value": "var(--colors-red-500)",
    "variable": "var(--colors-border-error)"
  },
  "colors.border.highlight": {
    "value": "var(--colors-yellow-500)",
    "variable": "var(--colors-border-highlight)"
  },
  "spacing.-1": {
    "value": "calc(var(--spacing-1) * -1)",
    "variable": "var(--spacing-1)"
  },
  "spacing.-2": {
    "value": "calc(var(--spacing-2) * -1)",
    "variable": "var(--spacing-2)"
  },
  "spacing.-3": {
    "value": "calc(var(--spacing-3) * -1)",
    "variable": "var(--spacing-3)"
  },
  "spacing.-4": {
    "value": "calc(var(--spacing-4) * -1)",
    "variable": "var(--spacing-4)"
  },
  "spacing.-5": {
    "value": "calc(var(--spacing-5) * -1)",
    "variable": "var(--spacing-5)"
  },
  "spacing.-6": {
    "value": "calc(var(--spacing-6) * -1)",
    "variable": "var(--spacing-6)"
  },
  "spacing.-7": {
    "value": "calc(var(--spacing-7) * -1)",
    "variable": "var(--spacing-7)"
  },
  "spacing.-8": {
    "value": "calc(var(--spacing-8) * -1)",
    "variable": "var(--spacing-8)"
  },
  "spacing.-9": {
    "value": "calc(var(--spacing-9) * -1)",
    "variable": "var(--spacing-9)"
  },
  "spacing.-10": {
    "value": "calc(var(--spacing-10) * -1)",
    "variable": "var(--spacing-10)"
  },
  "spacing.-11": {
    "value": "calc(var(--spacing-11) * -1)",
    "variable": "var(--spacing-11)"
  },
  "spacing.-12": {
    "value": "calc(var(--spacing-12) * -1)",
    "variable": "var(--spacing-12)"
  },
  "spacing.-14": {
    "value": "calc(var(--spacing-14) * -1)",
    "variable": "var(--spacing-14)"
  },
  "spacing.-16": {
    "value": "calc(var(--spacing-16) * -1)",
    "variable": "var(--spacing-16)"
  },
  "spacing.-20": {
    "value": "calc(var(--spacing-20) * -1)",
    "variable": "var(--spacing-20)"
  },
  "spacing.-24": {
    "value": "calc(var(--spacing-24) * -1)",
    "variable": "var(--spacing-24)"
  },
  "spacing.-25": {
    "value": "calc(var(--spacing-25) * -1)",
    "variable": "var(--spacing-25)"
  },
  "spacing.-28": {
    "value": "calc(var(--spacing-28) * -1)",
    "variable": "var(--spacing-28)"
  },
  "spacing.-32": {
    "value": "calc(var(--spacing-32) * -1)",
    "variable": "var(--spacing-32)"
  },
  "spacing.-36": {
    "value": "calc(var(--spacing-36) * -1)",
    "variable": "var(--spacing-36)"
  },
  "spacing.-40": {
    "value": "calc(var(--spacing-40) * -1)",
    "variable": "var(--spacing-40)"
  },
  "spacing.-44": {
    "value": "calc(var(--spacing-44) * -1)",
    "variable": "var(--spacing-44)"
  },
  "spacing.-48": {
    "value": "calc(var(--spacing-48) * -1)",
    "variable": "var(--spacing-48)"
  },
  "spacing.-50": {
    "value": "calc(var(--spacing-50) * -1)",
    "variable": "var(--spacing-50)"
  },
  "spacing.-52": {
    "value": "calc(var(--spacing-52) * -1)",
    "variable": "var(--spacing-52)"
  },
  "spacing.-56": {
    "value": "calc(var(--spacing-56) * -1)",
    "variable": "var(--spacing-56)"
  },
  "spacing.-60": {
    "value": "calc(var(--spacing-60) * -1)",
    "variable": "var(--spacing-60)"
  },
  "spacing.-64": {
    "value": "calc(var(--spacing-64) * -1)",
    "variable": "var(--spacing-64)"
  },
  "spacing.-72": {
    "value": "calc(var(--spacing-72) * -1)",
    "variable": "var(--spacing-72)"
  },
  "spacing.-75": {
    "value": "calc(var(--spacing-75) * -1)",
    "variable": "var(--spacing-75)"
  },
  "spacing.-80": {
    "value": "calc(var(--spacing-80) * -1)",
    "variable": "var(--spacing-80)"
  },
  "spacing.-96": {
    "value": "calc(var(--spacing-96) * -1)",
    "variable": "var(--spacing-96)"
  },
  "spacing.-100": {
    "value": "calc(var(--spacing-100) * -1)",
    "variable": "var(--spacing-100)"
  },
  "spacing.-150": {
    "value": "calc(var(--spacing-150) * -1)",
    "variable": "var(--spacing-150)"
  },
  "spacing.-200": {
    "value": "calc(var(--spacing-200) * -1)",
    "variable": "var(--spacing-200)"
  },
  "spacing.-250": {
    "value": "calc(var(--spacing-250) * -1)",
    "variable": "var(--spacing-250)"
  },
  "spacing.-300": {
    "value": "calc(var(--spacing-300) * -1)",
    "variable": "var(--spacing-300)"
  },
  "spacing.-400": {
    "value": "calc(var(--spacing-400) * -1)",
    "variable": "var(--spacing-400)"
  },
  "spacing.-500": {
    "value": "calc(var(--spacing-500) * -1)",
    "variable": "var(--spacing-500)"
  },
  "spacing.-600": {
    "value": "calc(var(--spacing-600) * -1)",
    "variable": "var(--spacing-600)"
  },
  "spacing.-800": {
    "value": "calc(var(--spacing-800) * -1)",
    "variable": "var(--spacing-800)"
  },
  "spacing.-1000": {
    "value": "calc(var(--spacing-1000) * -1)",
    "variable": "var(--spacing-1000)"
  },
  "spacing.-0.5": {
    "value": "calc(var(--spacing-0\\.5) * -1)",
    "variable": "var(--spacing-0\\.5)"
  },
  "spacing.-1.5": {
    "value": "calc(var(--spacing-1\\.5) * -1)",
    "variable": "var(--spacing-1\\.5)"
  },
  "spacing.-2.5": {
    "value": "calc(var(--spacing-2\\.5) * -1)",
    "variable": "var(--spacing-2\\.5)"
  },
  "spacing.-3.5": {
    "value": "calc(var(--spacing-3\\.5) * -1)",
    "variable": "var(--spacing-3\\.5)"
  },
  "colors.colorPalette": {
    "value": "var(--colors-color-palette)",
    "variable": "var(--colors-color-palette)"
  },
  "colors.colorPalette.50": {
    "value": "var(--colors-color-palette-50)",
    "variable": "var(--colors-color-palette-50)"
  },
  "colors.colorPalette.100": {
    "value": "var(--colors-color-palette-100)",
    "variable": "var(--colors-color-palette-100)"
  },
  "colors.colorPalette.200": {
    "value": "var(--colors-color-palette-200)",
    "variable": "var(--colors-color-palette-200)"
  },
  "colors.colorPalette.300": {
    "value": "var(--colors-color-palette-300)",
    "variable": "var(--colors-color-palette-300)"
  },
  "colors.colorPalette.400": {
    "value": "var(--colors-color-palette-400)",
    "variable": "var(--colors-color-palette-400)"
  },
  "colors.colorPalette.500": {
    "value": "var(--colors-color-palette-500)",
    "variable": "var(--colors-color-palette-500)"
  },
  "colors.colorPalette.600": {
    "value": "var(--colors-color-palette-600)",
    "variable": "var(--colors-color-palette-600)"
  },
  "colors.colorPalette.700": {
    "value": "var(--colors-color-palette-700)",
    "variable": "var(--colors-color-palette-700)"
  },
  "colors.colorPalette.800": {
    "value": "var(--colors-color-palette-800)",
    "variable": "var(--colors-color-palette-800)"
  },
  "colors.colorPalette.900": {
    "value": "var(--colors-color-palette-900)",
    "variable": "var(--colors-color-palette-900)"
  },
  "colors.colorPalette.950": {
    "value": "var(--colors-color-palette-950)",
    "variable": "var(--colors-color-palette-950)"
  },
  "colors.colorPalette.dark": {
    "value": "var(--colors-color-palette-dark)",
    "variable": "var(--colors-color-palette-dark)"
  },
  "colors.colorPalette.mid": {
    "value": "var(--colors-color-palette-mid)",
    "variable": "var(--colors-color-palette-mid)"
  },
  "colors.colorPalette.light": {
    "value": "var(--colors-color-palette-light)",
    "variable": "var(--colors-color-palette-light)"
  },
  "colors.colorPalette.disabled": {
    "value": "var(--colors-color-palette-disabled)",
    "variable": "var(--colors-color-palette-disabled)"
  },
  "colors.colorPalette.inverse": {
    "value": "var(--colors-color-palette-inverse)",
    "variable": "var(--colors-color-palette-inverse)"
  },
  "colors.colorPalette.accent.dark": {
    "value": "var(--colors-color-palette-accent-dark)",
    "variable": "var(--colors-color-palette-accent-dark)"
  },
  "colors.colorPalette.accent.mid": {
    "value": "var(--colors-color-palette-accent-mid)",
    "variable": "var(--colors-color-palette-accent-mid)"
  },
  "colors.colorPalette.accent.light": {
    "value": "var(--colors-color-palette-accent-light)",
    "variable": "var(--colors-color-palette-accent-light)"
  },
  "colors.colorPalette.success.dark": {
    "value": "var(--colors-color-palette-success-dark)",
    "variable": "var(--colors-color-palette-success-dark)"
  },
  "colors.colorPalette.success.mid": {
    "value": "var(--colors-color-palette-success-mid)",
    "variable": "var(--colors-color-palette-success-mid)"
  },
  "colors.colorPalette.success.light": {
    "value": "var(--colors-color-palette-success-light)",
    "variable": "var(--colors-color-palette-success-light)"
  },
  "colors.colorPalette.warning.dark": {
    "value": "var(--colors-color-palette-warning-dark)",
    "variable": "var(--colors-color-palette-warning-dark)"
  },
  "colors.colorPalette.warning.mid": {
    "value": "var(--colors-color-palette-warning-mid)",
    "variable": "var(--colors-color-palette-warning-mid)"
  },
  "colors.colorPalette.warning.light": {
    "value": "var(--colors-color-palette-warning-light)",
    "variable": "var(--colors-color-palette-warning-light)"
  },
  "colors.colorPalette.error.dark": {
    "value": "var(--colors-color-palette-error-dark)",
    "variable": "var(--colors-color-palette-error-dark)"
  },
  "colors.colorPalette.error.mid": {
    "value": "var(--colors-color-palette-error-mid)",
    "variable": "var(--colors-color-palette-error-mid)"
  },
  "colors.colorPalette.error.light": {
    "value": "var(--colors-color-palette-error-light)",
    "variable": "var(--colors-color-palette-error-light)"
  },
  "colors.colorPalette.highlight.dark": {
    "value": "var(--colors-color-palette-highlight-dark)",
    "variable": "var(--colors-color-palette-highlight-dark)"
  },
  "colors.colorPalette.highlight.mid": {
    "value": "var(--colors-color-palette-highlight-mid)",
    "variable": "var(--colors-color-palette-highlight-mid)"
  },
  "colors.colorPalette.highlight.light": {
    "value": "var(--colors-color-palette-highlight-light)",
    "variable": "var(--colors-color-palette-highlight-light)"
  },
  "colors.colorPalette.darkest": {
    "value": "var(--colors-color-palette-darkest)",
    "variable": "var(--colors-color-palette-darkest)"
  },
  "colors.colorPalette.lighter": {
    "value": "var(--colors-color-palette-lighter)",
    "variable": "var(--colors-color-palette-lighter)"
  },
  "colors.colorPalette.lightest": {
    "value": "var(--colors-color-palette-lightest)",
    "variable": "var(--colors-color-palette-lightest)"
  },
  "colors.colorPalette.accent.darkest": {
    "value": "var(--colors-color-palette-accent-darkest)",
    "variable": "var(--colors-color-palette-accent-darkest)"
  },
  "colors.colorPalette.accent.lightest": {
    "value": "var(--colors-color-palette-accent-lightest)",
    "variable": "var(--colors-color-palette-accent-lightest)"
  },
  "colors.colorPalette.success.darkest": {
    "value": "var(--colors-color-palette-success-darkest)",
    "variable": "var(--colors-color-palette-success-darkest)"
  },
  "colors.colorPalette.success.lightest": {
    "value": "var(--colors-color-palette-success-lightest)",
    "variable": "var(--colors-color-palette-success-lightest)"
  },
  "colors.colorPalette.warning.darkest": {
    "value": "var(--colors-color-palette-warning-darkest)",
    "variable": "var(--colors-color-palette-warning-darkest)"
  },
  "colors.colorPalette.warning.lightest": {
    "value": "var(--colors-color-palette-warning-lightest)",
    "variable": "var(--colors-color-palette-warning-lightest)"
  },
  "colors.colorPalette.error.darkest": {
    "value": "var(--colors-color-palette-error-darkest)",
    "variable": "var(--colors-color-palette-error-darkest)"
  },
  "colors.colorPalette.error.lightest": {
    "value": "var(--colors-color-palette-error-lightest)",
    "variable": "var(--colors-color-palette-error-lightest)"
  },
  "colors.colorPalette.highlight.darkest": {
    "value": "var(--colors-color-palette-highlight-darkest)",
    "variable": "var(--colors-color-palette-highlight-darkest)"
  },
  "colors.colorPalette.highlight.lightest": {
    "value": "var(--colors-color-palette-highlight-lightest)",
    "variable": "var(--colors-color-palette-highlight-lightest)"
  },
  "colors.colorPalette.wa.dark": {
    "value": "var(--colors-color-palette-wa-dark)",
    "variable": "var(--colors-color-palette-wa-dark)"
  },
  "colors.colorPalette.wa.mid": {
    "value": "var(--colors-color-palette-wa-mid)",
    "variable": "var(--colors-color-palette-wa-mid)"
  },
  "colors.colorPalette.wa.light": {
    "value": "var(--colors-color-palette-wa-light)",
    "variable": "var(--colors-color-palette-wa-light)"
  },
  "colors.colorPalette.wa.lightest": {
    "value": "var(--colors-color-palette-wa-lightest)",
    "variable": "var(--colors-color-palette-wa-lightest)"
  },
  "colors.colorPalette.accent": {
    "value": "var(--colors-color-palette-accent)",
    "variable": "var(--colors-color-palette-accent)"
  },
  "colors.colorPalette.success": {
    "value": "var(--colors-color-palette-success)",
    "variable": "var(--colors-color-palette-success)"
  },
  "colors.colorPalette.warning": {
    "value": "var(--colors-color-palette-warning)",
    "variable": "var(--colors-color-palette-warning)"
  },
  "colors.colorPalette.error": {
    "value": "var(--colors-color-palette-error)",
    "variable": "var(--colors-color-palette-error)"
  },
  "colors.colorPalette.highlight": {
    "value": "var(--colors-color-palette-highlight)",
    "variable": "var(--colors-color-palette-highlight)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar