// @ts-nocheck
import { motion } from "framer-motion";
import {
  LegacyButton,
  ButtonTagVariant,
  LegacyProductTile,
  LegacyTextArea,
  tokens,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { Xmark } from "iconoir-react";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { insertAndAfterLastComma } from "../../../utils/insertAndAfterLastComma";
import { handleBeaconClick } from "../../../utils/helpScout";
import { useCreateNotification } from "../../../hooks/useCreateNotification";
import { useOnboardingContext } from "../Layout";
import SlideInPage from "../../animation/SlideInPage.tsx";
import { GoogleMaps } from "../../../components/shared/GoogleMaps";
import { PlacesAutocompleteProps } from "../../../components/shared/GoogleMaps/PlacesAutocomplete";
import { useSendEmailMutation } from "../../../../gql/generated.ts";

const CREATE_TENANT = gql`
  mutation createTenant(
    $user_id: String
    $operators: String
    $registeredApps: String
    $dmo: String
  ) {
    createTenant(
      user_id: $user_id
      operators: $operators
      registeredApps: $registeredApps
      dmo: $dmo
    ) {
      _id
    }
  }
`;

const UPDATE_USER_METADATA = gql`
  mutation updateUserMetadata(
    $id: String
    $body: String
    $tenantId: String
    $userObject: String
  ) {
    updateUserMetadata(user_id: $id, body: $body) {
      user_id
    }
    addUserToTenant(tenantId: $tenantId, userObject: $userObject) {
      name
    }
  }
`;

export const HelpTemplate = ({ baseURL, next, stepData }) => {
  const { user } = useAuth0();

  const [
    updateUserMetadata,
    { data: dataUser, loading: loadingUser, error: errorUser },
  ] = useMutation(UPDATE_USER_METADATA);
  const [
    sendEmail,
    { data: emailSuccess, loading: emailSending, error: emailError },
  ] = useSendEmailMutation();
  const { businesses, customPortalData } = useOnboardingContext();
  const [selectedBusinesses, setSelectedBusinesses] = useState<
    google.maps.GeocoderResult[]
  >([]);
  const [text, setText] = useState("");
  const businessList = businesses
    .map(business => business.operator_name)
    .join(", ");
  const [address, setAddress] = useState<string[]>([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [fieldDisabled, setFieldDisabled] = useState(false);

  const onChange: PlacesAutocompleteProps["onChange"] = e => {
    setAddress([...address, `${e.formatted_address}`]);
    setSelectedBusinesses([...selectedBusinesses, e]);
  };

  const onEdit = e => {
    setText(e);
  };

  const [createNotification, { loading: notificationLoading }] =
    useCreateNotification();
  const [createTenant, { data: saved }] = useMutation(CREATE_TENANT);
  const navigate = useNavigate();

  useEffect(() => {
    if (address || text) {
      setSubmitDisabled(false);
    }
  }, [address]);

  const onOptionDelete = (option: SelectedBusiness) => {
    setSelectedBusinesses(prev =>
      prev.filter(item => {
        return item !== option;
      }),
    );
  };

  const handleApprove = () => {
    //send Email
    sendEmail({
      variables: {
        input: {
          messageBody: `${user?.email} :: ${text.text} \n\n address: ${address}`,
          senderName: "Whereabouts",
          senderEmail: "help@whereabouts.tech",
          subject: "An onboarding request",
          templateId: "d-7550f868c0e54be7a8d2be42d9895eb7",
          to: "adam@tourism.tech",
        },
        // body: JSON.stringify({
        //   requestedService: "EMAILER",
        //   when: {},
        //   // "when": {
        //   //     "repeating": "false",
        //   //     "timestamp": "Mon, 15 Jan 2024 02:18:00 GMT"
        //   // },
        //   payload: {
        //     to: "whereabouts@tourism.tech",
        //     templateId: "d-94b5c88fac074b37ad334d4bb0bfd834",
        //     emailBody: {
        //       subject: "Whereabouts OnBoarding Request",
        //       senderName: user?.name,
        //       senderAddress: address.full_address,
        //       senderEmail: user?.email,
        //       messageBody: address.full_address,
        //     },
        //   },
        //   logInfo: {
        //     business: "64225441a65afbc1cc8d5594",
        //     label: "Whereabouts Request",
        //   },
        // }),
      },
    }).then(r => console.log(r));
    setSubmitDisabled(true);
    setFieldDisabled(true);

    //run tenant mutation
    createTenant({
      variables: {
        user_id: user?.sub,
        dmo: customPortalData.settings.tenants[0],
        operators: JSON.stringify(businesses.map(b => b._id)),
        registeredApps: JSON.stringify(
          customPortalData.settings.registeredApps,
        ),
      },
    })
      .then(r =>
        updateUserMetadata({
          variables: {
            id: user?.sub,
            body: JSON.stringify({
              app_metadata: {
                tenants: [user?.sub],
                onboarding: { complete: false, pending: true, isAdmin: false },
                request: { text: text, addresses: address },
              },
            }),
            tenantId: JSON.stringify([
              user?.sub,
              customPortalData?.settings?.tenants[0],
            ]),
            userObject: JSON.stringify(user),
          },
        }),
      )
      .then(result => navigate(0));
  };

  return (
    <SlideInPage>
      <div className="tw-w-full md:tw-w-96 tw-mx-auto tw-flex tw-flex-col tw-gap-5">
        <LegacyTypography as="h2" variant="H2">
          {stepData.title}
        </LegacyTypography>
        {businessList ? (
          <LegacyTypography>
            We've matched you to {businessList}, but something's not right?
            Please send us a message! You can help us help you by searching
            below for the business you're missing (though it's not necessary).
          </LegacyTypography>
        ) : (
          <div className="tw-text-left">
            <LegacyTypography>
              We're sorry, but we couldn't match your email with any businesses
              in our database. Please give us a few more details and we'll take
              care of it.
            </LegacyTypography>
          </div>
        )}
        <div className="tw-flex tw-flex-col tw-text-left tw-gap-6">
          <GoogleMaps.PlacesAutocomplete
            onChange={onChange}
            label="Search by Business Name or Address"
          />
          {selectedBusinesses.map(business => {
            const descriptionArray = business.formatted_address.split(",");
            const descriptionName = descriptionArray.shift();
            const descriptionAddress = descriptionArray.join(",");
            return (
              <LegacyProductTile
                outlined
                key={nanoid()}
                style={{
                  padding: `${tokens.spacing.space250} ${tokens.spacing.space300}`,
                  cursor: "default",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className={"tw-flex tw-flex-col tw-gap-1 tw-text-left"}>
                  <LegacyTypography variant={TypographyVariant.primaryBold}>
                    {descriptionName}
                  </LegacyTypography>
                  <LegacyTypography color={tokens.semantic.textSubtle}>
                    {descriptionAddress}
                  </LegacyTypography>
                </div>
                <LegacyButton
                  variant={ButtonTagVariant.Unstyled}
                  icon={<Xmark />}
                  onClick={() => onOptionDelete(business)}
                />
              </LegacyProductTile>
            );
          })}
          <LegacyTextArea
            label={"Send us a message"}
            disabled={fieldDisabled}
            rows={4}
            placeholder=""
            onChange={val =>
              onEdit({
                text: val.target.value,
                date: Math.floor(Date.now() / 1000),
              })
            }
          />
        </div>
        <div className="tw-text-left">
          <LegacyButton
            fullWidth
            disabled={submitDisabled}
            onClick={() => handleApprove()}
            className="tw-ml-auto"
          >
            Send Request
          </LegacyButton>
        </div>
        <LegacyTypography>
          We'll get back to you within 1 business day.
        </LegacyTypography>
        {/*{emailSending && <LegacyLoadingSpinner />}*/}
        {emailError && (
          <>
            <h2>Error sending email</h2>
            <LegacyTypography
              variant={TypographyVariant.tertiaryMedium}
              color={tokens.semantic.textSubtlest}
            >
              Please{" "}
              <button
                className="tw-underline tw-text-textInfo tw-bg-transparent"
                onClick={() => handleBeaconClick(user)}
              >
                contact us
              </button>{" "}
            </LegacyTypography>
          </>
        )}
      </div>
    </SlideInPage>
  );
};
