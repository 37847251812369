import { Table } from "@ttc3k/trekker";
import { EnumRoomType, Maybe, Room, useAmenityByIdsQuery } from "gql/generated";
import _ from "lodash";
import pluralize from "pluralize";

type RoomRowProps = {
  room: Room | Maybe<Room>;
  index: number;
};

export const RoomRow = ({ room, index }: RoomRowProps) => {
  const { data, error, loading } = useAmenityByIdsQuery({
    variables: { ids: room?.amenities },
  });

  if (error && !loading) {
    throw new Error("Error fetching amenities on room");
  }

  if (!room || room?.type === EnumRoomType.Bath) {
    return null;
  }

  const amenities = data?.amenityByIds;

  const amenitiesString = amenities
    ?.map(amenity => {
      const amenityCounts = _.countBy(room.amenities);
      return pluralize(amenity.name.en ?? "", amenityCounts[amenity._id], true);
    })
    .join(", ");

  return (
    <Table.Row>
      <Table.Header>Bedroom {index + 1}</Table.Header>
      <Table.Cell>{amenitiesString}</Table.Cell>
    </Table.Row>
  );
};
