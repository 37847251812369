type GenerateEmbedStringProps = {
  borderRadius?: string;
  color?: string;
  bgColor?: string;
  text?: string;
  bookerPosition?: string;
  bookerId: string;
};

export const generateEmbedStrings = ({
  bookerId,
  bgColor,
  bookerPosition,
  borderRadius,
  color,
  text,
}: GenerateEmbedStringProps) => {
  const isDefaultBorderRadius = !borderRadius || borderRadius === "8";
  const isDefaultColor =
    !color || ["white", "#ffffff", "#FFFFFF"].includes(color);
  const isDefaultBg = !bgColor || bgColor === "#27272A";
  const isDefaultText = text && text === "Book now"

  const styleArray = [
    !isDefaultBorderRadius && borderRadius && borderRadius.length > 0
      ? `border-radius:${borderRadius}px`
      : null,
    !isDefaultColor && color && color.length > 0 ? `color: ${color}` : null,
  ].filter(Boolean);

  const scriptSrc = `https://${
    import.meta.env.VITE_ENVIRONMENT == "production" ? "" : "dev-"
  }api.whereabouts.tech/embeddable/booker/4/latest/main.js`;

  return {
    div: `
      <div class="ttc-booker-container"
        ${styleArray.length > 0 ? `style="${styleArray.join("; ")}"` : ""}
        data-booker-id="${bookerId}"
        ${!isDefaultBg ? `data-button-bg-color="${bgColor}"` : ""}
        ${text && !isDefaultText ? `data-button-copy="${text}"` : ""}
        ${bookerPosition === "left" ? `data-booker-position="left"` : ""}
      ></div>
    `,
    script: `<script type="module" src="${scriptSrc}"></script>`,
  };
};
