import { Button, SectionHeader, toastFactory } from "@ttc3k/trekker";
import { useAppContext } from "core/components/apps/PreferredLayout.tsx";
import { Space } from "core/components/shared/Space";
import { StripeAccountConnect } from "core/components/shared/StripeAccountConnect";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Flex } from "styled-system/jsx";
import { FormProvider } from "react-hook-form";
import { EnumProductType, useProductManyQuery } from "gql/generated";
import { RedirectToOperator } from "../components";
import { useSettingsForm } from "./hooks";
import { CalendarIntegration, PolicySection, TaxSection } from "./components";

export function Settings() {
  const { spaceId } = useParams();
  const { setTitle, setDescription, setActionButton } = useAppContext();
  const { data: productData } = useProductManyQuery({
    variables: { filter: { operator: spaceId } },
  });

  const { form, onSubmit } = useSettingsForm({
    operatorID: spaceId ?? "",
    onSuccess: () => {
      toastFactory.create({
        title: "Success!",
        description: "Your settings have been updated",
      });
    },
    onError: msg => {
      toastFactory.create({
        title: "Error",
        description: msg,
      });
    },
    products: productData,
  });

  const formID = "booker-settings-form";

  useEffect(() => {
    setTitle("Settings");
    setDescription(
      "Manage all of your e-commerce settings below. All of your business information is being pulled directly from the Directory; if you want to make any changes to its profile (address, hours of operation, etc), you can head there to make them at any time.",
    );
    setActionButton({ label: "Save settings", form: formID, type: "submit" });
  }, [setActionButton, setDescription, setTitle]);

  if (!spaceId || spaceId === "undefined") {
    return <RedirectToOperator />;
  }

  const isSomeProductsAccommodations = !!productData?.productMany.some(
    p => p.type === EnumProductType.Accommodation,
  );
  return (
    <Space>
      <Flex flexDir={"column"} gap={"500"} maxW={"712px"}>
        <SectionHeader text="Stripe Account" divider />
        <StripeAccountConnect operatorId={spaceId} />
        <FormProvider {...form}>
          <form id={formID} onSubmit={onSubmit}>
            <Flex flexDir={"column"} gap={"500"} width={"full"}>
              <TaxSection />
              {isSomeProductsAccommodations && productData && (
                <CalendarIntegration products={productData} />
              )}
              <PolicySection />
            </Flex>
          </form>
        </FormProvider>
        <Button form={formID} type={"submit"} size={"lg"}>
          Save settings
        </Button>
      </Flex>
    </Space>
  );
}
