import { gql, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { debounce } from "lodash";
import { useCallback, useState } from "react";

type GetOperatorsVariables = {
  tenants?: string;
  limit?: number;
  offset?: number;
  sort?: string;
  status?: string;
  operator_name?: string;
};

type GetOperatorsDocument = {
  _id: string;
  operator_name?: string;
};

export const GET_OPERATORS = gql`
  query GetOperators(
    $tenants: String
    $limit: Int
    $offset: Int
    $sort: String
    $status: String
    $operator_name: String
  ) {
    getOperators(
      tenants: $tenants
      limit: $limit
      offset: $offset
      sort: $sort
      status: $status
      operator_name: $operator_name
    ) {
      data {
        operator_name
        website
        _id
        status
        updated_at
        address
        email
        tenants
      }
    }
  }
`;

export const useGetOperatorsQuery = () => {
  const { user } = useAuth0();
  const [operatorName, setOperatorName] = useState("");

  const debouncedSearch = useCallback(
    debounce((text: string) => {
      setOperatorName(text.replace(/\(/g, "\\(").replace(/\)/g, "\\)"));
    }, 400),
    [setOperatorName],
  );

  const setSearch = (name: string) => {
    debouncedSearch(name);
  };

  const query = useQuery<
    { getOperators: { data: GetOperatorsDocument[] } },
    GetOperatorsVariables
  >(GET_OPERATORS, {
    skip: !operatorName,
    variables: {
      offset: 0,
      limit: 6,
      tenants: JSON.stringify(user?.whereaboutsTenants),
      operator_name: operatorName,
    },
    fetchPolicy: "no-cache",
  });

  return { ...query, setSearch };
};
