import LogoutButton from "../components/LogoutButton.tsx";
import { LegacyTypography } from "@ttc3k/ttc-design-system";
import HelpButton from "../components/shared/HelpButton";
import { useAuth0 } from "@auth0/auth0-react";

export default function ErrorPage() {
  const user = useAuth0();
  return (
    <div className="tw-h-screen tw-flex tw-flex-col tw-gap-5 tw-items-center tw-justify-center">
      {user && (
        <div className="tw-absolute tw-right-10 tw-top-5 tw-border-0">
          <LogoutButton />
        </div>
      )}
      <LegacyTypography variant={"H3"}>
        Oh no, something went wrong.
      </LegacyTypography>
      <LegacyTypography>
        Please try refreshing the page. Or please get in touch. It's probably an
        easy fix.
      </LegacyTypography>
      <HelpButton />
    </div>
  );
}
