import {
  NewImage,
  useImageCreateMutation,
  useImageRemoveMutation,
  useImageSortOrderMutation,
  useImageUpdateMutation,
} from "gql/generated";
import { MediaConfig } from "core/leaves/Media/index";
import { useState } from "react";
import { handleAsyncError } from "core/utils/alert";

interface UseMediaProps {
  id: string;
  config: MediaConfig;
}

export const useMedia = ({ id, config }: UseMediaProps) => {
  const [createImage, { loading: createImageLoading }] = useImageCreateMutation(
    {
      refetchQueries: [{ query: config.query, variables: { id: id } }],
      awaitRefetchQueries: true,
    },
  );

  const [removeImage] = useImageRemoveMutation({
    refetchQueries: [{ query: config.query, variables: { id: id } }],
  });

  const [updateImage] = useImageUpdateMutation({
    refetchQueries: [{ query: config.query, variables: { id: id } }],
  });

  const [sortOrderImage] = useImageSortOrderMutation({
    refetchQueries: [{ query: config.query, variables: { id: id } }],
  });

  const [deleteImageId, setDeleteImageId] = useState<string | null>(null);

  const handleUploadImages = async args => {
    try {
      if (args.event === "success" && args.info?.secure_url && id) {
        await createImage({
          variables: {
            data: args.info,
            entityId: id,
            entityType: config.entityType,
          },
        });
      }
    } catch (err) {
      handleAsyncError();
    }
  };

  const handleDeleteImage = async image => {
    if (!id || !image._id) return;
    setDeleteImageId(image._id ?? null);
    try {
      await removeImage({
        variables: {
          filter: {
            _id: image._id,
          },
          entityId: id,
          entityType: config.entityType,
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteImageId(null);
    }
  };

  const handleOnSaveImageInfo = async image => {
    if (!id || !image._id) return;

    /* Need to destructure legacy urls to save to DB */
    const {
      _id,
      full_url,
      teaser_url,
      teaser_url_x2,
      thumbnail_url,
      thumbnail_url_x2,
      ...restImg
    } = image;
    try {
      await updateImage({
        variables: {
          id: _id,
          record: restImg,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSortableChange = async (images: Partial<NewImage>[]) => {
    try {
      await sortOrderImage({
        variables: {
          entityType: config.entityType,
          entityId: id,
          images: images.map(image => {
            return image._id;
          }),
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return {
    handleUploadImages,
    handleDeleteImage,
    handleOnSaveImageInfo,
    handleSortableChange,
    deleteImageId,
    createImageLoading,
  };
};
