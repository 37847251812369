import { useCallback } from "react";
import { z } from "zod";
import { useFormHandler } from "core/hooks/useFormHandler";
import { phoneNumberRegex } from "booker/shared/constants";
import { useBookerSendContactUsEmailLazyQuery } from "gql/generated";
import { useBookerContext } from "booker/contexts";
import {
  MatomoEvent,
  useMatomoAnalyticsContext,
} from "@ttc3k/ttc-bkr-analytics";

const contactUsFormSchema = z.object({
  name: z.string().min(1, "Please enter your name"),
  email: z
    .string()
    .min(1, "Please enter your email")
    .email("Please enter a valid email"),
  phone: z
    .string()
    .min(1, "Please enter a phone number")
    .regex(phoneNumberRegex, "Please enter a valid phone number"),
  message: z.string().min(1, "Please enter a message"),
});

export type ContactUsFormValues = z.infer<typeof contactUsFormSchema>;

interface ContactUsFormOptions {
  onSuccess?: (v: ContactUsFormValues | undefined) => void;
  onError?: (message: string) => void;
}

export const useContactUsForm = ({
  onSuccess,
  onError,
}: ContactUsFormOptions) => {
  const defaultValues: ContactUsFormValues = {
    email: "",
    message: "",
    name: "",
    phone: "",
  };

  const formErrorMessage = "Email was unsuccessful, please try again later";

  const [sendContactUsEmail, { loading }] =
    useBookerSendContactUsEmailLazyQuery();
  const { operator, product } = useBookerContext();
  const { pushMatomoEvent } = useMatomoAnalyticsContext();

  const formHandler = useFormHandler<ContactUsFormValues>(
    useCallback(
      async data => {
        const payload = { ...data };
        const { email, message, name, phone } = payload;

        if (email && message && name && phone) {
          const { data: res, error } = await sendContactUsEmail({
            variables: {
              details: { email, name, phone, message },
              operatorId: operator?._id,
              productId: product?._id,
            },
          });

          if (res?.bookerSendContactUsEmail?.success) {
            onSuccess?.(data as ContactUsFormValues);
            pushMatomoEvent({
              event: MatomoEvent.ContactUsEmailSent,
              customerName: name,
              customerEmail: email,
            });
          } else if (error) {
            onError?.(formErrorMessage);
          } else {
            onError?.(formErrorMessage);
          }
        }
      },
      [
        sendContactUsEmail,
        operator?._id,
        product?._id,
        onSuccess,
        pushMatomoEvent,
        onError,
      ],
    ),
    defaultValues,
    { schema: contactUsFormSchema },
  );

  return { ...formHandler, loading };
};
