function unlockScrollbar() {
  const prevOverflow = document.body.getAttribute("data-bs-overflow");
  const prevScrollGutter = document.body.getAttribute(
    "data-bs-scrollbarGutter",
  );
  const prevDocOverflow =
    document.documentElement.getAttribute("data-doc-overflow");
  document.body.removeAttribute("data-bs-overflow");
  document.documentElement.removeAttribute("data-doc-overflow");
  document.body.style.overflow = prevOverflow ?? "visible";
  document.documentElement.style.overflow = prevDocOverflow ?? "visible";
  document.body.style.scrollbarGutter = prevScrollGutter ?? "auto";
}

export { unlockScrollbar };
