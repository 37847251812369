//@ts-nocheck
import { motion } from "framer-motion";
import {
  LegacyButton,
  Sizes,
  LegacyTypography,
  ButtonTagVariant,
} from "@ttc3k/ttc-design-system";
import { Link, useLocation } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import SlideInPage from "../../animation/SlideInPage.tsx";

export const ChoiceTemplate = ({ baseURL, next, stepData }) => {
  const { user } = useAuth0();
  const { state } = useLocation();

  return (
    <SlideInPage>
      <div className="tw-flex tw-flex-col tw-gap-5">
        <div className="tw-w-full md:tw-w-96 tw-mx-auto tw-flex tw-flex-col tw-gap-1">
          <LegacyTypography as="h2" variant="H2">
            {stepData.title}
          </LegacyTypography>
          <LegacyTypography>{stepData.description}</LegacyTypography>
          <div className="tw-w-full tw-flex tw-justify-between tw-gap-3">
            <Link
              state={{ email: state?.email }}
              className={"tw-pt-6 tw-w-full"}
              to={stepData.alternateChoice.link}
            >
              <LegacyButton
                type="button"
                fullWidth
                size={Sizes.Large}
                variant={ButtonTagVariant.Secondary}
              >
                {stepData.alternateChoice.label}
              </LegacyButton>
            </Link>
            <Link
              state={{ email: state?.email }}
              className={"tw-pt-6 tw-w-full"}
              to={`${baseURL}/${next}`}
            >
              <LegacyButton fullWidth size={Sizes.Large}>
                No
              </LegacyButton>
            </Link>
          </div>
        </div>
      </div>
    </SlideInPage>
  );
};
