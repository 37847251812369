import { parseToUTCDate } from "core/utils/parseToUTCDate";
import { compareAsc, isSameDay, isWithinInterval } from "date-fns";
import { NewClosure } from "../hooks";

type filterClosuresByDateProps = {
  closures: NewClosure[];
  selectedDate: string;
};

export const filterClosuresByDate = ({
  closures,
  selectedDate,
}: filterClosuresByDateProps) => {
  if (selectedDate && selectedDate.length > 0) {
    // Filter the closures by the start & end date range
    // Sort them in ascending order by the start date
    const filteredClosures = closures
      .filter(closure => {
        const closureDateRangeISO = closure.dateRangeISO;

        if (closureDateRangeISO) {
          return (
            isWithinInterval(parseToUTCDate(selectedDate), {
              start: parseToUTCDate(closureDateRangeISO?.start),
              end: parseToUTCDate(closureDateRangeISO?.end),
            }) ||
            isSameDay(
              parseToUTCDate(selectedDate),
              parseToUTCDate(closureDateRangeISO.start),
            )
          );
        } else return false;
      })
      .sort((a, b) => {
        const aClosureDateRangeISO = a.dateRangeISO;
        const bClosureDateRangeISO = b.dateRangeISO;

        const aDate = aClosureDateRangeISO?.start
          ? parseToUTCDate(aClosureDateRangeISO.start)
          : new Date(0);
        const bDate = bClosureDateRangeISO?.start
          ? parseToUTCDate(bClosureDateRangeISO.start)
          : new Date(0);

        return compareAsc(aDate, bDate);
      });

    return filteredClosures;
  } else return [];
};
