import {
  Heading,
  Icon,
  Link,
  SectionHeader,
  Table,
  Text,
} from "@ttc3k/trekker";
import { CartItem } from "apps/custom/ttc/booker/contexts";
import { CheckoutSummary } from "apps/custom/ttc/booker/shared";
import {
  OperatorByIdQuery,
  OrderByIdQuery,
  PricePerProduct,
  PurchasedProduct,
  useOrderPricesByIdQuery,
  useRefundManyQuery,
} from "gql/generated";
import {
  CreditCard,
  HomeAlt,
  List,
  OpenNewWindow,
  ProfileCircle,
} from "iconoir-react";
import { Flex, styled } from "styled-system/jsx";
import { MapBlock } from "../GoogleMaps/MapBlock";
import { createGoogleMapsUrl } from "../GoogleMaps";
import { TripDetails } from "./TripDetails";
import { RefundFlags } from "./RefundFlags";
import { RefundSummary } from "./RefundSummary";

type ReservationPageProps = {
  order: NonNullable<OrderByIdQuery["orderById"]>;
  operator?: OperatorByIdQuery["operatorById"];
  isPublicPage?: boolean;
};

export const ReservationPage = ({
  order,
  operator,
  isPublicPage = false,
}: ReservationPageProps) => {
  const { data } = useOrderPricesByIdQuery({
    variables: {
      orderId: order._id ?? "",
    },
  });

  const { data: refundData } = useRefundManyQuery({
    variables: { filter: { orderID: order._id } },
  });

  const operatorAddress = operator?.contact.address;

  const mainProduct = order.purchasedProducts[0];

  return (
    <Flex
      pt={{ base: "400", md: "60px" }}
      px={{ base: "300", md: "80px" }}
      pb={{ base: "100px", md: "120px" }}
      gap={"600"}
      justifyContent={"space-between"}
      flexDir={{ base: "column-reverse", lg: "row" }}
    >
      <Flex
        w={{ base: "100%", lg: "772px" }}
        minW={"320px"}
        alignSelf={"stretch"}
        flexDir={"column"}
        gap={"500"}
      >
        <RefundFlags
          refundData={refundData}
          isPublicPage={isPublicPage}
          orderGrandTotal={data?.orderPricesById?.grandTotal ?? 0}
          orderCustomerName={order.customerContact.name}
          operatorName={operator?.name}
        />
        {data?.orderPricesById && (
          <Flex width={"100%"} flexDir={"column"} gap={"150"}>
            <SectionHeader Icon={CreditCard} text="Order Summary" divider />
            <CheckoutSummary
              cart={convertOrderPurchasesToCart(
                order.purchasedProducts as PurchasedProduct[],
                data.orderPricesById.productPrices,
              )}
              grandTotal={data.orderPricesById.grandTotal}
              subTotal={data.orderPricesById.subTotal}
              tax={data.orderPricesById.taxTotal}
              isReservationPage
            />
          </Flex>
        )}
        <RefundSummary refundData={refundData} />
        {order.customerContact.guests &&
          order.customerContact.guests.length > 0 && (
            <Flex width={"full"} flexDir={"column"} gap={"150"}>
              <SectionHeader Icon={ProfileCircle} text="Guests" divider />
              <Table.Root>
                <Table.Body>
                  {order.customerContact.guests.map(guest => {
                    if (!guest) return null;
                    return (
                      <Table.Row key={guest._id}>
                        <Table.Header>{guest.name}</Table.Header>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table.Root>
            </Flex>
          )}
        {operator && operatorAddress && isPublicPage && (
          <Flex width={"full"} flexDir={"column"} gap={"300"}>
            <SectionHeader Icon={HomeAlt} text="Location" divider>
              <Link
                size={"sm"}
                href={createGoogleMapsUrl(operatorAddress)}
                target={"_blank"}
              >
                Open in Google Maps&nbsp;
                <Icon Element={OpenNewWindow} size={"sm"} />
              </Link>
            </SectionHeader>
            <Text visual={"bodyMedium"} color={"text.mid"}>
              {operatorAddress.line1}, {operatorAddress.city}
              &nbsp;{operatorAddress.subdivision}&nbsp;
              {operatorAddress.postalCode}
            </Text>
            {operatorAddress.location?.coordinates && (
              <MapBlock
                coordinates={operatorAddress.location.coordinates}
                w={"full"}
                height={"335px"}
                borderRadius={"100"}
                overflow={"hidden"}
              />
            )}
            <Text color={"text.mid"}>{operator.description?.en}</Text>
          </Flex>
        )}
        {mainProduct && isPublicPage && (
          <Flex width={"full"} flexDir={"column"} gap={"300"}>
            <SectionHeader Icon={List} text="Product Details" divider />
            <Flex
              flexDir={{ base: "column-reverse", md: "row" }}
              justifyContent={"space-between"}
              gap={"300"}
            >
              <Flex flexDir={"column"} gap={"100"}>
                <Heading size={"h5"} color={"text.dark"}>
                  {mainProduct.product.name.en}
                </Heading>
                <Text color={"text.mid"}>
                  {mainProduct.product.description?.en}
                </Text>
              </Flex>
              {mainProduct.product.images.length > 0 && (
                <styled.img
                  src={mainProduct.product.images[0]?.thumbnail_url}
                  alt={
                    mainProduct.product.images[0]?.caption?.en ??
                    mainProduct.product.name.en ??
                    ""
                  }
                  width={"140px"}
                  height={"140px"}
                  borderRadius={"100"}
                  aspectRatio={"square"}
                />
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
      <TripDetails order={order} isPublicPage={isPublicPage} />
    </Flex>
  );
};

function convertOrderPurchasesToCart(
  purchasedProducts: PurchasedProduct[],
  pricePerProduct: PricePerProduct[],
): CartItem[] {
  return purchasedProducts.map(p => {
    const productTotal = pricePerProduct.find(
      v => v.productID === p.product._id,
    );
    const claimedDateRange = p.inventoryClaims[0]?.claimedDateRangeISO;
    return {
      endDateISO: claimedDateRange?.end ?? "",
      startDateISO: claimedDateRange?.start ?? "",
      guestsPerTier: p.guestsPerTier ?? [],
      productID: p.product._id,
      productName: p.product.name.en ?? "",
      productType: p.product.type,
      total: productTotal?.price ?? 0,
    } as CartItem;
  });
}
