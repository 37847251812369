import { Heading, Input, Tag, Text } from "@ttc3k/trekker";
import { Box, Divider, Flex } from "styled-system/jsx";
import { useBookerContext } from "booker/contexts";
import { AnimatePresence, motion } from "framer-motion";

type GuestNamesProps = {
  guestNames: string[];
  onUpdateGuestNames: (name: string, index: number) => void;
};

export const GuestNames = ({
  guestNames,
  onUpdateGuestNames,
}: GuestNamesProps) => {
  const { product } = useBookerContext();
  const guestMetadata = product?.accommodationMetadata?.guestMetadata;
  if (!guestMetadata?.nameEntryAllowed && !guestMetadata?.nameEntryRequired) {
    return null;
  }
  return (
    <Flex flexDir={"column"} gap={"250"}>
      <Flex width={"full"} gap={"200"} alignItems={"center"}>
        <Box w={"max-content"}>
          <Heading size={"h5"} color={"text.dark"} w={"max-content"}>
            Guest names
          </Heading>
        </Box>
        <Divider borderColor={"border.light"} />
        <Box w={"max"}>
          {guestMetadata?.nameEntryRequired ? (
            <Tag visual={"warning"}>Required</Tag>
          ) : (
            <Tag visual={"primary"} mode={"light"}>
              Optional
            </Tag>
          )}
        </Box>
      </Flex>
      <Text color={"text.mid"}>
        Set your group size above and then enter each guest&apos;s full name
        below
      </Text>
      <AnimatePresence>
        {guestNames.map((g, i) => {
          return (
            <motion.div
              key={i}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <Input
                label={`Guest ${i + 1}`}
                value={g}
                onChange={e => {
                  onUpdateGuestNames(e.target.value, i);
                }}
                bg={"white"}
              />
            </motion.div>
          );
        })}
      </AnimatePresence>
    </Flex>
  );
};
