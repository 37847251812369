import {
  LegacyButton,
  ButtonTagVariant,
  LegacyModal,
  ModalVariant,
  LegacyTypography,
  TypographyVariant,
  tokens,
} from "@ttc3k/ttc-design-system";
import React, { useState } from "react";
import { RefreshDouble, Xmark } from "iconoir-react";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import { TextArea } from "../TextArea";
import { handleAsyncError } from "../../../utils/alert";
import { useGenerateSummaryMutation } from "../../../../gql/generated";

type SummarizeModalProps<T extends FieldValues> = {
  modalOverlayRef: React.RefObject<HTMLDivElement>;
  onModalClose: () => void;
  inputField: Path<T>;
  outputField: Path<T>;
};

export const SummarizeModal = <T extends FieldValues>({
  modalOverlayRef,
  onModalClose,
  inputField,
  outputField,
}: SummarizeModalProps<T>) => {
  const [summary, setSummary] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [generateSummary, { loading }] = useGenerateSummaryMutation();
  const { watch, setValue } = useFormContext();
  const description = watch(inputField);

  const handleClose = () => {
    setIsSubmitted(false);
    setSummary("");
    onModalClose();
  };

  const handleSummarize = async () => {
    try {
      const resp = await generateSummary({
        variables: { input: { text: description } },
      });
      setSummary(resp.data?.summarize.response || "");
      setIsSubmitted(true);
    } catch (error) {
      handleAsyncError();
    }
  };

  const handleSave = async () => {
    /**
     * We decided to type the inputField and outputField as
     * Path<TFieldValues> to ensure that the fields edited are a key of
     * the schema used. Although this type is an alias for a string,
     * the summary string type is incompatible.
     */
    setValue(outputField as string, summary, {
      shouldTouch: true,
      shouldDirty: true,
    });
    handleClose();
  };

  return (
    <LegacyModal
      variant={ModalVariant.wbts}
      modalOverlayRef={modalOverlayRef}
      onModalClose={handleClose}
    >
      <div className="tw-flex tw-flex-col tw-gap-4">
        {/* Header */}
        <div className="tw-flex tw-justify-between">
          <LegacyTypography variant={TypographyVariant.h5}>
            Summarize with AI
          </LegacyTypography>
          <LegacyButton
            variant={ButtonTagVariant.Unstyled}
            color={tokens.semantic.iconSubtlest}
            icon={<Xmark strokeWidth={2} height={24} width={24} />}
            onClick={handleClose}
          />
        </div>

        {/* Content */}
        <LegacyTypography color={tokens.semantic.textSubtle}>
          Use AI to summarize your description into 120 words and then edit the
          result to your liking.
        </LegacyTypography>
        <LegacyTypography
          variant={TypographyVariant.secondaryBold}
          color="#71717A"
          className="tw-uppercase"
        >
          current description
        </LegacyTypography>
        <span className="tw-border-l-4 tw-pl-5 tw-border-[#D4D4D8] tw-italic tw-text-[#71717A]">
          {description}
        </span>
        {isSubmitted && (
          <>
            <LegacyTypography
              variant={TypographyVariant.secondaryBold}
              color="#71717A"
              className="tw-uppercase"
            >
              summarized description
            </LegacyTypography>
            <TextArea
              placeholder="Hold tight, the magic is happening..."
              value={summary}
              onChange={e => setSummary(e.target.value)}
            />
          </>
        )}

        {/* Footer */}
        <div className="tw-w-full tw-flex tw-justify-between tw-gap-3 tw-mt-5">
          <LegacyButton
            variant={ButtonTagVariant.Secondary}
            onClick={handleClose}
            type="button"
          >
            Cancel
          </LegacyButton>
          <div className="tw-w-full tw-flex tw-justify-end tw-gap-3">
            <LegacyButton
              variant={
                isSubmitted
                  ? ButtonTagVariant.Secondary
                  : ButtonTagVariant.InfoBold
              }
              type="button"
              onClick={handleSummarize}
              icon={isSubmitted && <RefreshDouble />}
              iconStart
              disabled={loading}
            >
              {isSubmitted ? "Re-generate" : "Summarize!"}
            </LegacyButton>
            {isSubmitted && (
              <LegacyButton
                variant={ButtonTagVariant.InfoBold}
                type="button"
                iconStart
                disabled={loading}
                onClick={handleSave}
              >
                Save
              </LegacyButton>
            )}
          </div>
        </div>
      </div>
    </LegacyModal>
  );
};
