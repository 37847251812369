import { Heading, Table } from "@ttc3k/trekker";
import { formatPrice } from "core/utils/formatPrice";
import { Flex } from "styled-system/jsx";
import { useBookerContext } from "booker/contexts";
import { EnumProductType } from "gql/generated";
import { RoomRow } from "./RoomRow";
import { ShowAmenities } from "./ShowAmenities";

export const ProductDetails = () => {
  const { product } = useBookerContext();

  const accommodationPriceTier = product?.pricing.tiers.find(
    v => v?.price && v.price > 0,
  );
  return (
    <Flex flexDir={"column"} gap={"250"}>
      <Heading size="h5" color="text.dark">
        Product Details
      </Heading>
      <Flex flexDir={"column"} gap={"200"}>
        {product &&
          product.type === EnumProductType.Accommodation &&
          product.accommodationMetadata?.guestMetadata && (
            <Table.Root>
              <Table.Caption color={"text.light!"} fontWeight={"semiBold!"}>
                Occupancy
              </Table.Caption>
              <Table.Body>
                <Table.Row>
                  <Table.Header>Minimum</Table.Header>
                  <Table.Cell>
                    {product.accommodationMetadata.guestMetadata.minimumNumber}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Header>Maximum</Table.Header>
                  <Table.Cell>
                    {product.accommodationMetadata.guestMetadata.maximumNumber}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table.Root>
          )}
        <Table.Root>
          <Table.Caption color={"text.light!"} fontWeight={"semiBold!"}>
            Pricing
          </Table.Caption>
          <Table.Body>
            {product?.type === EnumProductType.Accommodation ? (
              <>
                {product && accommodationPriceTier && (
                  <Table.Row>
                    <Table.Header>Nightly rate</Table.Header>
                    <Table.Cell>
                      ${formatPrice(accommodationPriceTier.price)}
                    </Table.Cell>
                  </Table.Row>
                )}
              </>
            ) : (
              <>
                {product &&
                  product.pricing.tiers.map(tier => {
                    if (!tier) return null;
                    return (
                      <Table.Row key={tier._id}>
                        <Table.Header>{tier.label?.en}</Table.Header>
                        <Table.Cell>${formatPrice(tier.price)}</Table.Cell>
                      </Table.Row>
                    );
                  })}
              </>
            )}
          </Table.Body>
        </Table.Root>
        {product &&
          product.accommodationMetadata?.rooms &&
          product.accommodationMetadata.rooms.length > 0 && (
            <Table.Root>
              <Table.Caption color={"text.light!"} fontWeight={"semiBold!"}>
                Bedrooms
              </Table.Caption>
              <Table.Body>
                {product.accommodationMetadata.rooms.map((room, i) => {
                  return <RoomRow key={room?._id} room={room} index={i} />;
                })}
              </Table.Body>
            </Table.Root>
          )}
      </Flex>
      <ShowAmenities />
    </Flex>
  );
};
