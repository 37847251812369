import { Button, SectionHeader } from "@ttc3k/trekker";
import { EnumProductType, ProductManyQuery } from "gql/generated";
import { Calendar, HelpCircle } from "iconoir-react";
import { Flex } from "styled-system/jsx";
import { useState } from "react";
import { IntegrateExternalCalendar } from "./IntegrateExternalCalendar";
import { ExportBookerCalendar } from "./ExportBookerCalendar";
import { CalendarIntegrationHelpModal } from "./CalendarIntegrationHelpModal";

type CalendarIntegrationProps = {
  products: ProductManyQuery;
};

export const CalendarIntegration = ({ products }: CalendarIntegrationProps) => {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const productOptions = products.productMany
    .filter(p => p.type === EnumProductType.Accommodation)
    .map(p => ({
      value: p._id,
      label: p.name.en ?? p.name.es ?? p.name.fr ?? "Unknown name",
    }));

  return (
    <>
      <Flex flexDir={"column"} gap={"300"} width={"full"}>
        <SectionHeader text="Calendar Sync" Icon={Calendar} divider>
          <Button
            size={"sm"}
            visual={"outline"}
            Icon={HelpCircle}
            type={"button"}
            onClick={() => setIsHelpModalOpen(true)}
            css={{ "& svg": { strokeWidth: "2px" } }}
          >
            Help
          </Button>
        </SectionHeader>

        <ExportBookerCalendar productOptions={productOptions} />
        <IntegrateExternalCalendar productOptions={productOptions} />
      </Flex>
      <CalendarIntegrationHelpModal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
      />
    </>
  );
};
