import { LegacyTypography } from "@ttc3k/ttc-design-system";
import HelpButton from "../../components/shared/HelpButton";
import Logout from "../../components/LogoutButton.tsx";
import { useAuth0 } from "@auth0/auth0-react";

export default function Onboarding404() {
  const { user } = useAuth0();
  return (
    <>
      {user && (
        <div className="tw-absolute tw-right-10 tw-top-5 tw-border-0">
          <Logout />
        </div>
      )}
      <div className="tw-h-screen tw-gap-5 tw-flex tw-flex-col tw-justify-center tw-items-center">
        <LegacyTypography variant="H3">
          Hmmm, something is wrong with that URL.
        </LegacyTypography>
        <LegacyTypography>
          Please check that you have the right link for the DMO or program that
          you're trying to join.
        </LegacyTypography>
        <HelpButton />
      </div>
    </>
  );
}
