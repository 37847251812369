import { Card } from "@ttc3k/trekker";
import { ComponentProps } from "react";
import { RecipeVariantProps, cva } from "styled-system/css";
import { styled } from "styled-system/jsx";

const buttonCardSurfaceStyles = cva({
  base: {
    width: "full",
    outline: "0 transparent solid",
    transitionDuration: "normal",
    transitionProperty: "border, outline",
    transitionTimingFunction: "default",
    boxShadow: "none",
    _hover: {
      borderColor: "border.dark",
      outlineWidth: "1px",
      outlineColor: "border.dark",
    },
  },
  variants: {
    visual: {
      neutral: {},
      selected: {
        borderColor: "border.dark",
        outlineWidth: "1px",
        outlineColor: "border.dark",
      },
    },
  },
  defaultVariants: {
    visual: "neutral",
  },
});

const ButtonCardSurface = styled(Card.Surface, buttonCardSurfaceStyles);
const WrapperButton = styled("button");

type ButtonCardVariantProps = NonNullable<
  RecipeVariantProps<typeof buttonCardSurfaceStyles>
>;

type ButtonCardProps = Card.TitleProps &
  ButtonCardVariantProps &
  ComponentProps<typeof WrapperButton>;

export const ButtonCard = ({
  mainText,
  subText,
  visual,
  ...rest
}: ButtonCardProps) => {
  return (
    <WrapperButton type={"button"} textAlign={"left"} {...rest}>
      <ButtonCardSurface visual={visual}>
        <Card.Body bg="white" py={"250"}>
          <Card.BodyContent alignItems="center">
            <Card.Title mainText={mainText} subText={subText} />
          </Card.BodyContent>
        </Card.Body>
      </ButtonCardSurface>
    </WrapperButton>
  );
};
