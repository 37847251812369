import { WhereaboutsModal } from "core/components/shared/WhereaboutsModal";
import { PlusCircle, WarningTriangle } from "iconoir-react";
import { useState } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import { Button, Card, SectionHeader, toastFactory } from "@ttc3k/trekker";
import {
  ProductManyDocument,
  useUpdateClosuresPerProductMutation,
} from "gql/generated";
import { Box, Flex } from "styled-system/jsx";
import { ClosureFormComponents } from "./ClosureFormComponents";
import { useEditClosureForm } from "./useEditClosureForm";

type EditClosureModalProps = {
  isOpen: boolean;
  onClose: () => void;
  operatorID: string;
  closureID: string;
};

export const EditClosureModal = ({
  isOpen,
  onClose,
  operatorID,
  closureID,
}: EditClosureModalProps) => {
  const [formError, setFormError] = useState("");
  const { form, onSubmit, defaultValues } = useEditClosureForm({
    onSuccess: data => {
      form.reset(defaultValues);
      setFormError("");
      onClose();
      toastFactory.create({
        title: "Success!",
        description: `${data?.name} closure was updated successfully`,
      });
    },
    onError: m => setFormError(m),
    closureID,
  });

  const [updateClosures] = useUpdateClosuresPerProductMutation();

  const handleCancel = () => {
    onClose();
    form.reset(defaultValues);
  };

  const handleDeleteClosure = async () => {
    try {
      await updateClosures({
        variables: { closureId: closureID, deleteClosure: true },
        refetchQueries: [ProductManyDocument],
      });
      form.reset(defaultValues);
      setFormError("");
      onClose();
      toastFactory.create({
        title: "Success!",
        description: "This closure was deleted successfully",
      });
    } catch {
      setFormError(
        "There was an error trying to delete this closure. Please try again later",
      );
    }
  };

  const selectedProductIDs = useWatch({
    name: "productIDs",
    control: form.control,
  });

  const isProductIDsDirty =
    !defaultValues?.productIDs?.every(id => {
      if (id) {
        return selectedProductIDs.includes(id);
      }
      return false;
    }) || defaultValues?.productIDs?.length !== selectedProductIDs.length;

  const isFormDirty = form.formState.isDirty || isProductIDsDirty;
  const formID = "booker-edit-closure-form";
  return (
    <WhereaboutsModal
      isOpen={isOpen}
      onClose={handleCancel}
      title={"Edit Closure"}
      HeaderIcon={PlusCircle}
      cancelButtonProps={{ onClick: handleCancel }}
      saveButtonProps={{ form: formID, type: "submit", disabled: !isFormDirty }}
    >
      <FormProvider {...form}>
        <form onSubmit={onSubmit} id={formID}>
          <ClosureFormComponents
            operatorID={operatorID}
            formError={formError}
            isEditingClosure
          />
          <Flex
            flexDir={"column"}
            gap={"150"}
            mt={"250"}
            color={"text.error.mid"}
          >
            <SectionHeader text={"Danger Zone"} Icon={WarningTriangle} />
            <Card.Surface bg={"white"} boxShadow={"none"}>
              <Card.Body>
                <Card.BodyContent color={"text.dark"} alignItems={"center"}>
                  <Card.Title
                    mainText={"Delete this closure"}
                    subText={
                      "Bookings will be re-opened for all affected products as soon as this closure is deleted."
                    }
                  />
                  <Box>
                    <Button
                      visual={"destructive"}
                      onClick={handleDeleteClosure}
                      type={"button"}
                    >
                      Delete
                    </Button>
                  </Box>
                </Card.BodyContent>
              </Card.Body>
            </Card.Surface>
          </Flex>
        </form>
      </FormProvider>
    </WhereaboutsModal>
  );
};
