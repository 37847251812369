import { Box, Flex } from "styled-system/jsx";
import { Text } from "@ttc3k/trekker";
export const BuildingSite = () => {
  return (
    <Flex flexDir={"column"} gap={"8px"}>
      <Flex flexDir={"row"} gap={"8px"} alignItems={"center"}>
        <Box w={"12px"} h={"12px"} bg={"bg.warning.dark"} rounded={"full"} />
        <Text visual={"bodySemiBold"} color={"text.dark"}>
          Building your site...
        </Text>
      </Flex>
      <Text visual={"smallMedium"} color={"text.mid"}>
        We’re building your site! This typically takes 5-10 minutes. Please
        check back here and we’ll let you know when it’s ready.
      </Text>
    </Flex>
  );
};
