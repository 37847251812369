import { BookerPosition } from "apps/custom/ttc/booker/App";
import { useBookerByMainProductIdQuery } from "gql/generated";
import { useMemo, useState } from "react";
import { generateEmbedStrings } from "../../../embed/utils";

type EmbedStyle = {
  text: string;
  bgColor: string;
  color: string;
  borderRadius: string;
  bookerPosition: BookerPosition;
};

type EmbedDetailsReturn = {
  style: EmbedStyle;
  onUpdateEmbedStyle: (value: string, key: keyof EmbedStyle) => void;
  onResetEmbedStyle: () => void;
  embedCodeString: { div: string; script: string };
  bookerID: string | undefined;
};

export const useEmbedDetails = (productID: string): EmbedDetailsReturn => {
  const { data } = useBookerByMainProductIdQuery({
    variables: { productId: productID },
  });
  const defaultStyle: EmbedStyle = {
    text: "Book now",
    bgColor: "#27272A",
    color: "white",
    borderRadius: "8",
    bookerPosition: "right",
  };
  const [style, setStyle] = useState<EmbedStyle>(defaultStyle);

  const handleUpdateEmbedStyle = (value: string, key: keyof EmbedStyle) => {
    setStyle(prev => ({ ...prev, [key]: value }));
  };

  const handleResetEmbedStyle = () => {
    setStyle(defaultStyle);
  };

  const embedCodeString = useMemo(() => {
    return generateEmbedStrings({
      bookerId: data?.bookerByMainProductId?._id ?? "",
      bgColor: style.bgColor,
      bookerPosition: style.bookerPosition,
      borderRadius: style.borderRadius,
      color: style.color,
      text: style.text,
    });
  }, [
    data?.bookerByMainProductId?._id,
    style.bgColor,
    style.bookerPosition,
    style.borderRadius,
    style.color,
    style.text,
  ]);

  return {
    style,
    onUpdateEmbedStyle: handleUpdateEmbedStyle,
    onResetEmbedStyle: handleResetEmbedStyle,
    embedCodeString: embedCodeString,
    bookerID: data?.bookerByMainProductId?._id,
  };
};
