// @ts-nocheck

import { LegacyButton, LegacyTypography } from "@ttc3k/ttc-design-system";
import { motion } from "framer-motion";
import { nanoid } from "nanoid";
import { Card, Tag } from "@ttc3k/trekker";
import { styled } from "styled-system/jsx";
import { useRef, useState } from "react";
import { Widget } from "@typeform/embed-react";
import {
  EnumLearnerActivityLogEventType,
  GetCoursesByQuery,
  GetOperatorsQuery,
  PersonCoursesUpdateStatusEnum,
  useLearnerActivityLogCreateMutation,
  usePersonCourseUpdateMutation,
} from "gql/generated.ts";
import FullScreenModal from "../../components/FullScreenModal.tsx";
import { FetchMoreProps } from "apps/library/directory/api/typeDefs.ts";
import Plausible from "plausible-tracker";

export interface AllOperatorsProps {
  data: GetCoursesByQuery["coursesBy"];
  fetchMore: (params: { variables: FetchMoreProps }) => void;
  previousLocation: Location;
  isAdmin: boolean;
  filterStatus: string;
  selected?: Map<string, any>;
  onSelect?: (item: GetOperatorsQuery["getOperators"]["data"][0]) => void;
  profileId?: string;
}

export default function AllEntities({
  data,
  profileData,
  fetchMore,
  previousLocation,
  isAdmin,
  filterStatus,
  selected,
  onSelect,
  refetchPeople,
  user,
  dmo,
  learnerCourseData,
}: AllOperatorsProps) {
  const [openCourse, setOpenCourse] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const { trackEvent } = Plausible();
  const [learnerActivityLogCreate] = useLearnerActivityLogCreateMutation();
  const [personCourseUpdate] = usePersonCourseUpdateMutation();

  const handleCourseOpen = course => {
    trackEvent("Learner: Launch Course", {
      props: {
        "Course Name": course.courseName,
        Learner: `${profileData.firstName} ${profileData.lastName}`,
      },
    });
    setSelectedCourse(course);
    setOpenCourse(!openCourse);
  };

  const handleCourseClose = () => {
    refetchPeople({ limit: 1, auth0Id: user?.sub });
    setOpenCourse(!openCourse);
  };

  const ref = useRef(null);

  const renderCourse = course => {
    switch (course?.packageType) {
      case "SCORM":
        return (
          <iframe
            src={course.packageUrl}
            ref={ref}
            height="100%"
            width="100%"
          />
        );
      case "TYPEFORM":
        return (
          <Widget
            id={course.typeformId}
            style={{ width: "100%", height: "100%" }}
            className="my-form"
          />
        );
      case "PDF":
        return (
          <div
            className={
              "tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-5"
            }
          >
            <img src={course.coverImage.full_url} className={"tw-w-[300px]"} />
            <LegacyTypography variant={"H2"}>
              {course.courseName}
            </LegacyTypography>

            <div style={{ width: "100%", maxWidth: "450px" }}>
              <LegacyTypography>{course.description}</LegacyTypography>
            </div>
            <LegacyButton
              onClick={() => {
                const courseProgressData = profileData?.learner?.courses?.find(
                  c => c.courseId == selectedCourse.id,
                );
                if (courseProgressData !== "COMPLETE") {
                  learnerActivityLogCreate({
                    variables: {
                      record: {
                        eventType: EnumLearnerActivityLogEventType.Completed,
                        courseId: selectedCourse?._id,
                        courseName: selectedCourse?.courseName,
                        personId: profileData?._id,
                        personName:
                          profileData?.firstName + " " + profileData?.lastName,
                        operatorId: profileData?.operator?._id,
                        operatorName: profileData?.operator?.operator_name,
                        tenantsOf: [dmo],
                      },
                    },
                  });
                  personCourseUpdate({
                    variables: {
                      input: {
                        courseId: selectedCourse._id,
                        personId: profileData._id,
                        status: PersonCoursesUpdateStatusEnum.Complete,
                      },
                    },
                  });
                }
                window.open(course.pdfUrl, "_blank", "rel=noopener noreferrer");
              }}
            >
              Download PDF Here
            </LegacyButton>
          </div>
        );
    }
  };

  const renderTag = (id, personData) => {
    const courseProgressData = personData?.learner?.courses?.find(
      c => c.courseId == id,
    );
    //console.log(courseStatus);
    switch (courseProgressData?.status) {
      case "IN_PROGRESS":
        return { label: "In Progress", color: "warning", mode: "dark" };
      case "COMPLETE":
        return { label: "Complete", color: "success", mode: "dark" };
      default:
        return { label: "Not started", color: "primary", mode: "light" };
    }
  };

  const handleTerminated = () => {
    refetchPeople({ limit: 1, auth0Id: user?.sub });
  };

  //  console.log(profileData);
  return (
    <>
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div
          key={nanoid()}
          className="tw-flex tw-flex-col tw-gap-5 tw-m-1 tw-mb-[200px]"
        >
          {data?.map(course => (
            <Card.Surface key={course?._id} background={"white"}>
              <Card.Body>
                <Card.BodyContent>
                  <div
                    style={{
                      maxWidth: "200px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={course?.coverImage?.full_url || ""}
                      alt={"Sample travel coast"}
                      style={{ maxWidth: "150px", borderRadius: "5px" }}
                    />
                    <Tag
                      visual={renderTag(course?._id, profileData).color}
                      mode={renderTag(course?._id, profileData).mode}
                    >
                      {renderTag(course?._id, profileData).label}
                    </Tag>
                  </div>
                  <Card.Title
                    style={{ flex: "1 1 auto" }}
                    mainText={course?.courseName || ""}
                    subText={course?.description || ""}
                  />

                  <styled.button
                    bg={"ink.300"}
                    p={100}
                    borderRadius={50}
                    height={"50px"}
                    cursor={"pointer"}
                    onMouseDown={() => handleCourseOpen(course)}
                  >
                    Launch
                  </styled.button>
                </Card.BodyContent>
              </Card.Body>
            </Card.Surface>
          ))}
        </div>
      </motion.main>
      <FullScreenModal
        courseData={selectedCourse}
        profileData={profileData}
        isOpen={openCourse}
        onClose={handleCourseClose}
        frameRef={ref}
        content={renderCourse(selectedCourse)}
        refetchPeople={refetchPeople}
        user={user}
        terminated={handleTerminated}
        dmo={dmo}
        learnerCourseData={learnerCourseData}
      />
    </>
  );
}
