import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  NewImage,
  ProductByIdQuery,
  useBookerByMainProductIdQuery,
} from "gql/generated.ts";
import { ApolloError } from "@apollo/client";
import { Spinner, Text, toastFactory } from "@ttc3k/trekker";
import { useAppContext } from "core/components/apps/PreferredLayout";
import _ from "lodash";
import { BookerLeafNavLayout } from "core/components/shared/BookerLeafNavLayout";
import Media from "core/leaves/Media";
import { productMediaConfig } from "apps/library/booker/spaces/products/utils/productMediaConfig";
import { isStringProductType } from "../utils/isStringProductType";
import {
  AccommodationDetailsLeaf,
  AccommodationFeaturesLeaf,
  AccommodationPricingLeaf,
  AccommodationUpsellsLeaf,
} from "./leaves/accommodation";
import { BookerPreviewBox, useProductContext } from "./components";

enum AccommodationProductLeaf {
  ProductInfo = "PRODUCT_INFO",
  KeyFeatures = "KEY_FEATURES",
  Photos = "PHOTOS",
  PricingAndGuests = "PRICING_AND_GUESTS",
  Upsells = "UPSELLS",
}

type AccommodationProductProps = {
  product: ProductByIdQuery["productById"];
  loading: boolean;
  error: ApolloError | undefined;
};

export function AccommodationProduct({
  error,
  loading,
  product,
}: AccommodationProductProps) {
  const { setDescription, setTitle } = useAppContext();
  const { isFormError, isUnsavedChanges } = useProductContext();
  const { id, spaceId, leaf } = useParams();
  const navigate = useNavigate();

  const { data: bookerData } = useBookerByMainProductIdQuery({
    variables: { productId: id ?? "", operatorId: spaceId },
  });

  const isProductCreated = !!product;
  const accomNavData = [
    { label: "Product Info", value: AccommodationProductLeaf.ProductInfo },
    {
      label: "Key Features",
      value: AccommodationProductLeaf.KeyFeatures,
      disabled: !isProductCreated,
    },
    {
      label: "Photos",
      value: AccommodationProductLeaf.Photos,
      disabled: !isProductCreated,
    },
    {
      label: "Pricing & Guests",
      value: AccommodationProductLeaf.PricingAndGuests,
      disabled: !isProductCreated,
    },
    {
      label: "Upsells",
      value: AccommodationProductLeaf.Upsells,
      disabled: !isProductCreated,
    },
  ];
  const isImageAdded = !!product && product.images.length > 0;
  const isRoomsAdded =
    product &&
    product.accommodationMetadata &&
    product?.accommodationMetadata?.rooms.length > 0;
  const isPricingAdded = product && product.pricing.tiers.length > 0;
  const productBundle =
    bookerData &&
    bookerData.bookerByMainProductId &&
    bookerData.bookerByMainProductId.productBundles.find(
      v => v?.product === product?._id,
    );
  const isUpsellsAdded =
    productBundle && productBundle.addOns && productBundle.addOns.length > 0;
  const completedNavValues = _.compact([
    isProductCreated && AccommodationProductLeaf.ProductInfo,
    isImageAdded && AccommodationProductLeaf.Photos,
    isRoomsAdded && AccommodationProductLeaf.KeyFeatures,
    isPricingAdded && AccommodationProductLeaf.PricingAndGuests,
    isUpsellsAdded && AccommodationProductLeaf.Upsells,
  ]);
  const isParamIDProductType = isStringProductType(id ?? "");
  const [currLeaf, setCurrLeaf] = useState<AccommodationProductLeaf>(
    (leaf?.toUpperCase() as AccommodationProductLeaf) ??
      AccommodationProductLeaf.ProductInfo,
  );

  const handleLeafChange = (v: string) => {
    if (isUnsavedChanges) {
      toastFactory.create({
        title: "Warning",
        description:
          "You have unsaved changes, don't forget to save your changes before you continue",
      });
    } else if (isFormError) {
      toastFactory.create({
        title: "Warning",
        description:
          "Be sure to fix any errors listed in red and don't forget to save your changes before you continue",
      });
    } else {
      navigate(
        `/app/booker/${spaceId}/products/update-product/${id}/${v.toLowerCase()}`,
      );
      setCurrLeaf(v as AccommodationProductLeaf);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isUnsavedChanges || isFormError) {
        e.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormError, isUnsavedChanges]);

  useEffect(() => {
    if (!leaf) {
      navigate(AccommodationProductLeaf.ProductInfo.toLowerCase());
    }
  }, [leaf, navigate]);

  useEffect(() => {
    if (product) {
      setTitle(product.name.en ?? "Edit Accommodation");
      setDescription(product.description?.en ?? "");
    } else {
      setTitle("Create Accommodation");
      setDescription("");
    }
  }, [product, setDescription, setTitle]);

  if (loading) {
    return <Spinner width={"50px"} height={"50px"} margin={"60px auto"} />;
  }
  if (error && !isParamIDProductType)
    return (
      <Text
        margin={"60px auto"}
        width={"full"}
        textAlign={"center"}
        color={"text.error.mid"}
      >
        Error : {error.message}
      </Text>
    );
  return (
    <BookerLeafNavLayout
      data={accomNavData}
      completedValues={completedNavValues}
      activeValue={currLeaf}
      onValueChange={v => handleLeafChange(v.toString())}
    >
      {currLeaf === AccommodationProductLeaf.ProductInfo && (
        <AccommodationDetailsLeaf
          isUpdating={isProductCreated}
          productID={product?._id ?? ""}
        />
      )}
      {currLeaf === AccommodationProductLeaf.KeyFeatures && (
        <AccommodationFeaturesLeaf productID={product?._id ?? ""} />
      )}
      {currLeaf === AccommodationProductLeaf.Photos && (
        <Media
          data={product?.images as Partial<NewImage>[]}
          config={productMediaConfig}
        />
      )}
      {currLeaf === AccommodationProductLeaf.PricingAndGuests && (
        <AccommodationPricingLeaf productID={product?._id ?? ""} />
      )}
      {currLeaf === AccommodationProductLeaf.Upsells && (
        <AccommodationUpsellsLeaf
          productID={product?._id ?? ""}
          operatorID={spaceId ?? product?.operator ?? ""}
          booker={bookerData?.bookerByMainProductId}
        />
      )}

      <BookerPreviewBox
        bookerID={bookerData?.bookerByMainProductId?._id ?? ""}
      />
    </BookerLeafNavLayout>
  );
}
