import { useParams } from "react-router-dom";
import { Space } from "core/components/shared/Space";
import { Flex } from "styled-system/jsx";
import { RedirectToOperator } from "../components";
import { FilterMenu, OrderList } from "./components";
import { useOrderSpace } from "./hooks";

export function OrdersBundleView() {
  const { filter, onResetFilter, onUpdateFilter } = useOrderSpace();
  const { spaceId } = useParams();

  if (!spaceId || spaceId === "undefined") {
    return <RedirectToOperator />;
  }

  return (
    <>
      <Space>
        <Flex flexDir={"column"} gap={"400"}>
          <FilterMenu
            updateFilter={onUpdateFilter}
            resetFilter={onResetFilter}
            filter={filter}
          />
          <OrderList operatorID={spaceId} filter={filter} />
        </Flex>
      </Space>
    </>
  );
}
