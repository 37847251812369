import { Flag } from "@ttc3k/trekker";
import { formatDate } from "core/utils/formatDate";
import { formatPrice } from "core/utils/formatPrice";
import {
  EnumRefundStatus,
  Refund,
  RefundManyQuery,
} from "gql/generated";
import { Flex } from "styled-system/jsx";

type RefundFlagsProps = {
  isPublicPage?: boolean;
  orderGrandTotal: number;
  orderCustomerName: string;
  operatorName: string | undefined;
  refundData: RefundManyQuery | undefined;
};

export const RefundFlags = ({
  isPublicPage = false,
  orderGrandTotal,
  orderCustomerName,
  operatorName,
  refundData,
}: RefundFlagsProps) => {
  if (!refundData || !refundData.refundMany || refundData.refundMany.length < 1) {
    return null;
  }
  return (
    <Flex flexDir={"column"} gap={"200"}>
      {refundData.refundMany.map(refund => {
        const refundType =
          refund.amount === orderGrandTotal ? "Full" : "Partial";
        const refundStatus =
          refund.status === EnumRefundStatus.Failed
            ? "failed"
            : refund.status === EnumRefundStatus.Pending
              ? "pending"
              : "processed";

        const subText = getRefundFlagSubText(
          isPublicPage,
          refund,
          refundType,
          orderCustomerName,
          operatorName,
        );

        const flagVisualVariant = getFlagVisualVariantFromRefundStatus(
          refund.status,
        );
        return (
          <Flag
            key={refund._id}
            mainText={`${refundType} refund ${refundStatus}`}
            subText={subText}
            visual={flagVisualVariant}
            hideCloseTrigger
          />
        );
      })}
    </Flex>
  );
};

function getFlagVisualVariantFromRefundStatus(status: EnumRefundStatus) {
  switch (status) {
    case EnumRefundStatus.Succeeded:
      return "success";
    case EnumRefundStatus.Pending:
      return "highlight";
    case EnumRefundStatus.Failed:
      return "destructive";
  }
}

function getRefundFlagSubText(
  isPublicPage: boolean,
  refund: Refund,
  refundType: "Full" | "Partial",
  orderCustomerName: string,
  operatorName: string | undefined,
) {
  if (isPublicPage) {
    const refundDetails = `A ${refundType.toLowerCase()} refund of $${formatPrice(
      refund.amount,
    )} was initiated on ${formatDate(refund.createdDateISO)}`;
    const refundSuffix =
      refund.status === EnumRefundStatus.Failed
        ? ` but failed to be processed. Please contact ${
            operatorName ?? "the business"
          } for more info.`
        : refund.status === EnumRefundStatus.Pending
          ? " and is currently pending."
          : ". You can expect to receive it in 3 to 10 business days. ";
    return refundDetails + refundSuffix;
  } else {
    const refundDetails = `You initiated a ${refundType.toLowerCase()} refund of $${formatPrice(
      refund.amount,
    )} on ${formatDate(refund.createdDateISO)}`;
    const refundSuffix =
      refund.status === EnumRefundStatus.Failed
        ? ` but it failed to be processed by Stripe. You may have to coordinate with ${orderCustomerName} and settle this refund via e-transfer.`
        : refund.status === EnumRefundStatus.Pending
          ? " and it’s currently pending in Stripe. In order to complete the refund, you must log into your Stripe account and ensure it has enough funds to cover this refund."
          : " and it has been successfully processed. The customer can expect to receive it in 3 to 10 business days. ";

    return refundDetails + refundSuffix;
  }
}
