import { motion } from "framer-motion";
import {
  ButtonTagVariant,
  LegacyButton,
  LegacyTypography,
  TypographyVariant,
  tokens,
  LegacyToast,
} from "@ttc3k/ttc-design-system";
import { useLocation, useNavigate } from "react-router-dom";
import { RefreshDouble } from "iconoir-react";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { variants } from "../../shared.ts";
import { OtpInput } from "../../../../components/shared/OtpInput";
import { useOnboardingContext } from "../../Layout.tsx";
import {
  useGenerateOperatorOtpMutation,
  useValidateOperatorOtpMutation,
} from "../../../../../gql/generated.ts";
import SlideInPage from "../../../animation/SlideInPage.tsx";
import { gql, useMutation } from "@apollo/client";

const CREATE_TENANT = gql`
  mutation createTenant(
    $user_id: String
    $operators: String
    $registeredApps: String
    $dmo: String
  ) {
    createTenant(
      user_id: $user_id
      operators: $operators
      registeredApps: $registeredApps
      dmo: $dmo
    ) {
      _id
    }
  }
`;

export const ClaimWithCode = ({ urlAfterClaimFlow }) => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { state } = useLocation();
  const { businesses, saveOnboardingStep, baseURL, customPortalData } =
    useOnboardingContext();
  const [createTenant, { data: saved }] = useMutation(CREATE_TENANT);
  const business = businesses[0] || state.business;
  const email =
    business.email.slice(0, 3) +
    "*".repeat(6) +
    "@" +
    "*".repeat(4) +
    business.email.slice(-4);
  const [error, setError] = useState(false);

  const [generateOperatorOTP, { loading: loadingGenerateOperatorOTP }] =
    useGenerateOperatorOtpMutation();
  const [validateOperatorOTP, { loading: loadingValidateOperatorOTP }] =
    useValidateOperatorOtpMutation();

  const [otp, setOtp] = useState("");

  const handleSubmit = async () => {
    if (!user?.sub) return;
    try {
      await validateOperatorOTP({
        variables: {
          input: {
            operatorId: business._id,
            tenantId: user.sub,
            code: otp,
          },
        },
      })
        .then(r => {
          createTenant({
            variables: {
              user_id: user?.sub,
              dmo: customPortalData.settings.tenants[0],
              operators: JSON.stringify([business._id]),
              registeredApps: JSON.stringify(
                customPortalData.settings.registeredApps,
              ),
            },
          });
        })
        .then(r =>
          saveOnboardingStep({
            variables: {
              id: user.sub,
              page: `${baseURL}/${urlAfterClaimFlow}`,
              type: "VIA_CODE",
            },
          }),
        )
        .then(r =>
          navigate(`${baseURL}/${urlAfterClaimFlow}`, {
            state: { operatorId: business._id, businesses: businesses },
          }),
        );
    } catch (err) {
      console.error(err);
      setError(true);
    }
  };

  const handleSendCode = async () => {
    try {
      await generateOperatorOTP({ variables: { id: business._id } });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <SlideInPage>
      <div className="tw-w-full md:tw-w-96 tw-flex tw-flex-col tw-gap-5">
        <LegacyTypography
          variant={TypographyVariant.h4}
          color={tokens.semantic.colorText}
        >
          Claim your business
        </LegacyTypography>
        <LegacyTypography
          variant={TypographyVariant.primaryRegular}
          color={tokens.semantic.colorText}
        >
          We’ve sent a verification code to {email} Please enter it below to
          continue.
        </LegacyTypography>

        <OtpInput value={otp} onChange={setOtp} />
        {error && (
          <LegacyToast>Oops, please check the code and try again</LegacyToast>
        )}
        <LegacyButton
          fullWidth
          onClick={handleSubmit}
          disabled={otp.length !== 6}
          isLoading={loadingValidateOperatorOTP}
        >
          Submit
        </LegacyButton>
        <div className="tw-flex tw-justify-center">
          <LegacyButton
            variant={ButtonTagVariant.Unstyled}
            icon={
              <RefreshDouble className="tw-w-[20px] tw-h-[20px] tw-text-[#066DCC] tw-stroke-2" />
            }
            iconStart
            onClick={handleSendCode}
            isLoading={loadingGenerateOperatorOTP}
          >
            <LegacyTypography
              variant={TypographyVariant.primaryMedium}
              color={"#066DCC"}
            >
              Re-send code
            </LegacyTypography>
          </LegacyButton>
        </div>
      </div>
    </SlideInPage>
  );
};
