import { useAuth0 } from "@auth0/auth0-react";
import { LinkButton, Spinner, Text } from "@ttc3k/trekker";
import { useAppContext } from "core/components/apps/PreferredLayout";
import { handleBeaconClick } from "core/utils/helpScout";
import { useOperatorManyQuery } from "gql/generated";
import _ from "lodash";
import { useEffect } from "react";
import { Grid } from "styled-system/jsx";
import { OperatorCard } from "./components";

export default function BookerWelcome() {
  const { setTitle, setDescription } = useAppContext();
  const { user } = useAuth0();

  const { data, loading, error } = useOperatorManyQuery({
    variables: { filter: { tenantsOf: _.compact([user?.sub]) } },
  });

  useEffect(() => {
    setTitle("Booker Admin");
    setDescription(
      "Create sellable products here and then use Booker — Whereabouts’ powerful built-in booking engine — to take reservations anywhere on the web.",
    );
  }, [setTitle, setDescription]);

  if (loading) {
    return <Spinner width={"50px"} height={"50px"} margin={"60px auto"} />;
  }

  if (error) {
    return (
      <Text
        margin={"60px auto"}
        visual={"bodyMedium"}
        color={"text.error.mid"}
        width={"full"}
        textAlign={"center"}
      >
        Error : {error?.message} | Try refreshing the page or&nbsp;
        <LinkButton onClick={handleBeaconClick}>contact support</LinkButton>
      </Text>
    );
  }

  if (!data?.operatorMany || data.operatorMany.length < 1) {
    return (
      <Text
        margin={"60px auto"}
        visual={"bodyMedium"}
        color={"text.dark"}
        width={"full"}
        textAlign={"center"}
      >
        Cannot find any operators | Try refreshing the page or&nbsp;
        <LinkButton onClick={handleBeaconClick}>contact support</LinkButton>
      </Text>
    );
  }

  return (
    <Grid
      width={"full"}
      px={{ base: "400", lg: "1000" }}
      pt={"60px"}
      pb={"120px"}
      gridTemplateColumns={{
        base: "repeat(1, 1fr)",
        sm: "repeat(2, 1fr)",
        lg: "repeat(3, 1fr)",
      }}
      gap={"300"}
    >
      {data.operatorMany.map(op => (
        <OperatorCard key={op._id} operator={op} />
      ))}
    </Grid>
  );
}
