import { Flex, Box } from "styled-system/jsx";
import { ControlledInput } from "booker/shared";
import { Text } from "@ttc3k/trekker";
import { SelectClosureProducts } from "./SelectClosureProducts";

type ClosureFormComponentsProps = {
  formError: string;
  operatorID: string;
  isEditingClosure?: boolean;
};

export const ClosureFormComponents = ({
  formError,
  operatorID,
  isEditingClosure,
}: ClosureFormComponentsProps) => {
  return (
    <>
      <Flex flexDir={"column"} gap={"250"}>
        <SelectClosureProducts
          operatorID={operatorID}
          isEditingClosure={isEditingClosure}
        />
        <ControlledInput
          label={"Closure reason"}
          name={"name"}
          hint={"For easy reference in your calendar"}
          placeholder={"E.g. Summer vacation"}
        />
        <Flex width={"full"} gap={"200"} flex={1}>
          <Box flex={1}>
            <ControlledInput
              type={"date"}
              label={"Start date"}
              name={"startISO"}
            />
          </Box>
          <Box flex={1}>
            <ControlledInput type={"date"} label={"End date"} name={"endISO"} />
          </Box>
        </Flex>
        {formError.length > 0 && (
          <Text color={"text.error.mid"} visual={"smallMedium"}>
            {formError}
          </Text>
        )}
      </Flex>
    </>
  );
};
