import { Button, Card } from "@ttc3k/trekker";
import { formatPrice } from "core/utils/formatPrice";
import { ProductManyQuery } from "gql/generated";
import { EditPencil } from "iconoir-react";
import { RecipeVariantProps, cva } from "styled-system/css";
import { styled } from "styled-system/jsx";

const upsellCardSurface = cva({
  base: {
    bg: "white",
    boxShadow: "sm",
    cursor: "pointer",
    transitionDuration: "normal",
    transitionProperty: "box-shadow, border-color",
    transitionTimingFunction: "default",
    _hover: {
      borderColor: "border.dark",
      boxShadow: "0 0 0 1px token(colors.border.dark)",
    },
  },
  variants: {
    visual: {
      neutral: {},
      selected: {
        borderColor: "border.dark",
        boxShadow: "0 0 0 1px token(colors.border.dark)",
      },
    },
  },
  defaultVariants: { visual: "neutral" },
});

const UpsellCardSurface = styled(Card.Surface, upsellCardSurface);

type UpsellCardSurfaceVariants = NonNullable<
  RecipeVariantProps<typeof upsellCardSurface>
>;

type UpsellCardProps = UpsellCardSurfaceVariants & {
  onEditClick: () => void;
  onSelectUpsell: () => void;
  product: ProductManyQuery["productMany"][0];
};

export const UpsellCard = ({
  onEditClick,
  onSelectUpsell,
  product,
  visual,
}: UpsellCardProps) => {
  const upsellPrice =
    product.pricing.tiers.find(tier => tier && tier.price > 0)?.price ?? 0;

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onEditClick();
  };
  return (
    <UpsellCardSurface
      visual={visual}
      aria-label={"Select or unselect upsell"}
      role={"button"}
      tabIndex={"0"}
      onClick={onSelectUpsell}
      onKeyDown={({ key }: { key: string }) => {
        if (key === "Enter" || key === " ") onSelectUpsell();
      }}
    >
      <Card.Body>
        <Card.BodyContent alignItems={"center"}>
          <Card.Title
            mainText={product.name.en ?? ""}
            subText={`$${formatPrice(upsellPrice)}`}
          />

          <Button
            Icon={EditPencil}
            visual={"outline"}
            onClick={handleButtonClick}
            type={"button"}
          />
        </Card.BodyContent>
      </Card.Body>
    </UpsellCardSurface>
  );
};
