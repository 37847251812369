import { motion } from "framer-motion";
import {
  LegacyButton,
  LegacyTypography,
  TypographyVariant,
  tokens,
} from "@ttc3k/ttc-design-system";
import { useNavigate } from "react-router-dom";
import { variants } from "../../shared.ts";
import { TextField } from "../../../../components/shared/TextField";
import { useOnboardingContext } from "../../Layout.tsx";
import { useGenerateOperatorOtpMutation } from "../../../../../gql/generated.ts";
import SlideInPage from "../../../animation/SlideInPage.tsx";
import { useAuth0 } from "@auth0/auth0-react";
import { gql, useMutation } from "@apollo/client";

export const Verify = () => {
  const navigate = useNavigate();
  const {
    baseURL,
    businesses,
    setBusinesses,
    customPortalData,
    saveOnboardingStep,
  } = useOnboardingContext();
  const { user } = useAuth0();
  const business =
    businesses[0] == undefined
      ? JSON.parse(localStorage.getItem("onboardingBusiness"))
      : businesses[0];
  if (businesses[0] != undefined) {
    localStorage.setItem("onboardingBusiness", JSON.stringify(businesses[0]));
  }
  const email =
    business.email.slice(0, 3) +
    "*".repeat(6) +
    "@" +
    "*".repeat(4) +
    business.email.slice(-4);
  const [generateOperatorOTP, { loading }] = useGenerateOperatorOtpMutation();

  const handleSendCode = async () => {
    try {
      await generateOperatorOTP({ variables: { id: business._id } })
        .then(
          saveOnboardingStep({
            variables: {
              id: user.sub,
              page: `${baseURL}/claim`,
            },
          }),
        )
        .then(
          navigate(`${baseURL}/claim/enter-code`, {
            state: { business: business },
          }),
        );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <SlideInPage>
      <div className="tw-w-full md:tw-w-96 tw-flex tw-flex-col tw-gap-5">
        <LegacyTypography
          variant={TypographyVariant.h4}
          color={tokens.semantic.colorText}
        >
          Claim your business
        </LegacyTypography>
        <LegacyTypography
          variant={TypographyVariant.primaryRegular}
          color={tokens.semantic.colorText}
        >
          In our records, {business.operator_name} is associated with the email
          below. To claim this business, you'll need access to that email
          address or be in contact with the owner — that's where we'll send a
          verification code.
        </LegacyTypography>
        <TextField value={email} disabled />
        <LegacyButton fullWidth onClick={handleSendCode} isLoading={loading}>
          Send verification code
        </LegacyButton>
      </div>
    </SlideInPage>
  );
};
