import { NavArrowRight } from "iconoir-react";
import "./breadcrumbs.scss";
import { NavLink, useLocation } from "react-router-dom";
import { tokens } from "@ttc3k/ttc-design-system";
import { nanoid } from "nanoid";
import { ConfigSpace } from "../PreferredLayout";
import { Breadcrumb } from "./Breadcrumbs";

export type SpacesBreadcrumbsProps = {
  crumbs: Breadcrumb[];
  spaces: ConfigSpace[];
};

export const SpacesBreadcrumbs = ({
  crumbs,
  spaces,
}: SpacesBreadcrumbsProps) => {
  const location = useLocation();
  const previousLocation = location.state
    ? location.state.previousLocation
    : {
        pathname: "/",
        search: "",
        hash: "",
        state: null,
        key: "default",
      };
  const spacesNames = spaces.map(sp => sp.name);

  const filteredCrumbs = crumbs.filter(v =>
    spacesNames.includes(v.space ?? v.name),
  );

  return (
    <nav className={"ttc-breadcrumbs"}>
      {filteredCrumbs.map((crumb, i, arr) => {
        if (i === 0)
          return (
            <NavLink
              key={nanoid()}
              to={crumb.href}
              className={`tw-text-textSubtlest`}
            >
              {crumb.name}
            </NavLink>
          );
        else {
          return (
            <div key={nanoid()} className={"tw-flex tw-gap-1 tw-items-center"}>
              <NavArrowRight
                width={16}
                height={16}
                strokeWidth={2}
                color={tokens.semantic.iconDisabled}
              />
              <NavLink
                to={crumb.href}
                state={{
                  previousLocation: previousLocation,
                  filterStatus: location?.state?.filterStatus,
                }}
              >
                <div
                  className={`${
                    i === arr.length - 1 &&
                    "max-sm:tw-w-[120px] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
                  }`}
                  style={{
                    color:
                      i === arr.length - 1
                        ? tokens.semantic.textSubtle
                        : tokens.semantic.textSubtlest,
                  }}
                >
                  {crumb.name}
                </div>
              </NavLink>
            </div>
          );
        }
      })}
    </nav>
  );
};
