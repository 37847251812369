import { User } from "@auth0/auth0-react";

export const loadBeacon = (user: User | undefined, subjectLine?: string) => {
  const script = document.createElement("script");
  script.id = "beacon";
  script.text = `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`;
  document.head.appendChild(script);
  window.Beacon("init", import.meta.env.VITE_HELPSCOUT_BEACON_INIT);
  window.Beacon("prefill", {
    name: (user && user.nickname) ?? "",
    email: (user && user.email) ?? "",
    subject: subjectLine ?? "Need help with Whereabouts",
    text: "",
  });
};

export const handleBeaconClick = (user: User | undefined, subjectLine?: string)  => {
  // A little unconventional but need to attch the SDK to an id.
  const beacon = document.querySelector("#beacon");
  loadBeacon(user, subjectLine);
  beacon?.addEventListener(
    "touchstart",
    _ => {
      loadBeacon(user, subjectLine);
    },
    { passive: true },
  );
  window.Beacon("open");
};
