import {
  LegacyButton,
  ButtonTagVariant,
  LegacyModal,
  ModalVariant,
  LegacyTypography,
  TypographyVariant,
  tokens,
} from "@ttc3k/ttc-design-system";
import React from "react";
import { Xmark } from "iconoir-react";
import "./modal-overrides.scss";
import {
  GetFormResponsesDocument,
  useDeleteFormResponseMutation,
} from "../../../../../gql/generated.ts";
import { handleAsyncError } from "../../../../utils/alert.ts";

type DeleteFormResponseProps = {
  modalOverlayRef: React.RefObject<HTMLDivElement>;
  onModalClose: () => void;
  formResponseId: string;
};

export const DeleteModal = ({
  modalOverlayRef,
  onModalClose,
  formResponseId,
}: DeleteFormResponseProps) => {
  const handleClose = () => {
    onModalClose();
  };

  const [deleteFormResponse] = useDeleteFormResponseMutation({
    refetchQueries: [GetFormResponsesDocument],
  });

  async function handleDeleteFormResponse() {
    await deleteFormResponse({
      variables: { input: { _id: formResponseId } },
    })
      .then(() => {
        onModalClose();
      })
      .catch(() => {
        handleAsyncError();
      });
  }

  return (
    <div className="ttc-modal-override">
      <LegacyModal
        variant={ModalVariant.wbts}
        modalOverlayRef={modalOverlayRef}
        onModalClose={handleClose}
      >
        <div className="tw-flex tw-flex-col tw-gap-4">
          {/* Header */}
          <div className="tw-flex tw-justify-between tw-text-left">
            <LegacyTypography variant={TypographyVariant.h5}>
              Are you sure?
            </LegacyTypography>
            <LegacyButton
              variant={ButtonTagVariant.Unstyled}
              color={tokens.semantic.iconSubtlest}
              icon={<Xmark strokeWidth={2} height={24} width={24} />}
              onClick={handleClose}
            />
          </div>

          {/* Content */}
          <div className="tw-flex tw-flex-col tw-gap-9 tw-text-left">
            <LegacyTypography color={tokens.semantic.textSubtle}>
              Removing this submission will require you to add a new one in
              order to move on.
            </LegacyTypography>

            {/* Footer */}
            <div className="tw-w-full tw-flex tw-justify-between tw-gap-3">
              <LegacyButton
                variant={ButtonTagVariant.Secondary}
                onClick={handleClose}
                type="button"
                fullWidth
              >
                Go back
              </LegacyButton>
              <LegacyButton
                variant={ButtonTagVariant.ErrorBold}
                onClick={handleDeleteFormResponse}
                fullWidth
              >
                Confirm delete
              </LegacyButton>
            </div>
          </div>
        </div>
      </LegacyModal>
    </div>
  );
};
