export const useModalRoot = () => {
  function createModalRoot() {
    const rootContainer = document.createElement("div");
    rootContainer.setAttribute("id", "modal-root");
    document.body.appendChild(rootContainer);
    return rootContainer;
  }

  const modalRoot = document.getElementById("modal-root") || createModalRoot();

  return { modalRoot };
};
