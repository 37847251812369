import { z } from "zod";

// Profile schema
export const personalProfileSchema = z.object({
  firstName: z.string().min(1, "Required"),
  lastName: z.string().min(1, "Required"),
  email: z
    .string()
    .email("Please enter a valid email")
    //.regex(/^[a-z0-9+_.-]+@[a-z0-9.-]+$/, "Email must be lowercase")
    .min(1, "Required"),
});

export type personalProfileSchema = z.infer<typeof personalProfileSchema>;

export const personalProifleDefaultValues: personalProfileSchema = {
  email: "",
  firstName: "",
  lastName: "",
};
