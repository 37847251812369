import { useAuth0 } from "@auth0/auth0-react";
import {
  tokens,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { LogOut } from "iconoir-react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
      className="tw-cursor-pointer tw-w-full tw-bg-transparent tw-border-0"
    >
      <div className="tw-flex tw-gap-2 tw-w-full tw-items-center tw-justify-between">
        <LegacyTypography
          variant={TypographyVariant.secondaryMedium}
          color={tokens.semantic.colorText}
        >
          Log out
        </LegacyTypography>
        <LogOut
          strokeWidth={1.75}
          width={20}
          height={20}
          color={tokens.semantic.iconSubtlest}
        />
      </div>
    </button>
  );
};

export default LogoutButton;
