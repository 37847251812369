import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { LegacyTypography, TypographyVariant } from "@ttc3k/ttc-design-system";
import { ReactNode } from "react";
import { Autocomplete2 } from "../Autocomplete2";
import { cn } from "../../../utils/cn";

export type PlacesAutocompleteProps = {
  onChange?: (arg: google.maps.GeocoderResult|null) => void;
  value?: string;
  label?: string;
  helperText?: ReactNode
};

export const PlacesAutocomplete = ({
  value = "",
  onChange,
  label,
  helperText
}: PlacesAutocompleteProps) => {
  const {
    ready,
    value: valueAutocomplete,
    setValue,
    suggestions: { data, loading },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelected = async (
    address: google.maps.places.AutocompletePrediction | null,
  ) => {
    if (!address) return onChange?.(null);

    setValue(address.description, false);
    clearSuggestions();

    const resp = await getGeocode({ address: address.description });
    onChange?.(resp[0]);
  };

  return (
    <div className="tw-text-left">
      <Autocomplete2
        options={data}
        displayField="description"
        inputValue={valueAutocomplete || value}
        onInputChange={val => setValue(val)}
        label={label}
        isLoading={loading}
        disabled={!ready}
        onChange={handleSelected}
        optionComponent={({ description }, { active, selected }) => {
          const descriptionArray = description.split(",");
          const name = descriptionArray.shift();
          const address = descriptionArray.join(",");

          return (
            <div
              className={cn(
                "tw-cursor-pointer tw-px-[14px] tw-py-[10px] tw-flex tw-flex-col tw-gap-y-[4px]",
                {
                  "tw-bg-gray-200": selected,
                  "tw-bg-gray-100": active && !selected,
                },
              )}
            >
              <LegacyTypography>{address}</LegacyTypography>
              <LegacyTypography variant={TypographyVariant.secondaryBold}>
                {name}
              </LegacyTypography>
            </div>
          );
        }}
      />
      {helperText}
    </div>
  );
};
