import {
  EnumProductType,
  useBookerByMainProductIdQuery,
  useInventoryByProductBookedDatesQuery,
} from "gql/generated";
import { useFormContext, useWatch } from "react-hook-form";
import { Box, Flex, Grid } from "styled-system/jsx";
import { Calendar, Select, Text } from "@ttc3k/trekker";
import { ControlledInput } from "apps/custom/ttc/booker/shared";
import { LegacyAnimatedHeight } from "@ttc3k/ttc-design-system";
import { convertDateRangeToCalendarValue } from "booker/features/OverlayPages/DatesPage/helpers";
import { useEffect } from "react";
import { ManualBookingFormValues } from "./useManualBookingForm";
import { UpsellCard } from "./UpsellCard";

type ManualBookingFormComponentsProps = {
  productOptions: {
    label: string;
    value: string;
    type: EnumProductType;
  }[];
};

export const ManualBookingFormComponents = ({
  productOptions,
}: ManualBookingFormComponentsProps) => {
  const { control, setValue, formState } =
    useFormContext<ManualBookingFormValues>();

  const mainProductID = useWatch({ control, name: "mainProductID" });
  const productType = useWatch({ control, name: "mainProductType" });
  const startISO = useWatch({ control, name: "startISO" });
  const endISO = useWatch({ control, name: "endISO" });
  const selectedUpsellProductIDs = useWatch({
    control,
    name: "upsellProductIDs",
  });
  const feeOptions = productOptions.filter(v => v.type === EnumProductType.Fee);
  const accommodationOptions = productOptions.filter(
    v => v.type === EnumProductType.Accommodation,
  );

  const { data: invData } = useInventoryByProductBookedDatesQuery({
    variables: { productId: mainProductID },
  });

  const { data: bkrData } = useBookerByMainProductIdQuery({
    variables: { productId: mainProductID },
  });

  const upsellProductIDs =
    bkrData?.bookerByMainProductId?.productBundles[0]?.addOns ?? [];

  const handleSelectMainProduct = (id: string) => {
    setValue("mainProductID", id);
    const newProductType =
      productOptions.find(p => p.value === id)?.type ?? EnumProductType.Fee;
    setValue("mainProductType", newProductType);
  };

  const handleDateValueChange = (dates: string[]) => {
    if (dates.length > 1) {
      setValue("startISO", dates[0]);
      setValue("endISO", dates[1]);
    } else if (dates.length === 1) {
      setValue("startISO", dates[0]);
      setValue("endISO", "");
    }
  };

  const handleClearDates = () => {
    setValue("startISO", "");
    setValue("endISO", "");
  };

  const handleUpsellSelect = (id: string) => {
    const isIDSelected = selectedUpsellProductIDs.find(i => i === id);
    if (isIDSelected) {
      const newUpsellIDs = selectedUpsellProductIDs.filter(i => i !== id);
      setValue("upsellProductIDs", newUpsellIDs);
    } else {
      const newUpsellIDs = [...selectedUpsellProductIDs, id];
      setValue("upsellProductIDs", newUpsellIDs);
    }
  };

  useEffect(() => {
    if (bkrData?.bookerByMainProductId?._id) {
      setValue("bookerID", bkrData.bookerByMainProductId._id);
    }
  }, [bkrData?.bookerByMainProductId?._id, setValue]);
  return (
    <Flex flexDir={"column"} gap={"250"} minH={"230px"}>
      <Flex flexDir={"column"} gap={"250"}>
        <Select.Root
          items={productOptions}
          value={[mainProductID.toString()]}
          placeholder={"Select product"}
          css={{
            "& [data-scope='select'][data-part='trigger']": {
              bg: "white",
            },
          }}
          label={"Product"}
          onValueChange={({ value }: { value: string[] }) =>
            handleSelectMainProduct(value[0])
          }
        >
          {accommodationOptions.length > 0 && (
            <>
              <Select.ItemGroup>
                <Select.ItemGroupLabel>Accommodations</Select.ItemGroupLabel>
                {accommodationOptions.map(item => (
                  <Select.Item key={item.value} item={item} />
                ))}
              </Select.ItemGroup>
            </>
          )}
          {feeOptions.length > 0 && (
            <>
              <Select.ItemGroup>
                <Select.ItemGroupLabel>Fees</Select.ItemGroupLabel>
                {feeOptions.map(item => (
                  <Select.Item key={item.value} item={item} />
                ))}
              </Select.ItemGroup>
            </>
          )}
        </Select.Root>
        <Flex gap={"200"}>
          <ControlledInput name={"name"} label={"Customer name"} />
          <ControlledInput name={"email"} label={"Customer email"} />
        </Flex>
      </Flex>

      <LegacyAnimatedHeight
        isOpen={productType === EnumProductType.Accommodation}
      >
        <Box width={"full"} height={"360px"} mt={"250"}>
          <Calendar
            unavailableDatesISO={
              invData?.inventoryDatesByProduct?.bookedDatesISO
            }
            checkOutOnlyDatesISO={
              invData?.inventoryDatesByProduct?.checkOutOnlyDatesISO
            }
            onValueChange={d => handleDateValueChange(d.valueAsString)}
            value={convertDateRangeToCalendarValue({
              startDateISO: startISO,
              endDateISO: endISO,
            })}
            onClearDatesClick={handleClearDates}
            displayJumpToToday
          />
        </Box>
        {formState.errors["startISO"] && (
          <Text visual={"smallSemiBold"} color={"text.error.dark"} mt={"150"}>
            {formState.errors["startISO"].message}
          </Text>
        )}
      </LegacyAnimatedHeight>

      <LegacyAnimatedHeight isOpen={upsellProductIDs.length > 0}>
        <Flex flexDir={"column"} gap={"200"} mt={"250"}>
          <Flex flexDir={"column"} gap={"100"}>
            <Text visual={"bodySemiBold"}>Upsells</Text>
            <Text color={"text.mid"}>
              Select from any of your upsells and include them in this booking.
            </Text>
          </Flex>
          <Grid gridTemplateColumns={"repeat(2, 1fr)"} gap={"150"}>
            {upsellProductIDs.map(id => (
              <UpsellCard
                key={id}
                productID={id}
                onSelectProduct={i => handleUpsellSelect(i)}
                isSelected={selectedUpsellProductIDs.includes(id)}
              />
            ))}
          </Grid>
        </Flex>
      </LegacyAnimatedHeight>
    </Flex>
  );
};
