import { useRef, useEffect } from "react";
import { Portal } from "@ttc3k/trekker";
import { BookerProps } from "./App";
import { lockScrollbar, unlockScrollbar } from "./shared/utils";

type BookerIFrameProps = Pick<BookerProps, "bookerID" | "bookerPosition"> & {
  open: boolean;
  onClose: () => void;
};

function BookerIFrame({
  bookerID,
  open,
  onClose,
  bookerPosition = "right",
}: BookerIFrameProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const handleIframeMessage = (event: MessageEvent) => {
      if (event.data.type === "CLOSE_MODAL") {
        onClose();
      }
    };

    window.addEventListener("message", handleIframeMessage);

    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, [onClose]);

  useEffect(() => {
    const iframe = iframeRef.current;
    const iframeWindow = iframe?.contentWindow;
    if (iframe && iframeWindow && open) {
      iframeWindow.postMessage(
        {
          type: "OPEN_MODAL",
        },
        "*",
      );
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      lockScrollbar();
    } else {
      unlockScrollbar();
    }
  }, [open]);

  return (
    <Portal>
      <iframe
        ref={iframeRef}
        style={{
          border: 0,
          position: "fixed",
          width: open ? "100%" : 0,
          height: open ? "100%" : 0,
          top: open ? 0 : -1000,
          left: open ? 0 : -1000,
          opacity: open ? 1 : 0,
        }}
        srcDoc={`<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Booker Modal</title>
  </head>
  <body>
    <div id="root" data-booker-id="${bookerID}" data-booker-position="${bookerPosition}"></div>
    <script
      type="module"
      src="https://${
        import.meta.env.VITE_ENVIRONMENT == "production" ? "prod" : "dev"
      }-api.whereabouts.tech/embeddable/booker-modal/4/latest/main.js"
    ></script>
  </body>
</html>`}
        // src={`../../../../../bookerEmbed.html`}
      />
    </Portal>
  );
}

export { BookerIFrame };
