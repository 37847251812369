export const months = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
] as const;

export type Month = (typeof months)[number];

export type MonthMetadata = {
  shortName: string;
  name: string;
};

export type MonthsMetadata = Record<Month, MonthMetadata>;
export type MonthsValues = Record<Month, boolean>;
export type MonthsErrors = Partial<Record<Month, string>>;

export const initialMonthsValues: MonthsValues = {
  jan: false,
  feb: false,
  mar: false,
  apr: false,
  may: false,
  jun: false,
  jul: false,
  aug: false,
  sep: false,
  oct: false,
  nov: false,
  dec: false,
};

export const monthsMetadata: MonthsMetadata = {
  jan: {
    shortName: "Jan",
    name: "January",
  },
  feb: {
    shortName: "Feb",
    name: "February",
  },
  mar: {
    shortName: "Mar",
    name: "March",
  },
  apr: {
    shortName: "Apr",
    name: "April",
  },
  may: {
    shortName: "May",
    name: "May",
  },
  jun: {
    shortName: "Jun",
    name: "June",
  },
  jul: {
    shortName: "Jul",
    name: "July",
  },
  aug: {
    shortName: "Aug",
    name: "August",
  },
  sep: {
    shortName: "Sep",
    name: "September",
  },
  oct: {
    shortName: "Oct",
    name: "October",
  },
  nov: {
    shortName: "Nov",
    name: "November",
  },
  dec: {
    shortName: "Dec",
    name: "December",
  },
};
