import { useFormHandler } from "core/hooks/useFormHandler";
import { DATE_PATTERN_SIMPLE, formatDate } from "core/utils/formatDate";
import { parseToUTCDate } from "core/utils/parseToUTCDate";
import { isAfter } from "date-fns";
import {
  OrderByIdDocument,
  OrderByIdQuery,
  useInventoryUpdateClaimedDatesByOrderMutation,
} from "gql/generated";

import { useCallback } from "react";
import { z } from "zod";

const editDatesFormSchema = z
  .object({
    start: z.string().min(1, "Please select a start date"),
    end: z.string().min(1, "Please select a end date"),
  })
  .superRefine((data, ctx) => {
    if (isAfter(parseToUTCDate(data.start), parseToUTCDate(data.end))) {
      ctx.addIssue({
        message: "End date must be after the start date",
        path: ["end"],
        code: "custom",
      });
    }
  });

export type EditDatesFormValues = z.infer<typeof editDatesFormSchema>;

interface EditClosureFormOptions {
  onSuccess?: () => void;
  onError?: (message: string) => void;
  order: OrderByIdQuery["orderById"];
}

export const useEditDatesForm = ({
  onSuccess,
  onError,
  order,
}: EditClosureFormOptions) => {
  const mainProduct = order?.purchasedProducts[0];
  const mainProductDateRangeISO =
    mainProduct?.inventoryClaims[0]?.claimedDateRangeISO;
  const defaultValues: EditDatesFormValues = {
    end: mainProductDateRangeISO?.end
      ? formatDate(
          parseToUTCDate(mainProductDateRangeISO.end),
          DATE_PATTERN_SIMPLE,
        )
      : "",
    start: mainProductDateRangeISO?.start
      ? formatDate(
          parseToUTCDate(mainProductDateRangeISO.start),
          DATE_PATTERN_SIMPLE,
        )
      : "",
  };

  const [updateInventoryClaimedDateRange] =
    useInventoryUpdateClaimedDatesByOrderMutation();

  return useFormHandler<EditDatesFormValues>(
    useCallback(
      async data => {
        const payload = { ...data };
        const { end, start } = payload;

        const formErrorMessage = (err: string) => {
          if (err.includes("availability")) {
            return `These dates are unavailable, please try again`;
          } else {
            return `Could not update reservation dates, there has been an error`;
          }
        };

        if (start && end) {
          const startDate = parseToUTCDate(start);
          startDate.setUTCDate(startDate.getDate());
          startDate.setUTCHours(0, 0, 0, 0);

          const endDate = parseToUTCDate(end);
          endDate.setUTCDate(endDate.getDate());
          endDate.setUTCHours(23, 59, 59, 999);

          const startISO = startDate.toISOString();
          const endISO = endDate.toISOString();

          await updateInventoryClaimedDateRange({
            variables: {
              endIso: endISO,
              startIso: startISO,
              orderId: order?._id ?? "",
            },
            refetchQueries: [OrderByIdDocument],
          })
            .then(() => {
              onSuccess?.();
            })
            .catch(err => onError?.(formErrorMessage(err.message ?? "")));
        }
      },
      [onError, onSuccess, order?._id, updateInventoryClaimedDateRange],
    ),
    defaultValues,
    { schema: editDatesFormSchema },
  );
};
