import { ButtonCard } from "core/components/shared/ButtonCard";
import { Box, Flex, styled } from "styled-system/jsx";
import { Switch, Table, Text } from "@ttc3k/trekker";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { EnumProductType, useProductManyQuery } from "gql/generated";
import { AnimatePresence, motion } from "framer-motion";
import { LegacyAnimatedHeight } from "@ttc3k/ttc-design-system";
import { CreateClosureFormValues } from "./useCreateClosureForm";

type SelectClosureProductsProps = {
  operatorID: string;
  isEditingClosure?: boolean;
};

const MotionTableRow = motion(Table.Row);
const MotionTableRoot = motion(Table.Root);
const ButtonCardWrapper = styled(Flex, {
  base: {
    transition: "width 0.2s linear",
    "&[data-products-open]": {
      width: "calc(100% - 10px)",
    },
    "&[data-products-closed]": {
      width: "100%",
    },
  },
});

const rowVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const containerVariants = {
  visible: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

export const SelectClosureProducts = ({
  operatorID,
  isEditingClosure,
}: SelectClosureProductsProps) => {
  const [isScrollbarOpen, setIsScrollbarOpen] = useState(false);
  const [closureType, setClosureType] = useState<"full" | "partial">("full");
  const { data } = useProductManyQuery({
    variables: {
      filter: {
        type: EnumProductType.Accommodation,
        operator: operatorID,
        isActive: true,
      },
    },
    fetchPolicy: "cache-and-network",
  });
  const { control, setValue } = useFormContext<CreateClosureFormValues>();

  const handleSetFullClosure = () => {
    setClosureType("full");
    setValue("productIDs", data?.productMany?.map(p => p?._id) ?? []);
  };
  const handleSetPartialClosure = () => {
    setClosureType("partial");
    setValue("productIDs", []);
  };

  const selectedProductIDs = useWatch({
    control,
    name: "productIDs",
  });
  const {
    fieldState: { error },
  } = useController({
    name: "productIDs",
    control,
  });

  useEffect(() => {
    if (!isEditingClosure) {
      setValue("productIDs", data?.productMany?.map(p => p?._id) ?? []);
    }
  }, [data?.productMany, isEditingClosure, setValue]);

  useEffect(() => {
    if (
      selectedProductIDs.length !== data?.productMany.length &&
      isEditingClosure
    ) {
      setClosureType("partial");
    }
  }, [data?.productMany.length, selectedProductIDs.length, isEditingClosure]);

  useEffect(() => {
    const wrapper = document.getElementById(
      "whereabouts-modal-content-wrapper",
    );

    if (wrapper) {
      const checkScrollbar = () => {
        setIsScrollbarOpen(wrapper.scrollHeight > wrapper.clientHeight);
      };
      checkScrollbar();

      const resizeObserver = new ResizeObserver(checkScrollbar);
      resizeObserver.observe(wrapper);

      const mutationObserver = new MutationObserver(checkScrollbar);
      mutationObserver.observe(wrapper, { childList: true, subtree: true });

      wrapper.addEventListener("scroll", checkScrollbar);

      return () => {
        resizeObserver.disconnect();
        mutationObserver.disconnect();
        wrapper.removeEventListener("scroll", checkScrollbar);
      };
    }
  }, []);

  const isProductsOpen =
    closureType === "partial" && !!data && !!data.productMany;

  const conditionalProps = {
    ...(isScrollbarOpen
      ? { "data-products-open": true }
      : { "data-products-closed": true }),
  };

  return (
    <>
      <Box>
        <Text color={"text.dark"}>I want this closure to apply to...</Text>
        <AnimatePresence>
          <ButtonCardWrapper
            flex={1}
            gap={"150"}
            mt={"150"}
            {...conditionalProps}
          >
            <ButtonCard
              mainText="My entire business"
              subText="Use this option to turn off bookings for all products"
              flex={1}
              onClick={handleSetFullClosure}
              visual={closureType === "full" ? "selected" : "neutral"}
            />
            <ButtonCard
              mainText="Specific products"
              subText="Use this option to turn off bookings for specific products"
              flex={1}
              onClick={handleSetPartialClosure}
              visual={closureType === "partial" ? "selected" : "neutral"}
            />
          </ButtonCardWrapper>
        </AnimatePresence>
        {error && (
          <Text visual={"smallMedium"} color={"text.error.dark"} mt={"100"}>
            {error.message}
          </Text>
        )}
      </Box>
      <LegacyAnimatedHeight isOpen={isProductsOpen}>
        {isProductsOpen && (
          <MotionTableRoot
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <Table.Body>
              {data?.productMany.map((product, index) => {
                if (!product) return null;
                const isProductSelected = selectedProductIDs.includes(
                  product._id,
                );
                const handleCheckedChange = (checked: boolean) => {
                  if (checked) {
                    const newProductIDs = [...selectedProductIDs, product._id];
                    setValue("productIDs", newProductIDs);
                  } else {
                    const newProductIDs = selectedProductIDs.filter(
                      v => v !== product._id,
                    );
                    setValue("productIDs", newProductIDs);
                  }
                };
                return (
                  <MotionTableRow
                    key={product._id}
                    width={"full"}
                    variants={rowVariants}
                    transition={{ delay: index * 0.1, duration: 0.3 }}
                  >
                    <Table.Header>{product.name.en}</Table.Header>
                    <Table.Cell>
                      <Switch
                        checked={isProductSelected}
                        onCheckedChange={e => handleCheckedChange(e.checked)}
                        justifyContent={"flex-end"}
                      />
                    </Table.Cell>
                  </MotionTableRow>
                );
              })}
            </Table.Body>
          </MotionTableRoot>
        )}
      </LegacyAnimatedHeight>
    </>
  );
};
