import { useState } from "react";
import { Button, StyleRoot } from "@ttc3k/trekker";
import styles from "styled-system/styles.css?inline";
import { StyleMap } from "./shared/utils";
import { BookerModal } from "./BookerModal";
import { BookerIFrame } from "./BookerIFrame";

export type BookerPosition = "left" | "right";

export type BookerProps = {
  bookerID: string;
  buttonBgColor?: string;
  buttonCopy?: string;
  buttonStyle?: StyleMap;
  isEmbedding?: boolean;
  bookerPosition?: BookerPosition;
};

function Booker({
  bookerID,
  buttonBgColor,
  buttonCopy = "Book now",
  buttonStyle,
  isEmbedding = false,
  bookerPosition = "right",
}: BookerProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <StyleRoot styles={[styles]} useShadowDOM>
        <Button
          onClick={() => setIsModalOpen(true)}
          size={"lg"}
          style={{
            ...buttonStyle,
            backgroundColor: buttonBgColor
              ? buttonBgColor
              : buttonStyle?.["background-color"]
                ? buttonStyle?.["background-color"].toString()
                : undefined,
          }}
        >
          {buttonCopy}
        </Button>
      </StyleRoot>
      {isEmbedding ? (
        <BookerIFrame
          bookerID={bookerID}
          open={isModalOpen}
          onClose={handleClose}
          bookerPosition={bookerPosition}
        />
      ) : (
        <BookerModal
          bookerID={bookerID}
          onClose={handleClose}
          open={isModalOpen}
          bookerPosition={bookerPosition}
        />
      )}
    </>
  );
}

export { Booker };
