import { Box, styled } from "styled-system/jsx";
import { StepHeader, Tag } from "@ttc3k/trekker";
import { PropsWithChildren } from "react";

const StepWrapper = styled(Box, {
  base: {
    transitionDuration: "normal",
    transitionProperty: "height",
    transitionTimingFunction: "ease-in-out",
    display: "flex",
    flexDirection: "column",
    gap: "250",
  },
});

type CollapsableStepProps = PropsWithChildren & {
  complete: boolean;
  title: string;
  optionalStep?: boolean;
};

export const CollapsableStep = ({
  complete,
  title,
  children,
  optionalStep = false,
}: CollapsableStepProps) => {
  return (
    <StepWrapper>
      <StepHeader
        rightElement={
          optionalStep ? (
            <Tag visual={"primary"} mode={"light"}>
              Optional
            </Tag>
          ) : undefined
        }
        checked={complete}
      >
        {title}
      </StepHeader>
      {children}
    </StepWrapper>
  );
};
