import {
  OrderManyQuery,
  OrderPricesByIdsQuery,
  RefundsByOrderIdsQuery,
} from "gql/generated";
import { PaymentStatus } from "../hooks";
import {
  getOrderRefundStatusMap,
  OrderRefundStatus,
} from "./getOrderRefundStatusMap";

export const getOrderIdsByRefundStatus = (
  orders: OrderManyQuery | undefined,
  orderPrices: OrderPricesByIdsQuery | undefined,
  refunds: RefundsByOrderIdsQuery | undefined,
  filterStatus: PaymentStatus,
): string[] => {
  const orderStatusFromPaymentStatus =
    filterStatus === PaymentStatus.FullRefund
      ? OrderRefundStatus.Full
      : filterStatus === PaymentStatus.PartialRefund
        ? OrderRefundStatus.Partial
        : filterStatus === PaymentStatus.NoRefund
          ? OrderRefundStatus.None
          : OrderRefundStatus.None;

  const statusMap = getOrderRefundStatusMap(orders, orderPrices, refunds);
  return Object.entries(statusMap)
    .filter(([_, s]) => s === orderStatusFromPaymentStatus)
    .map(([orderId]) => orderId);
};
