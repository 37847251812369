import { ApolloError } from "@apollo/client";
import { z } from "zod";

export enum ErrorType {
  Invalid = "INVALID",
}

export enum CustomErrorCode {
  InvalidParameters = "InvalidParameters",
  UnknownError = "UnknownError",
  NetworkError = "NetworkError",
  ZodSchemaError = "ZodSchemaError",
}

export type ErrorResponse = {
  message: string;
  code: ErrorType | CustomErrorCode;
};

export abstract class CustomError extends Error {
  public abstract code: CustomErrorCode;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function formatErrorResponse(error: any): ErrorResponse {
  /* eslint-enable @typescript-eslint/no-explicit-any */

  if (error instanceof CustomError) {
    return {
      message: error.message,
      code: error.code,
    };
  }

  // This is an error returned from the backend
  if (error instanceof ApolloError) {
    if (error.networkError) {
      // This would only happen if the front end doesn't have internat or the backend is down.
      return {
        message:
          "We are having trouble reaching the backend resources. Please check you internet connection or try again later.",
        code: CustomErrorCode.NetworkError,
      };
    }

    if (error.graphQLErrors.length) {
      // This is a valid error that has been return by the backend. Let's only report on the first one.
      const { message, extensions } = error.graphQLErrors[0];
      const data = extensions?.data as { ApiErrorCode?: ErrorType } | undefined;
      const code = data?.ApiErrorCode ?? CustomErrorCode.UnknownError;
      return {
        message,
        code,
      };
    }

    // This should never actually happen. Only here as a safety
    return {
      message: "An unknown error has occurred",
      code: CustomErrorCode.UnknownError,
    };
  }

  if (error instanceof z.ZodError) {
    return {
      message: error.message,
      code: CustomErrorCode.ZodSchemaError,
    };
  }

  // This is any generic error
  if (error instanceof Error) {
    return {
      message: error.message,
      code: CustomErrorCode.UnknownError,
    };
  }

  // This would happen if a developer threw an error that isn't actually an error class. Don't do that...
  console.error(
    "Caught an error that is not an instance of an error! Replacing with a proper error but please fix this.",
    error,
  );

  return {
    message: "An unknown error has occurred",
    code: CustomErrorCode.UnknownError,
  };
}
