import {
  LegacyButton,
  ButtonTagVariant,
  LegacyModal,
  ModalVariant,
  LegacyTypography,
  TypographyVariant,
  tokens,
} from "@ttc3k/ttc-design-system";
import React from "react";
import { Xmark } from "iconoir-react";
import "./modal-overrides.scss";
import { FormResponse } from "../../../../../gql/generated.ts";
import { TypeFormSubmission } from "./TypeFormSubmission.tsx";
import { nanoid } from "nanoid";

type ViewFormModalProps = {
  modalOverlayRef: React.RefObject<HTMLDivElement>;
  onModalClose: () => void;
  data: FormResponse;
  onUpdateSuccess?: () => void;
};

export const EntityModal = ({
  modalOverlayRef,
  onModalClose,
  data,
}: ViewFormModalProps) => {
  const handleClose = () => {
    onModalClose();
  };

  return (
    <div className="ttc-modal-override">
      <LegacyModal
        variant={ModalVariant.wbts}
        modalOverlayRef={modalOverlayRef}
        onModalClose={handleClose}
      >
        <div className="tw-flex tw-flex-col tw-gap-4">
          {/* Header */}
          <div className="tw-flex tw-justify-between">
            <LegacyTypography variant={TypographyVariant.h5}>
              Your Submission
            </LegacyTypography>
            <LegacyButton
              variant={ButtonTagVariant.Unstyled}
              color={tokens.semantic.iconSubtlest}
              icon={<Xmark strokeWidth={2} height={24} width={24} />}
              onClick={handleClose}
            />
          </div>

          {/* Content */}
          <div className="tw-text-left">
            <TypeFormSubmission key={nanoid()} submission={data.request} />
          </div>
        </div>
      </LegacyModal>
    </div>
  );
};
