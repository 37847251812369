import { CSVLink } from "react-csv";
import { Button, ButtonProps } from "@ttc3k/trekker";


interface ExportToCSVProps {
  buttonProps?: ButtonProps;
  colHeaders: string[];
  data: string[][];
  fileName: string;
}

export const ExportToCSV = ({
  buttonProps,
  colHeaders,
  data = [],
  fileName,
}: ExportToCSVProps) => {
  const csvData = [colHeaders, ...data];
  return (
    <CSVLink
      className="downloadbtn"
      filename={`${fileName}.csv`}
      data={csvData}
    >
      <Button w={"full"} size={"lg"} {...buttonProps}>
        Export to CSV
      </Button>
    </CSVLink>
  );
};
