import { useFormHandler } from "core/hooks/useFormHandler";
import { parseToUTCDate } from "core/utils/parseToUTCDate";
import { add } from "date-fns";
import {
  EnumProductType,
  useOrderCreateMutation,
  OrderCreateMutationVariables,
  OrderManyDocument,
} from "gql/generated";

import { useCallback } from "react";
import { z } from "zod";

const manualBookingFormSchema = z
  .object({
    mainProductID: z.string().min(1, "Please select a product"),
    mainProductType: z.string(),
    upsellProductIDs: z.array(z.string()),
    name: z.string().min(1, "Please enter a name"),
    email: z
      .string()
      .min(1, "Please enter an email")
      .email("Please enter a valid email"),
    startISO: z.string(),
    endISO: z.string(),
    bookerID: z.string().min(1),
  })
  .superRefine((data, ctx) => {
    if (data.mainProductType === "ACCOMMODATION") {
      if (data.startISO.length < 1 || data.endISO.length < 1) {
        ctx.addIssue({
          message: "Please select the dates of this booking",
          path: ["startISO"],
          code: "custom",
        });
      }
    }
  });

export type ManualBookingFormValues = z.infer<typeof manualBookingFormSchema>;

interface ManualBookingFormOptions {
  onSuccess?: (orderID: string) => void;
  onError?: (message: string) => void;
  operatorID: string;
  screen: "build" | "review";
}

export const useManualBookingForm = ({
  onSuccess,
  onError,
  operatorID,
  screen,
}: ManualBookingFormOptions) => {
  const defaultValues: ManualBookingFormValues = {
    mainProductID: "",
    mainProductType: EnumProductType.Fee,
    name: "",
    email: "",
    startISO: "",
    endISO: "",
    upsellProductIDs: [],
    bookerID: "",
  };

  const [createOrder] = useOrderCreateMutation();

  return useFormHandler<ManualBookingFormValues>(
    useCallback(
      async data => {
        const formErrorMessage = (isUnavailableError?: boolean) =>
          isUnavailableError
            ? "The dates you've selected are now unavailable. Refresh the page and try again"
            : "Could not create this manual booking, please try again later";

        if (screen === "review" && data) {
          const upsellPurchaseRequests =
            data.upsellProductIDs?.map(id => ({
              productId: id ?? "",
              startDateISO: "",
              endDateISO: "",
              guestTiers: [],
            })) ?? [];

          const payload: OrderCreateMutationVariables = {
            source: { bookerID: data.bookerID ?? "", operator: operatorID },
            customerContact: {
              email: data.email ?? "",
              name: data.name ?? "",
              phone: "1231231234",
              guests: [],
            },
            paymentMethodId: "manual_order",
            productPurchaseRequests: [
              {
                productId: data.mainProductID ?? "",
                startDateISO:
                  data.startISO && data.startISO.length > 0
                    ? add(parseToUTCDate(data.startISO), {
                        hours: 1,
                      }).toISOString()
                    : "",
                endDateISO:
                  data.endISO && data.endISO.length > 0
                    ? parseToUTCDate(data.endISO).toISOString()
                    : "",
                guestTiers: [],
              },
            ].concat(upsellPurchaseRequests),
          };
          await createOrder({
            variables: payload,
            refetchQueries: [OrderManyDocument],
          })
            .then(res => {
              if (res.data && res.data?.orderCreate?.recordId) {
                onSuccess?.(res.data.orderCreate.recordId);
              }
            })
            .catch(e => {
              onError?.(
                formErrorMessage(
                  e.message.includes(
                    "No accommodation inventory item is available",
                  ),
                ),
              );
            });
        }
      },
      [createOrder, onError, onSuccess, operatorID, screen],
    ),
    defaultValues,
    { schema: manualBookingFormSchema },
  );
};
