import { Heading, SectionHeader, Text } from "@ttc3k/trekker";
import { DateRangeIso } from "gql/generated";
import { Calendar } from "iconoir-react";
import { Flex } from "styled-system/jsx";
import { formatDate } from "core/utils/formatDate";
import { OrderManyItems } from "core/types";
import { filterClosuresByDate, filterOrdersByDate } from "../utils";
import { NewClosure } from "../hooks";
import { ReservationCard } from "./ReservationCard";
import { ClosureCard } from "./closures/ClosureCard";

type ReservationsPerDayDetailsProps = {
  selectedDate: string;
  orders: OrderManyItems;
  onReservationHover: (dateRangeISO: DateRangeIso | undefined) => void;
  closures: NewClosure[];
  operatorID: string;
};

export const ReservationsPerDayDetails = ({
  selectedDate,
  orders,
  onReservationHover,
  closures,
  operatorID,
}: ReservationsPerDayDetailsProps) => {
  const filteredOrders = filterOrdersByDate({
    selectedDate,
    orders,
  });
  const filteredClosures = filterClosuresByDate({ selectedDate, closures });

  const isNoReservations =
    filteredOrders.length < 1 && filteredClosures.length < 1;

  return (
    <Flex flexDir={"column"} gap={"400"} w={"full"} minW={"320px"}>
      <SectionHeader text={formatDate(selectedDate)} Icon={Calendar} divider />
      {isNoReservations && (
        <Flex
          flexDir={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          p={"600"}
          h={"322px"}
          bg={"bg.wa.light"}
          borderRadius={"100"}
          alignSelf={"stretch"}
          gap={"150"}
        >
          <Heading size={"h5"} color={"text.dark"}>
            No reservations today
          </Heading>
          <Text>Looks like the day is clear!</Text>
        </Flex>
      )}
      <Flex
        flexDir={"column"}
        justifyContent={"center"}
        alignSelf={"stretch"}
        gap={"200"}
      >
        {filteredOrders.length > 0 && (
          <>
            {filteredOrders.map(o => (
              <ReservationCard
                key={o._id}
                order={o}
                selectedDate={selectedDate}
                onReservationHover={onReservationHover}
              />
            ))}
          </>
        )}
        {filteredClosures.length > 0 && (
          <>
            {filteredClosures.map(c => (
              <ClosureCard key={c._id} closure={c} operatorID={operatorID} />
            ))}
          </>
        )}
      </Flex>
    </Flex>
  );
};
