import { CollapsableStep, StripeCardElement } from "booker/shared";
import { Text } from "@ttc3k/trekker";
import { usePaymentMethod } from "./usePaymentMethod";

export const PaymentMethod = () => {
  const { onChange, isComplete } = usePaymentMethod();

  return (
    <CollapsableStep title={"Payment Method"} complete={isComplete}>
      <Text color="text.mid">
        All transactions are securely processed through Stripe, our e-commerce
        partner. We accept debit and all major credit cards.
      </Text>
      <StripeCardElement onChange={onChange} />
    </CollapsableStep>
  );
};
