import { z } from "zod";

//Part1
export const createBusinessSchemaPart1 = z.object({
  name: z.string().min(1, "Required"),
  email: z.string().email(),
  localPhone: z.string(),
  tollFreePhone: z.string(),
  website: z.string(),
  type: z.string(),
});

export type CreateBusinessSchemaPart1 = z.infer<
  typeof createBusinessSchemaPart1
>;

export const createBusinessPart1DefauldValues: CreateBusinessSchemaPart1 = {
  name: "",
  email: "",
  localPhone: "",
  tollFreePhone: "",
  website: "",
  type: "",
};

//Part2
export const createBusinessSchemaPart2 = z.object({
  address: z.string(),
  latitude: z.coerce.number(),
  longitude: z.coerce.number(),
  province: z.string(),
  city: z.string(),
  line1: z.string(),
  postal_code: z.string(),
  country: z.string(),
});

export type CreateBusinessSchemaPart2 = z.infer<
  typeof createBusinessSchemaPart2
>;

export const createBusinessPart2DefauldValues: CreateBusinessSchemaPart2 = {
  address: "",
  latitude: 0,
  longitude: 0,
  province: "",
  city: "",
  line1: "",
  postal_code: "",
  country: "",
};

export const createBusinessSchema = createBusinessSchemaPart1.merge(
  createBusinessSchemaPart2,
);

export type CreateBusinessSchema = z.infer<typeof createBusinessSchema>;

export const createBusinessDefauldValues: CreateBusinessSchema = {
  ...createBusinessPart1DefauldValues,
  ...createBusinessPart2DefauldValues,
};
