import { Calendar } from "@ttc3k/trekker";
import { parseToUTCDate } from "core/utils/parseToUTCDate";
import { eachDayOfInterval } from "date-fns";
import { Closure, DateRangeIso } from "gql/generated";
import { Box } from "styled-system/jsx";
import { DATE_PATTERN_SIMPLE, formatDate } from "core/utils/formatDate";
import { OrderManyItems } from "core/types";
import { getBookedDatesByOrders } from "../utils";

type CalendarSectionProps = {
  selectedDate: string;
  onUpdateSelectedDate: (date: string) => void;
  hoveredReservationDateRangeISO: DateRangeIso | undefined;
  closures: Closure[];
  orders: OrderManyItems;
};

export const CalendarSection = ({
  selectedDate,
  onUpdateSelectedDate,
  hoveredReservationDateRangeISO,
  closures,
  orders,
}: CalendarSectionProps) => {
  const primaryRangeDatesISO = hoveredReservationDateRangeISO
    ? eachDayOfInterval({
        start: parseToUTCDate(hoveredReservationDateRangeISO.start),
        end: parseToUTCDate(hoveredReservationDateRangeISO.end),
      }).map(d => d.toISOString())
    : [];

  const highlightedRangeDatesISO = closures
    .flatMap(v =>
      eachDayOfInterval({
        start: parseToUTCDate(v.dateRangeISO.start),
        end: parseToUTCDate(v.dateRangeISO.end),
      }),
    )
    .map(d => d.toISOString());

  const bookedDatesISO = getBookedDatesByOrders({ orders });

  return (
    <Box
      w={{ base: "100%", lg: "420px" }}
      minW={"360px"}
      h={"400px"}
      minH={"230px"}
    >
      <Calendar
        value={[selectedDate]}
        onValueChange={d => onUpdateSelectedDate(d.valueAsString[0] ?? "")}
        primaryRangeDatesISO={primaryRangeDatesISO}
        highlightedRangeDatesISO={highlightedRangeDatesISO}
        outlinedDatesISO={bookedDatesISO}
        selectionMode="single"
        disablePastDates={false}
        displayJumpToToday
        onJumpToToday={() =>
          onUpdateSelectedDate(formatDate(new Date(), DATE_PATTERN_SIMPLE))
        }
        displayLegend
      />
    </Box>
  );
};
