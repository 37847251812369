import { Box } from "styled-system/jsx";
import { Text } from "@ttc3k/trekker";

type CreateNewBoxProps = {
  itemText: string;
};

export function CreateNewBox({ itemText }: CreateNewBoxProps) {
  return (
    <Box
      borderWidth={"1px"}
      borderStyle={"dashed"}
      borderColor={"border.light"}
      minWidth={"280px"}
      padding={"300"}
      borderRadius={"100"}
      bg={"white"}
    >
      <Text visual={"bodyMedium"} color={"text.accent.dark"}>
        + Create new {itemText}
      </Text>
    </Box>
  );
}
