import { SectionHeader, Text, toastFactory } from "@ttc3k/trekker";
import { useAppContext } from "core/components/apps/PreferredLayout.tsx";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Flex } from "styled-system/jsx";
import {
  SiteByOperatorIdDocument,
  useSiteByOperatorIdQuery,
  useSiteDeployMutation,
} from "gql/generated";
import { ControlledInput } from "apps/custom/ttc/booker/shared";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RedirectToOperator } from "../components";
import {
  createSiteDefauldValues,
  createSiteSchema,
  CreateSiteSchema,
} from "./formSchema";
import { DeploySite } from "./components/DeploySite";
import { BuildingSite } from "./components/BuildingSite";
import { SiteReady } from "./components/SiteReady";

export function Site() {
  const { spaceId } = useParams();
  const { setTitle, setDescription, setActionButton } = useAppContext();
  const [siteDeploy] = useSiteDeployMutation({
    refetchQueries: [SiteByOperatorIdDocument],
  });
  const { data } = useSiteByOperatorIdQuery({
    variables: { input: { operatorId: spaceId! } },
    skip: !spaceId,
    pollInterval: 20000,
  });

  const form = useForm({
    resolver: zodResolver(createSiteSchema),
    defaultValues: createSiteDefauldValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = form;

  const onSubmit: SubmitHandler<CreateSiteSchema> = async formData => {
    try {
      if (!spaceId) return;
      await siteDeploy({
        variables: {
          input: {
            operatorId: spaceId,
            siteName: formData.siteName,
            customDomain: formData.siteName + ".whereabouts.tech",
          },
        },
      });
      toastFactory.create({
        title: "Success!",
        description: "Your site has been created",
      });
    } catch (error) {
      toastFactory.create({
        title: "Error!",
        description: "There has been an error creating your site",
      });
    }
  };

  useEffect(() => {
    setTitle("Booker Site");
    setDescription(
      "Generate a fully polished, single-page website to showcase all of your bookable products and capture bookings!",
    );
    // setActionButton({ label: "Save settings", form: formID, type: "submit" });
  }, [setActionButton, setDescription, setTitle]);

  if (!spaceId || spaceId === "undefined") {
    return <RedirectToOperator />;
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          flexDir={{ base: "column", lg: "row" }}
          gap={"36px"}
          my={"40px"}
          mx={{ base: "20px", lg: "56px" }}
        >
          <Flex flex={1} flexDir={"column"} gap={"24px"}>
            <SectionHeader text="Site settings" divider />
            <Text color={"text.mid"}>
              If you don’t have a website to showcase and sell your products,
              Whereabouts can create one for you in a few minutes — just give
              your site a name below and click the “Build Site” button on the
              right.
            </Text>
            <ControlledInput
              label="Site name"
              name="siteName"
              placeholder="my-booker-site"
              disabled={isSubmitting || !!data?.siteByOperatorId?.url}
            />
          </Flex>
          <div>
            <Flex
              width={{ base: "100%", lg: "360px" }}
              flexDir={"column"}
              gap={"24px"}
              backgroundColor={"bg.wa.light"}
              padding={"32px"}
              borderColor={"border.light"}
              borderWidth={"1px"}
              borderRadius={"8px"}
            >
              {!data?.siteByOperatorId && (
                <DeploySite
                  isLoading={isSubmitting}
                  disabled={!isDirty || isSubmitting}
                />
              )}
              {data?.siteByOperatorId?.status === null && <BuildingSite />}
              {data?.siteByOperatorId?.status === "ready" && (
                <SiteReady link={data.siteByOperatorId.url} />
              )}
            </Flex>
          </div>
        </Flex>
      </form>
    </FormProvider>
  );
}
