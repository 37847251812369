import { useFormHandler } from "core/hooks/useFormHandler";
import {
  EnumProductPublishingStatus,
  EnumProductType,
  ProductManyDocument,
  useBookerCreateMutation,
  useProductCreateMutation,
} from "gql/generated";
import { useCallback } from "react";
import { z } from "zod";

const createAccomFormSchema = z.object({
  name: z.string().min(1, "Please enter a name"),
  description: z.string(),
  shortDescription: z.string(),
  inventory: z
    .string()
    .min(1, "Please enter an inventory quantity")
    .transform(val => parseFloat(val))
    .refine(val => !isNaN(val) && val > 0, {
      message: "Please enter a positive number",
    })
    .transform(val => val.toString()),
});

export type CreateAccomFormValues = z.infer<typeof createAccomFormSchema>;

interface CreateAccomFormOptions {
  onSuccess?: (
    v: (CreateAccomFormValues | undefined) & {
      bookerID: string;
      productID: string;
    },
  ) => void;
  onError?: (message: string) => void;
  operatorID: string;
}

export const useCreateAccomForm = ({
  onSuccess,
  onError,
  operatorID,
}: CreateAccomFormOptions) => {
  const defaultValues: CreateAccomFormValues = {
    name: "",
    description: "",
    shortDescription: "",
    inventory: "1",
  };
  const [createProduct] = useProductCreateMutation();
  const [createBooker] = useBookerCreateMutation();
  return useFormHandler<CreateAccomFormValues>(
    useCallback(
      async data => {
        const payload = { ...data };
        const { name, description, shortDescription, inventory } = payload;

        const formErrorMessage =
          "Could not create this product, please try again later";

        if (name) {
          const emptyMultilingual = { en: "", es: "", fr: "" };
          const { data: res } = await createProduct({
            variables: {
              inventoryCount: inventory ? Number(inventory) : 1,
              record: {
                name: { ...emptyMultilingual, en: name },
                description: { ...emptyMultilingual, en: description },
                shortDescription: {
                  ...emptyMultilingual,
                  en: shortDescription,
                },
                pricing: {
                  description: emptyMultilingual,
                  tiers: [],
                },
                images: [],
                type: EnumProductType.Accommodation,
                isUpsellOnly: false,
                operator: operatorID,
                publishingStatus: EnumProductPublishingStatus.Draft,
                accommodationMetadata: {
                  closures: [],
                  guestMetadata: {
                    nameEntryAllowed: true,
                    nameEntryRequired: false,
                  },
                  maximumNights: 0,
                  minimumNights: 1,
                  rooms: [],
                  amenities: [],
                },
              },
            },
            refetchQueries: [ProductManyDocument],
          });

          if (res?.productCreate && res?.productCreate.recordId.length > 0) {
            const { data: bookerRes } = await createBooker({
              variables: {
                record: {
                  operator: operatorID,
                  productBundles: [{ product: res?.productCreate?.recordId }],
                },
              },
            });

            if (bookerRes && bookerRes.bookerCreate?.recordId) {
              onSuccess?.({
                ...(data as CreateAccomFormValues),
                bookerID: bookerRes.bookerCreate?.recordId,
                productID: res.productCreate.recordId,
              });
            }
          } else {
            onError?.(formErrorMessage);
          }
        }
      },
      [createBooker, createProduct, onError, onSuccess, operatorID],
    ),
    defaultValues,
    { schema: createAccomFormSchema },
  );
};
