import { Button, ButtonProps } from "@ttc3k/trekker";
import { ComponentProps } from "react";

export type ActionButtonProps = ButtonProps &
  ComponentProps<typeof Button> & {
    label: string;
  };

export default function ActionButton({
  label,
  onClick,
  ...buttonProps
}: ActionButtonProps) {
  return (
    <Button onClick={onClick} size={"lg"} {...buttonProps}>
      {label}
    </Button>
  );
}
