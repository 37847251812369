import { useFormHandler } from "core/hooks/useFormHandler";
import { parseToUTCDate } from "core/utils/parseToUTCDate";
import { isAfter } from "date-fns";
import {
  ProductManyDocument,
  useCreateClosuresPerProductMutation,
} from "gql/generated";
import _ from "lodash";
import { useCallback } from "react";
import { z } from "zod";

const createClosureFormSchema = z
  .object({
    productIDs: z
      .array(z.string())
      .min(1, "Please select what product(s) this closure applies to"),
    name: z.string().min(1, "Please enter a closure name"),
    startISO: z.string().min(1, "Please enter a start date"),
    endISO: z.string().min(1, "Please enter a end date"),
  })
  .superRefine((data, ctx) => {
    if (isAfter(parseToUTCDate(data.startISO), parseToUTCDate(data.endISO))) {
      ctx.addIssue({
        message: "End date must be after the start date",
        path: ["endISO"],
        code: "custom",
      });
    }
  });

export type CreateClosureFormValues = z.infer<typeof createClosureFormSchema>;

interface CreateClosureFormOptions {
  onSuccess?: (v: CreateClosureFormValues | undefined) => void;
  onError?: (message: string) => void;
}

export const useCreateClosureForm = ({
  onSuccess,
  onError,
}: CreateClosureFormOptions) => {
  const defaultValues: CreateClosureFormValues = {
    name: "",
    startISO: "",
    endISO: "",
    productIDs: [],
  };
  const [createClosures] = useCreateClosuresPerProductMutation();
  return useFormHandler<CreateClosureFormValues>(
    useCallback(
      async data => {
        const payload = { ...data };
        const { endISO, startISO, name, productIDs } = payload;

        const formErrorMessage =
          "Could not create closure, please try again later";

        if (endISO && startISO && name && productIDs) {
          const { data: res } = await createClosures({
            variables: {
              endIso: parseToUTCDate(endISO).toISOString(),
              startIso: parseToUTCDate(startISO).toISOString(),
              name,
              productIDs: _.compact(productIDs),
            },
            refetchQueries: [ProductManyDocument],
          });
          if (
            res?.createClosuresPerProduct &&
            res?.createClosuresPerProduct.length > 0
          ) {
            onSuccess?.(data as CreateClosureFormValues);
          } else {
            onError?.(formErrorMessage);
          }
        }
      },
      [createClosures, onError, onSuccess],
    ),
    defaultValues,
    { schema: createClosureFormSchema },
  );
};
