import { motion } from "framer-motion";
import { PropsWithChildren, ReactNode } from "react";
import { Box, Flex, styled } from "styled-system/jsx";

const MotionMain = motion(styled("main"));

type SpaceProps = PropsWithChildren & {
  sideBarComponent?: ReactNode;
  sideBarPosition?: "left" | "right";
};

export const Space = ({
  children,
  sideBarComponent,
  sideBarPosition,
}: SpaceProps) => {
  return (
    <MotionMain
      display="flex"
      gap="400"
      flexDirection={{
        base: "column",
        lg: sideBarComponent
          ? sideBarPosition === "right"
            ? "row-reverse"
            : "row"
          : "column",
      }}
      pt={"3.75rem"}
      pb={"7.5rem"}
      px={{ base: "400", lg: "1000" }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {sideBarComponent && (
        <Box position="relative" width={{ lg: "200px" }}>
          <Box position={{ lg: "sticky" }} top={{ lg: "10px" }}>
            {sideBarComponent}
          </Box>
        </Box>
      )}
      <Flex flex={1} display="flex" flexDirection="column" columnGap={"8"}>
        {children}
      </Flex>
    </MotionMain>
  );
};
