//@ts-nocheck
import { motion } from "framer-motion";
import {
  LegacyButton,
  Sizes,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { Link, useLocation } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import SlideInPage from "../../animation/SlideInPage.tsx";

export const GET_OPERATORS_ONBOARDING = gql`
  query operators($tenants: String, $limit: Int, $offset: Int, $email: String) {
    getOperators(
      tenants: $tenants
      limit: $limit
      offset: $offset
      email: $email
    ) {
      data {
        operator_name
        address
        _id
      }
    }
  }
`;
export const InfoTemplate = ({ baseURL, next, stepData }) => {
  const { user } = useAuth0();
  const location = useLocation();
  const { hash, pathname, search } = location;
  const { data, loading, error } = useQuery(GET_OPERATORS_ONBOARDING, {
    variables: {
      limit: 30,
      offset: 0,
      email: user?.email,
      tenants: JSON.stringify([user?.sub, "vtc"]),
    },
  });

  if (loading) {
    return <h1>Loading .... </h1>;
  }

  if (error) {
    return <h1>Error .... </h1>;
  }

  const nextStep = data?.getOperators?.data.length > 0 ? next : "help";

  return (
    <SlideInPage>
      <div className="tw-w-full md:tw-w-96 tw-mx-auto tw-flex tw-flex-col tw-gap-1">
        <LegacyTypography as="h2" variant="H2">
          {stepData.title}
        </LegacyTypography>
        <LegacyTypography>{stepData.description}</LegacyTypography>
        {next && (
          <Link className={"tw-pt-6"} to={`${baseURL}/${nextStep}`}>
            <LegacyButton fullWidth size={Sizes.Large}>
              {stepData.buttonLabel || "Next"}
            </LegacyButton>
          </Link>
        )}
      </div>
    </SlideInPage>
  );
};
