import {
  BookerCartPriceQuery,
  EnumProductType,
  Exact,
  InputMaybe,
  ProductPurchaseRequest,
} from "gql/generated";
import { LazyQueryExecFunction } from "@apollo/client";
import { parseToUTCDate } from "core/utils/parseToUTCDate";
import { add } from "date-fns";
import { CartItem } from "./CartContextProvider";

function convertCartToProductPurchaseRequests(
  cart: CartItem[],
): ProductPurchaseRequest[] {
  return cart.map(item => ({
    startDateISO:
      item.startDateISO.length > 0
        ? add(parseToUTCDate(item.startDateISO), { hours: 1 }).toISOString()
        : item.startDateISO,
    endDateISO:
      item.endDateISO.length > 0
        ? parseToUTCDate(item.endDateISO).toISOString()
        : item.endDateISO,
    productId: item.productID,
    guestTiers: item.guestsPerTier,
  }));
}

type adjustCartPricingProps = {
  getCartPrices: LazyQueryExecFunction<
    BookerCartPriceQuery,
    Exact<{
      bookerId: string;
      productPurchaseRequests:
        | InputMaybe<ProductPurchaseRequest>[]
        | InputMaybe<ProductPurchaseRequest>;
    }>
  >;
  bookerID: string;
  setTotals: React.Dispatch<
    React.SetStateAction<{
      subTotal: number;
      tax: number;
      grandTotal: number;
    }>
  >;
  setCart: React.Dispatch<React.SetStateAction<CartItem[]>>;
  newCart: CartItem[];
};

function adjustCartPricing({
  getCartPrices,
  bookerID,
  setTotals,
  setCart,
  newCart,
}: adjustCartPricingProps) {
  // Filter out any cart items that aren't complete
  const filteredCart = newCart.filter(item => {
    if (
      item.productType === EnumProductType.Accommodation &&
      item.startDateISO.length < 1
    )
      return false;
    else return true;
  });
  // Set cart first before pricing is calculated to ensure no lag
  setCart(newCart);

  if (filteredCart) {
    getCartPrices({
      variables: {
        bookerId: bookerID,
        productPurchaseRequests:
          convertCartToProductPurchaseRequests(filteredCart),
      },
    })
      .then(res => {
        const newTotals = res.data?.bookerCartPrice;
        if (newTotals) {
          setTotals({
            subTotal: newTotals.subTotal,
            grandTotal: newTotals.grandTotal,
            tax: newTotals.taxTotal,
          });

          const cartWithPrice = newCart.map(item => {
            let itemTotal = item.total;
            newTotals?.productPrices.forEach(p => {
              const isProduct = p.productID === item.productID;
              if (isProduct) itemTotal = p.price;
            });
            return { ...item, total: itemTotal };
          });

          setCart(cartWithPrice);
        }
      })
      .catch(err => {
        throw new Error(err);
      });
  } else {
    setCart(newCart);
  }
}

export { convertCartToProductPurchaseRequests, adjustCartPricing };
