import { z } from "zod";

export const createSiteSchema = z.object({
    siteName: z.string().min(1, "Please enter a site name").regex(/^[a-z0-9-]+$/, { message: "Please enter a valid site name" }),
});

export type CreateSiteSchema = z.infer<typeof createSiteSchema>;

export const createSiteDefauldValues: CreateSiteSchema = {
    siteName: "",
};