import React from "react";
import { NavArrowRight } from "iconoir-react";
import "./breadcrumbs.scss";
import { NavLink, useLocation } from "react-router-dom";
import { tokens } from "@ttc3k/ttc-design-system";
import { nanoid } from "nanoid";

export type Breadcrumb = {
  name: string;
  href: string;
  space?: string;
};

export type BreadcrumbsProps = {
  crumbs: Breadcrumb[];
};

export const Breadcrumbs = ({ crumbs }: BreadcrumbsProps) => {
  const location = useLocation();
  const previousLocation = location.state
    ? location.state.previousLocation
    : {
        pathname: "/",
        search: "",
        hash: "",
        state: null,
        key: "default",
      };

  const [isFirst, ...rest] = crumbs;
  const isLast = rest.pop();
  return (
    <nav className={"ttc-breadcrumbs"}>
      {crumbs.map(crumb => {
        if (crumb === isFirst)
          return (
            <NavLink key={nanoid()} to={"/"} className={`tw-text-textSubtlest`}>
              Home
            </NavLink>
          );
        else {
          return (
            <div key={nanoid()} className={"tw-flex tw-gap-1 tw-items-center"}>
              <NavArrowRight
                width={16}
                height={16}
                strokeWidth={2}
                color={tokens.semantic.iconDisabled}
              />
              <NavLink
                to={crumb.href}
                state={{
                  previousLocation: previousLocation,
                  filterStatus: location?.state?.filterStatus,
                }}
              >
                <div
                  className={`${
                    crumb === isLast &&
                    "max-sm:tw-w-[120px] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
                  }`}
                  style={{
                    color:
                      crumb === isLast
                        ? tokens.semantic.textSubtle
                        : tokens.semantic.textSubtlest,
                  }}
                >
                  {crumb.name}
                </div>
              </NavLink>
            </div>
          );
        }
      })}
    </nav>
  );
};
