import React, { PropsWithChildren, useEffect, useMemo } from "react";
import {
  MatomoEvent,
  useMatomoAnalyticsContext,
} from "@ttc3k/ttc-bkr-analytics";
import {
  ProductByIdQuery,
  BookerByIdQuery,
  OperatorByIdQuery,
} from "gql/generated";
import { useGetBookerData } from "./useGetBookerData";

export type BookerContextValues = {
  booker: BookerByIdQuery["bookerById"] | null;
  product: ProductByIdQuery["productById"] | null;
  operator: OperatorByIdQuery["operatorById"] | null;
  error: boolean;
  loading: boolean;
  onCloseBookerModal: () => void;
};

type BookerContextProviderProps = PropsWithChildren & {
  isModalOpen: boolean;
  onCloseBookerModal: () => void;
  bookerID: string;
};

const context = React.createContext<BookerContextValues | null>(null);

export const BookerContextProvider: React.FC<BookerContextProviderProps> = ({
  isModalOpen,
  onCloseBookerModal,
  bookerID,
  children,
}) => {
  const { error, loading, operator, product, booker } =
    useGetBookerData(bookerID, isModalOpen);
  const { pushMatomoEvent } = useMatomoAnalyticsContext();

  useEffect(() => {
    if (!loading && !error && operator && isModalOpen) {
      pushMatomoEvent({
        event: MatomoEvent.OnBkrLoad,
        bkrID: bookerID,
        operatorName:
          operator && operator.name ? operator.name : "unknown operator name",
      });
    }
  }, [bookerID, error, loading, operator, pushMatomoEvent, isModalOpen]);

  const contextValue: BookerContextValues = useMemo(
    () => ({
      booker,
      product,
      operator,
      loading,
      error,
      onCloseBookerModal,
    }),
    [booker, product, operator, loading, error, onCloseBookerModal],
  );

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export function useBookerContext() {
  const bookerContext = React.useContext(context);

  if (!bookerContext) {
    throw new Error(
      "useBookerContext must be used within a BookerContextProvider",
    );
  }

  return bookerContext;
}
