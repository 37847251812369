import {
  LegacyButton,
  ButtonTagVariant,
  LegacyLoadingSpinner,
  LegacyTypography,
  TypographyVariant,
  tokens,
} from "@ttc3k/ttc-design-system";
import { InfoCircle } from "iconoir-react";
import {
  EnumStripeOnboardingStatus,
  useOperatorStripeAccountStatusQuery,
  useOperatorConnectStripeAccountMutation,
  useOperatorDisconnectStripeAccountMutation,
  OperatorStripeAccountStatusDocument,
} from "../../../../gql/generated";

type StripeAccountConnectProps = {
  operatorId: string;
};

export const StripeAccountConnect = ({
  operatorId,
}: StripeAccountConnectProps) => {
  const { data, loading } = useOperatorStripeAccountStatusQuery({
    variables: { operatorId },
  });
  const [connectStripeAcct, { loading: isConnectStripeAcctLoading, error }] =
    useOperatorConnectStripeAccountMutation();
  const [disconnectStripeAcct, { loading: isDisconnectAcctLoading }] =
    useOperatorDisconnectStripeAccountMutation({
      refetchQueries: [OperatorStripeAccountStatusDocument],
    });
  const fullUrl = window.location.href;

  const handleConnectStripeAccount = () => {
    connectStripeAcct({
      variables: { operatorId, redirectUrl: fullUrl },
    }).then(res => {
      if (res.data?.operatorConnectStripeAccount?.link) {
        window.location.href = res.data.operatorConnectStripeAccount.link;
      }
    });
  };

  const handleDisconnectStripeAccount = () => {
    disconnectStripeAcct({ variables: { operatorId } });
  };

  if (loading) {
    return (
      <div className="tw-flex tw-flex-col tw-gap-6 tw-p-8 tw-rounded-lg tw-border-borderInformation tw-border-2 tw-bg-white">
        <LegacyLoadingSpinner />
      </div>
    );
  }
  if (
    data?.operatorStripeAccountStatus &&
    data.operatorStripeAccountStatus.status ===
      EnumStripeOnboardingStatus.Complete
  ) {
    return (
      <div className="tw-bg-white tw-py-5 tw-px-6 tw-flex tw-justify-between tw-items-center tw-gap-12 tw-rounded-lg tw-border tw-border-zinc-300">
        <div>
          <LegacyTypography
            variant={TypographyVariant.primaryBold}
            className="tw-capitalize"
          >
            {data.operatorStripeAccountStatus.name.toLowerCase()}
          </LegacyTypography>
          <LegacyTypography
            variant={TypographyVariant.primaryMedium}
            color={tokens.semantic.textSubtlest}
          >
            {data.operatorStripeAccountStatus.id}
          </LegacyTypography>
        </div>
        <LegacyButton
          onClick={handleDisconnectStripeAccount}
          disabled={isDisconnectAcctLoading}
          variant={ButtonTagVariant.Secondary}
        >
          Disconnect
        </LegacyButton>
      </div>
    );
  } else {
    return (
      <div className="tw-flex tw-flex-col tw-gap-6 tw-p-8 tw-rounded-lg  tw-border-gray-200 tw-border tw-bg-white">
        <div className="tw-flex tw-flex-col tw-gap-3">
          <LegacyTypography
            variant={TypographyVariant.primaryBold}
            color={tokens.semantic.textInfo}
            className="tw-flex tw-gap-2 tw-items-center"
          >
            <InfoCircle height={24} width={24} strokeWidth={1.75} />
            Stripe Account Required
          </LegacyTypography>
          <LegacyTypography color={tokens.semantic.textSubtle}>
            In order to process transactions through Booker, you’ll need to
            create or connect a Stripe account. The account creation process is
            quick and easy, but note that you’ll need access to key business
            information (address, banking info, etc.) to complete this process.
          </LegacyTypography>
        </div>
        <div>
          <LegacyButton
            onClick={handleConnectStripeAccount}
            variant={ButtonTagVariant.InfoBold}
            disabled={isConnectStripeAcctLoading}
          >
            Create or connect Stripe account
          </LegacyButton>
          {error && error.message && (
            <div className="tw-mt-2">
              <LegacyTypography
                variant={TypographyVariant.secondaryBold}
                color={tokens.semantic.textError}
              >
                Error: {error?.message}
              </LegacyTypography>
            </div>
          )}
        </div>
      </div>
    );
  }
};
