import {
  InventoryByProductBookedDatesDocument,
  useOrderCreateMutation,
} from "gql/generated";
import {
  useBookerContext,
  useCartContext,
  convertCartToProductPurchaseRequests,
} from "booker/contexts";
import {
  MatomoEvent,
  useMatomoAnalyticsContext,
} from "@ttc3k/ttc-bkr-analytics";
import { useState } from "react";
import _ from "lodash";

export const useCheckout = () => {
  const [status, setStatus] = useState({
    loading: false,
    error: false,
    errorMsg: "",
    orderComplete: false,
  });
  const {
    customer,
    cart,
    grandTotal,
    stripeCardElement,
    paymentMethodID,
    guestNames,
  } = useCartContext();
  const { booker } = useBookerContext();
  const [createOrder] = useOrderCreateMutation({
    refetchQueries: [InventoryByProductBookedDatesDocument],
  });
  const { pushMatomoEvent } = useMatomoAnalyticsContext();

  async function handleCheckoutSubmit() {
    setStatus(prev => ({ ...prev, loading: true }));

    if (!paymentMethodID || !stripeCardElement) {
      setStatus(prev => ({
        ...prev,
        loading: false,
        error: true,
        errorMsg: "Stripe error: Unable to complete payment",
      }));
      return;
    }

    await createOrder({
      variables: {
        customerContact: {
          email: customer.email,
          phone: customer.phone,
          name: customer.name,
          guests: _.compact(
            guestNames.map(v => {
              if (v.length > 0) return { name: v };
              else return null;
            }),
          ),
        },
        paymentMethodId: paymentMethodID,
        productPurchaseRequests: convertCartToProductPurchaseRequests(cart),
        source: { bookerID: booker?._id, operator: booker?.operator?._id },
      },
    })
      .then(value => {
        if (value.data?.orderCreate?.recordId) {
          setStatus(prev => ({ ...prev, orderComplete: true }));
          pushMatomoEvent({
            event: MatomoEvent.Purchase,
            grandTotal: grandTotal,
          });
        }
      })
      .catch(err => {
        setStatus(prev => ({
          ...prev,
          error: true,
          errorMsg: "Error creating order",
        }));
        throw new Error(err);
      })
      .finally(() => {
        setStatus(prev => ({ ...prev, loading: false }));
      });
  }
  return { onCheckoutSubmit: handleCheckoutSubmit, ...status };
};
