import { Link } from "react-router-dom";
import { LegacyButton } from "@ttc3k/ttc-design-system";

export function PageNotFound() {
  return (
    <div className="tw-h-screen tw-w-[100%] tw-flex-col tw-justify-center tw-items-center tw-gap-10 tw-inline-flex">
      <div className="tw-flex-col tw-justify-start tw-items-center tw-gap-6 tw-flex">
        <div className="tw-text-stone-500 tw-text-7xl tw-font-extrabold tw-font-['Figtree'] tw-leading-10">
          404
        </div>
        <div className="tw-flex-col tw-justify-start tw-items-center tw-gap-2 tw-flex">
          <div className="tw-text-center tw-text-stone-600 tw-text-2xl tw-font-semibold tw-font-['Figtree'] tw-leading-9">
            Page Not Found
          </div>
          <div className="tw-w-80 tw-text-center tw-text-stone-500 tw-text-base tw-font-medium tw-font-['Figtree'] tw-leading-snug">
            Whoops — looks like this resource no longer exists or never existed
            in the first place
          </div>
        </div>
      </div>
      <div className="tw-justify-start tw-items-start tw-inline-flex">
        <Link to="/">
          <LegacyButton>Take me home, country road</LegacyButton>
        </Link>
      </div>
    </div>
  );
}
