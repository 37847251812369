import {
  EnumProductType,
  ProductPurchaseRequest,
  useBookerCartPriceQuery,
  useProductsByIdsQuery,
} from "gql/generated";
import { useFormContext, useWatch } from "react-hook-form";
import { Heading, Table, Text } from "@ttc3k/trekker";
import { formatPrice } from "core/utils/formatPrice";
import { Flex } from "styled-system/jsx";
import pluralize from "pluralize";
import { differenceInCalendarDays } from "date-fns";
import { parseToUTCDate } from "core/utils/parseToUTCDate";
import { ManualBookingFormValues } from "./useManualBookingForm";

export const ReviewManualBooking = () => {
  const { control } = useFormContext<ManualBookingFormValues>();
  const email = useWatch({ control, name: "email" });
  const mainProductID = useWatch({ control, name: "mainProductID" });
  const startISO = useWatch({ control, name: "startISO" });
  const endISO = useWatch({ control, name: "endISO" });
  const upsellProductIDs = useWatch({
    control,
    name: "upsellProductIDs",
  });
  const bookerID = useWatch({
    control,
    name: "bookerID",
  });

  const productPurchaseRequests: ProductPurchaseRequest[] = [
    {
      productId: mainProductID,
      guestTiers: [],
      startDateISO: startISO,
      endDateISO: endISO,
    },
  ].concat(
    upsellProductIDs.map(id => ({
      productId: id,
      guestTiers: [],
      startDateISO: "",
      endDateISO: "",
    })),
  );

  const productIDs = productPurchaseRequests.map(r => r.productId);

  const { data: products } = useProductsByIdsQuery({
    variables: { ids: productIDs },
  });

  const { data } = useBookerCartPriceQuery({
    variables: {
      bookerId: bookerID,
      productPurchaseRequests: productPurchaseRequests,
    },
  });

  const productPrices =
    data?.bookerCartPrice?.productPrices.map(p => {
      const fullProduct = products?.productsByIds.find(
        prod => prod._id === p.productID,
      );
      return {
        _id: p.productID,
        price: p.price,
        name:
          fullProduct?.name.en ??
          fullProduct?.name.es ??
          fullProduct?.name.fr ??
          "Unknown name",
        type: fullProduct?.type ?? EnumProductType.Fee,
      };
    }) ?? [];

  const numberOfNights = differenceInCalendarDays(
    parseToUTCDate(endISO),
    parseToUTCDate(startISO),
  );

  return (
    <Flex flexDir={"column"} gap={"250"} minH={"280px"}>
      <Table.Root>
        <Table.Body>
          {productPrices.map(v => (
            <Table.Row key={v._id}>
              <Table.Header paddingRight={"150"}>
                <Flex flexDir={"column"}>
                  <Text color={"text.dark"}>
                    {v.name}
                    {v.type === EnumProductType.Accommodation &&
                      ` • ${pluralize("night", numberOfNights, true)}`}
                  </Text>
                </Flex>
              </Table.Header>
              <Table.Cell>${formatPrice(v.price)}</Table.Cell>
            </Table.Row>
          ))}
          <Table.Row>
            <Table.Header>Subtotal</Table.Header>
            <Table.Cell>
              ${formatPrice(data?.bookerCartPrice?.subTotal ?? 0)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Header>Tax</Table.Header>
            <Table.Cell>
              ${formatPrice(data?.bookerCartPrice?.taxTotal ?? 0)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Header>Total</Table.Header>
            <Table.Cell>
              <Heading size={"h4"} color={"text.dark"}>
                ${formatPrice(data?.bookerCartPrice?.grandTotal ?? 0)}
              </Heading>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table.Root>
      <Text visual={"smallRegular"} color={"text.mid"}>
        We&apos;ll send the invoice directly to&nbsp;
        <Text as={"span"} visual={"smallSemiBold"}>
          {email}
        </Text>
        , and they&apos;ll have 7 days to make their payment.
      </Text>
    </Flex>
  );
};
