import { Button, Heading, ModalOverlay, Text } from "@ttc3k/trekker";
import { Center } from "styled-system/jsx";
import { useBookerContext, useCartContext } from "booker/contexts";
import orderSuccess from "./order-success.svg";

type OrderSuccessPageProps = {
  open: boolean;
};

export const OrderSuccessPage = ({ open }: OrderSuccessPageProps) => {
  const { onCloseBookerModal } = useBookerContext();
  const { resetCartValuesToDefault } = useCartContext();

  const handleClose = () => {
    resetCartValuesToDefault();
    onCloseBookerModal();
  };

  return (
    <ModalOverlay open={open}>
      <Center
        bg={"cream.50"}
        h={"full"}
        w={"full"}
        px={"400"}
        flexDir={"column"}
        gap="300"
        textAlign={"center"}
      >
        <img src={orderSuccess} alt="Loading" />
        <Center flexDir="column" gap="100">
          <Heading size="h5" color="text.dark">
            You're booked!
          </Heading>
          <Text color="text.mid">
            We'll send you a confirmation email in a sec with all of your
            booking details.
          </Text>
          <Button mt={"100"} visual={"outline"} onClick={handleClose}>
            Back to site
          </Button>
        </Center>
        <Center position="absolute" bottom="600" flexDir="column">
          <Text color="text.light" visual="smallRegular">
            Powered by
          </Text>
          <Text color="text.mid" visual="smallLabel">
            Whereabouts
          </Text>
        </Center>
      </Center>
    </ModalOverlay>
  );
};
