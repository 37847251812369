import { Menu, MultiActionButton, Text } from "@ttc3k/trekker";
import { NavArrowDown } from "iconoir-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { CreateClosureModal } from "./closures/CreateClosureModal";
import { ManualBookingModal } from "./manualBooking";

enum CalendarMenuOption {
  createClosure = "CREATE_CLOSURE",
  viewClosures = "VIEW_CLOSURES",
  createBooking = "CREATE_BOOKING",
}

export const CalendarMenu = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    [CalendarMenuOption.createClosure]: false,
    [CalendarMenuOption.createBooking]: false,
    [CalendarMenuOption.viewClosures]: false,
  });

  const { spaceId } = useParams();

  const handleMenuSelect = (value: CalendarMenuOption) => {
    switch (value) {
      case CalendarMenuOption.createClosure:
        setIsModalOpen(prev => ({
          ...prev,
          [CalendarMenuOption.createClosure]: true,
        }));
        break;
      case CalendarMenuOption.createBooking:
        setIsModalOpen(prev => ({
          ...prev,
          [CalendarMenuOption.createBooking]: true,
        }));
        break;
      case CalendarMenuOption.viewClosures:
        setIsModalOpen(prev => ({
          ...prev,
          [CalendarMenuOption.viewClosures]: true,
        }));
        break;
    }
  };
  return (
    <>
      <Menu.Root
        trigger={
          <MultiActionButton
            visual={"outline"}
            type={"button"}
            Icon={NavArrowDown}
            size={"lg"}
            width={"max-content"}
          >
            Calendar actions
          </MultiActionButton>
        }
        onSelect={d => handleMenuSelect(d.value as CalendarMenuOption)}
      >
        <Menu.Item
          value={CalendarMenuOption.createClosure}
          gap={"100"}
          justifyContent={"flex-start"}
        >
          <Text visual={"smallMedium"} color={"text.dark"}>
            Create closure
          </Text>
        </Menu.Item>
        {/* <Menu.Item
          value={CalendarMenuOption.viewClosures}
          gap={"100"}
          justifyContent={"flex-start"}
        >
          <Text visual={"smallMedium"} color={"text.dark"}>
            See closures
          </Text>
        </Menu.Item> */}
        <Menu.Item
          value={CalendarMenuOption.createBooking}
          gap={"100"}
          justifyContent={"flex-start"}
        >
          <Text visual={"smallMedium"} color={"text.dark"}>
            Create manual booking
          </Text>
        </Menu.Item>
      </Menu.Root>

      <CreateClosureModal
        isOpen={isModalOpen[CalendarMenuOption.createClosure]}
        onClose={() =>
          setIsModalOpen(prev => ({
            ...prev,
            [CalendarMenuOption.createClosure]: false,
          }))
        }
        operatorID={spaceId ?? ""}
      />
      <ManualBookingModal
        isOpen={isModalOpen[CalendarMenuOption.createBooking]}
        onClose={() =>
          setIsModalOpen(prev => ({
            ...prev,
            [CalendarMenuOption.createBooking]: false,
          }))
        }
        operatorID={spaceId ?? ""}
      />
    </>
  );
};
