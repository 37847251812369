import _ from "lodash";
import { DateRange } from "../../Steps/components/DatesAndGuests/useDatesAndGuests";

function convertDateRangeToCalendarValue(range: DateRange): string[] {
  const start = range.startDateISO.length > 0 ? range.startDateISO : undefined;
  const end = range.endDateISO.length > 0 ? range.endDateISO : undefined;
  return _.compact([start, end]);
}

export { convertDateRangeToCalendarValue };
