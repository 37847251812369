import { SectionHeader, Table } from "@ttc3k/trekker";
import { formatDate } from "core/utils/formatDate";
import { formatPrice } from "core/utils/formatPrice";
import { EnumRefundStatus, RefundManyQuery } from "gql/generated";
import { Undo } from "iconoir-react";
import { Flex } from "styled-system/jsx";

type RefundSummaryProps = {
  refundData: RefundManyQuery | undefined;
};

export const RefundSummary = ({ refundData }: RefundSummaryProps) => {
  const successfulRefundData = refundData?.refundMany.filter(
    r => r.status === EnumRefundStatus.Succeeded,
  );

  if (!successfulRefundData || successfulRefundData.length < 1) {
    return null;
  }

  const totalRefundedAmount = successfulRefundData.reduce((acc, refund) => {
    return acc + refund.amount;
  }, 0);
  return (
    <Flex width={"full"} flexDir={"column"} gap={"150"}>
      <SectionHeader Icon={Undo} text="Refunds" divider />
      <Table.Root>
        <Table.Body>
          {successfulRefundData.map(refund => {
            return (
              <Table.Row key={refund._id}>
                <Table.Header>{formatDate(refund.createdDateISO)}</Table.Header>
                <Table.Cell color={"text.error.mid"}>
                  -${formatPrice(refund.amount)}
                </Table.Cell>
              </Table.Row>
            );
          })}
          {successfulRefundData.length > 1 && (
            <Table.Row>
              <Table.Header>Total refunded</Table.Header>
              <Table.Cell color={"text.error.mid"}>
                -${formatPrice(totalRefundedAmount)}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table.Root>
    </Flex>
  );
};
