import { gql, useMutation } from "@apollo/client";
import { GET_NOTIFICATIONS } from "../pages/home/Notifications";

const CREATE_NOTIFICATION = gql`
  mutation CreateNotification($notification: String) {
    createNotification(notification: $notification) {
      tenant
    }
  }
`;

export const useCreateNotification = () => useMutation(CREATE_NOTIFICATION);
