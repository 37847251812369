// @ts-nocheck
import { motion } from "framer-motion";
import {
  LegacyButton,
  LegacyLoadingSpinner,
  LegacyTypography,
} from "@ttc3k/ttc-design-system";
import { gql, useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { insertAndAfterLastComma } from "../../../utils/insertAndAfterLastComma.ts";
import { variants } from "../shared.ts";
import { useOnboardingContext } from "../Layout.tsx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "@react-hook/window-size";
import Confetti from "react-confetti";
import { useState } from "react";

const UPDATE_USER_METADATA = gql`
  mutation updateUserMetadata(
    $id: String
    $body: String
    $tenantId: String
    $userObject: String
  ) {
    updateUserMetadata(user_id: $id, body: $body) {
      user_id
    }
    addUserToTenant(tenantId: $tenantId, userObject: $userObject) {
      name
    }
  }
`;
export const SuccessTemplate = ({ next, baseURL, stepData }) => {
  const { user } = useAuth0();
  const { state } = useLocation();
  const [confetti, startConfetti] = useState(false);

  const navigate = useNavigate();
  const {
    businesses: contextBusinesses,
    setOnboardingStatus,
    successSetOnboardingStatus,
    loadingSetOnboardingStatus,
    errorSetOnboardingStatus,
  } = useOnboardingContext();

  const businesses =
    contextBusinesses.length > 0
      ? contextBusinesses
      : user?.whereaboutsOnboarding?.businesses || [
          localStorage.getItem("onboardingBusiness"),
        ];

  const businessList = insertAndAfterLastComma(
    businesses.map(business => `${business?.operator_name}`).join(", "),
  );
  const { width, height } = useWindowSize();

  return (
    <motion.div
      className={"tw-flex tw-flex-col tw-text-center tw-gap-6"}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
    >
      <LegacyTypography as="h2" variant="H2">
        Success!
      </LegacyTypography>
      <LegacyTypography>
        {/*You’ve claimed {businessList}.<br />*/}
        Your account is almost ready.
      </LegacyTypography>
      <LegacyButton
        onClick={() => {
          startConfetti(true);
          setOnboardingStatus({
            variables: {
              id: user.sub,
              status: true,
            },
          })
            .then(() => new Promise(resolve => setTimeout(resolve, 1500)))
            .then(() => navigate(0));
        }}
      >
        {loadingSetOnboardingStatus || successSetOnboardingStatus ? (
          <LegacyLoadingSpinner size={20} />
        ) : (
          "Click to complete"
        )}
      </LegacyButton>
      {confetti && <Confetti width={width} height={height} />}
    </motion.div>
  );
};
