import { nanoid } from "nanoid";

export const TypeFormSubmission = ({ submission }) => {
  return (
    <>
      {submission.requestBody?.form_response?.definition.fields.map(
        (field, index) => {
          return (
            <div key={nanoid()}>
              <p>
                <strong>{field.title}</strong>
              </p>
              <p>
                {submission.requestBody.form_response.answers[index].text}
                {
                  submission.requestBody.form_response.answers[index].choice
                    ?.label
                }
                {submission.requestBody.form_response.answers[index].date}
                {submission.requestBody.form_response.answers[index].number}
                {submission.requestBody.form_response.answers[
                  index
                ].choices?.labels.map(r => `${r} | `)}
              </p>
            </div>
          );
        },
      )}
    </>
  );
};
