import { EnumLearnerActivityLogEventType } from "gql/generated";
import { useState } from "react";

export type FilterValue = {
  searchParams: string;
  status: EnumLearnerActivityLogEventType | undefined;
  createdAt: { start: string; end: string };
};

export const useFilter = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    refundModal: false,
  });
  const defaultFilterValue: FilterValue = {
    searchParams: "",
    status: "" as EnumLearnerActivityLogEventType,
    createdAt: { start: "", end: "" },
  };
  const [filterValue, setFilterValue] = useState(defaultFilterValue);

  const handleOpenIssueRefundModal = () => {
    setIsModalOpen(prev => ({ ...prev, refundModal: true }));
  };
  const handleCloseIssueRefundModal = () => {
    setIsModalOpen(prev => ({ ...prev, refundModal: false }));
  };

  const handleUpdateFilter = <K extends keyof FilterValue>(
    key: K,
    value: FilterValue[K],
  ) => {
    setFilterValue(prev => ({ ...prev, [key]: value }));
  };

  const handleResetFilter = () => {
    setFilterValue(defaultFilterValue);
  };
  return {
    filter: filterValue,
    onUpdateFilter: handleUpdateFilter,
    onResetFilter: handleResetFilter,
    isIssueRefundModalOpen: isModalOpen,
    onOpenIssueRefundModal: handleOpenIssueRefundModal,
    onCloseIssueRefundModal: handleCloseIssueRefundModal,
  };
};
