import { OrderManyItems } from "core/types";
import { Text, Tag, Spinner } from "@ttc3k/trekker";
import { NavLink, useParams } from "react-router-dom";
import { formatDate } from "core/utils/formatDate";
import { formatPrice } from "core/utils/formatPrice";
import { EnumRefundStatus, useRefundManyQuery } from "gql/generated";
import { OrderTableRow } from "./OrderTableRow";
import { OrderTableCell } from "./OrderTableCell";
import { OrderMenu } from "./OrderMenu";

type OrderRowProps = {
  order: OrderManyItems[0];
  orderNum: number;
  orderGrandTotal: number;
  grandTotalLoading: boolean;
};

export const OrderRow = ({
  order,
  orderNum,
  orderGrandTotal,
  grandTotalLoading,
}: OrderRowProps) => {
  const { spaceId } = useParams();
  const { data: orderRefunds } = useRefundManyQuery({
    variables: { filter: { orderID: order._id } },
  });

  const isManualOrder = !!order.stripeMetadata.invoiceID;
  const totalAmountRefunded =
    orderRefunds?.refundMany.reduce((acc, refund) => {
      if (refund.status === EnumRefundStatus.Succeeded) {
        return acc + refund.amount;
      } else {
        return acc;
      }
    }, 0) ?? 0;
  return (
    <OrderTableRow>
      <OrderTableCell>
        <Text
          fontSize={"2xs"}
          lineHeight={"2xs"}
          fontFamily={"mono"}
          color={"text.light"}
        >
          {orderNum}
        </Text>
      </OrderTableCell>
      <OrderTableCell>
        <NavLink
          to={`/app/booker/${spaceId}/calendar/order-details/${order._id}`}
        >
          <Text
            fontSize={"sm"}
            lineHeight={"sm"}
            fontFamily={"mono"}
            color={"text.accent.mid"}
          >
            {order._id}
          </Text>
        </NavLink>
      </OrderTableCell>
      <OrderTableCell width={"200px"}>
        <Text visual={"bodySemiBold"} color={"text.dark"} lineClamp={1}>
          {order.customerContact.name}
        </Text>
      </OrderTableCell>
      <OrderTableCell width={"200px"}>
        <Text color={"text.dark"} lineClamp={1}>
          {order.customerContact.email}
        </Text>
      </OrderTableCell>
      <OrderTableCell>
        {grandTotalLoading ? (
          <Spinner width={"16px"} height={"16px"} />
        ) : (
          <Text color={"text.dark"}>${formatPrice(orderGrandTotal)}</Text>
        )}
      </OrderTableCell>
      <OrderTableCell>
        <Text color={"text.dark"}>
          {formatDate(order.createdDateISO, "dd/MM/yyyy")}
        </Text>
      </OrderTableCell>
      <OrderTableCell justifyContent={"flex-end"}>
        {totalAmountRefunded > 0 ? (
          <Tag visual={"warning"}>Refunded</Tag>
        ) : (
          <>
            {totalAmountRefunded === 0 && !isManualOrder ? (
              <Tag visual={"success"}>Paid</Tag>
            ) : (
              <Tag visual={"success"}>Manual order</Tag>
            )}
          </>
        )}
      </OrderTableCell>
      <OrderTableCell>
        <OrderMenu order={order} orderRefunds={orderRefunds} />
      </OrderTableCell>
    </OrderTableRow>
  );
};
