import { z } from "zod";

// Profile schema
export const userSchema = z.object({
  email: z
    .string()
    .email("Please enter a valid email")
    //.regex(/^[a-z0-9+_.-]+@[a-z0-9.-]+$/, "Email must be lowercase")
    .min(1, "Required"),
});

export type userSchema = z.infer<typeof userSchema>;

export const userDefaultValues: userSchema = {
  email: "",
};
