import { ReactNode, forwardRef, useId } from "react";
import { cn } from "../../../utils/cn";
import { Label } from "../Label";
import { HelperText } from "../HelperText";
import "./text-area.scss";

type TextAreaProps = {
  label?: string;
  labelRight?: string;
  error?: boolean;
  bottomAdornment?: ReactNode;
  helperTextClassName?: string;
};

type TextAreaMainProps = Omit<
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >,
  "ref"
>;

export type Ref = HTMLTextAreaElement;

export const TextArea = forwardRef<Ref, TextAreaProps & TextAreaMainProps>(
  (props, ref) => {
    const {
      label,
      labelRight,
      error,
      bottomAdornment,
      helperTextClassName,
      ...textAreaMainProps
    } = props;
    const id = useId();

    return (
      <div className="tw-w-full tw-gap-[4px] tw-flex tw-flex-col">
        {label && (
          <Label id={id} label={label} labelRight={labelRight} error={error} />
        )}
        <textarea
          ref={ref}
          id={id}
          className={cn(
            "tw-text-colorText tw-py-[12px] tw-px-[16px] tw-rounded-[8px] focus:tw-border-[#2E92EF] tw-outline-none tw-border-border tw-border-[1px] tw-bg-white",
            { "tw-border-[#DB330D] focus:tw-border-[#DB330D]": error },
          )}
          rows={7}
          {...textAreaMainProps}
        />
        {bottomAdornment && typeof bottomAdornment === "string" ? (
          <HelperText
            text={bottomAdornment}
            error={error}
            className={helperTextClassName}
          />
        ) : (
          bottomAdornment
        )}
      </div>
    );
  },
);
