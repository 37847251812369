import { Logo } from "../../Logo.tsx";

export default function WhereaboutsLogo() {
  return (
    <>
      <div className="tw-w-full tw-flex tw-items-center tw-justify-center tw-mb-5">
        <Logo />
      </div>
      <div className="tw-text-center tw-mb-5 tw-text-zinc-800 tw-text-2xl tw-font-semibold tw-font-figtree tw-leading-9 tw-tracking-wide">
        WHEREABOUTS
      </div>
    </>
  );
}
