import { useId } from "react";
import {
  tokens,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { cn } from "../../../../utils/cn";
import {
  MonthsValues,
  months,
  monthsMetadata,
  initialMonthsValues,
} from "./utils";
import { Button, ButtonProps } from "./button";

export type RowProps = {
  label?: string;
  value?: MonthsValues;
  onChange?: (value: MonthsValues) => void;
  variant?: ButtonProps["variant"];
};

export const Row = ({
  label,
  value = initialMonthsValues,
  onChange,
  variant,
}: RowProps) => {
  const id = useId();

  return (
    <div className={cn("tw-flex tw-items-center tw-gap-x-[12px]")}>
      {label && (
        <LegacyTypography
          className={"tw-w-[48px] tw-min-w-[48px]"}
          variant={TypographyVariant.secondaryBold}
          color={tokens.semantic.textSubtle}
        >
          {label}
        </LegacyTypography>
      )}
      <div
        className={cn(
          "tw-flex tw-items-center tw-gap-x-[6px] tw-overflow-auto no-scrollbar tw-w-full",
        )}
      >
        {months.map(month => {
          const selectMonthProps = value[month];
          const selectMonthMeta = monthsMetadata[month];
          return (
            <Button
              key={id + month}
              className={"tw-flex-1"}
              type="button"
              onClick={() =>
                onChange?.({ ...value, [month]: !selectMonthProps })
              }
              variant={selectMonthProps ? variant : "default"}
            >
              {selectMonthMeta.shortName}
            </Button>
          );
        })}
      </div>
    </div>
  );
};
