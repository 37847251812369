import {
  LegacyButton,
  ButtonTagVariant,
  LegacyLoadingSpinner,
  LegacyTag,
  LegacyTypography,
  TypographyVariant,
  tokens,
} from "@ttc3k/ttc-design-system";
import { Trash, Download, ChatLines, Xmark, Check } from "iconoir-react";
import { scale } from "@cloudinary/url-gen/actions/resize";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ImageLazyLoad } from "core/components/shared/ImageLazyLoad";
import { NewImage } from "gql/generated";
import { Dialog } from "../Dialog";
import { cloudinaryInstance } from "../../../utils/cloudinary";
import { TextArea } from "../TextArea";
import { ChatLinesDark } from "../Icons/ChatLinesDark";
import {
  ImageInfoSchema,
  captionInitialValue,
  imageInfoInitialValues,
  imageInfoSchema,
} from "./schema";

export type ImageMediaProps = {
  image: Partial<NewImage>;
  onDelete?: (image: Partial<NewImage>) => void;
  onSave?: (image: Partial<NewImage>) => Promise<void>;
  loading?: boolean;
  showTag?: boolean;
};

const getFileName = (text?: string) => {
  return text?.match(/([^/]+)\.\w+$/)?.[0];
};

export const ImageMedia = ({
  image,
  onDelete,
  onSave,
  loading = false,
  showTag = false,
}: ImageMediaProps) => {
  const [open, setOpen] = useState(false);

  const isCloudinary = image.full_url?.includes("cloudinary.com");

  const handleDownload = async () => {
    try {
      if (!image.full_url) return;
      if (isCloudinary) {
        const response = await fetch(image.full_url);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = getFileName(image.full_url) || "download";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        window.open(image.full_url, "_blank");
      }
    } catch (error) {
      console.error("Download error", error);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
  } = useForm({
    resolver: zodResolver(imageInfoSchema),
    defaultValues: imageInfoInitialValues,
  });

  const onSubmit: SubmitHandler<ImageInfoSchema> = async formData => {
    if (!image._id) return;
    try {
      await onSave?.({
        ...image,
        caption: {
          en: formData.caption.en,
          fr: formData.caption.fr,
          es: formData.caption.es,
        },
      });
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (image?.full_url)
      reset({
        caption: {
          ...captionInitialValue,
          en: image.caption?.en || "",
        },
      });
  }, [image.caption?.en, image?.full_url, reset]);

  let myUrl = image.teaser_url_x2 || image.full_url || undefined;

  if (isCloudinary) {
    const match = /whereabouts(.*?)(?:\?|$)/.exec(image.full_url || "");
    const imagePath = match?.[0];
    const myImage = cloudinaryInstance.image(imagePath);
    myImage.resize(scale(1000)).format("auto").quality("auto");
    myUrl = myImage.toURL();
  }

  return (
    <div className="tw-relative tw-flex tw-flex-col tw-gap-y-3 tw-w-full tw-h-full">
      {showTag && (
        <LegacyTag
          className="tw-absolute tw-top-3 tw-left-3"
          backgroundColor={tokens.colors.b500}
        >
          <LegacyTypography variant={TypographyVariant.tertiaryBold}>
            Featured
          </LegacyTypography>
          <Check width={16} height={16} />
        </LegacyTag>
      )}
      <ImageLazyLoad src={myUrl} blurhash={image.blurhash} alt={""} />
      <div className=" tw-flex tw-w-full tw-justify-end tw-gap-x-[16px]">
        {loading ? (
          <LegacyLoadingSpinner size={20} />
        ) : (
          <>
            <LegacyButton
              variant={ButtonTagVariant.Unstyled}
              onClick={() => setOpen(true)}
            >
              {image.caption?.en ? (
                <ChatLinesDark />
              ) : (
                <ChatLines
                  strokeWidth={1.75}
                  width={20}
                  height={20}
                  color={tokens.colors.z500}
                />
              )}
            </LegacyButton>
            <LegacyButton
              variant={ButtonTagVariant.Unstyled}
              onClick={handleDownload}
            >
              <Download
                strokeWidth={1.75}
                width={20}
                height={20}
                color={tokens.colors.z500}
              />
            </LegacyButton>
            <LegacyButton
              variant={ButtonTagVariant.Unstyled}
              onClick={() => onDelete?.(image)}
            >
              <Trash
                strokeWidth={1.75}
                width={20}
                height={20}
                color={tokens.colors.z500}
              />
            </LegacyButton>
          </>
        )}

        <Dialog isOpen={open} onClose={() => setOpen(false)}>
          <form
            className="tw-flex tw-flex-col tw-space-y-[36px]"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* Title */}
            <div className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-items-center tw-space-x-space-150">
                <ChatLines className="tw-text-[#27272A] tw-stroke-[2px]" />
                <span className="tw-font-['Figtree'] tw-font-[600] tw-text-[18px] tw-leading-[24px] tw-text-[#27272A]">
                  Image Info
                </span>
              </div>
              <LegacyButton
                variant={ButtonTagVariant.Unstyled}
                onClick={() => setOpen(false)}
              >
                <Xmark className="tw-text-[#A1A1AA]" />
              </LegacyButton>
            </div>

            <div className="tw-flex tw-flex-col tw-space-y-space-100">
              <span className="tw-font-['Figtree'] tw-text-[#27272A] tw-font-[600] tw-leading-[22px] tw-text-[15px]">
                Caption
              </span>
              <span className="tw-font-['Figtree'] tw-text-[#71717A] tw-font-[400] tw-leading-[22px] tw-text-[15px]">
                Adding image metadata like captions can vastly improve
                accessibility for users with low vision.
              </span>
              <Controller
                control={control}
                name="caption.en"
                render={({ field, fieldState }) => (
                  <TextArea
                    placeholder="Add your caption here"
                    helperTextClassName="tw-text-right"
                    rows={4}
                    error={!!fieldState.error?.message}
                    bottomAdornment={field.value.length + "/300"}
                    {...field}
                  />
                )}
              />
            </div>

            <div className="tw-flex tw-space-x-space-150">
              <LegacyButton
                fullWidth
                variant={ButtonTagVariant.Secondary}
                onClick={() => setOpen(false)}
              >
                Cancel
              </LegacyButton>
              <LegacyButton
                fullWidth
                variant={ButtonTagVariant.InfoBold}
                disabled={isSubmitting || !isDirty}
                type="submit"
              >
                {isSubmitting ? <LegacyLoadingSpinner size={18} /> : "Save"}
              </LegacyButton>
            </div>
          </form>
        </Dialog>
      </div>
    </div>
  );
};
