import {
  LegacyButton,
  ButtonTagVariant,
  Sizes,
  LegacyDropdown,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { ChangeEvent, useId, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { HelperText } from "../HelperText";
import { Option } from "../Select";
import {
  DayProps,
  DaysValues,
  days,
  daysMetadata,
  initialDaysValues,
  Day,
  DaysErrors,
} from "./utils";

type HoursOfOperationProps = {
  value?: DaysValues;
  onChange?: (value: DaysValues) => void;
  errors?: DaysErrors;
};

export const HoursOfOperation = ({
  value = initialDaysValues,
  onChange,
  errors,
}: HoursOfOperationProps) => {
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const id = useId();

  const handleEnable = (dayProps: DayProps, day: Day) => {
    const newValue = {
      ...value,
      [day]: { ...dayProps, isActive: !dayProps.isActive },
    };
    onChange?.(newValue);
  };

  const handleOptionChange = (
    e: ChangeEvent<HTMLSelectElement>,
    dayProps: DayProps,
    day: Day,
  ) => {
    const newValue = {
      ...value,
      [day]: { ...dayProps, [e.target.name]: e.target.value },
    };
    onChange?.(newValue);
  };

  const timeOptions = useMemo(() => {
    const hoursOfDay: Option[] = [];

    const convertMinutesToTime = (min: number) => {
      // Calculate hours and minutes
      const hours = Math.floor(min / 60) % 24;
      const remainingMinutes = min % 60;

      // Adjust hours for the 12-hour clock
      const adjustedHours = hours > 12 ? hours - 12 : hours;

      // Format adjusted hours and minutes
      const formattedHours = adjustedHours.toString().padStart(2, "0");
      const formattedMinutes = remainingMinutes.toString().padStart(2, "0");

      // Determine AM or PM
      const period = hours < 12 ? "am" : "pm";

      // Build the final text
      const timeText = `${formattedHours}:${formattedMinutes} ${period}`;

      return timeText;
    };

    for (let minutes = 0; minutes < 60 * 24; minutes += 30) {
      const label = convertMinutesToTime(minutes);
      hoursOfDay.push({ label, value: minutes.toString() });
    }

    return hoursOfDay;
  }, []);
  return (
    <div className="tw-flex tw-flex-col tw-gap-y-[24px]">
      <div className="tw-flex tw-gap-2 max-sm:tw-flex-wrap">
        {days.map(day => {
          const selectDayProps = value[day];
          const selectDayMeta = daysMetadata[day];
          return (
            <LegacyButton
              key={id + day + "_button"}
              size={Sizes.Small}
              variant={
                selectDayProps.isActive
                  ? ButtonTagVariant.InfoBold
                  : ButtonTagVariant.Secondary
              }
              fullWidth={!isMobile}
              onClick={() => handleEnable(selectDayProps, day)}
            >
              {selectDayMeta.shortName}
            </LegacyButton>
          );
        })}
      </div>
      <div className="tw-flex tw-flex-col tw-gap-y-[16px]">
        {days.map(day => {
          const selectDayProps = value[day];
          const selectDayMeta = daysMetadata[day];
          const selectDayError = errors?.[day];
          if (!selectDayProps.isActive) return null;
          return (
            <div key={id + day + "_row"} className="tw-flex tw-flex-col">
              <div className="tw-flex tw-items-center tw-gap-x-[12px]">
                <LegacyTypography
                  className={"tw-min-w-[100px] max-sm:tw-min-w-[30px]"}
                  variant={TypographyVariant.primaryMedium}
                >
                  {isMobile ? selectDayMeta.shortName : selectDayMeta.name}
                </LegacyTypography>
                {/*<div className={"tw-w-full"}>*/}
                {/*  <Select*/}
                {/*    id={"from"}*/}
                {/*    name="from"*/}
                {/*    options={timeOptions}*/}
                {/*    value={selectDayProps.from}*/}
                {/*    onChange={e => handleOptionChange(e, selectDayProps, day)}*/}
                {/*  />*/}
                {/*</div>*/}
                <LegacyDropdown
                  name="from"
                  options={timeOptions}
                  value={selectDayProps.from}
                  onChange={e => handleOptionChange(e, selectDayProps, day)}
                />
                <p>to</p>
                <LegacyDropdown
                  name="to"
                  options={timeOptions}
                  value={selectDayProps.to}
                  onChange={e => handleOptionChange(e, selectDayProps, day)}
                />
                {/*<div className={"tw-w-full"}>*/}
                {/*  <Select*/}
                {/*    id={"to"}*/}
                {/*    name="to"*/}
                {/*    options={timeOptions}*/}
                {/*    value={selectDayProps.from}*/}
                {/*    onChange={e => handleOptionChange(e, selectDayProps, day)}*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
              <div className="tw-flex tw-justify-end">
                {selectDayError && <HelperText error text={selectDayError} />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
