import {
  LegacyButton,
  ButtonTagVariant,
  Sizes,
  tokens,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { NavLink, useLocation } from "react-router-dom";
import { ArrowRight } from "iconoir-react";
import { formatUnix } from "../../utils/formatDate";

/* TODO: fix final typings of Notification */
interface NotificationProps {
  title: string;
  message: string;
  createdAt?: number;
  button: any;
  color?: string;
}

export default function NotificationsCard({
  title,
  message,
  createdAt,
  button,
  color,
}: NotificationProps) {
  const location = useLocation();
  const previousLocation = location.state
    ? location.state.previousLocation
    : {
        pathname: "/",
        search: "",
        hash: "",
        state: null,
        key: "default",
      };
  return (
    <div className={"tw-flex"}>
      <div className={`tw-w-1 ${color ? color : "tw-bg-red-300"}`} />
      <div
        className={
          "tw-py-4 tw-pl-5 tw-pr-8 tw-bg-[#FFF9F1] tw-flex tw-flex-col md:tw-flex-row tw-gap-5 md:tw-gap-12 tw-w-full md:tw-items-center tw-justify-between"
        }
      >
        <div className={"tw-flex tw-flex-col tw-gap-0.5"}>
          <LegacyTypography
            variant={TypographyVariant.primaryBold}
            color={tokens.semantic.colorText}
          >
            {title}
          </LegacyTypography>
          <LegacyTypography color={tokens.semantic.textSubtle}>
            {message}
          </LegacyTypography>
          <p
            className={"tw-font-ibmPlexMono tw-text-[13px]"}
            style={{ color: tokens.semantic.textSubtlest }}
          >
            {formatUnix(createdAt)}
          </p>
        </div>
        {button?.label && (
          <NavLink to={button.path} state={{ previousLocation }}>
            <LegacyButton
              size={Sizes.Small}
              variant={ButtonTagVariant.Secondary}
              className={"tw-rounded-full tw-bg-[#F1EAE1] hover:tw-border-none"}
              color={tokens.semantic.colorText}
              icon={
                <ArrowRight
                  strokeWidth={2}
                  width={16}
                  height={16}
                  color={tokens.semantic.iconSubtlest}
                />
              }
            >
              {button.label}
            </LegacyButton>
          </NavLink>
        )}
      </div>
    </div>
  );
}
