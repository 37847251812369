import { Icon } from "@ttc3k/trekker";
import { ControlledInput } from "apps/custom/ttc/booker/shared";
import { Dollar } from "iconoir-react";
import { Box } from "styled-system/jsx";

type ControlledPriceInputProps = {
  name: string;
  label?: string;
};

export const ControlledPriceInput = ({
  name,
  label,
}: ControlledPriceInputProps) => {
  return (
    <Box position={"relative"} width={"full"}>
      <ControlledInput name={name} label={label} type={"number"} step=".01" />
      <Box
        position={"absolute"}
        top={"35px"} // 35px is halfway past the input - 50% includes the hint
        right={"100"}
        bg={"white"}
        py={"2px"}
      >
        <Icon Element={Dollar} color={"icon.mid"} />
      </Box>
    </Box>
  );
};
