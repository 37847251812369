import { Box, Flex } from "styled-system/jsx";
import { Text, toastFactory } from "@ttc3k/trekker";
import { Copy } from "iconoir-react";

interface SiteReadyProps {
  link?: string;
}

export const SiteReady = ({ link = "" }: SiteReadyProps) => {
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(link);
      toastFactory.create({
        title: "",
        description: "Copied to clipboard",
      });
    } catch (err) {
      console.error("Error copying:", err);
    }
  };

  const subdomain = link.replace("https://", "").split(".")[0];
  const domain = "whereabouts.tech";

  return (
    <Flex flexDir={"column"} gap={"8px"}>
      <Flex flexDir={"row"} gap={"8px"} alignItems={"center"}>
        <Box w={"12px"} h={"12px"} bg={"bg.success.dark"} rounded={"full"} />
        <Text visual={"bodySemiBold"} color={"text.dark"}>
          Site ready!
        </Text>
      </Flex>
      <Text visual={"smallMedium"} color={"text.mid"}>
        Your site is ready. Here’s the link. If you need to make any changes,
        please get in touch.
      </Text>
      <Flex
        rounded={"8px"}
        px={"12px"}
        py={"8px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        border={"1px solid"}
        borderColor={"border.light"}
        bg={"white"}
      >
        <Flex>
          <Text>{subdomain}.</Text>
          <Text color={"text.light"}>{domain}</Text>
        </Flex>
        <button onClick={handleCopyClick} type="button">
          <Copy fontSize={12} />
        </button>
      </Flex>
    </Flex>
  );
};
