import { Flex } from "styled-system/jsx";
import { Button, Text } from "@ttc3k/trekker";

interface DeploySiteProps {
  isLoading?: boolean;
  disabled?: boolean;
}

export const DeploySite = ({
  isLoading = false,
  disabled = false,
}: DeploySiteProps) => {
  return (
    <>
      <Flex flexDir={"column"} gap={"8px"}>
        <Text visual={"bodySemiBold"} color={"text.dark"}>
          Build your site
        </Text>
        <Text visual={"smallMedium"} color={"text.mid"}>
          Click the button below to build your site. This process typically
          takes 5-10 minutes. Please check back here and we’ll let you know when
          it’s ready.
        </Text>
      </Flex>
      <Button
        type={"submit"}
        size={"lg"}
        isLoading={isLoading}
        disabled={disabled}
        visual="success"
      >
        Build Site
      </Button>
    </>
  );
};
