import { Heading, Text } from "@ttc3k/trekker";
import React from "react";
import { Flex } from "styled-system/jsx";

type BookerLeafSubheaderProps = {
  title: string;
  description: string | JSX.Element;
};

export const BookerLeafSubheader = ({
  title,
  description,
}: BookerLeafSubheaderProps) => {
  return (
    <Flex flexDir={"column"} gap={"100"}>
      <Heading size={"h5"}>{title}</Heading>
      <Text color={"text.dark"}>{description}</Text>
    </Flex>
  );
};
