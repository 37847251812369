import { Icon, LinkButton, Text } from "@ttc3k/trekker";
import { NavArrowDown, NavArrowUp } from "iconoir-react";
import { useEffect, useRef, useState } from "react";
import { Box, styled } from "styled-system/jsx";

type CollapsableTextProps = {
  /**
   * max-height in px
   */
  maxHeight: number;
  text: string;
};

const AnimatedTextWrapper = styled(Box, {
  base: {
    overflow: "hidden",
    transitionDuration: "normal",
    transitionProperty: "max-height",
    transitionTimingFunction: "ease-in-out",
  },
});

export const CollapsableText = ({ maxHeight, text }: CollapsableTextProps) => {
  const [showMore, setShowMore] = useState(false);
  const [actualHeight, setActualHeight] = useState(0);

  const textRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (textRef.current?.clientHeight) {
      setActualHeight(textRef.current.clientHeight);
    }
  }, [textRef.current?.clientHeight]);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const isSomeTextHidden = actualHeight > maxHeight;

  return (
    <Box>
      <AnimatedTextWrapper
        style={{ maxHeight: showMore ? `${actualHeight}px` : `${maxHeight}px` }}
      >
        <Text ref={textRef} color={"text.dark"} position={"relative"}>
          {text}
        </Text>
      </AnimatedTextWrapper>
      {isSomeTextHidden && (
        <LinkButton onClick={toggleShowMore}>
          {showMore ? "Show less" : "Show more"}
          {showMore ? (
            <Icon Element={NavArrowUp} />
          ) : (
            <Icon Element={NavArrowDown} />
          )}
        </LinkButton>
      )}
    </Box>
  );
};
