import { WhereaboutsModal } from "core/components/shared/WhereaboutsModal";
import { EditPencil, PlusCircle, Trash } from "iconoir-react";
import { useEffect, useState } from "react";
import { Button, LinkButton, Select, Text } from "@ttc3k/trekker";
import { EnumAmenityType, useAmenityManyQuery } from "gql/generated";
import { Divider, Flex } from "styled-system/jsx";
import _ from "lodash";
import { UpdateAccomFeaturesFormValues } from "./useUpdateAccomFeaturesForm";

type AddEditBedroomModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (
    b: UpdateAccomFeaturesFormValues["bedrooms"][0],
    isDeleting: boolean,
  ) => void;
  bedroom: UpdateAccomFeaturesFormValues["bedrooms"][0];
};

export const AddEditBedroomModal = ({
  onClose,
  onSave,
  isOpen,
  bedroom,
}: AddEditBedroomModalProps) => {
  const [beds, setBeds] = useState(bedroom.amenities);

  const { data, error } = useAmenityManyQuery({
    variables: { filter: { type: EnumAmenityType.RoomSpecific } },
  });

  if (error) {
    throw new Error("Could not load amenities");
  }

  const isEditing = bedroom.amenities.every(v => v.length > 0);

  const handleSelectBed = (id: string, index: number) => {
    const newBeds = [...beds];
    newBeds[index] = id;

    setBeds(newBeds);
  };
  const handleAddBed = () => {
    setBeds(prev => [...prev, ""]);
  };
  const handleRemoveBed = (index: number) => {
    const newBeds = [...beds];
    newBeds.splice(index, 1);
    setBeds(newBeds);
  };
  const handleCancelButtonClick = () => {
    if (isEditing) {
      onSave(bedroom, true);
    } else {
      onClose();
    }
  };
  const handleSaveClick = () => {
    onSave(
      {
        amenities: _.compact(beds.map(v => (v.length > 0 ? v : null))),
        _id: bedroom._id,
      },
      false,
    );
  };

  useEffect(() => {
    setBeds(bedroom.amenities);
  }, [bedroom]);
  return (
    <WhereaboutsModal
      isOpen={isOpen}
      onClose={onClose}
      HeaderIcon={isEditing ? EditPencil : PlusCircle}
      title={isEditing ? "Edit a bedroom" : "Add a bedroom"}
      description={
        "Specify the number (and types) of beds included in this bedroom below."
      }
      cancelButtonProps={{
        onClick: handleCancelButtonClick,
        visual: isEditing ? "destructive" : "outline",
      }}
      cancelButtonCopy={isEditing ? "Delete" : "Cancel"}
      saveButtonProps={{
        onClick: handleSaveClick,
        disabled: beds.length < 1 || beds.every(v => v.length < 1),
      }}
    >
      <Flex flexDir={"column"} gap={"150"} alignItems={"flex-start"}>
        {beds.map((amenityID, i) => {
          const items = _.sortBy(
            data?.amenityMany.map(a => ({
              label: a.name.en,
              value: a._id,
            })),
            "label",
          );
          return (
            <Flex key={i} gap={"300"} alignItems={"center"} width={"full"}>
              <Text as={"label"} visual={"bodyMedium"} minW={"60px"}>
                Bed {i + 1}
              </Text>
              <Select.Root
                items={items}
                value={amenityID.length > 0 ? [amenityID] : []}
                onValueChange={({ value }: { value: string[] }) =>
                  handleSelectBed(value[0], i)
                }
                placeholder={"Select bed type"}
                width={"full"}
                css={{
                  "& [data-scope='select'][data-part='trigger']": {
                    bg: "white",
                  },
                }}
              >
                {items?.map(item => (
                  <Select.Item key={item.value} item={item} />
                ))}
              </Select.Root>
              <Button
                Icon={Trash}
                visual={"outline"}
                disabled={i < 1}
                onClick={handleRemoveBed}
              />
            </Flex>
          );
        })}
        <Divider
          borderColor={"border.light"}
          borderStyle={"dashed"}
          pt={"100"}
          mb={"100"}
        />
        <LinkButton onClick={handleAddBed}>+ Add a bed</LinkButton>
      </Flex>
    </WhereaboutsModal>
  );
};
