import { ReactNode } from "react";
import {
  tokens,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { cn } from "../../../utils/cn";

const CheckCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7 12.5L10 15.5L17 8.5"
        stroke="#A1A1AA"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="#A1A1AA"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CheckCircleFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#429F72"
        stroke="#429F72"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 12.5L10 15.5L17 8.5"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

type ProfileChecklistProps = {
  label?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
  onIcon?: ReactNode;
  offIcon?: ReactNode;
};

export const CheckItem = ({
  label,
  value,
  offIcon = <CheckCircle />,
  onIcon = <CheckCircleFilled />,
  onChange,
}: ProfileChecklistProps) => {
  return (
    <div
      className={cn("tw-flex tw-items-center tw-gap-[12px] tw-cursor-pointer", {
        "tw-text-[#429F72]": value,
      })}
      onClick={() => onChange?.(!value)}
    >
      <div>{value ? onIcon : offIcon}</div>
      <LegacyTypography
        variant={TypographyVariant.secondaryMedium}
        color={tokens.semantic.textSubtlest}
      >
        {label}
      </LegacyTypography>
    </div>
  );
};
