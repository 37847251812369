import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useModalRoot } from "core/hooks/useModalRoot";
import {
  EnumLearnerActivityLogEventType,
  PersonCoursesUpdateStatusEnum,
  useLearnerActivityLogCreateMutation,
  usePersonCourseUpdateMutation,
} from "gql/generated";

const FullScreenModal = ({
  isOpen,
  onClose,
  content,
  frameRef,
  profileData,
  courseData,
  terminated,
  dmo,
  learnerCourseData,
}) => {
  const { modalRoot } = useModalRoot();
  const [personCourseUpdate] = usePersonCourseUpdateMutation();
  const [learnerActivityLogCreate] = useLearnerActivityLogCreateMutation();

  //ref created to keep variables in sync inside window.api method
  const courseProgressData = learnerCourseData?.find(
    c => c.courseId == courseData?._id,
  );
  const courseProgressDataRef = useRef(courseProgressData);

  useEffect(() => {
    // Append the modal root to the body when the component mounts
    document.body.appendChild(modalRoot);

    // Cleanup by removing the modal root from the body when the component unmounts
    return () => {
      modalRoot.remove();
    };
  }, [modalRoot]);

  useEffect(() => {
    courseProgressDataRef.current = courseProgressData;
    //Handle Tracking for PDFs
    if (
      courseData?.packageType === "PDF" &&
      courseProgressDataRef.current?.status !== "IN_PROGRESS" &&
      courseProgressDataRef.current?.status !== "COMPLETE" &&
      courseProgressDataRef.current?.status !== "VIEWED"
    ) {
      personCourseUpdate({
        variables: {
          input: {
            courseId: courseData._id,
            personId: profileData._id,
            status: PersonCoursesUpdateStatusEnum.InProgress,
          },
        },
        refetchQueries: ["GetPersonMany"],
      });
      learnerActivityLogCreate({
        variables: {
          record: {
            eventType: EnumLearnerActivityLogEventType.Viewed,
            courseId: courseData?._id,
            courseName: courseData?.courseName,
            personId: profileData?._id,
            personName: profileData?.firstName + " " + profileData?.lastName,
            operatorId: profileData?.operator?._id,
            operatorName: profileData?.operator?.operator_name,
            tenantsOf: [dmo],
          },
        },
      });
    }

    // SCORM API initialization
    window.API_1484_11 = {
      Initialize: async () => {
        console.log("SCORM course initialized");
        await learnerActivityLogCreate({
          variables: {
            record: {
              eventType:
                courseProgressDataRef.current?.status == "IN_PROGRESS"
                  ? EnumLearnerActivityLogEventType.Continued
                  : EnumLearnerActivityLogEventType.Viewed,
              courseId: courseData?._id,
              courseName: courseData?.courseName,
              personId: profileData?._id,
              personName: profileData?.firstName + " " + profileData?.lastName,
              operatorId: profileData?.operator?._id,
              operatorName: profileData?.operator?.operator_name,
              tenantsOf: [dmo],
            },
          },
        });
      },
      Terminate: async () => {
        console.log("SCORM course terminated");
        terminated?.();
        return "true";
      },
      GetValue: function (element, value) {
        console.log(`Getting value for ${element} = ${value}`);
        if (element == "cmi.suspend_data" && courseProgressData?.suspendData)
          return courseProgressData.suspendData;
      },
      GetStatus: function (element) {
        console.log(`Getting status for ${element}`);
        // Dummy return for example purposes
        return "some value";
      },
      SetBookmark: function (element) {
        console.log(`Setting bookmark for ${element}`);
        // Dummy return for example purposes
        return "some value";
      },
      SetValue: async (element: string, value: string) => {
        // console.log({ element, value });
        if (element == "cmi.completion_status") {
          console.log("setting completion status", "complete");
          if (value === "completed") {
            await personCourseUpdate({
              variables: {
                input: {
                  courseId: courseData._id,
                  personId: profileData._id,
                  status: PersonCoursesUpdateStatusEnum.Complete,
                },
              },
              refetchQueries: ["GetPersonMany"],
            });
          }

          if (
            value === "completed" &&
            courseProgressDataRef.current?.status !== "COMPLETE"
          ) {
            await learnerActivityLogCreate({
              variables: {
                record: {
                  eventType: EnumLearnerActivityLogEventType.Completed,
                  courseId: courseData?._id,
                  courseName: courseData?.courseName,
                  personId: profileData?._id,
                  personName:
                    profileData?.firstName + " " + profileData?.lastName,
                  operatorId: profileData?.operator?._id,
                  operatorName: profileData?.operator?.operator_name,
                  tenantsOf: [dmo],
                },
              },
            });
          }
        } else if (element == "cmi.suspend_data") {
          console.log("setting suspend data", "in progress");
          await personCourseUpdate({
            variables: {
              input: {
                courseId: courseData._id,
                personId: profileData._id,
                status: PersonCoursesUpdateStatusEnum.InProgress,
                suspendData: value,
              },
            },
            refetchQueries: ["GetPersonMany"],
          });
          if (
            courseProgressDataRef.current?.status !== "IN_PROGRESS" &&
            courseProgressDataRef.current?.status !== "COMPLETE" &&
            courseProgressDataRef.current?.status !== "VIEWED"
          ) {
            await learnerActivityLogCreate({
              variables: {
                record: {
                  eventType: EnumLearnerActivityLogEventType.Started,
                  courseId: courseData?._id,
                  courseName: courseData?.courseName,
                  personId: profileData?._id,
                  personName:
                    profileData?.firstName + " " + profileData?.lastName,
                  operatorId: profileData?.operator?._id,
                  operatorName: profileData?.operator?.operator_name,
                  tenantsOf: [dmo],
                },
              },
            });
          }
        }

        return "true";
      },
      Commit: function (element, value) {
        // console.log("Committing data");
        return "true";
      },
      GetLastError: function () {
        return "0";
      },
      GetErrorString: function (errorCode) {
        return "No error";
      },
      GetDiagnostic: function (errorCode) {
        return "No diagnostic information";
      },
    };

    // Function to handle messages from the iFrame
    const handleReceiveMessage = event => {
      if (!event.data) return;
      //console.log("Received message:", event.data);
      // Here you can handle messages
    };

    window.addEventListener("message", handleReceiveMessage);

    return () => {
      window.removeEventListener("message", handleReceiveMessage);
      window.API_1484_11 = null; // Clean up the API
    };
  }, [
    courseData,
    profileData,
    dmo,
    personCourseUpdate,
    learnerActivityLogCreate,
    terminated,
  ]);

  if (!isOpen) return null;

  return createPortal(
    <div className="tw-fixed tw-z-[9999999] tw-p-10 tw-left-0 tw-top-0 tw-h-screen tw-w-screen tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-80 tw-animate-fadeIn">
      <div className="tw-relative tw-w-full tw-h-full tw-bg-white tw-p-4 tw-overflow-auto">
        <button
          onClick={onClose}
          className="tw-absolute tw-z-10 tw-p-2 tw-top-4 tw-right-4 tw-bg-white tw-border-none tw-text-3xl tw-cursor-pointer"
        >
          &times;
        </button>
        {/* Modal content goes here */}
        {content}
      </div>
    </div>,
    modalRoot,
  );
};

export default FullScreenModal;
