import { useCallback } from "react";
import { z } from "zod";
import { useFormHandler } from "core/hooks/useFormHandler";
import { phoneNumberRegex } from "booker/shared/constants";
import { useCartContext } from "booker/contexts";

const customerDetailsFormSchema = z.object({
  name: z.string().min(1, "Please enter your name"),
  email: z
    .string()
    .min(1, "Please enter your email")
    .email("Please enter a valid email"),
  phone: z
    .string()
    .min(1, "Please enter a phone number")
    .regex(phoneNumberRegex, "Please enter a valid phone number"),
});

export type CustomerDetailsFormValues = z.infer<
  typeof customerDetailsFormSchema
>;

export default function useCustomerDetailsForm() {
  const { updateCustomer } = useCartContext();
  const defaultValues: CustomerDetailsFormValues = {
    email: "",
    name: "",
    phone: "",
  };

  return useFormHandler<CustomerDetailsFormValues>(
    useCallback(
      async data => {
        const payload = { ...data };
        const { email, name, phone } = payload;

        if (payload && email && name && phone) {
          updateCustomer({ email, name, phone, complete: true });
        }
      },
      [updateCustomer],
    ),
    defaultValues,
    { schema: customerDetailsFormSchema },
    { shouldFocusError: false, mode: "onChange" },
  );
}
