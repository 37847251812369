import { Spinner, Table, Text, Pagination } from "@ttc3k/trekker";
import { useAppContext } from "core/components/apps/PreferredLayout";
import {
  CustomOrderFilter,
  SortFindManyOrderInput,
  useOrderManyQuery,
  useOrderPricesByIdsQuery,
  useRefundsByOrderIdsQuery,
} from "gql/generated";
import { useEffect } from "react";
import { Box, Flex } from "styled-system/jsx";
import { parseToUTCDate } from "core/utils/parseToUTCDate";
import { ExportToCSV } from "core/components/shared/ExportToCSV";
import { formatPrice } from "core/utils/formatPrice";
import { DATE_PATTERN_SIMPLE, formatDate } from "core/utils/formatDate";
import { OrderFilterValue, PaymentStatus } from "../hooks";
import { getOrderIdsByRefundStatus } from "../utils";
import { OrderTableRow } from "./OrderTableRow";
import { OrderRow } from "./OrderRow";
import { orderHeaders } from "./constants";

type OrderListProps = {
  operatorID: string;
  filter: OrderFilterValue;
};

const PER_PAGE = 8;

export const OrderList = ({ operatorID, filter }: OrderListProps) => {
  const { setActionSection } = useAppContext();

  const filterEndDate =
    filter.transactionDates.end.length > 0
      ? parseToUTCDate(filter.transactionDates.end)
      : undefined;
  filterEndDate?.setUTCDate(filterEndDate.getDate());
  filterEndDate?.setUTCHours(23, 59, 59, 999);
  const filterStartDate =
    filter.transactionDates.start.length > 0
      ? parseToUTCDate(filter.transactionDates.start)
      : undefined;
  filterStartDate?.setUTCDate(filterStartDate.getDate());
  filterStartDate?.setUTCHours(0, 0, 0, 0);

  const customFilterPayload: CustomOrderFilter = {
    search: filter.searchParams.length > 1 ? filter.searchParams : undefined,
    createdEndDate: filterEndDate ? filterEndDate.toISOString() : undefined,
    createdStartDate: filterStartDate
      ? filterStartDate.toISOString()
      : undefined,
  };

  const { data: fullOrderData } = useOrderManyQuery({
    variables: {
      filter: {
        source: { operator: operatorID },
        _custom: customFilterPayload,
      },
      perPage: 1000000,
      sort: SortFindManyOrderInput.CreatedDesc,
    },
  });
  const { data: orderPrices, loading: orderPricesLoading } =
    useOrderPricesByIdsQuery({
      variables: {
        orderIDs: fullOrderData?.orderMany?.items?.map(o => o._id) ?? [],
      },
    });
  const { data: refundsByOrderIds } = useRefundsByOrderIdsQuery({
    variables: {
      orderIDs: fullOrderData?.orderMany?.items?.map(o => o._id) ?? [],
    },
  });

  const filteredByPaymentStatusOrderIds =
    filter.paymentStatus !== PaymentStatus.All
      ? getOrderIdsByRefundStatus(
          fullOrderData,
          orderPrices,
          refundsByOrderIds,
          filter.paymentStatus,
        )
      : undefined;

  const {
    data: filteredAndPaginatedOrders,
    loading,
    error,
    fetchMore,
  } = useOrderManyQuery({
    variables: {
      filter: {
        source: { operator: operatorID },
        _custom: {
          ...customFilterPayload,
          _ids: filteredByPaymentStatusOrderIds,
        },
      },
      perPage: PER_PAGE,
      sort: SortFindManyOrderInput.CreatedDesc,
    },
  });

  useEffect(() => {
    const ordersData =
      fullOrderData?.orderMany?.items
        ?.map(o => {
          const orderGrandTotal =
            orderPrices?.orderPricesByIds?.find(v => v.orderID === o._id)
              ?.prices?.grandTotal ?? 0;
          return [
            o._id,
            o.customerContact.name,
            o.customerContact.email,
            `$${formatPrice(orderGrandTotal)}`,
            formatDate(o.createdDateISO),
            "Paid",
          ].filter(Boolean);
        })
        .filter(Boolean) ?? [];

    setActionSection(
      <ExportToCSV
        fileName={`booker-orders-${formatDate(
          new Date(),
          DATE_PATTERN_SIMPLE,
        )}`}
        colHeaders={orderHeaders.map(o => o.label)}
        data={ordersData}
      />,
    );

    return () => setActionSection(undefined);
  }, [
    operatorID,
    fullOrderData?.orderMany,
    orderPrices?.orderPricesByIds,
    setActionSection,
  ]);

  if (loading) {
    return <Spinner width={"50px"} height={"50px"} margin={"60px auto"} />;
  }
  if (error) {
    return (
      <Text
        margin={"60px auto"}
        width={"full"}
        textAlign={"center"}
        color={"text.error.mid"}
      >
        Error : {error.message}
      </Text>
    );
  }

  if (
    !filteredAndPaginatedOrders ||
    !filteredAndPaginatedOrders.orderMany ||
    !filteredAndPaginatedOrders.orderMany.items ||
    filteredAndPaginatedOrders.orderMany.items.length < 1 ||
    (filteredByPaymentStatusOrderIds && filteredByPaymentStatusOrderIds.length < 1)
  ) {
    return (
      <Text margin={"60px auto"} width={"full"} textAlign={"center"}>
        There have been no orders at this time
      </Text>
    );
  }

  const onPageChange = (newPage: number) => {
    fetchMore({
      variables: {
        page: newPage,
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;
        return fetchMoreResult;
      },
    });
  };

  return (
    <Flex flexDir={"column"} gap={"200"}>
      <Box overflowX={"auto"}>
        <Table.Root>
          <Table.Body>
            <OrderTableRow>
              <Table.Header py={0} />
              {orderHeaders.map(o => (
                <Table.Header
                  key={o.label}
                  width={o.largeWidth ? "200px" : undefined}
                  textAlign={o.alignRight ? "right!" : undefined}
                  py={0}
                >
                  <Text visual={"smallMedium"} color={"text.light"}>
                    {o.label}
                  </Text>
                </Table.Header>
              ))}
              <Table.Header py={0} />
            </OrderTableRow>
            {filteredAndPaginatedOrders.orderMany.items.map((order, i) => {
              const currentIndex = i + 1;
              return (
                <OrderRow
                  key={order._id}
                  order={order}
                  orderNum={
                    (filteredAndPaginatedOrders.orderMany?.pageInfo
                      .currentPage ?? 1) *
                      PER_PAGE -
                    (PER_PAGE - currentIndex)
                  }
                  orderGrandTotal={
                    orderPrices?.orderPricesByIds?.find(
                      v => v.orderID === order._id,
                    )?.prices?.grandTotal ?? 0
                  }
                  grandTotalLoading={orderPricesLoading}
                />
              );
            })}
          </Table.Body>
        </Table.Root>
      </Box>
      <Flex w={"full"} justifyContent={"flex-end"}>
        <Pagination
          count={filteredAndPaginatedOrders.orderMany.pageInfo.pageCount ?? 0}
          page={filteredAndPaginatedOrders.orderMany.pageInfo.currentPage ?? 1}
          pageSize={1}
          siblingCount={1}
          onPageChange={({ page }: { page: number }) => onPageChange(page)}
        />
      </Flex>
    </Flex>
  );
};
