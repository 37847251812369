import { motion } from "framer-motion";
import {
  LegacyButton,
  tokens,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField } from "../../../../components/shared/TextField";
import {
  createBusinessPart1DefauldValues,
  CreateBusinessSchemaPart1,
  createBusinessSchemaPart1,
} from "../../../../schemas/createBusinessSchema.ts";
import { Select } from "../../../../components/shared/Select";
import { useGetTags } from "../../../../../apps/library/directory/branches/leaves/Profile/utils.ts";
import { variants } from "../../shared.ts";
import { useOnboardingContext } from "../../Layout.tsx";
import { useCreateOperatorMutation } from "../../../../../gql/generated.ts";
import SlideInPage from "../../../animation/SlideInPage.tsx";

export const CreateBusiness = () => {
  const { baseURL } = useOnboardingContext();
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { data: tags } = useGetTags([
    { type: "TYPE_OF_OPERATOR", dbName: "Core", collectionName: "tagsohto" },
  ]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm({
    resolver: zodResolver(createBusinessSchemaPart1),
    defaultValues: createBusinessPart1DefauldValues,
  });

  const onSubmit: SubmitHandler<CreateBusinessSchemaPart1> = async formData => {
    if (!user?.sub) return;

    localStorage.setItem(
      "onboardingCreatedBusinessformData",
      JSON.stringify(formData),
    );
    navigate(`${baseURL}/claim/create-address`);
  };

  const onSubmitError: SubmitErrorHandler<
    CreateBusinessSchemaPart1
  > = errors => {
    console.error(errors);
  };

  useEffect(() => {
    const createdBusinessInfo = localStorage.getItem(
      "onboardingCreatedBusinessformData",
    );
    if (createdBusinessInfo) {
      reset(JSON.parse(createdBusinessInfo));
    }
  }, []);

  return (
    <motion.div
      className="tw-flex tw-w-full md:tw-w-96 tw-mt-auto tw-flex-col tw-gap-6"
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
    >
      <LegacyTypography
        variant={TypographyVariant.h4}
        color={tokens.semantic.colorText}
        className="tw-text-center"
      >
        Create your profile
      </LegacyTypography>
      <LegacyTypography
        variant={TypographyVariant.primaryRegular}
        color={tokens.semantic.colorText}
        className="tw-text-center"
      >
        Let's start with the basics.
      </LegacyTypography>

      <div className="tw-flex tw-flex-col tw-w-screen md:tw-w-full  tw-px-5  md:tw-px-0 tw-gap-3">
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Business or Organization Name"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Public email address"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="localPhone"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Local Public phone"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="tollFreePhone"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Toll Free Public phone"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="website"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Public Website"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={field.onChange}
              label="Business/Organization Type"
              options={
                tags?.type_of_operator?.map(item => ({
                  value: item.label?.en || "",
                  label: item.label?.en || "",
                })) || []
              }
              helperText=""
            />
          )}
        />
      </div>

      <div className="tw-w-full tw-flex tw-px-5 md:tw-px-0 tw-justify-between tw-gap-3">
        <LegacyButton
          onClick={handleSubmit(onSubmit, onSubmitError)}
          type="button"
          fullWidth
          isLoading={isSubmitting}
        >
          Next
        </LegacyButton>
      </div>
    </motion.div>
  );
};
