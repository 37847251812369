import { handleAsyncError } from "core/utils/alert.ts";
import { useState } from "react";
import {
  useAnalyzeLearnerReportMutation,
  useGenerateSummaryMutation,
} from "gql/generated.ts";
import { WhereaboutsModal } from "core/components/shared/WhereaboutsModal";
import { Code, MagicWand } from "iconoir-react";
import { formatDate } from "core/utils/formatDate.ts";
import { LegacyTypography } from "@ttc3k/ttc-design-system";
import "./report.css";

export default function GenerateInsightsModal({
  isModalOpen,
  setIsModalOpen,
  report,
  headers,
}) {
  const [summary, setSummary] = useState("");
  const [analyzeReport, { loading }] = useAnalyzeLearnerReportMutation();

  const dataForAI = [headers, ...report];

  const handleSummarize = async () => {
    try {
      const resp = await analyzeReport({
        variables: { input: { text: JSON.stringify(dataForAI) } },
      });
      setSummary(resp.data?.analyzeLearnerReport.response || "");
    } catch (error) {
      handleAsyncError();
    }
  };

  return (
    <WhereaboutsModal
      title={"Generate Reporting Insights"}
      HeaderIcon={MagicWand}
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      saveButtonCopy={summary == "" ? "Let's Go" : "Ok"}
      saveButtonProps={{
        onClick: () => {
          summary == "" ? handleSummarize() : setIsModalOpen(false);
        },
        //disabled: !isDirty,
        isLoading: loading,
      }}
      cancelButtonCopy={"Cancel"}
      cancelButtonProps={{
        onClick: () => {
          setIsModalOpen(false);
          setSummary("");
        },
      }}
    >
      {!summary && (
        <LegacyTypography>
          Analyze the {report?.length} results for the selected time span?
        </LegacyTypography>
      )}
      <LegacyTypography>
        <div
          className="learnerReport"
          dangerouslySetInnerHTML={{ __html: summary }}
        ></div>
      </LegacyTypography>
    </WhereaboutsModal>
  );
}
