import _ from "lodash";

export type Option = {
  value: string;
  label: string;
};

export type OptionWithCount = {
  value: string;
  label: string;
  count: number;
};

export const getUniqueOptionsWithCount = (
  options: Option[],
): OptionWithCount[] => {
  const counts = _.countBy(options, "value");
  const uniqueArray = _.sortBy(
    _.uniqBy(options, "value").map(prod => ({
      ...prod,
      count: counts[prod.value],
    })),
    "value",
  );
  return uniqueArray;
};
