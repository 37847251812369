import { Button, SectionHeader, Text, toastFactory } from "@ttc3k/trekker";
import { BookerLeafHeader } from "core/components/shared/BookerLeafHeader";
import { Bathroom, Bed } from "iconoir-react";
import { Flex } from "styled-system/jsx";
import { FormProvider, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { useAppContext } from "core/components/apps/PreferredLayout";
import { nanoid } from "nanoid";
import { ControlledInput } from "apps/custom/ttc/booker/shared";
import { ProductMenuButton, useProductContext } from "../../components";
import {
  AddEditBedroomModal,
  useUpdateAccomFeaturesForm,
  UpdateAccomFeaturesFormValues,
  BedroomCard,
  AmenitiesSelection,
} from "./components";

type AccommodationFeaturesLeafProps = {
  productID: string;
};

export const AccommodationFeaturesLeaf = ({
  productID,
}: AccommodationFeaturesLeafProps) => {
  const [currentBedroom, setCurrentBedroom] = useState<
    UpdateAccomFeaturesFormValues["bedrooms"][0] | undefined
  >(undefined);
  const { setActionSection } = useAppContext();
  const { useWatchProductFormState } = useProductContext();

  const { form, onSubmit } = useUpdateAccomFeaturesForm({
    productID,
    onSuccess: name => {
      toastFactory.create({
        title: "Success!",
        description: `${name} was updated successfully`,
      });
    },
    onError: errorMsg => {
      toastFactory.create({
        title: "Error",
        description: errorMsg,
      });
    },
  });

  useWatchProductFormState<UpdateAccomFeaturesFormValues>(form);

  const bedrooms = useWatch({ name: "bedrooms", control: form.control });

  const formID = "update-accommodation-features-form";

  useEffect(() => {
    setActionSection(
      <Flex gap={"150"}>
        <ProductMenuButton productID={productID} />
        <Button size={"lg"} type={"submit"} form={formID}>
          Save
        </Button>
      </Flex>,
    );

    return () => setActionSection(undefined);
  }, [productID, setActionSection]);

  const handleBedroomSave = (
    room: UpdateAccomFeaturesFormValues["bedrooms"][0],
    isDeleting: boolean,
  ) => {
    const isEditingRoom = bedrooms.find(r => r._id === room._id);

    const newBedrooms = isEditingRoom
      ? [...bedrooms].map(r => {
          if (r._id === room._id) {
            return { ...room, _id: r._id };
          }
          return r;
        })
      : [...bedrooms, room];

    form.setValue(
      "bedrooms",
      isDeleting ? newBedrooms.filter(v => v._id !== room._id) : newBedrooms,
    );
    setCurrentBedroom(undefined);
  };
  return (
    <>
      <FormProvider {...form}>
        <form id={formID} onSubmit={onSubmit}>
          <Flex flexDir={"column"} gap={"300"} width={"full"}>
            <BookerLeafHeader
              title={"Key Features"}
              description={
                "Capture how many beds and bathrooms this accommodation product offers."
              }
            />
            <SectionHeader text="Beds" Icon={Bed} divider />
            <Text color={"text.mid"}>
              Add as many bedrooms as you need for your accommodation. When you
              create a new bedroom, you’ll be able to specify the number and
              types of beds within it.
            </Text>
            {bedrooms.map((room, i) => (
              <BedroomCard
                key={i}
                room={room}
                index={i}
                onEditClick={() => setCurrentBedroom(room)}
              />
            ))}
            <Button
              visual={"outline"}
              size={"lg"}
              width={"max-content"}
              type={"button"}
              onClick={() =>
                setCurrentBedroom({ amenities: [""], _id: nanoid() })
              }
            >
              Add a bedroom
            </Button>

            <SectionHeader text="Bathrooms" Icon={Bathroom} divider />
            <ControlledInput
              name={"bathrooms"}
              label={"How many bathrooms are in this accommodation?"}
              type={"number"}
            />

            <AmenitiesSelection />
          </Flex>
        </form>
      </FormProvider>
      <AddEditBedroomModal
        isOpen={currentBedroom !== undefined}
        bedroom={currentBedroom ?? { amenities: [], _id: nanoid() }}
        onClose={() => setCurrentBedroom(undefined)}
        onSave={handleBedroomSave}
      />
    </>
  );
};
