import { PropsWithChildren } from "react";
import { useMediaQuery } from "react-responsive";
import { Box } from "styled-system/jsx";

type ModalContentLayoutProps = PropsWithChildren & {
  imagesExist: boolean;
};

export const ModalContentLayout = ({
  children,
  imagesExist,
}: ModalContentLayoutProps) => {
  // Panda responsive design syntax isn't working as expected for tablets
  const isDevice = useMediaQuery({ maxWidth: 1024 });
  return (
    <Box
      bg={"cream.50"}
      borderTopRadius={imagesExist ? "300" : undefined}
      px={300}
      pt={imagesExist ? 600 : "120px"}
      pb={isDevice ? "130px" : 400}
      zIndex={1}
      position={"relative"}
      display={"flex"}
      flexDirection={"column"}
      gap={"600"}
    >
      {children}
    </Box>
  );
};
