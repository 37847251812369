import {
  EnumPriceTierType,
  Maybe,
  PriceTier,
  PurchasedProductGuestsPerTier,
} from "gql/generated";

/** 
 * Get the total guest count with or without pets. If you want to exclude pets
 * in the total guest count, pass the product's price tiers to the fn
 */
export const getTotalGuestCount = (
  guests:
    | PurchasedProductGuestsPerTier[]
    | Maybe<PurchasedProductGuestsPerTier>[],
  priceTiers?: Maybe<PriceTier>[],
  excludePets: boolean = false,
) => {
  let totalGuests = 0;

  guests.forEach(g => {
    if (excludePets && priceTiers) {
      const tier = priceTiers.find(t => t?._id === g?.tierId);
      if (tier?.type !== EnumPriceTierType.Pet && g?.count) {
        totalGuests += g.count;
      }
    } else {
      if (g?.count) totalGuests += g.count;
    }
  });

  return totalGuests;
};
