import { useState } from "react";
import { EditPencil } from "iconoir-react";
import {
  Checkbox,
  Flag,
  Input,
  Radio,
  RadioGroup,
  toastFactory,
} from "@ttc3k/trekker";
import { FormProvider, useWatch } from "react-hook-form";
import { OrderManyItems } from "core/types";
import { Box, Divider } from "styled-system/jsx";
import {
  EnumRefundStatus,
  OrderByIdQuery,
  RefundManyQuery,
  useOrderPricesByIdQuery,
} from "gql/generated";
import { formatPrice } from "core/utils/formatPrice";
import { WhereaboutsModal } from "../WhereaboutsModal";
import { useOrderRefundCancelForm } from "./useOrderRefundCancelForm";
import { radioOptions } from "./constants";

type OrderRefundModalProps = {
  isOpen: boolean;
  onClose: () => void;
  order: OrderManyItems[0] | NonNullable<OrderByIdQuery["orderById"]>;
  orderRefunds?: RefundManyQuery | undefined;
};

export const OrderRefundModal = ({
  isOpen,
  onClose,
  order,
  orderRefunds,
}: OrderRefundModalProps) => {
  const [displayCancellationInput, setDisplayCancellationInput] =
    useState(false);
  const { data } = useOrderPricesByIdQuery({
    variables: { orderId: order._id },
  });

  const orderTotal = data?.orderPricesById?.grandTotal ?? 0;
  const orderRefundedTotal =
    orderRefunds?.refundMany.reduce((acc, currRefund) => {
      const successfullyRefundedAmount =
        currRefund.status === EnumRefundStatus.Succeeded
          ? currRefund.amount
          : 0;

      return acc + successfullyRefundedAmount;
    }, 0) ?? 0;
  const currentRefundableOrderAmount = (orderTotal - orderRefundedTotal) / 100;

  const { form, onSubmit, defaultValues } = useOrderRefundCancelForm({
    onSuccess: () => {
      form.reset(defaultValues);
      onClose();
      toastFactory.create({
        title: "Success!",
        description: `${order._id} was updated successfully`,
      });
    },
    onError: msg =>
      toastFactory.create({
        title: "Error",
        description: msg,
      }),
    orderTotal: currentRefundableOrderAmount,
    orderID: order._id,
  });

  const handleCancel = () => {
    onClose();
    form.reset(defaultValues);
    setDisplayCancellationInput(false);
  };

  const handleCancellationConfirmation = (val: string) => {
    if (val === order._id) {
      form.setValue("cancelled", true);
    } else {
      form.setValue("cancelled", false);
    }
  };

  const handleRefundRadioGroupChange = (value: "none" | "partial" | "full") => {
    switch (value) {
      case "none":
        form.setValue("refundAmount", 0);
        break;
      case "partial":
        form.setValue(
          "refundAmount",
          Number((currentRefundableOrderAmount - 0.01).toFixed(2)),
        );
        break;
      case "full":
        form.setValue("refundAmount", currentRefundableOrderAmount);
        break;
    }
  };

  const handlePartialRefundAmountChange = (value: string) => {
    form.setValue(
      "refundAmount",
      value.length > 0
        ? Number(value) < currentRefundableOrderAmount
          ? Number(value)
          : currentRefundableOrderAmount
        : 0,
    );
  };

  const handleCancelReservationCheckedChange = (checked: boolean) => {
    setDisplayCancellationInput(checked);
    if (!checked) {
      form.setValue("cancelled", false);
    }
  };

  const getRefundValue = (refundAmount: number) => {
    if (refundAmount === 0) {
      return "none";
    } else if (
      refundAmount < currentRefundableOrderAmount &&
      refundAmount > 0
    ) {
      return "partial";
    } else {
      return "full";
    }
  };

  const watchedCancelled = useWatch({
    control: form.control,
    name: "cancelled",
  });
  const watchedRefundAmount = useWatch({
    control: form.control,
    name: "refundAmount",
  });

  const formID = "booker-create-closure-form";
  const isNoRefund = watchedRefundAmount <= 0;

  return (
    <WhereaboutsModal
      isOpen={isOpen}
      onClose={handleCancel}
      title={"Refund and/or cancel"}
      description={`Issue a full or partial refund for booking ${order._id} and/or cancel it.`}
      HeaderIcon={EditPencil}
      cancelButtonProps={{ onClick: handleCancel }}
      saveButtonProps={{
        form: formID,
        type: "submit",
        disabled: !watchedCancelled && isNoRefund,
        isLoading: form.formState.isSubmitting,
      }}
      saveButtonCopy={
        watchedCancelled
          ? isNoRefund
            ? "Cancel order"
            : "Refund and cancel"
          : isNoRefund
            ? "Save"
            : "Refund"
      }
    >
      <FormProvider {...form}>
        <form onSubmit={onSubmit} id={formID}>
          {!order.cancelled && (
            <>
              <Checkbox
                onCheckedChange={v =>
                  handleCancelReservationCheckedChange(!!v.checked)
                }
                checked={displayCancellationInput}
                name={"cancelled"}
                label={"Cancel reservation"}
                hint={`Select if you’d like to cancel this order. We’ll take care of sending the customer a cancellation email, and the dates will become available for booking immediately. ${
                  displayCancellationInput
                    ? `Please enter the order number (${order._id}) below to confirm:`
                    : ""
                }`}
                checkboxFill={"white"}
              />
              {displayCancellationInput && (
                <Box ml={"400"}>
                  <Input
                    placeholder={order._id}
                    name={"orderConfirmation"}
                    bg={"white"}
                    mt={"100"}
                    onChange={e =>
                      handleCancellationConfirmation(e.currentTarget.value)
                    }
                    borderColor={
                      watchedCancelled ? "border.success" : undefined
                    }
                    boxShadow={
                      watchedCancelled
                        ? "0 0 0 1px token(colors.border.success)"
                        : undefined
                    }
                    _hover={{
                      borderColor: watchedCancelled
                        ? "border.success"
                        : undefined,
                      boxShadow: watchedCancelled
                        ? "0 0 0 1px token(colors.border.success)"
                        : undefined,
                      _focus: {
                        borderColor: watchedCancelled
                          ? "border.success"
                          : undefined,
                        boxShadow: watchedCancelled
                          ? "0 0 0 1px token(colors.border.success)"
                          : undefined,
                      },
                    }}
                    _focus={{
                      borderColor: watchedCancelled
                        ? "border.success"
                        : undefined,
                      boxShadow: watchedCancelled
                        ? "0 0 0 1px token(colors.border.success)"
                        : undefined,
                    }}
                  />
                </Box>
              )}
              {currentRefundableOrderAmount > 0 && (
                <Divider
                  borderStyle={"dashed"}
                  borderColor={"border.light"}
                  my={"300"}
                />
              )}
            </>
          )}
          {currentRefundableOrderAmount > 0 && (
            <RadioGroup
              onValueChange={({
                value,
              }: {
                value: "none" | "partial" | "full";
              }) => handleRefundRadioGroupChange(value)}
              value={getRefundValue(watchedRefundAmount)}
            >
              {radioOptions.map(radio => {
                const isFull = radio.value === "full";
                const isPartial = radio.value === "partial";
                const isCurrentValuePartial =
                  getRefundValue(watchedRefundAmount) === "partial";
                return (
                  <Box key={radio.value}>
                    <Radio
                      helperText={
                        radio.helperText +
                        (isFull
                          ? ` $${formatPrice(
                              currentRefundableOrderAmount * 100,
                            )}`
                          : "")
                      }
                      value={radio.value}
                      css={{
                        "& [data-scope='radio-group'][data-part='item-control']":
                          {
                            bg: "white",
                          },
                      }}
                    >
                      {radio.label}
                    </Radio>
                    {isPartial && (
                      <Box ml={"500"}>
                        <Input
                          name={"refundAmount"}
                          value={watchedRefundAmount}
                          bg={"white"}
                          disabled={!isCurrentValuePartial}
                          type={"number"}
                          step={".01"}
                          onChange={e =>
                            handlePartialRefundAmountChange(
                              e.currentTarget.value,
                            )
                          }
                        />
                      </Box>
                    )}
                  </Box>
                );
              })}
            </RadioGroup>
          )}
          {currentRefundableOrderAmount === 0 && order.cancelled && (
            <Flag
              mainText={"No further action"}
              subText={
                "This order has been fully refunded and cancelled. No further action can be taken."
              }
              visual={"highlight"}
              hideCloseTrigger
            />
          )}
        </form>
      </FormProvider>
    </WhereaboutsModal>
  );
};
