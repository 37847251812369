import { Button, Heading, LinkButton, Spinner, Text } from "@ttc3k/trekker";
import { ReservationPage } from "core/components/shared/ReservationPage";
import { handleBeaconClick } from "core/utils/helpScout";
import { useOperatorByIdQuery, useOrderByIdQuery } from "gql/generated";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { Box, Flex } from "styled-system/jsx";

export const PublicReservationPage = () => {
  const { id } = useParams();

  const {
    data: orderData,
    loading,
    error,
  } = useOrderByIdQuery({
    variables: { id: id ?? "" },
  });
  const { data: operatorData } = useOperatorByIdQuery({
    variables: { id: orderData?.orderById?.source.operator ?? "" },
  });
  const isAboveMd = useMediaQuery({ minWidth: "48rem" });

  if (loading) {
    return <Spinner width={"50px"} height={"50px"} margin={"60px auto"} />;
  }
  if (error) {
    return (
      <Text
        margin={"60px auto"}
        visual={"bodyMedium"}
        color={"text.error.mid"}
        width={"full"}
        textAlign={"center"}
      >
        Error : {error?.message}
      </Text>
    );
  }

  if (!orderData?.orderById) {
    return (
      <Text
        margin={"60px auto"}
        visual={"bodyMedium"}
        color={"text.dark"}
        width={"full"}
        textAlign={"center"}
      >
        Cannot find order details, try refreshing the page or&nbsp;
        <LinkButton onClick={handleBeaconClick}>contact support</LinkButton>
      </Text>
    );
  }

  return (
    <Box bg={"bg.wa.lightest"} minH={"100vh"} w={"full"}>
      <Flex
        bg={"white"}
        minH={"240px"}
        px={{ base: "300", md: "80px" }}
        pt={{ base: "600", md: "400" }}
        pb={"400"}
        gap={"400"}
        justifyContent={{ base: undefined, md: "space-between" }}
        alignItems={{ base: undefined, md: "center" }}
        w={"full"}
        flexDir={{ base: "column", md: "row" }}
        borderBottomWidth={"1px"}
        borderBottomStyle={"solid"}
        borderBottomColor={"border.light"}
      >
        <Flex flexDir={"column"} gap={{ base: "50", md: "150" }}>
          <Heading size={isAboveMd ? "h3" : "h4"}>
            {operatorData?.operatorById?.name}
          </Heading>
          <Text color={"text.mid"} visual={"bodyMedium"}>
            Reservation ID:&nbsp;
            <Text as={"span"} fontWeight={"bold"} color={"text.mid"}>
              {orderData.orderById._id}
            </Text>
          </Text>
        </Flex>
        <Button
          visual={"success"}
          size={"lg"}
          onClick={() =>
            handleBeaconClick(
              undefined,
              `I need help with my order ${orderData.orderById?._id}`,
            )
          }
        >
          Get Support
        </Button>
      </Flex>
      <ReservationPage
        order={orderData.orderById}
        operator={operatorData?.operatorById}
        isPublicPage
      />
    </Box>
  );
};
