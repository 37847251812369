// @ts-nocheck
import { motion } from "framer-motion";
import {
  LegacyButton,
  LegacyLoadingSpinner,
  LegacyTypography,
} from "@ttc3k/ttc-design-system";
import { gql, useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { insertAndAfterLastComma } from "../../../utils/insertAndAfterLastComma.ts";
import { variants } from "../shared.ts";
import { useOnboardingContext } from "../Layout.tsx";
import { useNavigate } from "react-router-dom";

const UPDATE_USER_METADATA = gql`
  mutation updateUserMetadata(
    $id: String
    $body: String
    $tenantId: String
    $userObject: String
  ) {
    updateUserMetadata(user_id: $id, body: $body) {
      user_id
    }
    addUserToTenant(tenantId: $tenantId, userObject: $userObject) {
      name
    }
  }
`;
export const ApproveTemplate = ({ next, baseURL, stepData }) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const {
    businesses,
    setOnboardingStatus,
    successSetOnboardingStatus,
    loadingSetOnboardingStatus,
    errorSetOnboardingStatus,
  } = useOnboardingContext();
  const businessList = insertAndAfterLastComma(
    businesses.map(business => `${business.operator_name}`).join(", "),
  );
  console.log(`${baseURL}/next`);
  return (
    <motion.div
      className={
        "tw-flex tw-w-full md:tw-w-96 tw-flex-col tw-text-center tw-gap-6"
      }
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
    >
      <LegacyTypography as="h2" variant="H2">
        Success!
      </LegacyTypography>
      <LegacyTypography>
        You’ve claimed {businessList}.<br />
        Your account is almost ready.
      </LegacyTypography>
      <LegacyButton
        onClick={() =>
          setOnboardingStatus({
            variables: {
              id: user.sub,
              status: true,
            },
          })
            .then(() => new Promise(resolve => setTimeout(resolve, 1500)))
            .then(() => navigate(0))
        }
      >
        {loadingSetOnboardingStatus || successSetOnboardingStatus ? (
          <LegacyLoadingSpinner size={20} />
        ) : (
          "Start Using Whereabouts"
        )}
      </LegacyButton>
      {/*<a href="/src/core/pages/public" className="tw-flex tw-justify-center">*/}
      {/*  <LegacyButton>Start using Whereabouts</LegacyButton>*/}
      {/*</a>*/}
    </motion.div>
  );
};
