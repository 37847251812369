import { Maybe, PriceTier, PurchasedProductGuestsPerTier } from "gql/generated";
import _ from "lodash";
import pluralize from "pluralize";

const getGuestSummaryString = (
  guests: PurchasedProductGuestsPerTier[],
  priceTiers: PriceTier[] | Maybe<PriceTier>[],
): string => {
  const summaryString = _.compact(
    guests.map(g => {
      const fullTier = priceTiers.find(t => t?._id === g.tierId);
      if (g.count > 0) {
        const tierTypeString = fullTier?.type
          .toLowerCase()
          .replace(/\b\w/g, char => char.toUpperCase());
        return pluralize(tierTypeString ?? "", g.count, true);
      } else return null;
    }),
  ).join(" • ");
  return summaryString.length > 0 ? summaryString : "Select guests";
};

export { getGuestSummaryString };
