import { Lifebelt } from "iconoir-react";
import { handleBeaconClick } from "../../../utils/helpScout.ts";

export default function HelpButton() {
  return (
    <div
      onClick={() => handleBeaconClick()}
      className="tw-w-42 tw-cursor-pointer tw-h-12 tw-px-4 tw-py-3 tw-bg-[#429F72] hover:tw-bg-[#3B823B] tw-transition-colors tw-rounded-lg tw-justify-center tw-items-center tw-gap-2 tw-inline-flex"
    >
      <div className="tw-justify-start tw-items-start tw-gap-2.5">
        <Lifebelt color="white" />
      </div>
      <div className="tw-text-white tw-flex tw-text-base tw-font-medium tw-font-['Figtree'] tw-leading-normal">
        Get support
      </div>
    </div>
  );
}
