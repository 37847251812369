export const ChatLinesDark = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4117_9721)">
        <path
          d="M9.99984 18.3334C14.6022 18.3334 18.3332 14.6024 18.3332 10C18.3332 5.39765 14.6022 1.66669 9.99984 1.66669C5.39746 1.66669 1.6665 5.39765 1.6665 10C1.6665 11.5179 2.07231 12.941 2.78136 14.1667L2.08317 17.9167L5.83317 17.2185C7.05889 17.9275 8.48197 18.3334 9.99984 18.3334Z"
          fill="#71717A"
          stroke="#71717A"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.6665 8.33331L9.99984 8.33331L13.3332 8.33331"
          stroke="white"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.6665 11.6667L8.33317 11.6667L9.99984 11.6667"
          stroke="white"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4117_9721">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
