import { LegacyButton } from "@ttc3k/ttc-design-system";
import ttcLogo from "../../../../assets/ttc-logo-full-whitebehind.png";
import { handleBeaconClick } from "../../../utils/helpScout";
export default function Footer() {
  return (
    <footer className="tw-bg-white tw-rounded-lg tw-shadow tw-m-4">
      <div className="tw-w-full tw-max-w-screen-xl tw-mx-auto tw-p-4 md:tw-py-8">
        <div className="sm:tw-flex sm:tw-items-center sm:tw-justify-between">
          <a
            href="https://tourism.tech"
            className="tw-flex tw-items-center tw-mb-4 sm:tw-mb-0 tw-space-x-3 rtl:tw-space-x-reverse"
          >
            <img
              className="tw-max-w-[200px] tw-my-3 tw-block tw-m-auto"
              src={ttcLogo}
            />
          </a>
          <div className="tw-flex tw-flex-wrap tw-items-center tw-mb-6 tw-text-sm tw-font-medium tw-text-gray-500 sm:tw-mb-0">
            <LegacyButton
              onClick={() => handleBeaconClick(undefined)}
              className="tw-ml-auto"
            >
              Contact Us
            </LegacyButton>
          </div>
        </div>
        <hr className="tw-my-6 tw-border-gray-200 sm:tw-mx-auto lg:tw-my-8" />
        <span className="tw-block tw-text-sm tw-text-gray-500 sm:tw-text-center dark:tw-text-gray-400">
          © 2024{" "}
          <a
            href="https://tourism.tech"
            className="hover:tw-underline tw-font-bold"
          >
            The Tourism Technology Company
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}
