import { motion } from "framer-motion";
import {
  LegacyButton,
  ButtonTagVariant,
  tokens,
  LegacyTypography,
  TypographyVariant,
  LegacyProductTile,
  LegacyCheckbox,
} from "@ttc3k/ttc-design-system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOnboardingContext } from "../../Layout.tsx";
import { variants } from "../../shared.ts";
import { Autocomplete2 } from "../../../../components/shared/Autocomplete2";
import { useGetOperatorsQuery } from "../../../../../apps/library/events/branches/components/CreateEvent/hooks.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { useSendEmailMutation } from "../../../../../gql/generated.ts";

export const SearchForBusiness = () => {
  const navigate = useNavigate();
  const {
    baseURL,
    setBusinesses,
    setMultipleTourismBusinesses,
    multipleTourismBusinesses,
    customPortalData,
  } = useOnboardingContext();
  const [searchValue, setSearchValue] = useState<any>(null);
  const { user } = useAuth0();
  const [
    sendEmail,
    { data: emailSuccess, loading: emailSending, error: emailError },
  ] = useSendEmailMutation();
  const {
    data: operatorsData,
    loading: operatorsLoading,
    setSearch,
  } = useGetOperatorsQuery();

  const handleContinue = () => {
    setBusinesses([searchValue]);
    if (multipleTourismBusinesses) {
      sendEmail({
        variables: {
          input: {
            messageBody: `${user.email} says they manage multiple tourism businesses`,
            senderName: "Whereabouts",
            senderEmail: "help@whereabouts.tech",
            subject: `Multiple tourism businesses alert`,
            templateId: "d-7550f868c0e54be7a8d2be42d9895eb7",
            to: "adam@tourism.tech",
          },
        },
      });
    }
    navigate(`${baseURL}/claim/verify`);
  };

  const handleCreate = () => {
    navigate(`${baseURL}/claim/create`);
  };

  return (
    <motion.div
      className="tw-flex tw-w-full md:tw-w-96 tw-mt-auto tw-flex-col tw-text-center tw-gap-6"
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
    >
      <LegacyTypography
        variant={TypographyVariant.h4}
        color={tokens.semantic.colorText}
      >
        Search
      </LegacyTypography>
      <LegacyTypography
        variant={TypographyVariant.primaryRegular}
        color={tokens.semantic.colorText}
      >
        Sorry, we couldn't find a match for <strong>{user?.email}</strong>
      </LegacyTypography>
      <LegacyTypography
        variant={TypographyVariant.primaryRegular}
        color={tokens.semantic.colorText}
      >
        That's OK! Please try searching for your business or organization name
        in our database.
      </LegacyTypography>

      <Autocomplete2
        label="Tourism Business or Organization"
        displayField="operator_name"
        options={operatorsData?.getOperators.data ?? []}
        onInputChange={val => setSearch(val)}
        isLoading={operatorsLoading}
        inputValue={searchValue}
        onChange={val => setSearchValue(val)}
      />

      {searchValue && (
        <LegacyProductTile
          outlined
          key={searchValue._id}
          style={{
            padding: `${tokens.spacing.space250} ${tokens.spacing.space300}`,
            cursor: "default",
            display: "flex",
            flexDirection: "row",
            textAlign: "left",
          }}
        >
          <div className={"tw-flex tw-flex-col tw-gap-1 tw-text-left"}>
            <LegacyTypography variant={TypographyVariant.primaryBold}>
              {searchValue.operator_name}
            </LegacyTypography>
            <LegacyTypography color={tokens.semantic.textSubtle}>
              {searchValue.address}
            </LegacyTypography>
          </div>
        </LegacyProductTile>
      )}

      {!searchValue ? (
        <LegacyTypography
          variant={TypographyVariant.secondaryMedium}
          color={"#27704C"}
        >
          If you can't find a match, you can create a new profile.
        </LegacyTypography>
      ) : (
        <>
          <LegacyTypography
            variant={TypographyVariant.secondaryMedium}
            color={"#27704C"}
          >
            Do you manage multiple tourism businesses? Please complete this
            onboarding process by claiming at least one business, and we'll help
            you to add more later.
          </LegacyTypography>
          <div className="temporary-checkbox-override tw-m-auto">
            <LegacyCheckbox
              onClick={() =>
                setMultipleTourismBusinesses(!multipleTourismBusinesses)
              }
              label="Yes, I manage multiple tourism businesses"
            />
          </div>
        </>
      )}

      <div className="tw-w-full tw-flex tw-justify-between tw-gap-3">
        <LegacyButton
          onClick={handleCreate}
          variant={ButtonTagVariant.Secondary}
          type="button"
          fullWidth
          disabled={searchValue}
        >
          Create new...
        </LegacyButton>
        <LegacyButton
          onClick={handleContinue}
          type="button"
          fullWidth
          disabled={!searchValue}
        >
          Continue
        </LegacyButton>
      </div>
    </motion.div>
  );
};
