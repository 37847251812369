import { useAppContext } from "core/components/apps/PreferredLayout.tsx";
import { useEffect, useState } from "react";
import {
  EnumProductPublishingStatus,
  EnumProductType,
  useProductManyQuery,
} from "gql/generated";
import { useNavigate, useParams } from "react-router-dom";
import { Button, SegmentGroup, Spinner, Text } from "@ttc3k/trekker";
import { Space } from "core/components/shared/Space";
import { Flex, styled } from "styled-system/jsx";
import { RedirectToOperator } from "../components";
import {
  CreateProductStartModal,
  ProductSection,
  CreateEditUpsellModal,
  NoProductsFound,
} from "./components";
import { getProductOptions, isStringProductType } from "./utils";

export function ProductsBundleView() {
  const [isModalOpen, setIsModalOpen] = useState({
    createProductType: false,
    createUpsell: false,
  });
  const [filterValue, setFilterValue] = useState("ALL");
  const { setTitle, setDescription, setActionSection } = useAppContext();
  const { spaceId } = useParams();
  const navigate = useNavigate();

  const { data: allProductData, loading: allProductDataLoading } =
    useProductManyQuery({
      variables: {
        filter: {
          operator: spaceId,
          isActive: true,
        },
      },
    });
  const { data, error, loading } = useProductManyQuery({
    variables: {
      filter: {
        operator: spaceId,
        isUpsellOnly:
          filterValue === "UPSELL"
            ? true
            : filterValue === "FEE"
              ? false
              : undefined,
        type: isStringProductType(filterValue)
          ? (filterValue as EnumProductType)
          : undefined,
        publishingStatus: EnumProductPublishingStatus.Published,
        isActive: true,
      },
    },
    fetchPolicy: "cache-and-network"
  });

  const handleOpenCreateProductModal = () => {
    setIsModalOpen(prev => ({ ...prev, createProductType: true }));
  };
  const handleCloseCreateProductModal = () => {
    setIsModalOpen(prev => ({ ...prev, createProductType: false }));
  };
  const handleOpenUpsellModal = () =>
    setIsModalOpen(prev => ({ ...prev, createUpsell: true }));
  const handleCloseUpsellModal = () => {
    setIsModalOpen(prev => ({ ...prev, createUpsell: false }));
  };

  useEffect(() => {
    setTitle("Products");
    setDescription(
      "Create sellable products here and then use Booker — Whereabouts’ powerful built-in booking engine — to take reservations anywhere on the web.",
    );
    setActionSection(
      <Button
        size={"lg"}
        onClick={handleOpenCreateProductModal}
        disabled={!spaceId || spaceId === "undefined"}
      >
        Create product
      </Button>,
    );

    return () => setActionSection(undefined);
  }, [setActionSection, setDescription, setTitle, spaceId]);

  const handleCreateProductStart = async (type: EnumProductType) => {
    navigate(`create-product/${type}`);
  };

  if (allProductDataLoading) {
    return <Spinner width={"50px"} height={"50px"} margin={"60px auto"} />;
  }
  if (!spaceId || spaceId === "undefined") {
    return <RedirectToOperator />;
  }
  if (error) {
    return (
      <Text
        margin={"60px auto"}
        width={"full"}
        textAlign={"center"}
        color={"text.error.mid"}
      >
        Error : {error.message}
      </Text>
    );
  }

  if (
    !allProductData ||
    !allProductData.productMany ||
    allProductData.productMany.length < 1
  ) {
    return (
      <>
        <NoProductsFound onCreateProductClick={handleOpenCreateProductModal} />
        <CreateProductStartModal
          open={isModalOpen.createProductType}
          onClose={handleCloseCreateProductModal}
          onCreateProductStart={handleCreateProductStart}
          onUpsellSelect={handleOpenUpsellModal}
        />
        <CreateEditUpsellModal
          open={isModalOpen.createUpsell}
          onClose={handleCloseUpsellModal}
          operatorID={spaceId ?? ""}
        />
      </>
    );
  }

  const filterOptions = getProductOptions(
    allProductData.productMany.filter(
      p => p.publishingStatus === EnumProductPublishingStatus.Published,
    ),
  );
  const dataOptions = data?.productMany
    ? getProductOptions(data?.productMany)
    : [];
  const draftProducts = allProductData.productMany.filter(
    p => p.publishingStatus === EnumProductPublishingStatus.Draft,
  );

  return (
    <>
      <Space
        sideBarComponent={
          filterOptions.length > 0 && (
            <SegmentGroup.Root
              value={filterValue}
              onValueChange={v => setFilterValue(v.value)}
            >
              <SegmentGroup.Item value={"ALL"}>
                <span>All</span>
                <styled.span fontSize={"12px"} fontWeight={"bold"}>
                  {allProductData.productMany.length}
                </styled.span>
              </SegmentGroup.Item>
              {filterOptions.map(v => (
                <SegmentGroup.Item key={v.value} value={v.value}>
                  <span>{v.label}</span>
                  <styled.span fontSize={"12px"} fontWeight={"bold"}>
                    {v.count}
                  </styled.span>
                </SegmentGroup.Item>
              ))}
            </SegmentGroup.Root>
          )
        }
        sideBarPosition={"left"}
      >
        {loading && (
          <Spinner width={"50px"} height={"50px"} margin={"60px auto"} />
        )}
        <Flex flexDir={"column"} gap={"300"}>
          {draftProducts.length > 0 && filterValue === "ALL" && (
            <ProductSection
              products={draftProducts}
              title={"Drafts"}
              sectionValue={"draft"}
            />
          )}
          {dataOptions.length > 0 &&
            dataOptions.map(d => {
              const sectionData = data?.productMany.filter(
                p =>
                  (p.type === d.value && !p.isUpsellOnly) ||
                  (d.value === "UPSELL" && p.isUpsellOnly),
              );
              return (
                <ProductSection
                  key={d.value}
                  products={sectionData ? sectionData : []}
                  title={d.label}
                  sectionValue={d.value}
                  onCreateNewUpsell={handleOpenUpsellModal}
                />
              );
            })}
        </Flex>
      </Space>

      <CreateProductStartModal
        open={isModalOpen.createProductType}
        onClose={handleCloseCreateProductModal}
        onCreateProductStart={handleCreateProductStart}
        onUpsellSelect={handleOpenUpsellModal}
      />
      <CreateEditUpsellModal
        open={isModalOpen.createUpsell}
        onClose={handleCloseUpsellModal}
        operatorID={spaceId ?? ""}
      />
    </>
  );
}
