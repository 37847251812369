import { Heading, Text } from "@ttc3k/trekker";
import React from "react";
import { Flex } from "styled-system/jsx";

type BookerLeafHeaderProps = {
  title: string;
  description?: string;
};

export const BookerLeafHeader = ({
  title,
  description,
}: BookerLeafHeaderProps) => {
  return (
    <Flex flexDir={"column"} gap={"100"}>
      <Heading size={"h4"}>{title}</Heading>
      {description && <Text color={"text.dark"}>{description}</Text>}
    </Flex>
  );
};
