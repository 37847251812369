import { Button, Heading, Icon, Text } from "@ttc3k/trekker";
import { Cart } from "iconoir-react";
import { Box, Flex, VStack } from "styled-system/jsx";
import { CheckoutSummary } from "booker/shared";
import { formatPrice } from "core/utils/formatPrice";
import { useBookerContext, useCartContext } from "booker/contexts";
import { getTotalGuestCount } from "booker/shared/utils";
import { EnumProductType } from "gql/generated";
import { OrderSuccessPage } from "../OverlayPages";
import { useCheckout } from "./useCheckout";

export const Checkout = () => {
  const { product } = useBookerContext();
  const {
    subTotal,
    grandTotal,
    tax,
    cart,
    acceptedTerms,
    customer,
    stripeCardElement,
  } = useCartContext();
  const { onCheckoutSubmit, error, errorMsg, loading, orderComplete } =
    useCheckout();

  const isMainProductComplete = cart.length > 0 && cart[0].total > 0;
  const isAccommodationMainProductIsComplete =
    isMainProductComplete &&
    cart[0].productType === EnumProductType.Accommodation &&
    !!product
      ? cart[0].startDateISO.length > 0 &&
        getTotalGuestCount(cart[0].guestsPerTier) >=
          (product.accommodationMetadata?.guestMetadata.minimumNumber ?? 0)
      : true;

  const isBookerComplete =
    isMainProductComplete &&
    isAccommodationMainProductIsComplete &&
    grandTotal > 0 &&
    acceptedTerms &&
    customer.complete &&
    !!stripeCardElement;

  return (
    <VStack gap={"400"} alignItems={"flex-start"} w={"full"}>
      <Heading size={"h5"} display={"flex"} gap={"100"} alignItems={"center"}>
        <Icon Element={Cart} size={"icons.lg"} />
        Checkout
      </Heading>
      <VStack gap={"300"} alignItems={"flex-start"} w={"full"}>
        <CheckoutSummary
          cart={cart}
          subTotal={subTotal}
          grandTotal={grandTotal}
          tax={tax}
        />
        <Box
          borderTopWidth={"2px"}
          borderTopStyle={"solid"}
          borderTopColor={"border.dark"}
          w={"full"}
        />
        <Flex w={"full"} justifyContent={"space-between"} alignItems={"center"}>
          <Box>
            <Text visual={"bodySemiBold"} color={"text.dark"}>
              Total Due
            </Text>
            <Text visual={"smallMedium"} color={"text.light"}>
              Taxes included
            </Text>
          </Box>
          <Heading size="h2">${formatPrice(grandTotal)}</Heading>
        </Flex>
      </VStack>

      <Box w={"full"}>
        <Button
          size={"lg"}
          w={"full"}
          disabled={!isBookerComplete}
          onClick={onCheckoutSubmit}
          isLoading={loading}
        >
          Pay&nbsp;${formatPrice(grandTotal)}
        </Button>
        <Flex
          flexDir={"column"}
          justifyContent={"space-between"}
          height={"120px"}
        >
          <Text
            mt={"100"}
            visual={"smallRegular"}
            color={!error ? "text.light" : "text.error.mid"}
            lineClamp={3}
          >
            {!error
              ? "All payments are securely processed with Stripe"
              : `Whoops — we couldn’t process your payment. ${errorMsg}`}
          </Text>
          <Box>
            <Text color="text.light" visual="smallRegular">
              Powered by
            </Text>
            <Text color="text.mid" visual="smallLabel">
              Whereabouts
            </Text>
          </Box>
        </Flex>
      </Box>
      <OrderSuccessPage open={orderComplete} />
    </VStack>
  );
};
