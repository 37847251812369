import { FormProvider } from "react-hook-form";
import { useAppContext } from "core/components/apps/PreferredLayout";
import { useNavigate, useParams } from "react-router-dom";
import { Button, toastFactory } from "@ttc3k/trekker";
import { useEffect } from "react";
import { Flex } from "styled-system/jsx";
import { useCreateEditFeeForm } from "../../../components/useCreateEditFeeForm";
import { FeeDetailsFormFields } from "./FeeDetailsFormFields";

export const CreateFeeForm = () => {
  const { setActionSection } = useAppContext();
  const { spaceId } = useParams();
  const navigate = useNavigate();

  const { form, onSubmit } = useCreateEditFeeForm({
    operatorID: spaceId ?? "",
    onSuccess: values => {
      const newPath = `/app/booker/${spaceId}/products/update-product/${values.productID}/product_info`;
      navigate(newPath);
      toastFactory.create({
        title: "Success!",
        description: `${values?.name} was created successfully`,
      });
    },
    onError: errorMsg => {
      toastFactory.create({
        title: "Error",
        description: errorMsg,
      });
    },
  });

  const formID = "create-fee-form";

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (form.formState.isDirty) {
        e.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [form.formState.isDirty]);

  useEffect(() => {
    setActionSection(
      <Flex gap={"150"}>
        <Button size={"lg"} type={"submit"} form={formID}>
          Save
        </Button>
      </Flex>,
    );
    return () => setActionSection(undefined);
  }, [setActionSection]);

  return (
    <FormProvider {...form}>
      <form id={formID} onSubmit={onSubmit}>
        <FeeDetailsFormFields />
      </form>
    </FormProvider>
  );
};
