import { useState } from "react";
import { Text } from "@ttc3k/trekker";
import { useBookerContext, useCartContext } from "booker/contexts";
import { ActionCard, CollapsableStep } from "booker/shared";
import { TermsPage } from "booker/features/OverlayPages";

export const Terms = () => {
  const { acceptTerms, acceptedTerms } = useCartContext();
  const { operator } = useBookerContext();

  const [isTermsPageOpen, setIsTermsPageOpen] = useState(false);
  return (
    <CollapsableStep title={"Terms & Conditions"} complete={acceptedTerms}>
      <Text color="text.mid">
        You must accept our terms and conditions before proceeding to checkout.
      </Text>
      <ActionCard
        mainText="Terms & Conditions"
        subText={acceptedTerms ? "Accepted" : "Required"}
        visual={acceptedTerms ? "success" : "neutral"}
        isEditable={false}
        onClick={() => setIsTermsPageOpen(true)}
      />
      <TermsPage
        open={isTermsPageOpen}
        onClose={() => setIsTermsPageOpen(false)}
        acceptedTerms={acceptedTerms}
        acceptTerms={acceptTerms}
        terms={operator?.bookerOptions?.termsConditions}
        cancellationPolicy={operator?.bookerOptions?.cancellationPolicy}
      />
    </CollapsableStep>
  );
};
