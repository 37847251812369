import { Address } from "gql/generated";

function createGoogleMapsUrl(address: Address): string {
  if (address.location && address.location.coordinates.length === 2) {
    const [latitude, longitude] = address.location.coordinates;
    if (latitude !== null && longitude !== null) {
      return `https://www.google.com/maps?q=${latitude},${longitude}`;
    }
  }

  const addressParts: string[] = [
    encodeURIComponent(address.line1),
    address.line2 ? encodeURIComponent(address.line2) : "",
    encodeURIComponent(address.city),
    encodeURIComponent(address.subdivision),
    encodeURIComponent(address.postalCode),
    encodeURIComponent(address.country),
  ].filter(Boolean);

  const addressString = addressParts.join(",");
  return `https://www.google.com/maps?q=${addressString}`;
}

export { createGoogleMapsUrl };
