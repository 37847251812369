import { Select, Text } from "@ttc3k/trekker";
import {
  EnumPriceTierType,
  Maybe,
  PriceTier,
  PurchasedProductGuestsPerTier,
} from "gql/generated";
import { Box, Flex } from "styled-system/jsx";

type GuestCountsProps = {
  guests: PurchasedProductGuestsPerTier[];
  onUpdateGuests: (g: PurchasedProductGuestsPerTier) => void;
  priceTiers: Maybe<PriceTier>[] | PriceTier[];
  guestMaximum: number | undefined | null;
};

export const GuestCounts = ({
  guests,
  onUpdateGuests,
  priceTiers,
  guestMaximum,
}: GuestCountsProps) => {
  return (
    <Flex flexDir={"column"} gap={"250"}>
      {guests.length > 0 &&
        guests.map(g => {
          const fullTier = priceTiers.find(t => t?._id === g.tierId);
          const isAdultTier = fullTier?.type === EnumPriceTierType.Adult;
          const options = new Array(
            guestMaximum && guestMaximum > 0 ? guestMaximum + 1 : 21,
          )
            .fill("")
            .map((_, i) => ({
              value: i.toString(),
              label: i.toString(),
              disabled: i === 0 && isAdultTier,
            }));

          const handleUpdateGuestCount = (newCount: string) => {
            onUpdateGuests({ ...g, count: Number(newCount) });
          };
          return (
            <Flex
              key={g.tierId}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                <Text
                  visual={"bodyMedium"}
                  color={"black"}
                  textTransform={"capitalize"}
                >
                  {fullTier?.type.toLowerCase()}
                </Text>
                <Text visual={"smallMedium"} color={"text.light"} mt={"50"}>
                  {fullTier?.label?.en}
                </Text>
              </Box>
              <Select.Root
                items={options}
                value={[g.count.toString()]}
                placeholder={"0"}
                maxW={"140px"}
                css={{
                  "& [data-scope='select'][data-part='trigger']": {
                    bg: "white",
                  },
                }}
                onValueChange={({ value }: { value: string[] }) =>
                  handleUpdateGuestCount(value[0])
                }
              >
                {options.map(v => (
                  <Select.Item key={v.value} item={v} />
                ))}
              </Select.Root>
            </Flex>
          );
        })}
      <div></div>
    </Flex>
  );
};
