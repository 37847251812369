import { compact } from "lodash";

export function formatPhoneNumber(phone?: string | null) {
  const cleaned = ("" + phone).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return compact([
      match[1] ? `${match[1]} ` : null,
      match[2],
      "-",
      match[3],
      "-",
      match[4],
    ]).join("");
  }

  return null;
}
