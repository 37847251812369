import { styled } from "styled-system/jsx";

export const TableCell = styled("td", {
  base: {
    py: 0,
    display: "flex",
    alignItems: "center",
    textAlign: "left",
  },
});
