// @ts-nocheck
import {
  LegacyButton,
  LegacyCheckbox,
  LegacyLoadingSpinner,
  LegacyTypography,
} from "@ttc3k/ttc-design-system";
import { useAuth0 } from "@auth0/auth0-react";
import { handleBeaconClick } from "../../../utils/helpScout.ts";
import SlideInPage from "../../animation/SlideInPage.tsx";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useOnboardingContext } from "../Layout.tsx";
import { useNavigate } from "react-router-dom";
import { useSendEmailMutation } from "../../../../gql/generated.ts";

const UPDATE_USER_METADATA = gql`
  mutation updateUserMetadata(
    $id: String
    $body: String
    $tenantId: String
    $userObject: String
  ) {
    updateUserMetadata(user_id: $id, body: $body) {
      user_id
    }
    addUserToTenant(tenantId: $tenantId, userObject: $userObject) {
      name
    }
  }
`;

export const Pending = () => {
  const { user } = useAuth0();
  const { customPortalData } = useOnboardingContext();
  //const [register, setRegister] = useState(false);
  const navigate = useNavigate();
  const disabled =
    user?.whereaboutsOnboarding?.pending != undefined
      ? user?.whereaboutsOnboarding?.pending
      : false;
  const [
    sendEmail,
    { data: emailSuccess, loading: emailSending, error: emailError },
  ] = useSendEmailMutation();
  const [
    updateUserMetadata,
    { data: dataUser, loading: loadingUser, error: errorUser },
  ] = useMutation(UPDATE_USER_METADATA);
  const handleSave = () => {
    updateUserMetadata({
      variables: {
        id: user?.sub,
        body: JSON.stringify({
          app_metadata: {
            tenants: [user?.sub],
            onboarding: { complete: false, pending: true, isAdmin: false },
            request: "Interested in joining mailing list",
          },
        }),
        tenantId: JSON.stringify([
          user?.sub,
          customPortalData?.settings?.tenants[0],
        ]),
        userObject: JSON.stringify(user),
      },
    })
      .then(
        sendEmail({
          variables: {
            input: {
              messageBody: `${user.email} wants to join a mailing list`,
              senderName: "Whereabouts",
              senderEmail: "help@whereabouts.tech",
              subject: `Join mailing list alert`,
              templateId: "d-7550f868c0e54be7a8d2be42d9895eb7",
              to: "adam@tourism.tech",
            },
          },
        }),
      )
      .then(result => navigate(0));
  };

  return (
    <SlideInPage>
      <div className="tw-w-full md:tw-w-96 tw-flex tw-flex-col tw-gap-5">
        <LegacyTypography as="h2" variant="H2">
          Thank you for your interest
        </LegacyTypography>
        {!disabled ? (
          <>
            <LegacyTypography>
              Please click below to add <b>{user.email}</b> to our mailing list
              and add you as a trusted Community Member to our database. We'll
              follow up soon.
            </LegacyTypography>
            {/*<div className="temporary-checkbox-override tw-mx-auto tw-mt-5">*/}
            {/*  <LegacyCheckbox*/}
            {/*    onClick={() => setRegister(!register)}*/}
            {/*    selected*/}
            {/*    label="Yes, please send me tourism news for my region"*/}
            {/*  />*/}
            {/*</div>*/}
            <LegacyButton
              onClick={() => handleSave()}
              type="button"
              fullWidth
              disabled={disabled}
            >
              {loadingUser ? <LegacyLoadingSpinner size={20} /> : "Register Me"}
            </LegacyButton>
          </>
        ) : (
          <LegacyTypography>
            We are still processing your request. We'll follow up at{" "}
            <b>{user.email}</b> shortly.
          </LegacyTypography>
        )}
      </div>
    </SlideInPage>
  );
};
