import { Text } from "@ttc3k/trekker";
import { CollapsableStep } from "booker/shared";
import { useCartContext } from "booker/contexts";
import { useAddOns } from "./useAddOns";
import { AddOnItem } from "./AddOnItem";

export const AddOns = () => {
  const { cart } = useCartContext();
  const mainProductID = cart.length > 0 ? cart[0].productID : null
  const { addOns, error, loading } = useAddOns(mainProductID);

  if (!!error && !loading) {
    throw new Error("Error loading add-ons");
  }

  if (!loading && (!addOns || addOns.length < 1)) {
    return null;
  }

  return (
    // Add-ons are optional, so this step is alway set to complete
    <CollapsableStep title={"Add-ons"} complete optionalStep>
      <Text color="text.mid">
        We offer a few basic add-ons to further customize your trip. Flip the
        switch on any one below to add it to your reservation.
      </Text>
      {addOns.map((p, i) => (
        <AddOnItem addOn={p} key={p?._id ?? i} />
      ))}
    </CollapsableStep>
  );
};
