import { WhereaboutsModal } from "core/components/shared/WhereaboutsModal";
import { EnumProductType } from "gql/generated";
import {
  ArrowRight,
  CalendarPlus,
  DollarCircle,
  HomeAlt,
  PlusCircle,
} from "iconoir-react";
import {
  ForwardRefExoticComponent,
  RefAttributes,
  SVGProps,
  useState,
} from "react";
import { Divider, Flex, Grid } from "styled-system/jsx";
import { LinkButton, Text } from "@ttc3k/trekker";
import { ProductTypeCard } from "./ProductTypeCard";

type CreateProductStartModalProps = {
  open: boolean;
  onClose: () => void;
  onCreateProductStart: (productType: EnumProductType) => void;
  onUpsellSelect: () => void;
};

export type ProductTypeData = {
  title: string;
  description: string;
  icon: ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, "ref"> & RefAttributes<SVGSVGElement>
  >;
  type: EnumProductType;
  available: boolean;
};

const productTypes: ProductTypeData[] = [
  {
    title: "Accommodation",
    description:
      "Create an accommodation product (cabin, vacation rental, etc.)",
    icon: HomeAlt,
    type: EnumProductType.Accommodation,
    available: true,
  },
  {
    title: "Fee",
    description: "Create a simple fee product to easily collect payments.",
    icon: DollarCircle,
    type: EnumProductType.Fee,
    available: true,
  },
  {
    title: "Event",
    description:
      "Create a scheduled event for which you’d like to sell tickets.",
    icon: CalendarPlus,
    type: EnumProductType.Event,
    available: false,
  },
];

export const CreateProductStartModal = ({
  onClose,
  open,
  onCreateProductStart,
  onUpsellSelect,
}: CreateProductStartModalProps) => {
  const [selectedProductType, setSelectedProductType] = useState<
    EnumProductType | ""
  >("");

  const handleClose = () => {
    onClose();
    setSelectedProductType("");
  };

  const handleCreateProductStart = () => {
    handleClose();
    if (selectedProductType !== "") onCreateProductStart(selectedProductType);
  };

  const handleSelectUpsell = () => {
    handleClose();
    onUpsellSelect();
  };
  return (
    <WhereaboutsModal
      isOpen={open}
      onClose={handleClose}
      cancelButtonProps={{ onClick: handleClose, flex: undefined }}
      saveButtonProps={{
        flex: undefined,
        visual: "accent",
        Icon: ArrowRight,
        isIconRightAligned: true,
        onClick: handleCreateProductStart,
        disabled: selectedProductType === "",
      }}
      saveButtonCopy="Continue"
      HeaderIcon={PlusCircle}
      title="Product Type"
      description="Select the type of product you’d like to create from the options below."
      size={"lg"}
    >
      <Flex flexDir={"column"} gap={"200"}>
        <Grid
          gap={"200"}
          gridTemplateColumns={{ md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
        >
          {productTypes.map(p => (
            <ProductTypeCard
              key={p.type}
              productTypeData={p}
              selectedProductType={selectedProductType}
              onSelectProductType={type => setSelectedProductType(type)}
            />
          ))}
        </Grid>
        <Flex gap={"400"} flex={1} alignItems={"center"}>
          <Divider flex={9.25} borderColor={"border.light"} />
          <Text flex={0.5} color={"text.mid"} visual={"bodySemibold"}>
            or
          </Text>
          <Divider flex={9.25} borderColor={"border.light"} />
        </Flex>
        <Text color={"text.mid"} width={"full"} textAlign={"center"}>
          Simply want to create an add-on that you can attach to any of your
          main products?&nbsp;
          <LinkButton onClick={handleSelectUpsell}>
            Create an upsell instead!
          </LinkButton>
        </Text>
      </Flex>
    </WhereaboutsModal>
  );
};
