import { Card, Switch } from "@ttc3k/trekker";
import { cva } from "styled-system/css";
import { styled } from "styled-system/jsx";

const productCardSurfaceStyles = cva({
  base: {
    width: "full",
    outline: "0 transparent solid",
    transitionDuration: "normal",
    transitionProperty: "border, outline",
    transitionTimingFunction: "default",
    boxShadow: "none",
  },
  variants: {
    visual: {
      unChecked: {},
      checked: {
        borderColor: "border.dark",
        outlineWidth: "1px",
        outlineColor: "border.dark",
      },
    },
  },
  defaultVariants: {
    visual: "unChecked",
  },
});

const ProductCardSurface = styled(Card.Surface, productCardSurfaceStyles);


type ProductCardProps = {
  mainText: string;
  subText: string;
  onCheckedChange?: (v: boolean) => void;
  checked: boolean;
};

export const ProductToggleCard = ({
  mainText,
  subText,
  onCheckedChange,
  checked,
}: ProductCardProps) => {
  return (
    <ProductCardSurface visual={checked ? "checked" : "unChecked"}>
      <Card.Body bg="white" py={"250"}>
        <Card.BodyContent alignItems="center">
          <Card.Title mainText={mainText} subText={subText} />
          <Switch
            checked={checked}
            onCheckedChange={e => onCheckedChange?.(e.checked)}
          />
        </Card.BodyContent>
      </Card.Body>
    </ProductCardSurface>
  );
};
