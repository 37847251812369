import { Menu, MultiActionButton, Text } from "@ttc3k/trekker";
import { EnumProductType, OrderByIdQuery } from "gql/generated";
import { NavArrowDown } from "iconoir-react";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { OrderRefundModal } from "core/components/shared/OrderRefundModal";
import { EditDatesModal } from "./EditDatesModal";

enum ReservationMenuOption {
  Copy = "COPY",
  Edit = "EDIT",
  RefundCancel = "REFUND_CANCEL",
}

type ReservationMenuProps = {
  order: NonNullable<OrderByIdQuery["orderById"]>;
};

export const ReservationMenu = ({ order }: ReservationMenuProps) => {
  const [isEditDatesModalOpen, setIsEditDatesModalOpen] = useState(false);
  const [isRefundCancelModalOpen, setIsRefundCancelModalOpen] = useState(false);
  const { id } = useParams();

  const isOrderDatesEditable =
    order?.purchasedProducts[0]?.product.type === EnumProductType.Accommodation;

  const handleCopyPublicLink = useCallback(async () => {
    const publicLink = `${window.location.origin}/booker/reservation/${
      id ?? ""
    }`;
    await navigator.clipboard.writeText(publicLink);
  }, [id]);

  const handleMenuSelect = (value: ReservationMenuOption) => {
    switch (value) {
      case ReservationMenuOption.Copy:
        handleCopyPublicLink();
        break;
      case ReservationMenuOption.Edit:
        setIsEditDatesModalOpen(true);
        break;
      case ReservationMenuOption.RefundCancel:
        setIsRefundCancelModalOpen(true);
        break;
    }
  };
  return (
    <>
      <Menu.Root
        trigger={
          <MultiActionButton
            visual={"outline"}
            type={"button"}
            Icon={NavArrowDown}
            size={"lg"}
            width={"max-content"}
          >
            Product actions
          </MultiActionButton>
        }
        onSelect={d => handleMenuSelect(d.value as ReservationMenuOption)}
      >
        <Menu.Item
          value={ReservationMenuOption.Copy}
          gap={"100"}
          justifyContent={"flex-start"}
        >
          <Text visual={"smallMedium"} color={"text.dark"}>
            Copy public link
          </Text>
        </Menu.Item>
        {isOrderDatesEditable && (
          <Menu.Item
            value={ReservationMenuOption.Edit}
            gap={"100"}
            justifyContent={"flex-start"}
          >
            <Text visual={"smallMedium"} color={"text.dark"}>
              Edit dates
            </Text>
          </Menu.Item>
        )}
        <Menu.Item
          value={ReservationMenuOption.RefundCancel}
          gap={"100"}
          justifyContent={"flex-start"}
        >
          <Text visual={"smallMedium"} color={"text.dark"}>
            Refund and/or cancel
          </Text>
        </Menu.Item>
      </Menu.Root>
      <EditDatesModal
        isOpen={isEditDatesModalOpen}
        onClose={() => setIsEditDatesModalOpen(false)}
        order={order}
      />
      <OrderRefundModal
        isOpen={isRefundCancelModalOpen}
        onClose={() => setIsRefundCancelModalOpen(false)}
        order={order}
      />
    </>
  );
};
