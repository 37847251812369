import { Flex } from "styled-system/jsx";
import {
  DatesAndGuests,
  CustomerDetails,
  PaymentMethod,
  Terms,
  AddOns,
} from "./components";

export const Steps = () => {
  return (
    <Flex flexDirection={"column"} gap={"600"}>
      <DatesAndGuests />
      <CustomerDetails />
      <AddOns />
      <PaymentMethod />
      <Terms />
    </Flex>
  );
};
