import { DATE_PATTERN_SIMPLE, formatDate } from "core/utils/formatDate";
import {
  Closure,
  DateRangeIso,
  EnumProductType,
  useProductManyQuery,
} from "gql/generated";
import _ from "lodash";
import { useState } from "react";

export type NewClosure = Closure & {
  isFullClosure: boolean;
};

export const useCalendarData = (operatorID: string) => {
  const [selectedDate, setSelectedDate] = useState(
    formatDate(new Date(), DATE_PATTERN_SIMPLE),
  );
  const [hoveredReservationDateRangeISO, setHoveredReservationDateRangeISO] =
    useState<DateRangeIso | undefined>(undefined);

  function onUpdateSelectedDate(date: string) {
    setSelectedDate(date);
  }

  function onReservationHover(dateRangeISO: DateRangeIso | undefined) {
    setHoveredReservationDateRangeISO(dateRangeISO);
  }

  const { data } = useProductManyQuery({
    variables: {
      filter: {
        type: EnumProductType.Accommodation,
        operator: operatorID,
        isActive: true,
      },
    },
  });

  const products = data?.productMany;

  const numOfProducts = products?.length ?? 0;
  const allAccommodationClosures = _.compact(
    products?.flatMap(v => v.accommodationMetadata?.closures),
  );
  const processedClosures = processClosures(
    allAccommodationClosures,
    numOfProducts,
  );

  return {
    selectedDate,
    onUpdateSelectedDate,
    hoveredReservationDateRangeISO,
    onReservationHover,
    closures: processedClosures,
  };
};

function processClosures(
  closures: Closure[],
  numOfProducts: number,
): NewClosure[] {
  return _.chain(closures)
    .groupBy("_id")
    .map(group => ({
      ...group[0],
      isFullClosure: group.length === numOfProducts,
    }))
    .value();
}
