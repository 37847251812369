import { useFormHandler } from "core/hooks/useFormHandler";
import { DATE_PATTERN_SIMPLE, formatDate } from "core/utils/formatDate";
import { parseToUTCDate } from "core/utils/parseToUTCDate";
import { isAfter } from "date-fns";
import {
  ProductManyDocument,
  useProductClosureByIdQuery,
  useUpdateClosuresPerProductMutation,
} from "gql/generated";
import _ from "lodash";

import { useCallback } from "react";
import { z } from "zod";

const editClosureFormSchema = z
  .object({
    productIDs: z
      .array(z.string())
      .min(1, "Please select what product(s) this closure applies to"),
    name: z.string().min(1, "Please enter a closure name"),
    startISO: z.string().min(1, "Please enter a start date"),
    endISO: z.string().min(1, "Please enter a end date"),
  })
  .superRefine((data, ctx) => {
    if (isAfter(parseToUTCDate(data.startISO), parseToUTCDate(data.endISO))) {
      ctx.addIssue({
        message: "End date must be after the start date",
        path: ["endISO"],
        code: "custom",
      });
    }
  });

export type EditClosureFormValues = z.infer<typeof editClosureFormSchema>;

interface EditClosureFormOptions {
  onSuccess?: (v: EditClosureFormValues | undefined) => void;
  onError?: (message: string) => void;
  closureID: string;
}

const useDefaultValues = (closureId: string): EditClosureFormValues => {
  const { data } = useProductClosureByIdQuery({ variables: { closureId } });
  const currentClosure = data?.productClosureById?.closure;
  const currentProductIDs = data?.productClosureById?.productIDs;

  return {
    startISO: currentClosure?.dateRangeISO.start
      ? formatDate(
          parseToUTCDate(currentClosure.dateRangeISO.start),
          DATE_PATTERN_SIMPLE,
        )
      : "",
    endISO: currentClosure?.dateRangeISO.end
      ? formatDate(
          parseToUTCDate(currentClosure.dateRangeISO.end),
          DATE_PATTERN_SIMPLE,
        )
      : "",
    name: currentClosure?.name ?? "",
    productIDs: currentProductIDs ?? [],
  };
};

export const useEditClosureForm = ({
  onSuccess,
  onError,
  closureID,
}: EditClosureFormOptions) => {
  const defaultValues = useDefaultValues(closureID);
  const [updateClosures] = useUpdateClosuresPerProductMutation();

  return useFormHandler<EditClosureFormValues>(
    useCallback(
      async data => {
        const payload = { ...data };
        const { endISO, startISO, name, productIDs } = payload;

        const formErrorMessage =
          "Could not update closure, please try again later";

        if (closureID) {
          const { data: res } = await updateClosures({
            variables: {
              closureId: closureID,
              deleteClosure: false,
              name: name,
              endIso: endISO ? parseToUTCDate(endISO).toISOString() : undefined,
              startIso: startISO
                ? parseToUTCDate(startISO).toISOString()
                : undefined,
              productIDs: productIDs ? _.compact(productIDs) : undefined,
            },
            refetchQueries: [ProductManyDocument],
          });
          if (
            res?.updateClosuresPerProduct &&
            res?.updateClosuresPerProduct.length > 0
          ) {
            onSuccess?.(data as EditClosureFormValues);
          } else {
            onError?.(formErrorMessage);
          }
        }
      },
      [closureID, onError, onSuccess, updateClosures],
    ),
    defaultValues,
    { schema: editClosureFormSchema },
  );
};
