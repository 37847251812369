import { LinkButton, Text } from "@ttc3k/trekker";
import { NavLink } from "react-router-dom";
import { Box } from "styled-system/jsx";

export const RedirectToOperator = () => {
  return (
    <Box margin={"60px auto"} width={"full"} textAlign={"center"}>
      <Text>Cannot find operator.</Text>
      <NavLink to={"/app/booker"}>
        <LinkButton>Please select an operator first.</LinkButton>
      </NavLink>
    </Box>
  );
};
