import { Button, Heading, ModalOverlay, Text } from "@ttc3k/trekker";
import { ComponentProps } from "react";
import { useMediaQuery } from "react-responsive";
import { Box, Divider, Flex } from "styled-system/jsx";

type OverlayPageProps = ComponentProps<typeof ModalOverlay> & {
  title: string;
  subtitle?: string;
  onClose: () => void;
  buttonLabel?: string;
  buttonDisabled?: boolean;
  onButtonClick?: () => void;
};

export type ConsumingOverlayPageProps = Omit<
  OverlayPageProps,
  "title" | "subtitle" | "buttonLabel" | "buttonDisabled" | "onButtonClick"
>;

export const OverlayPage = ({
  open,
  title,
  subtitle,
  children,
  onClose,
  buttonLabel = "Done",
  buttonDisabled,
  onButtonClick,
  ...rest
}: OverlayPageProps) => {
  // Panda responsive design syntax isn't working for overlay
  const isDevice = useMediaQuery({ maxWidth: 1024 });
  return (
    <ModalOverlay open={open} {...rest}>
      <Box
        bg={"cream.50"}
        minH={"full"}
        h={"auto"}
        w={"full"}
        px={"400"}
        pt={"800"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"space-between"}
        position={"relative"}
      >
        <Box>
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={"100"}
          >
            <Heading size={"h4"}>{title}</Heading>
            <Button visual={"outline"} onClick={onClose}>
              Back
            </Button>
          </Flex>
          <Divider my={"250"} h={"2px"} bg={"border.dark"} />
          {subtitle && <Text color={"text.mid"}>{subtitle}</Text>}
          {children}
        </Box>
        <Box
          position={"sticky"}
          bottom={0}
          left={0}
          boxSizing={"border-box"}
          width={"full"}
          // Added bottom spacing to account for mobile & tablet screens
          mt={isDevice ? "500" :"150"}
          pb={isDevice ? "130px" : "500"}
          bg={"cream.50"}
        >
          <Box
            height={"60px"}
            position={"absolute"}
            top={"-60px"}
            left={0}
            width={"full"}
            background={
              "linear-gradient(0deg, token(colors.cream.50) 0%, rgba(250, 247, 243, 0.00) 100%)"
            }
            pointerEvents={"none"}
          />
          <Button
            width={"full"}
            size={"lg"}
            disabled={buttonDisabled}
            onClick={onButtonClick}
          >
            {buttonLabel}
          </Button>
        </Box>
      </Box>
    </ModalOverlay>
  );
};
