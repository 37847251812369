import { WhereaboutsModal } from "core/components/shared/WhereaboutsModal";
import { ArrowRight, PlusCircle } from "iconoir-react";
import { FormProvider, useWatch } from "react-hook-form";
import { useProductManyQuery } from "gql/generated";
import { Button, Spinner, Text, toastFactory } from "@ttc3k/trekker";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { LegacyAnimatedHeight } from "@ttc3k/ttc-design-system";
import { Flex } from "styled-system/jsx";
import { useManualBookingForm } from "./useManualBookingForm";
import { ManualBookingFormComponents } from "./ManualBookingFormComponents";
import { ReviewManualBooking } from "./ReviewManualBooking";

type ManualBookingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  operatorID: string;
};

const variants = {
  enter: { x: "100%", opacity: 0 },
  center: { x: 0, opacity: 1 },
  exit: { x: "-100%", opacity: 0 },
};

export const ManualBookingModal = ({
  isOpen,
  onClose,
  operatorID,
}: ManualBookingModalProps) => {
  const [formError, setFormError] = useState("");
  const [screen, setScreen] = useState<"build" | "review">("build");
  const { form, onSubmit, defaultValues } = useManualBookingForm({
    operatorID,
    screen,
    onSuccess: data => {
      form.reset(defaultValues);
      setScreen("build");
      setFormError("");
      onClose();
      toastFactory.create({
        title: "Success!",
        description: `Order ${data} was created successfully`,
      });
    },
    onError: m => setFormError(m),
  });

  const { data, loading, error } = useProductManyQuery({
    variables: { filter: { operator: operatorID, isActive: true } },
  });

  const formID = "booker-manual-booking-form";

  const productOptions =
    data?.productMany
      .filter(p => !p.isUpsellOnly)
      .map(p => ({
        label: p.name.en ?? p.name.fr ?? p.name.es ?? "No product name",
        value: p._id as string,
        type: p.type,
      })) ?? [];

  const mainProductID = useWatch({
    control: form.control,
    name: "mainProductID",
  });

  const handleClose = () => {
    form.reset(defaultValues);
    setScreen("build");
    onClose();
  };

  const handleReviewClick = async () => {
    const isValid = await form.trigger();

    if (isValid) {
      // Bug with react-hook-forms, when screen state changes at the same time
      // as the form is triggered, it triggers the submission of the form which
      // we don't want
      setTimeout(() => setScreen("review"), 1);
    }
  };
  return (
    <WhereaboutsModal
      isOpen={isOpen}
      onClose={handleClose}
      title={"Create manual booking"}
      description={
        "Create a manual booking on behalf of a customer and create an invoice for collecting their payment — we’ll take care of sending it to them!"
      }
      HeaderIcon={PlusCircle}
      cancelButtonProps={{ onClick: handleClose }}
      customFooter={
        <Flex
          px={"500"}
          py={"400"}
          flex={screen === "build" ? 1 : undefined}
          gap={"150"}
          justifyContent={"space-between"}
        >
          <Button
            flex={screen === "build" ? 1 : undefined}
            size={"lg"}
            type={"button"}
            visual={"outline"}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Flex flex={screen === "build" ? 1 : undefined} gap={"100"}>
            {screen === "review" && (
              <Button
                size={"lg"}
                visual={"outline"}
                onClick={() => setScreen("build")}
              >
                Back
              </Button>
            )}
            <Button
              flex={screen === "build" ? 1 : undefined}
              size={"lg"}
              form={screen === "review" ? formID : undefined}
              type={screen === "review" ? "submit" : "button"}
              disabled={mainProductID.length < 1}
              isLoading={form.formState.isSubmitting}
              Icon={screen === "build" ? ArrowRight : undefined}
              isIconRightAligned={screen === "build" ? true : undefined}
              onClick={screen === "build" ? handleReviewClick : undefined}
            >
              {screen === "build" ? "Review" : "Send invoice"}
            </Button>
          </Flex>
        </Flex>
      }
    >
      <FormProvider {...form}>
        <form onSubmit={onSubmit} id={formID}>
          <LegacyAnimatedHeight isOpen key={screen}>
            {loading ? (
              <Spinner width={"50px"} height={"50px"} margin={"60px auto"} />
            ) : (
              <>
                {error ? (
                  <Text
                    margin={"60px auto"}
                    width={"full"}
                    textAlign={"center"}
                    color={"text.error.mid"}
                  >
                    Error : {error.message}
                  </Text>
                ) : (
                  <AnimatePresence mode={"wait"}>
                    {screen === "build" ? (
                      <motion.div
                        key="build"
                        initial="exit"
                        animate="center"
                        exit="exit"
                        variants={variants}
                        transition={{
                          duration: 0.45,
                          ease: "easeInOut",
                          stiffness: 75,
                          damping: 12,
                          type: "spring",
                        }}
                      >
                        <ManualBookingFormComponents
                          productOptions={productOptions}
                        />
                      </motion.div>
                    ) : (
                      <motion.div
                        key="review"
                        initial="enter"
                        animate="center"
                        exit="exit"
                        variants={variants}
                        transition={{
                          duration: 0.45,
                          ease: "easeInOut",
                          stiffness: 75,
                          damping: 12,
                          type: "spring",
                        }}
                      >
                        <ReviewManualBooking />
                      </motion.div>
                    )}
                  </AnimatePresence>
                )}
              </>
            )}
          </LegacyAnimatedHeight>
        </form>
      </FormProvider>
      {formError.length > 0 && (
        <Text visual={"smallSemiBold"} color={"text.error.dark"} mt={"100"}>
          {formError}
        </Text>
      )}
    </WhereaboutsModal>
  );
};
