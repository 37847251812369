import { Dialog as DialogHUI } from "@headlessui/react";
import { ReactNode } from "react";
import { cn } from "../../../utils/cn";

export type DialogProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  className?: string;
};

export const Dialog = (props: DialogProps) => {
  const { isOpen, onClose, children, className } = props;

  return (
    <DialogHUI
      open={isOpen}
      onClose={() => onClose?.()}
      className="tw-relative tw-z-50"
    >
      <div
        className="tw-fixed tw-inset-0 tw-bg-[#00000080]"
        aria-hidden="true"
      />
      <div className="tw-fixed tw-inset-0 tw-flex tw-screen tw-items-center tw-justify-center tw-p-4">
        <DialogHUI.Panel
          className={cn(
            "tw-max-h-screen tw-overflow-y-auto tw-w-full md:tw-w-1/2 lg:tw-w-2/5 tw-bg-[#FAF7F3] tw-shadow-[4px_4px_0_0_#CDC9C2] tw-p-[36px]",
            className,
          )}
        >
          {children}
        </DialogHUI.Panel>
      </div>
    </DialogHUI>
  );
};
