import {
  LegacyTypography,
  TypographyVariant,
  tokens,
  useLegacyModal,
} from "@ttc3k/ttc-design-system";
import { isSameMinute } from "date-fns";
import { useMediaQuery } from "react-responsive";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { NavArrowDown, NavArrowRight } from "iconoir-react";
import { formatDate } from "../../../../utils/formatDate.ts";
import { EntityModal } from "./EntityModal.tsx";
import { DeleteModal } from "./DeleteModal.tsx";
import { FormResponse } from "../../../../../gql/generated.ts";
import { parseToUTCDate } from "../../../../utils/parseToUTCDate.ts";

type OccurrenceCardProps = {
  entity: FormResponse;
  isPast?: boolean;
  onUpdateSuccess?: () => void;
};

export const EditEntityCard = ({
  entity,
  onUpdateSuccess,
  isPast = false,
}: OccurrenceCardProps) => {
  const id = entity.request._id;
  const {
    closeModal: onCloseEditModal,
    modalOverlayRef: editModalOverlayRef,
    openModal: onOpenEditModal,
  } = useLegacyModal();
  const {
    closeModal: onCloseCancelModal,
    modalOverlayRef: cancelModalOverlayRef,
    openModal: onOpenCancelModal,
  } = useLegacyModal();
  const {
    closeModal: onCloseDeleteModal,
    modalOverlayRef: deleteModalOverlayRef,
    openModal: onOpenDeleteModal,
  } = useLegacyModal();
  const isMobileXs = useMediaQuery({ maxWidth: 450 });

  const { isActive, startDateTime, endDateTime } = entity;

  const startUTCDate = parseToUTCDate(startDateTime);
  const endUTCDate = endDateTime ? parseToUTCDate(endDateTime) : undefined;

  const isSingleDateTime = endUTCDate
    ? isSameMinute(startUTCDate, endUTCDate)
    : true;

  return (
    <>
      <div
        className={[
          "tw-py-5 tw-px-8 tw-bg-white tw-rounded-lg tw-text-left tw-border-border tw-border tw-flex tw-justify-between tw-items-center",
          (!isActive || isPast) && "tw-opacity-50",
        ].join(" ")}
      >
        <div className="tw-flex tw-flex-col tw-gap-1 tw-w-full">
          {/*<LegacyTypography*/}
          {/*  variant={TypographyVariant.tertiaryBold}*/}
          {/*  color={tokens.semantic.textSubtlest}*/}
          {/*>*/}
          {/*  Status: In Review*/}
          {/*</LegacyTypography>*/}
          <LegacyTypography
            color={tokens.semantic.bgWbtsBold}
            variant={TypographyVariant.primaryMedium}
          >
            {/*{entity.title}*/}
            Additional info
          </LegacyTypography>

          <div>
            <LegacyTypography
              color={tokens.semantic.textSubtlest}
              variant={TypographyVariant.secondaryMedium}
            >
              Submitted: {formatDate(startUTCDate)}
            </LegacyTypography>
          </div>
        </div>
        <div
          className={[
            "tw-flex tw-gap-2 ttc-button-override",
            isMobileXs && "tw-flex-col",
          ].join(" ")}
        >
          {isPast ? (
            <LegacyTypography variant={TypographyVariant.primaryMedium}>
              Archived
            </LegacyTypography>
          ) : (
            <>
              {isActive ? (
                <Menu
                  as="div"
                  className="tw-relative tw-inline-block tw-text-left"
                >
                  <div>
                    <Menu.Button className="tw-inline-flex tw-w-full tw-justify-center tw-gap-x-1.5 tw-rounded-lg tw-bg-white tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50">
                      <div className="tw-flex tw-px-3">
                        <LegacyTypography
                          variant={TypographyVariant.secondaryMedium}
                          color={tokens.semantic.colorText}
                          className="tw-border-r tw-border-r-border tw-pr-2 tw-py-2"
                        >
                          Options
                        </LegacyTypography>
                        <div className="tw-pl-2 tw-py-2 tw-flex tw-items-center">
                          <NavArrowDown
                            width={16}
                            height={16}
                            strokeWidth={1.75}
                            color={tokens.semantic.colorText}
                          />
                        </div>
                      </div>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="tw-transition tw-ease-out tw-duration-100"
                    enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                    enterTo="tw-transform tw-opacity-100 tw-scale-100"
                    leave="tw-transition tw-ease-in tw-duration-75"
                    leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                    leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                  >
                    <Menu.Items className="tw-absolute tw-right-0 tw-z-10 tw-mt-2 tw-w-36 tw-origin-top-right tw-rounded-lg tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-border focus:tw-outline-none tw-overflow-hidden tw-py-2">
                      <div className="py-1">
                        <Menu.Item>
                          <button
                            className="tw-px-4 tw-py-2 tw-w-full tw-bg-transparent hover:tw-bg-gray-100 tw-transition tw-flex tw-justify-between tw-items-center"
                            onClick={onOpenEditModal}
                          >
                            <LegacyTypography
                              variant={TypographyVariant.secondaryRegular}
                              color={tokens.semantic.colorText}
                            >
                              View
                            </LegacyTypography>
                            <NavArrowRight
                              width={16}
                              height={16}
                              strokeWidth={1.75}
                              className="tw-text-iconSubtle"
                            />
                          </button>
                        </Menu.Item>
                        <Menu.Item>
                          <button
                            className="tw-px-4 tw-py-2 tw-w-full tw-bg-transparent hover:tw-bg-gray-100 tw-transition tw-flex tw-justify-between tw-items-center"
                            onClick={onOpenDeleteModal}
                          >
                            <LegacyTypography
                              variant={TypographyVariant.secondaryRegular}
                              color={tokens.semantic.textError}
                            >
                              Delete
                            </LegacyTypography>
                            <NavArrowRight
                              width={16}
                              height={16}
                              strokeWidth={1.75}
                              className="tw-text-iconSubtle"
                            />
                          </button>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : (
                <LegacyTypography
                  variant={TypographyVariant.primaryMedium}
                  color={tokens.semantic.textError}
                >
                  COMPLETE
                </LegacyTypography>
              )}
            </>
          )}
        </div>
      </div>
      <EntityModal
        modalOverlayRef={editModalOverlayRef}
        onModalClose={onCloseEditModal}
        data={entity}
        onUpdateSuccess={onUpdateSuccess}
      />
      <DeleteModal
        modalOverlayRef={deleteModalOverlayRef}
        onModalClose={onCloseDeleteModal}
        formResponseId={id}
      />
    </>
  );
};
