import { ReactNode } from "react";
import { LegacyTypography, TypographyVariant } from "@ttc3k/ttc-design-system";
import { cn } from "../../../utils/cn";

export type TagProps = {
  label: string;
  variant?: "warning" | "info";
  endIcon?: ReactNode;
};

export const Tag = ({ label, variant = "info", endIcon }: TagProps) => {
  return (
    <div
      className={cn(
        "tw-flex tw-items-center tw-px-[12px] tw-py-[2px] tw-rounded-full tw-text-white tw-gap-x-[6px] tw-font-[600] tw-w-fit",
        {
          "tw-bg-[#2E92EF]": variant === "info",
          "tw-bg-[#E08D11]": variant === "warning",
        },
      )}
    >
      <LegacyTypography variant={TypographyVariant.secondaryRegular}>
        {label}
      </LegacyTypography>
      <div>{endIcon}</div>
    </div>
  );
};
