import { SectionHeader, Select, Text } from "@ttc3k/trekker";
import { DollarCircle } from "iconoir-react";
import { useFormContext, useWatch } from "react-hook-form";
import { Flex } from "styled-system/jsx";
import { SettingsFormValues } from "../hooks";

const canadianTaxOptions = [
  { label: "(0%) No tax - tax exempt", value: "0" },
  {
    label:
      "(5%) Alberta, British Columbia, Manitoba, Northwest Territories, Nunavut, Quebec, Saskatchewan, and Yukon",
    value: "0.05",
  },
  { label: "(13%) Ontario", value: "0.13" },
  {
    label:
      "(15%) New Brunswick, Newfoundland and Labrador, Nova Scotia, and Prince Edward Island",
    value: "0.15",
  },
];

export const TaxSection = () => {
  const { control, setValue } = useFormContext<SettingsFormValues>();
  const watchedTax = useWatch({ control, name: "tax" });

  const handleTaxChange = (tax: string) => {
    setValue("tax", Number(tax));
  };
  return (
    <Flex flexDir={"column"} gap={"300"} width={"full"}>
      <SectionHeader text="Taxes" Icon={DollarCircle} divider />
      <Text color={"text.mid"}>
        It is your responsibility to ensure you’re collecting the right taxes
        based on your business’s location. Select your province below and we’ll
        set the appropriate tax rate.
      </Text>
      <Select.Root
        items={canadianTaxOptions}
        value={[watchedTax?.toString()]}
        placeholder={"Select tax rate"}
        css={{
          "& [data-scope='select'][data-part='trigger']": {
            bg: "white",
            textAlign: "left",
          },
        }}
        onValueChange={({ value }: { value: string[] }) =>
          handleTaxChange(value[0])
        }
      >
        {canadianTaxOptions.map(v => (
          <Select.Item key={v.value} item={v} />
        ))}
      </Select.Root>
    </Flex>
  );
};
