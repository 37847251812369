import { ActionCard, CollapsableStep } from "booker/shared";
import { useState } from "react";
import { Flex } from "styled-system/jsx";
import { DatesPage, GuestsPage } from "booker/features/OverlayPages";
import { useBookerContext } from "booker/contexts";
import {
  getDatesSummaryString,
  getGuestSummaryString,
} from "booker/shared/utils";
import { EnumProductType } from "gql/generated";
import { useDatesAndGuests } from "./useDatesAndGuests";

export const DatesAndGuests = () => {
  const [isDatesOpen, setIsDatesOpen] = useState(false);
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);
  const {
    complete,
    onUpdateDates,
    onUpdateGuests,
    onUpdateGuestNames,
    dates,
    guests,
    guestNames,
    completeDates,
    completeGuests,
    resetDates,
    resetGuests,
  } = useDatesAndGuests(isGuestsOpen);
  const { product } = useBookerContext();

  if (product?.type === EnumProductType.Fee) {
    return null;
  }

  return (
    <CollapsableStep
      title={"Dates & Guests"}
      complete={Object.values(complete).every(v => !!v)}
    >
      <Flex flexDir="column" gap="150">
        <ActionCard
          mainText="Dates"
          subText={getDatesSummaryString(dates)}
          visual={complete.dates ? "success" : "neutral"}
          onClick={() => setIsDatesOpen(true)}
        />
        <DatesPage
          open={isDatesOpen}
          onClose={() => setIsDatesOpen(false)}
          selectedDates={dates}
          onUpdateDates={onUpdateDates}
          completeDates={completeDates}
          resetDates={resetDates}
        />
        <ActionCard
          mainText="Guests"
          subText={getGuestSummaryString(guests, product?.pricing.tiers ?? [])}
          visual={complete.guests ? "success" : "neutral"}
          onClick={() => setIsGuestsOpen(true)}
        />
        <GuestsPage
          open={isGuestsOpen}
          onClose={() => setIsGuestsOpen(false)}
          guests={guests}
          guestNames={guestNames}
          onUpdateGuests={onUpdateGuests}
          onUpdateGuestNames={onUpdateGuestNames}
          completeGuests={completeGuests}
          resetGuests={resetGuests}
        />
      </Flex>
    </CollapsableStep>
  );
};
