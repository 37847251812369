import { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { ReservationPage } from "core/components/shared/ReservationPage";
import { useLocation, useParams } from "react-router-dom";
import { useOrderByIdQuery } from "gql/generated.ts";
import { LinkButton, Spinner, Text } from "@ttc3k/trekker";
import { handleBeaconClick } from "core/utils/helpScout.ts";
import { useAppContext } from "core/components/apps/PreferredLayout.tsx";
import { ReservationMenu } from "./components";

export default function CalendarLeafOrderDetails() {
  const { setTitle, setCrumbs, setDescription, setActionSection } =
    useAppContext();
  const { id, spaceId } = useParams();
  const location = useLocation();

  const { data, loading, error } = useOrderByIdQuery({
    variables: { id: id ?? "" },
  });

  useEffect(() => {
    setTitle(data?.orderById?.customerContact.name ?? `Order ${id}`);
    setDescription("");
    setCrumbs(prev => [
      ...prev.slice(0, 2),
      {
        space: "Calendar",
        name: "Calendar",
        href: `/app/booker/${spaceId}/calendar`,
      },
      {
        space: "Calendar",
        name: "Reservation",
        href: location.pathname,
      },
    ]);
    if (data?.orderById) {
      setActionSection(<ReservationMenu order={data.orderById} />);
    }
  }, [
    setCrumbs,
    setTitle,
    data?.orderById,
    id,
    setDescription,
    spaceId,
    location.pathname,
    setActionSection,
  ]);

  if (loading) {
    return <Spinner width={"50px"} height={"50px"} margin={"60px auto"} />;
  }
  if (error) {
    return (
      <Text
        margin={"60px auto"}
        visual={"bodyMedium"}
        color={"text.error.mid"}
        width={"full"}
        textAlign={"center"}
      >
        Error : {error?.message}
      </Text>
    );
  }

  if (!data?.orderById) {
    return (
      <Text
        margin={"60px auto"}
        visual={"bodyMedium"}
        color={"text.dark"}
        width={"full"}
        textAlign={"center"}
      >
        Cannot find order details, try refreshing the page or&nbsp;
        <LinkButton onClick={handleBeaconClick}>contact support</LinkButton>
      </Text>
    );
  }

  return (
    <AnimatePresence>
      <ReservationPage order={data.orderById} />
    </AnimatePresence>
  );
}
