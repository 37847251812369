import { motion } from "framer-motion";
import { variants } from "../onboarding/shared.ts";

export default function SlideInPage({ children }) {
  return (
    <motion.div
      className={
        "tw-flex tw-flex-col tw-justify-center tw-gap-2 tw-text-center"
      }
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
    >
      {children}
    </motion.div>
  );
}
