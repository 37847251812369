import {
  Button,
  Heading,
  Icon,
  Modal,
  Text,
  ButtonProps,
} from "@ttc3k/trekker";
import { Box, Divider, Flex } from "styled-system/jsx";
import {
  ForwardRefExoticComponent,
  PropsWithChildren,
  RefAttributes,
  SVGProps,
} from "react";

type WhereaboutsModalProps = PropsWithChildren & {
  isOpen: boolean;
  onClose: () => void;
  HeaderIcon?: ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, "ref"> & RefAttributes<SVGSVGElement>
  >;
  title?: string;
  description?: string;
  saveButtonProps?: ButtonProps;
  saveButtonCopy?: string;
  cancelButtonProps?: ButtonProps;
  cancelButtonCopy?: string;
  size?: "md" | "lg";
  customFooter?: JSX.Element;
  disableBodyPadding?: boolean;
};

export const WhereaboutsModal = ({
  isOpen,
  onClose,
  HeaderIcon,
  title,
  description,
  saveButtonProps,
  saveButtonCopy = "Save",
  cancelButtonProps,
  cancelButtonCopy = "Cancel",
  size = "md",
  customFooter,
  disableBodyPadding,
  children,
}: WhereaboutsModalProps) => {
  return (
    <Modal
      open={isOpen}
      placement={"centerPane"}
      onOpenChange={onClose}
      closeTriggerVisual={"dark"}
      noMaxH
      portal
    >
      <Box
        bg={"bg.wa.light"}
        w={{
          base: "full",
          md: size === "md" ? "600px" : "800px",
          lg: size === "md" ? "600px" : "1000px",
          xl: size === "md" ? "600px" : "1000px",
        }}
        h={"full"}
        minH={"300px"}
      >
        {/* Header */}
        <Flex
          bg={"white"}
          p={"500"}
          flexDir={"column"}
          gap={"200"}
          borderBottomWidth={"1px"}
          borderBottomColor={"border.light"}
          borderBottomStyle={"solid"}
        >
          <Flex alignItems={"center"} gap={"150"}>
            {HeaderIcon && <Icon Element={HeaderIcon} />}
            <Heading size={"h5"} color={"text.dark"}>
              {title}
            </Heading>
          </Flex>

          {description && <Text color={"text.mid"}>{description}</Text>}
        </Flex>

        {/* Body */}
        <Box
          padding={disableBodyPadding ? 0 :"500"}
          maxH={"59vh"}
          overflowY={"auto"}
          overflowX={"hidden"}
          id={"whereabouts-modal-content-wrapper"}
        >
          {children}
        </Box>

        {/* Footer */}
        <Divider borderColor={"border.light"} />
        {customFooter ? (
          customFooter
        ) : (
          <Flex px={"500"} py={"400"} flex={1} gap={"150"} justifyContent={"space-between"}>
            <Button
              flex={1}
              size={"lg"}
              type={"button"}
              visual={"outline"}
              {...cancelButtonProps}
            >
              {cancelButtonCopy}
            </Button>
            <Button flex={1} size={"lg"} {...saveButtonProps}>
              {saveButtonCopy}
            </Button>
          </Flex>
        )}
      </Box>
    </Modal>
  );
};
