// @ts-nocheck
import {
  tokens,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { gql, useQuery } from "@apollo/client";
import { AnimatePresence, motion } from "framer-motion";
import { useAuth0 } from "@auth0/auth0-react";
import { HomeAlt } from "iconoir-react";
import { Loader } from "../../components/Loader.tsx";
import NotificationsCard from "./NotificationsCard";
import { nanoid } from "nanoid";

export const GET_NOTIFICATIONS = gql`
  query Notifications($tenants: String) {
    notifications(tenants: $tenants) {
      _id
      title
      tenant
      message
      createdAt
      type
      button {
        label
        path
      }
    }
  }
`;

const GET_TEAM = gql`
  query GetTeamInfo($name: String) {
    getTeamInfo(name: $name) {
      dmo
    }
  }
`;

export default function Notifications() {
  const { user } = useAuth0();
  const { loading, error, data } = useQuery(GET_NOTIFICATIONS, {
    variables: { tenants: JSON.stringify(user?.whereaboutsTenants) },
  });

  const {
    data: tenantData,
    error: tenantError,
    loading: tenantLoading,
  } = useQuery(GET_TEAM, {
    variables: {
      name: user?.whereaboutsTenants[0],
    },
  });

  if (loading || tenantLoading) {
    return (
      <div className="tw-absolute tw-top-1/2 tw-left-1/2">
        <Loader type="LOGO" />
      </div>
    );
  }

  if (error) {
    return <p>Opps, there was a problem loading your notifications</p>;
  }
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-pt-4 tw-gap-8 md:tw-px-28 tw-pb-24 max-sm:tw-px-5">
      <div className={"tw-flex tw-flex-col tw-text-center tw-gap-3"}>
        <div className="tw-flex tw-w-full tw-justify-center tw-align-items">
          <HomeAlt width={36} height={36} strokeWidth={2} />
        </div>
        <LegacyTypography
          as="h3"
          variant={TypographyVariant.h3}
          color={tokens.semantic.colorText}
        >
          Latest Updates
        </LegacyTypography>
        {/*<LegacyTypography*/}
        {/*  variant={TypographyVariant.primaryMedium}*/}
        {/*  color={tokens.semantic.textSubtle}*/}
        {/*>*/}
        {/*  Keep an eye on this space for updates and actions items.*/}
        {/*</LegacyTypography>*/}
      </div>
      <AnimatePresence>
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className={"tw-flex tw-flex-col tw-gap-3 tw-max-w-[700px]"}>
            {tenantData?.getTeamInfo.dmo ===
              "ohto|660dfd13dd810ca68f35231f" && (
              <NotificationsCard
                key={nanoid()}
                title={"New Online Courses Are Available!"}
                message={"OHTO just launched 4 new courses in Learner"}
                button={{ label: "Open Learner", path: "/app/learner/courses" }}
                color={"tw-bg-blue-400"}
              />
            )}
            {data.notifications.map(n => (
              <NotificationsCard
                key={n._id}
                title={n.title}
                message={n.message}
                button={n.button}
                createdAt={n.createdAt}
              />
            ))}
            {/*<NotificationsCard*/}
            {/*  key={5}*/}
            {/*  title={"Events!"}*/}
            {/*  message={"Submit events to the Visit the County Events Calendar"}*/}
            {/*  button={{ label: "Get Started", path: "/app/events" }}*/}
            {/*/>*/}
            <NotificationsCard
              key={3}
              title={"Welcome to Whereabouts!"}
              message={"Please click here to watch a quick orientation video."}
              button={{ label: "Watch", path: "/app/help" }}
            />
            <NotificationsCard
              key={1}
              title={"Thank you for registering!"}
              message={"Your account has been created."}
            />
          </div>
        </motion.main>
      </AnimatePresence>
    </div>
  );
}
