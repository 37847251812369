import { useCallback, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Outlet } from "react-router-dom";

export function Canvas() {
  const modalRef = useRef();
  const initial = { scale: 0.8, y: "100%", opacity: 1 };
  const animate = { scale: 1, y: 0, opacity: 1 };
  const exit = { opacity: 1, scale: 0.8, y: "-100%" };
  const transition = { duration: 0.3 };
  //
  // useEffect(() => {
  //   const observerRefValue = modalRef.current;
  //   disableBodyScroll(observerRefValue);
  //
  //   return () => {
  //     if (observerRefValue) {
  //       enableBodyScroll(observerRefValue);
  //     }
  //   };
  // }, []);

  const disableBodyScroll = useCallback(() => {
    document.body.style.overflow = "hidden";
  }, []);

  const enableBodyScroll = useCallback(() => {
    document.body.style.overflow = "auto";
  }, []);

  useEffect(() => {
    disableBodyScroll();

    return () => {
      enableBodyScroll();
    };
  }, [disableBodyScroll, enableBodyScroll]);

  return (
    <>
      <div className="tw-h-screen tw-w-screen tw-fixed tw-z-1 tw-left-0 tw-right-0 tw-bottom-0">
        <motion.main
          className="tw-fixed tw-z-10 tw-h-[calc(100%-78px)] tw-max-w-[1400px] tw-w-full tw-left-0 tw-right-0 tw-bottom-0 tw-mx-auto tw-px-12 tw-flex tw-justify-center tw-items-center max-sm:tw-px-3.5"
          initial={initial}
          animate={animate}
          exit={exit}
          transition={transition}
        >
          <div
            className="tw-relative tw-flex tw-flex-col tw-h-full tw-w-full tw-bg-bgWbtsCanvas tw-rounded-t-3xl tw-overflow-hidden tw-border-border tw-border-[1px] tw-border-solid"
            ref={modalRef}
          >
            <Outlet />
          </div>
        </motion.main>
      </div>
    </>
  );
}
