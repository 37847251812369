export function insertAndAfterLastComma(inputString) {
  // Split the string into an array using commas as the separator
  const words = inputString.split(",");

  // Check if there are at least two words separated by commas
  if (words.length >= 2) {
    // Remove the last word (after the last comma)
    const lastWord = words.pop().trim();

    // Join the remaining words back together with commas and insert "and" before the last word
    const result = words.join(", ") + " and " + lastWord;

    return result;
  } else {
    // If there are less than two words, return the original string
    return inputString;
  }
}
