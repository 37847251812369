import { WhereaboutsModal } from "core/components/shared/WhereaboutsModal";
import {
  OrderByIdQuery,
  useInventoryByProductBookedDatesQuery,
} from "gql/generated";
import { EditPencil } from "iconoir-react";
import { FormProvider, useWatch } from "react-hook-form";
import { Box, Flex } from "styled-system/jsx";
import { useState } from "react";
import { toastFactory, Text, Calendar } from "@ttc3k/trekker";
import { convertDateRangeToCalendarValue } from "booker/features/OverlayPages/DatesPage/helpers";
import { formatDate } from "core/utils/formatDate";
import { useEditDatesForm } from "./useEditDatesForm";

const DATE_PATTERN_SHORT_MONTH = "LLL d, yyyy";

type EditDatesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  order: OrderByIdQuery["orderById"];
};

export const EditDatesModal = ({
  isOpen,
  onClose,
  order,
}: EditDatesModalProps) => {
  const [formError, setFormError] = useState("");
  const { form, onSubmit, defaultValues } = useEditDatesForm({
    order,
    onSuccess: () => {
      form.reset(defaultValues);
      setFormError("");
      onClose();
      toastFactory.create({
        title: "Success!",
        description: `${order?._id} booking was updated successfully`,
      });
    },
    onError: m => setFormError(m),
  });
  const [focusValue, setFocusValue] = useState(defaultValues?.start ?? "");

  const formID = "edit-reservation-dates";

  const handleClose = () => {
    form.reset(defaultValues);
    setFormError("");
    onClose();
    setFocusValue(defaultValues?.start ?? "");
  };

  const mainProductID = order?.purchasedProducts[0]?.product._id;

  const { data: invData } = useInventoryByProductBookedDatesQuery({
    variables: { productId: mainProductID ?? "" },
  });

  const { control, formState, setValue } = form;

  const watchedStartDate = useWatch({ control, name: "start" });
  const watchedEndDate = useWatch({ control, name: "end" });

  const handleDateValueChange = (dates: string[]) => {
    if (dates.length > 1) {
      setValue("start", dates[0]);
      setValue("end", dates[1]);
    } else if (dates.length === 1) {
      setValue("start", dates[0]);
      setValue("end", "");
    }
  };

  const handleClearDates = () => {
    setValue("start", "");
    setValue("end", "");
  };

  const isStartDateUpdated =
    (defaultValues?.start ?? "") !== watchedStartDate &&
    watchedStartDate.length > 0;
  const isEndDateUpdated =
    (defaultValues?.end ?? "") !== watchedEndDate && watchedEndDate.length > 0;
  const isDatesUpdated = isStartDateUpdated || isEndDateUpdated;

  const isSaveDisabled =
    watchedStartDate.length < 1 || watchedEndDate.length < 1 || !isDatesUpdated;

  const validationError =
    formState.errors["end"]?.message || formState.errors["start"]?.message;
  return (
    <WhereaboutsModal
      isOpen={isOpen}
      onClose={handleClose}
      title={"Edit reservation dates"}
      description={`Edit reservation dates for booking ${order?._id}`}
      HeaderIcon={EditPencil}
      cancelButtonProps={{ type: "button", onClick: handleClose }}
      saveButtonProps={{
        form: formID,
        type: "submit",
        isLoading: form.formState.isSubmitting,
        disabled: isSaveDisabled,
      }}
    >
      <FormProvider {...form}>
        <form onSubmit={onSubmit} id={formID}>
          <Flex flexDir={"column"} gap={"250"}>
            <Flex
              width={"full"}
              flexDir={{ base: "column-reverse", sm: "row" }}
              divideX={{ base: "0", sm: "1px" }}
              divideColor={"border.light"}
              justifyContent={"space-between"}
            >
              <Box
                pr={{ base: 0, sm: "300" }}
                pt={{ base: "300", sm: 0 }}
                minW={"300px"}
                w={"full"}
                h={{ base: "380px", sm: "330px" }}
                borderTopWidth={{ base: "1px", sm: 0 }}
                borderColor={"border.light"}
              >
                <Calendar
                  unavailableDatesISO={
                    invData?.inventoryDatesByProduct?.bookedDatesISO
                  }
                  checkOutOnlyDatesISO={
                    invData?.inventoryDatesByProduct?.checkOutOnlyDatesISO
                  }
                  onValueChange={d => handleDateValueChange(d.valueAsString)}
                  value={convertDateRangeToCalendarValue({
                    startDateISO: watchedStartDate,
                    endDateISO: watchedEndDate,
                  })}
                  onClearDatesClick={handleClearDates}
                  focusedValue={focusValue}
                  onFocusChange={d => setFocusValue(d.focusedValue.toString())}
                  displayJumpToToday
                />
              </Box>
              <Flex
                pl={{ base: 0, sm: "300" }}
                pb={{ base: "300", sm: 0 }}
                w={"180px"}
                flexDir={"column"}
                gap={"250"}
              >
                <Flex flexDir={"column"} gap={"25"}>
                  <Text visual={"smallMedium"} color={"text.mid"}>
                    Start Date
                  </Text>
                  {isStartDateUpdated && (
                    <Text visual={"bodySemiBold"} color={"text.dark"}>
                      {formatDate(watchedStartDate, DATE_PATTERN_SHORT_MONTH)}
                    </Text>
                  )}
                  <Text
                    visual={isStartDateUpdated ? "bodyRegular" : "bodySemiBold"}
                    color={isStartDateUpdated ? "text.mid" : "text.dark"}
                    textDecoration={
                      isStartDateUpdated ? "line-through" : undefined
                    }
                  >
                    {formatDate(defaultValues?.start, DATE_PATTERN_SHORT_MONTH)}
                  </Text>
                </Flex>
                <Flex flexDir={"column"} gap={"25"}>
                  <Text visual={"smallMedium"} color={"text.mid"}>
                    End Date
                  </Text>
                  {isEndDateUpdated && (
                    <Text visual={"bodySemiBold"} color={"text.dark"}>
                      {formatDate(watchedEndDate, DATE_PATTERN_SHORT_MONTH)}
                    </Text>
                  )}
                  <Text
                    visual={isEndDateUpdated ? "bodyRegular" : "bodySemiBold"}
                    color={isEndDateUpdated ? "text.mid" : "text.dark"}
                    textDecoration={
                      isEndDateUpdated ? "line-through" : undefined
                    }
                  >
                    {formatDate(defaultValues?.end, DATE_PATTERN_SHORT_MONTH)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            {(formError.length > 0 || validationError) && (
              <Text color={"text.error.mid"} visual={"smallMedium"}>
                {formError ?? validationError}
              </Text>
            )}
          </Flex>
        </form>
      </FormProvider>
    </WhereaboutsModal>
  );
};
