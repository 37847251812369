import { Button, Card, Icon, Spinner, Text } from "@ttc3k/trekker";
import { formatPrice } from "core/utils/formatPrice";
import {
  NewOperator,
  OperatorManyQuery,
  useOperatorTotalSalesQuery,
  useProductManyQuery,
} from "gql/generated";
import { MediaImage, NavArrowRight } from "iconoir-react";
import pluralize from "pluralize";
import { useNavigate } from "react-router-dom";
import { Center, Flex, styled } from "styled-system/jsx";

type OperatorCardProps = {
  operator: OperatorManyQuery["operatorMany"][0] | NewOperator;
};

export const OperatorCard = ({ operator }: OperatorCardProps) => {
  const navigate = useNavigate();
  const { data } = useProductManyQuery({
    variables: { filter: { operator: operator._id, isActive: true } },
  });
  const numOfProducts = data?.productMany ? data.productMany.length : 0;
  const { data: totalSalesData, loading } = useOperatorTotalSalesQuery({
    variables: { id: operator._id },
  });
  const handleSelectOperator = () => {
    navigate(`${operator._id}/products`);
  };
  return (
    <Card.Surface bg={"white"} boxShadow={"25"}>
      <Card.Hero maxH={"220px"} minW={"full"}>
        {operator.images.length > 0 ? (
          <styled.img
            src={operator.images[0]?.full_url}
            alt={operator.images[0]?.caption?.en ?? ""}
            width={"full"}
            height={"220px"}
            objectFit={"cover"}
            objectPosition={"center"}
          />
        ) : (
          <Center w={"full"} h={"full"} bg={"bg.wa.light"}>
            <Icon Element={MediaImage} size={"lg"} />
          </Center>
        )}
      </Card.Hero>
      <Card.Body>
        <Card.BodyContent>
          <Card.Title
            mainText={operator.name}
            subText={pluralize("product", numOfProducts, true)}
          />
          {numOfProducts < 1 ? (
            <Flex width={"full"} justifyContent={"flex-end"}>
              <Button
                visual={"accent"}
                size={"sm"}
                h={"min-content"}
                py={"5px"}
                onClick={handleSelectOperator}
              >
                Create product
              </Button>
            </Flex>
          ) : (
            <Button
              Icon={NavArrowRight}
              visual={"outline"}
              h={"min-content"}
              padding={"8px"}
              onClick={handleSelectOperator}
            />
          )}
        </Card.BodyContent>
        <Card.BodyContent>
          {loading ? (
            <Flex gap={"75"} alignItems={"center"}>
              <Text visual={"bodyMedium"} color={"text.dark"}>
                Total sales:
              </Text>
              <Spinner width={"16px"} height={"16px"} />
            </Flex>
          ) : (
            <Text visual={"bodyMedium"} color={"text.dark"}>
              Total sales: $
              {formatPrice(totalSalesData?.operatorTotalSales ?? 0)}
            </Text>
          )}
        </Card.BodyContent>
      </Card.Body>
    </Card.Surface>
  );
};
