import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { styled } from "styled-system/jsx";
import { PointLocation, AddressLocation } from "gql/generated";
import _ from "lodash";

type MapIndicatorProps = {
  coordinates: PointLocation["coordinates"] | AddressLocation["coordinates"];
};

const API_KEY = import.meta.env.VITE_GOOGLE_MAPS_KEY;

const BareMapBlock = ({ coordinates, ...rest }: MapIndicatorProps) => {
  if (!API_KEY) {
    throw new Error("Missing Google API key");
  }

  const formattedCoordinates = _.compact(coordinates);
  const [latitude, longitude] = formattedCoordinates;
  const position = {
    lat: latitude,
    lng: longitude,
  };

  return (
    <APIProvider apiKey={API_KEY}>
      <Map
        defaultCenter={position}
        defaultZoom={12}
        disableDefaultUI={true}
        {...rest}
      >
        <Marker position={position} />
      </Map>
    </APIProvider>
  );
};

export const MapBlock = styled(BareMapBlock);
