import { Table } from "@ttc3k/trekker";
import { styled } from "styled-system/jsx";

export const OrderTableRow = styled(Table.Row, {
  base: {
    display: "grid",
    gridTemplateColumns: "30px 98px 1fr 1fr 100px 110px 110px 40px",
    width: "full",
    columnGap: "400",
    py: "150",
  },
});
