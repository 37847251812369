import { Button, Card } from "@ttc3k/trekker";
import { EditPencil, NavArrowRight } from "iconoir-react";
import { cva } from "styled-system/css";
import { styled } from "styled-system/jsx";
import { RecipeVariantProps } from "styled-system/types";

const actionCardSurfaceStyles = cva({
  base: {
    width: "full",
    outline: "0 transparent solid",
    transitionDuration: "normal",
    transitionProperty: "border, outline",
    transitionTimingFunction: "default",
    boxShadow: "none",
  },
  variants: {
    visual: {
      neutral: {},
      success: {
        borderColor: "border.success",
        outlineWidth: "1px",
        outlineColor: "border.success",
      },
    },
  },
  defaultVariants: {
    visual: "neutral",
  },
});

const ActionCardSurface = styled(Card.Surface, actionCardSurfaceStyles);

type ActionCardVariantProps = NonNullable<
  RecipeVariantProps<typeof actionCardSurfaceStyles>
>;

type ActionCardProps = ActionCardVariantProps & {
  mainText: string;
  subText: string;
  isEditable?: boolean;
  onClick?: () => void;
};

export const ActionCard = ({
  mainText,
  subText,
  visual,
  onClick,
  isEditable = true,
}: ActionCardProps) => {
  const Icon = visual === "success" && isEditable ? EditPencil : NavArrowRight;
  return (
    <ActionCardSurface visual={visual}>
      <Card.Body bg="white" py={"250"}>
        <Card.BodyContent alignItems="center">
          <Card.Title mainText={mainText} subText={subText} />
          <Button Icon={Icon} visual="outline" onClick={onClick} />
        </Card.BodyContent>
      </Card.Body>
    </ActionCardSurface>
  );
};
