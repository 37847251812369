import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  MatomoEvent,
  useMatomoAnalyticsContext,
} from "@ttc3k/ttc-bkr-analytics";
import {
  EnumPriceTierType,
  EnumProductType,
  PurchasedProductGuestsPerTier,
  useBookerCartPriceLazyQuery,
} from "gql/generated";
import { StripeCardElement } from "@stripe/stripe-js";
import { useBookerContext } from "../BookerContextProvider";
import { adjustCartPricing } from "./helpers";

export type CartItem = {
  productName: string;
  productID: string;
  productType: EnumProductType;
  startDateISO: string;
  endDateISO: string;
  total: number;
  guestsPerTier: PurchasedProductGuestsPerTier[];
};

type CustomerContact = {
  name: string;
  email: string;
  phone: string;
  complete: boolean;
};

export type CartContextValues = {
  subTotal: number;
  tax: number;
  grandTotal: number;
  cart: CartItem[];
  customer: CustomerContact;
  stripeCardElement: StripeCardElement | undefined;
  paymentMethodID: string;
  acceptedTerms: boolean;
  guestNames: string[];
  addCartItem: (cartItem: CartItem) => void;
  updateCartItem: (productID: string, updatedProps: Partial<CartItem>) => void;
  removeCartItem: (productID: string) => void;
  updateCustomer: (cust: CustomerContact) => void;
  incompleteCustomer: (cust?: Partial<CustomerContact>) => void;
  updateStripeCardElement: (card?: StripeCardElement) => void;
  updatePaymentMethodID: (id: string) => void;
  acceptTerms: () => void;
  updateGuestNames: (guestNames: string[]) => void;
  resetCartValuesToDefault: () => void;
};

const defaultValues = {
  customer: {
    name: "",
    email: "",
    phone: "",
    complete: false,
  },
  totals: { subTotal: 0, tax: 0, grandTotal: 0 },
  cart: [],
  stripeCardElement: undefined,
  paymentMethodID: "",
  acceptedTerms: false,
  guestNames: [""],
};

const context = React.createContext<CartContextValues | null>(null);

export const CartContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { product, booker } = useBookerContext();

  const [cart, setCart] = useState<CartContextValues["cart"]>(
    defaultValues.cart,
  );
  const [customer, setCustomer] = useState<CartContextValues["customer"]>(
    defaultValues.customer,
  );
  const [totals, setTotals] = useState(defaultValues.totals);
  const [stripeCardElement, setStripeCardElement] = useState<
    CartContextValues["stripeCardElement"]
  >(defaultValues.stripeCardElement);
  const [paymentMethodID, setPaymentMethodID] = useState<
    CartContextValues["paymentMethodID"]
  >(defaultValues.paymentMethodID);
  const [acceptedTerms, setAcceptedTerms] = useState<
    CartContextValues["acceptedTerms"]
  >(defaultValues.acceptedTerms);
  const [guestNames, setGuestNames] = useState<CartContextValues["guestNames"]>(
    defaultValues.guestNames,
  );
  const [getCartPrices] = useBookerCartPriceLazyQuery();

  const { pushMatomoEvent } = useMatomoAnalyticsContext();

  const resetCartValuesToDefault = useCallback(() => {
    setCart(defaultValues.cart);
    setCustomer(defaultValues.customer);
    setTotals(defaultValues.totals);
    setStripeCardElement(defaultValues.stripeCardElement);
    setPaymentMethodID(defaultValues.paymentMethodID);
    setAcceptedTerms(defaultValues.acceptedTerms);
    setGuestNames(defaultValues.guestNames);
  }, []);

  const updatePaymentMethodID = (id: string) => {
    setPaymentMethodID(id);
  };

  const acceptTerms = () => {
    setAcceptedTerms(true);
  };

  const updateStripeCardElement = (card?: StripeCardElement) => {
    setStripeCardElement(card);
  };

  const updateCustomer = (cust: CustomerContact) => {
    setCustomer(cust);
  };
  const incompleteCustomer = (cust?: Partial<CustomerContact>) => {
    setCustomer(prev => ({ ...prev, ...cust, complete: false }));
  };

  const addCartItem = useCallback(
    (cartItem: CartItem) => {
      pushMatomoEvent({
        event: MatomoEvent.ProductAdded,
        productID: cartItem.productID,
      });
      const newCart = [...cart, cartItem];

      adjustCartPricing({
        bookerID: booker?._id,
        getCartPrices,
        newCart,
        setCart,
        setTotals,
      });
    },
    [booker?._id, cart, getCartPrices, pushMatomoEvent],
  );

  const updateCartItem = useCallback(
    (productID: string, updatedProps: Partial<CartItem>) => {
      const newCart = cart.map(item => {
        if (item.productID === productID) {
          return { ...item, ...updatedProps };
        }
        return item;
      });

      adjustCartPricing({
        bookerID: booker?._id,
        getCartPrices,
        newCart,
        setCart,
        setTotals,
      });
    },
    [booker?._id, cart, getCartPrices],
  );

  const removeCartItem = useCallback(
    (productID: string) => {
      pushMatomoEvent({
        event: MatomoEvent.ProductRemoved,
        productID: productID,
      });
      const newCart = cart.filter(item => item.productID !== productID);

      adjustCartPricing({
        bookerID: booker?._id,
        getCartPrices,
        newCart,
        setCart,
        setTotals,
      });
    },
    [booker?._id, cart, getCartPrices, pushMatomoEvent],
  );

  const updateGuestNames = (names: string[]) => {
    setGuestNames(names);
  };

  /** Initialize the cart with the main product */
  useEffect(() => {
    if (product && !cart.find(item => item.productID === product._id)) {
      addCartItem({
        productID: product._id,
        productName: product.name.en ?? "",
        productType: product.type,
        endDateISO: "",
        startDateISO: "",
        guestsPerTier: product.pricing.tiers.map(tier => ({
          count: tier?.type === EnumPriceTierType.Adult ? 1 : 0,
          tierId: tier?._id,
        })) as PurchasedProductGuestsPerTier[],
        total: 0,
      });
    }
  }, [product, addCartItem, cart]);

  const contextValue: CartContextValues = useMemo(
    () => ({
      cart,
      customer,
      stripeCardElement,
      paymentMethodID,
      acceptedTerms,
      subTotal: totals.subTotal,
      tax: totals.tax,
      grandTotal: totals.grandTotal,
      addCartItem,
      updateCartItem,
      removeCartItem,
      updateCustomer,
      incompleteCustomer,
      updateStripeCardElement,
      updatePaymentMethodID,
      acceptTerms,
      guestNames,
      updateGuestNames,
      resetCartValuesToDefault,
    }),
    [
      cart,
      customer,
      stripeCardElement,
      paymentMethodID,
      acceptedTerms,
      totals.subTotal,
      totals.tax,
      totals.grandTotal,
      addCartItem,
      updateCartItem,
      removeCartItem,
      guestNames,
      resetCartValuesToDefault,
    ],
  );

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export function useCartContext() {
  const cartContext = React.useContext(context);

  if (!cartContext) {
    throw new Error("useCartContext must be used within a CartContextProvider");
  }

  return cartContext;
}
