import * as stripe from "@stripe/stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import { token } from "styled-system/tokens";
import { styled } from "styled-system/jsx";

type StripeCardElementProps = {
  onChange?: (payload: stripe.StripeCardElementChangeEvent) => void;
};

/**
 * Stripe passes these styles to their components in an iframe
 * We must use non-semantic tokens to ensure the appropriate HEX values are
 * used here rather than css variables.
 * We also must use 'px' for sizes instead of 'rem'
 */
const inputStyle = {
  fontFamily: "'Figtree', sans-serif",
  fontWeight: token("fontWeights.normal"),
  fontSize: "16px",
  lineHeight: "28px",
  fontSmoothing: "antialiased",
  ":-webkit-autofill": {
    color: token("colors.orange.600"),
  },
  "::placeholder": {
    color: token("colors.ink.400"),
  },
  "::selection": {
    color: token("colors.blue.500"),
  },
};

/**
 * These are styles applied to the stripe element outside of the iframe
 */
const CardElementWrapper = styled("div", {
  base: {
    "& > .StripeElement--focus": {
      borderColor: "border.dark!",
      boxShadow: "0 0 0 1px token(colors.border.dark)",
      _hover: {
        borderColor: "border.dark!",
        boxShadow: "0 0 0 1px token(colors.border.dark)!",
      },
    },
    "& > .StripeElement--invalid": {
      borderColor: "border.error!",
      boxShadow: "0 0 0 1px token(colors.border.error)",
      _hover: {
        borderColor: "border.error!",
        boxShadow: "0 0 0 1px token(colors.border.error)!",
      },
    },
    "& > .StripeElement": {
      borderWidth: "1px",
      borderColor: "border.light",
      borderRadius: "100",
      px: "150",
      py: "100",
      bg: "white",
      color: "text.dark",
      fontFamily: "body",
      fontSize: "md",
      lineHeight: "xs",
      width: "full",
      transitionDuration: "normal",
      transitionProperty: "box-shadow, border-color",
      transitionTimingFunction: "default",
      _autofill: {
        color: "text.warning.mid",
      },
      _placeholder: {
        color: "text.accent.mid",
      },
      _selection: {
        color: "text.accent.mid",
      },
      _hover: {
        borderColor: "border.mid",
        boxShadow: "0 0 0 1px token(colors.border.mid)",
      },
    },
  },
});

export const StripeCardElement = ({ onChange }: StripeCardElementProps) => {
  return (
    <CardElementWrapper>
      <CardElement
        onChange={onChange}
        options={{
          style: {
            base: inputStyle,
          },
          disableLink: true,
        }}
      />
    </CardElementWrapper>
  );
};
