import { Table, Text } from "@ttc3k/trekker";
import { formatPrice } from "core/utils/formatPrice";
import { differenceInCalendarDays } from "date-fns";
import pluralize from "pluralize";
import { parseToUTCDate } from "core/utils/parseToUTCDate";
import { Flex } from "styled-system/jsx";
import { CartItem } from "booker/contexts";
import { EnumProductType } from "gql/generated";
import { getTotalGuestCount } from "../utils";

type CheckoutSummaryProps = {
  subTotal: number;
  tax: number;
  grandTotal: number;
  cart: CartItem[];
  isReservationPage?: boolean;
};

export const CheckoutSummary = ({
  cart,
  grandTotal,
  subTotal,
  tax,
  isReservationPage = false,
}: CheckoutSummaryProps) => {
  return (
    <Table.Root>
      {!isReservationPage && (
        <Table.Caption color={"text.light!"}>Summary</Table.Caption>
      )}
      <Table.Body>
        {cart.length > 0 &&
          cart.map(item => {
            const totalGuestCount = getTotalGuestCount(item.guestsPerTier);
            const numberOfNights = differenceInCalendarDays(
              parseToUTCDate(item.endDateISO),
              parseToUTCDate(item.startDateISO),
            );
            if (
              item.productType === EnumProductType.Accommodation &&
              (!totalGuestCount || !numberOfNights)
            ) {
              return null;
            }

            return (
              <Table.Row key={item.productName}>
                <Table.Header paddingRight={"150"}>
                  <Flex flexDir={"column"}>
                    <Text color={"text.dark"}>
                      {item.productName}
                      {item.productType === EnumProductType.Accommodation &&
                        isReservationPage &&
                        ` | ${pluralize("night", numberOfNights, true)}`}
                    </Text>
                    {item.productType === EnumProductType.Accommodation &&
                      !isReservationPage && (
                        <Text color={"text.mid"}>
                          {pluralize("night", numberOfNights, true)}
                          &nbsp;•&nbsp;
                          {pluralize("guest", totalGuestCount, true)}
                        </Text>
                      )}
                  </Flex>
                </Table.Header>
                <Table.Cell>${formatPrice(item.total)}</Table.Cell>
              </Table.Row>
            );
          })}
        <Table.Row>
          <Table.Header>Subtotal</Table.Header>
          <Table.Cell>${formatPrice(subTotal)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Header>Tax</Table.Header>
          <Table.Cell>${formatPrice(tax)}</Table.Cell>
        </Table.Row>
        <Table.Row border={!isReservationPage ? "none" : undefined}>
          <Table.Header>Total</Table.Header>
          <Table.Cell>${formatPrice(grandTotal)}</Table.Cell>
        </Table.Row>
        {isReservationPage && (
          <Table.Row border={"none"}>
            <Table.Header>Amount paid</Table.Header>
            <Table.Cell>${formatPrice(grandTotal)}</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table.Root>
  );
};
