import { ClipboardButton, SectionHeader, Tag, Text } from "@ttc3k/trekker";
import {
  DATE_PATTERN_STANDARD_WEEKDAY,
  formatDate,
} from "core/utils/formatDate";
import { formatPhoneNumber } from "core/utils/formatPhoneNumber";
import { parseToUTCDate } from "core/utils/parseToUTCDate";
import { isAfter, isSameDay, isWithinInterval } from "date-fns";
import {
  EnumPriceTierType,
  EnumProductType,
  OrderByIdQuery,
} from "gql/generated";
import { Check } from "iconoir-react";
import pluralize from "pluralize";
import { Box, Flex } from "styled-system/jsx";

type TripDetailsProps = {
  order: NonNullable<OrderByIdQuery["orderById"]>;
  isPublicPage?: boolean;
};

export const TripDetails = ({ order, isPublicPage }: TripDetailsProps) => {
  const mainProduct = order.purchasedProducts[0];
  const mainProductDateRangeISO =
    mainProduct?.inventoryClaims[0]?.claimedDateRangeISO;

  const mainProductStartDate = mainProductDateRangeISO
    ? parseToUTCDate(mainProductDateRangeISO?.start ?? "")
    : undefined;
  const mainProductEndDate = mainProductDateRangeISO
    ? parseToUTCDate(mainProductDateRangeISO?.end ?? "")
    : undefined;
  const today = parseToUTCDate(new Date());
  const isReservationUpcoming = mainProductStartDate
    ? isAfter(mainProductStartDate, today)
    : false;
  const isCancelled = order.cancelled;
  const isInProgress =
    mainProductStartDate && mainProductEndDate
      ? isWithinInterval(today, {
          start: mainProductStartDate,
          end: mainProductEndDate,
        }) || isSameDay(today, mainProductEndDate)
      : false;
  return (
    <Flex
      bg={"bg.wa.light"}
      p={"400"}
      borderRadius={"100"}
      flexDir={"column"}
      gap={"300"}
      alignItems={"flex-start"}
      w={{ base: "100%", lg: "380px" }}
      height={"full"}
    >
      <SectionHeader text="Trip Details">
        {isCancelled && <Tag visual={"destructive"}>Cancelled</Tag>}
        {isReservationUpcoming && !isCancelled && (
          <Tag visual={"accent"} Icon={Check}>
            Upcoming
          </Tag>
        )}
        {isInProgress && !isCancelled && (
          <Tag visual={"success"}>In progress</Tag>
        )}
      </SectionHeader>
      <Flex flexDir={"column"} gap={"200"}>
        <Flex flexDir={"column"} gap={"100"}>
          <Text visual={"smallLabel"} color={"text.light"}>
            Id
          </Text>
          <Flex
            gap={"75"}
            alignItems={"center"}
            // Override the clipboard button size to be small
            css={{
              "& svg": {
                width: "icons.sm",
                height: "icons.sm",
                strokeWidth: 1.5,
              },
            }}
          >
            <Text visual={"bodySemiBold"} color={"text.dark"}>
              {order._id}
            </Text>
            <ClipboardButton value={order._id} />
          </Flex>
        </Flex>

        <Flex flexDir={"column"} gap={"100"}>
          <Text visual={"smallLabel"} color={"text.light"}>
            {isPublicPage ? "Your info" : "Customer info"}
          </Text>
          <Box>
            <Text color={"text.dark"}>{order.customerContact.name}</Text>
            <Text color={"text.dark"}>{order.customerContact.email}</Text>
            <Text color={"text.dark"}>
              {formatPhoneNumber(order.customerContact.phone)}
            </Text>
          </Box>
        </Flex>

        {mainProduct && (
          <>
            <Flex flexDir={"column"} gap={"100"}>
              <Text visual={"smallLabel"} color={"text.light"}>
                Guests
              </Text>
              {mainProduct.guestsPerTier.map(tier => {
                if (!tier || tier.count < 1) return null;
                const fullPriceTier = mainProduct.product.pricing.tiers.find(
                  t => t?._id === tier.tierId,
                );
                const priceTierLabel =
                  fullPriceTier?.label?.en +
                  " " +
                  (fullPriceTier?.type === EnumPriceTierType.General
                    ? "guest"
                    : fullPriceTier?.type);
                return (
                  <Text
                    color={"text.dark"}
                    textTransform={"capitalize"}
                    key={tier.tierId}
                  >
                    {pluralize(
                      priceTierLabel.length > 1
                        ? priceTierLabel.toLowerCase()
                        : "guest",
                      tier.count,
                      true,
                    )}
                  </Text>
                );
              })}
            </Flex>

            <Flex flexDir={"column"} gap={"100"}>
              <Text visual={"smallLabel"} color={"text.light"}>
                {mainProduct.product.type}
              </Text>
              <Text color={"text.dark"}>{mainProduct.product.name.en}</Text>
            </Flex>

            {mainProductStartDate && (
              <Flex flexDir={"column"} gap={"100"}>
                <Text visual={"smallLabel"} color={"text.light"}>
                  {mainProduct.product.type === EnumProductType.Accommodation
                    ? "Check-in"
                    : "Start"}
                </Text>
                <Text color={"text.dark"}>
                  {formatDate(
                    mainProductStartDate,
                    DATE_PATTERN_STANDARD_WEEKDAY,
                  )}
                </Text>
              </Flex>
            )}
            {mainProductEndDate && (
              <Flex flexDir={"column"} gap={"100"}>
                <Text visual={"smallLabel"} color={"text.light"}>
                  {mainProduct.product.type === EnumProductType.Accommodation
                    ? "Check-out"
                    : "End"}
                </Text>
                <Text color={"text.dark"}>
                  {formatDate(
                    mainProductEndDate,
                    DATE_PATTERN_STANDARD_WEEKDAY,
                  )}
                </Text>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};
