import { Flex } from "styled-system/jsx";
import { Maybe } from "gql/generated";
import { Heading, Link, Text } from "@ttc3k/trekker";
import { ConsumingOverlayPageProps, OverlayPage } from "../OverlayPage";

type TermsPageProps = ConsumingOverlayPageProps & {
  terms?: string | Maybe<string>;
  cancellationPolicy?: string | Maybe<string>;
  acceptedTerms: boolean;
  acceptTerms: () => void;
};

export const TermsPage = ({
  terms,
  cancellationPolicy,
  acceptedTerms,
  onClose,
  acceptTerms,
  ...rest
}: TermsPageProps) => {
  const handleButtonClick = () => {
    acceptTerms();
    onClose();
  };
  return (
    <OverlayPage
      title={"Terms & Conditions"}
      onButtonClick={handleButtonClick}
      onClose={onClose}
      buttonDisabled={acceptedTerms}
      buttonLabel={acceptedTerms ? "Accepted" : "Accept"}
      {...rest}
    >
      <Flex flexDir={"column"} gap={"400"} my={"400"}>
        {terms && (
          <Flex flexDir={"column"} gap={"200"}>
            <Heading size={"h5"}>Terms</Heading>
            <Text color={"text.mid"}>{terms}</Text>
          </Flex>
        )}
        {cancellationPolicy && (
          <Flex flexDir={"column"} gap={"200"}>
            <Heading size={"h5"}>Cancellation Policy</Heading>
            <Text color={"text.mid"}>{cancellationPolicy}</Text>
          </Flex>
        )}
        <Text color={"text.dark"}>
          By clicking accept below, you also accept the following terms of
          service found here:&nbsp;
          <Link
            href={
              "https://www.tourism.tech/terms-of-service/additional-terms-of-service-for-business-customers"
            }
            target="_blank"
          >
            TTC Terms of Service
          </Link>
        </Text>
      </Flex>
    </OverlayPage>
  );
};
