import { Heading, Icon, Text } from "@ttc3k/trekker";
import { WarningWindow } from "iconoir-react";
import { css } from "styled-system/css";
import { Center } from "styled-system/jsx";
import { HELPSCOUT_EMAIL } from "booker/shared/constants";

export const PageError = () => {
  return (
    <Center
      bg="bg.wa.lightest"
      height="100%"
      gap="300"
      flexDir="column"
      position="relative"
      px="300"
    >
      <Icon Element={WarningWindow} size="xl" color="icon.error.mid" />
      <Center flexDir="column" gap="100" textAlign="center">
        <Heading size="h5" color="text.dark">
          Don&apos;t pack those bags quite yet...
        </Heading>
        <Text color="text.mid">
          We&apos;re having a hard time loading this. Try again in a bit
          or&nbsp;
          <a
            href={`mailto:${HELPSCOUT_EMAIL}`}
            className={css({ textDecoration: "underline", cursor: "pointer" })}
          >
            contact us
          </a>
          &nbsp;for help
        </Text>
      </Center>
      <Center position="absolute" bottom="600" flexDir="column">
        <Text color="text.light" visual="smallRegular">
          Powered by
        </Text>
        <Text color="text.mid" visual="smallLabel">
          Whereabouts
        </Text>
      </Center>
    </Center>
  );
};
