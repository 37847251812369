import {
  LegacyButton,
  ButtonTagVariant,
  tokens,
  LegacyTag,
} from "@ttc3k/ttc-design-system";
import React, { useState } from "react";
import { Copy } from "iconoir-react";
import { AnimatePresence, motion } from "framer-motion";

export interface CodeBlockProps {
  allowCopy?: boolean;
  text: string;
}

export const CodeBlock = ({ allowCopy, text }: CodeBlockProps) => {
  const [copied, setCopied] = useState(false);
  return (
    <div
      className={
        "tw-flex tw-gap-4 tw-py-5 tw-px-6 tw-bg-[#EDEDEE] tw-rounded-lg tw-justify-between"
      }
    >
      <p
        className={
          "tw-font-ibmPlexMono tw-text-[15px] tw-leading-6 tw-text-colorText"
        }
      >
        {text}
      </p>
      {allowCopy && (
        <div className={"tw-relative"}>
          <LegacyButton
            className={"tw-relative tw-self-start"}
            variant={ButtonTagVariant.Unstyled}
            icon={
              <Copy strokeWidth={1.75} color={tokens.semantic.iconSubtle} />
            }
            onClick={() => {
              navigator.clipboard.writeText(text);
              setCopied(true);
              window.setTimeout(() => {
                setCopied(false);
              }, 2000);
            }}
          />
          <AnimatePresence>
            {copied ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className={"tw-absolute tw-right-0 tw-mt-1"}
              >
                <LegacyTag variant={ButtonTagVariant.SuccessBold}>
                  Copied!
                </LegacyTag>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};
