import { Flex } from "styled-system/jsx";
import { BookerLeafHeader } from "core/components/shared/BookerLeafHeader";
import { CreateFeeForm } from "./CreateFeeForm";
import { UpdateFeeForm } from "./UpdateFeeForm";

type FeeDetailsLeafProps = {
  isUpdating: boolean;
  productID: string;
};

export const FeeDetailsLeaf = ({
  isUpdating,
  productID,
}: FeeDetailsLeafProps) => {
  return (
    <Flex flexDir={"column"} gap={"300"} width={"full"}>
      <BookerLeafHeader
        title={"Product Info"}
        description={
          "Start by capturing the key information for your product here."
        }
      />
      {!isUpdating ? (
        <CreateFeeForm />
      ) : (
        <UpdateFeeForm productID={productID} />
      )}
    </Flex>
  );
};
