import "./tripper.css";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import {
  LegacyLoadingSpinner,
  LegacyPageDivider,
  LegacyTypography,
} from "@ttc3k/ttc-design-system";
import { gql, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import Footer from "../../../components/shared/Footer";
import { handleBeaconClick } from "../../../utils/helpScout";
import { Loader } from "../../../components/Loader";
import { CodeBlock } from "../../../components/shared/CodeBlock";
import ttcLogo from "../../../../assets/ttc-logo-full-whitebehind.png";
import { variants } from "../../onboarding/shared";
import { PageNotFound } from "../../404.tsx";

const GET_ORG = gql`
  query Org($slug: String) {
    org(slug: $slug) {
      slug
      name
      tripperSettings {
        teamId
      }
    }
  }
`;

const GET_TRIPPERS = gql`
  query Tripper($teamId: String) {
    tripper(teamId: $teamId) {
      title {
        en
      }
      type
      nid
    }
  }
`;

export default function TripperPlayground({ importedSlug }) {
  const { slug } = useParams();
  const {
    data: orgData,
    loading: orgLoading,
    error: orgError,
  } = useQuery(GET_ORG, {
    variables: { slug: importedSlug ? importedSlug : slug },
  });
  const teamId = orgData?.org?.tripperSettings?.teamId;
  const orgName = orgData?.org?.name;
  const {
    data: trippers,
    loading,
    error,
  } = useQuery(GET_TRIPPERS, { skip: !teamId, variables: { teamId } });

  //   const { data: profileData } = useQuery(PROFILE_INFO)
  //   const planetId = profileData?.planet?.id
  //   const { data: planetData } = useQuery(PLANET,
  //       skip: !planetId,
  //       variables: { planetId }
  // )

  const itineraryLauncherOptions = [
    { value: "MAP_PATH", label: "Map" },
    { value: "MAP", label: "Map Carousel" },
    { value: "", label: "Simple Carousel" },
    { value: "BUTTON", label: "Simple Button" },
  ];

  const atlasLauncherOptions = [
    { value: "", label: "Carousel" },
    { value: "BUTTON", label: "Simple Button" },
  ];

  const ref = useRef();

  const [trprOptions, setTrprOptions] = useState([]);
  const [trprID, setTrprID] = useState("");
  const [btnLabel, setBtnLabel] = useState("Book Now!");
  const [variant, setVariant] = useState("");
  const [selectedOption, setSelectedOption] = useState();
  const [selectedTrprIDOption, setSelectedTrprIDOption] = useState();
  const [lang, setLang] = useState("en");
  const [options, setOptions] = useState();
  const [trprIDOptions, setTrprIDOptions] = useState();
  const [guideType, setGuideType] = useState("");
  const [trprLoading, setTrprLoading] = useState(false);

  useEffect(() => {
    if (trippers) {
      const options = trippers.tripper.map(t => ({
        label: t.title.en,
        value: t.nid,
        type: t.type.toUpperCase(),
      }));
      setVariant(options[0].type == "ATLAS" ? "" : "MAP_PATH");
      setOptions(
        options[0].type == "ATLAS"
          ? atlasLauncherOptions
          : itineraryLauncherOptions,
      );
      setSelectedOption(
        options[0].type == "ATLAS"
          ? atlasLauncherOptions[0]
          : itineraryLauncherOptions[0],
      );
      setGuideType(options[0].type);
      setTrprOptions(options[0].value);
      setTrprIDOptions(options);
      setTrprID(options[0].value);
      setSelectedTrprIDOption(options[0]);
    }
  }, [trippers]);

  const handleSelectChange = option => {
    setSelectedOption(option);
    setVariant(option.value);
    setTrprLoading(true);
  };

  const handleSelectTrprChange = option => {
    setSelectedTrprIDOption(option);
    setTrprID(option.value);
    setGuideType(option.type);
    setOptions(
      option.type === "ITINERARY"
        ? itineraryLauncherOptions
        : atlasLauncherOptions,
    );
    setVariant(option.type === "ITINERARY" ? "MAP_PATH" : "");
    setSelectedOption(
      option.type === "ITINERARY"
        ? itineraryLauncherOptions[0]
        : atlasLauncherOptions[0],
    );
    setTrprLoading(true);
  };

  if (trprLoading) {
    setTimeout(() => {
      setTrprLoading(false);
    }, 3000);
  }

  const wcRef = useRef(null);

  if (error) {
    <h1>Oops, error loading your trippers</h1>;
  }

  if (loading || orgLoading) {
    return <Loader />;
  }

  if (!orgData.org) {
    return <PageNotFound />;
  }

  return (
    <AnimatePresence>
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
      >
        <Helmet>
          {`<title>Tripper Embed Playground for ${orgName} | Whereabouts</title>`}
        </Helmet>
        <div className="CONTAINER-WRAPPER md:tw-w-[700px] tw-m-auto">
          <div className="INSTRUCTIONS tw-flex tw-flex-1 tw-left-0 tw-overflow-auto tw-flex-col tw-gap-6 tw-p-6">
            <img
              className="tw-max-w-[200px] tw-my-3 tw-block tw-m-auto"
              src={ttcLogo}
            />
            <LegacyTypography as="h1" variant="H1">
              Embed Trippers on Your Site
            </LegacyTypography>
            <LegacyTypography>
              To embed a <strong>{orgName} Tripper</strong> you need to create a{" "}
              <strong>Tripper Launcher</strong>. On this custom page for{" "}
              {orgName} you can generate Launchers and follow instructions for
              embedding them on your site.
            </LegacyTypography>
            <LegacyPageDivider />
            <LegacyTypography as="h3" variant="H3">
              Step 1: Add these scripts to your site
            </LegacyTypography>
            <div className="playground-section">
              <LegacyTypography>
                Make sure to include the following scripts on every page where
                you want to embed a Tripper, ideally before the closing{" "}
                <code>&lt;/body&gt;</code> tag. Usually, your website developer
                will choose to add these scripts to your site's template so they
                appear on every page. They only need to appear once per page.
              </LegacyTypography>

              <CodeBlock
                allowCopy
                text={`<script type="module" src="https://${slug}-launcher.tripper.app/build/ttc-tripper-launcher.esm.js" data-stencil-namespace="ttc-tripper-launcher"></script>
  
                <script nomodule src="https://${slug}-launcher.tripper.app/build/ttc-tripper-launcher.js" data-stencil-namespace="ttc-tripper-launcher"></script>
              `}
              ></CodeBlock>

              <hr className="page-divider tw-mt-5" />
              <LegacyTypography as="h3" variant="H3">
                Step 2: Generate a Launcher
              </LegacyTypography>
              <LegacyTypography>
                <strong>Tripper</strong> comes pre-built with different launcher
                styles depending on the kind of Tripper you are creating. Build
                your launcher by selecting options below.
              </LegacyTypography>
            </div>
          </div>
        </div>
        <div className="STAGE tw-p-3 tw-flex tw-flex-1 tw-right-0 tw-flex-col tw-gap-6 tw-items-center tw-justify-center">
          <div
            ref={wcRef}
            className="TRIPPER_WRAPPER tw-min-h-[450px] tw-w-full tw-p-2 tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-[#F2DCBB] tw-rounded-lg"
          >
            {trprLoading ? (
              <LegacyLoadingSpinner />
            ) : (
              <ttc-tripper-launcher
                trpr-id={trprID}
                lang={lang}
                variant={variant}
                btn-label={btnLabel}
                type={guideType}
              />
            )}
          </div>
        </div>
        <div className="CONTAINER-WRAPPER md:tw-w-[700px] tw-m-auto">
          <div className="INSTRUCTIONS tw-flex tw-flex-1 tw-left-0 tw-overflow-auto tw-flex-col tw-gap-6 tw-p-6">
            <div className="TOGGLES tw-flex tw-flex-row tw-justify-center tw-gap-5">
              <label>
                Select a Tripper
                <Select
                  value={selectedTrprIDOption}
                  onChange={e => handleSelectTrprChange(e)}
                  options={trprIDOptions}
                />
              </label>
              <label>
                Select Launcher Style
                <Select
                  value={selectedOption}
                  onChange={handleSelectChange}
                  options={options}
                />
              </label>
            </div>
            <hr className="page-divider tw-mt-5" />
            <LegacyTypography as="h3" variant="H3">
              Step 3: Copy the launcher code and add it to your site
            </LegacyTypography>
            <LegacyTypography>
              Every time you change the settings above, the launcher code below
              will update. When you're ready, copy the code below and paste it
              into the code of your web page where you want the launcher to
              appear. You can embed as many Trippers on a given page as you
              like.
            </LegacyTypography>
            <CodeBlock
              allowCopy
              text={`<ttc-tripper-launcher type="${guideType}" variant="${variant}" trpr-id="${trprID}" lang="${lang}"></ttc-tripper-launcher>
            `}
            ></CodeBlock>
            <hr className="page-divider tw-mt-5" />
            <LegacyTypography as="h2" variant="H2">
              Additional Embedding Options
            </LegacyTypography>
            <LegacyTypography as="h4" variant="H4">
              Generate an HTML Wrapper Launcher
            </LegacyTypography>
            <LegacyTypography>
              Would you rather design your own launcher instead of using our
              pre-built ones? You can simply wrap the launcher code around your
              own custom HTML elements, even just a{" "}
              <ttc-tripper-launcher
                type={`${guideType}`}
                trpr-id={`${trprID}`}
                lang={`${lang}`}
                style={{ display: "inline-block" }}
              >
                <a href="#!" className="tw-text-black tw-underline">
                  simple text link
                </a>
              </ttc-tripper-launcher>
              .
            </LegacyTypography>

            <CodeBlock
              allowCopy
              text={`<ttc-tripper-launcher type="${guideType}" trpr-id="${trprID}" lang="${lang}"><a href="#!" className="text-white underline">simple text link</a></ttc-tripper-launcher>`}
            ></CodeBlock>

            <hr className="page-divider" />

            <LegacyTypography as="h4" variant="H4">
              Styling the Launcher (Advanced)
            </LegacyTypography>
            <LegacyTypography>
              <strong>Tripper Launcher</strong> makes available css variables
              for easy styling. You can control the Tripper modal using css
              style overrides. For example, copy the following class into your
              site's CSS stylesheet.
            </LegacyTypography>

            <div className="playground-section tw-mb-3">
              <CodeBlock
                allowCopy
                text={`.style-overrides {
                    --trpr-launcher-modal-max-width: 90vw;
                    --trpr-launcher-modal-width: 90vw;
                    --trpr-launcher-modal-height: calc(100vh - 170px);
                    --trpr-launcher-modal-max-height: calc(100vh - 170px);
                    --trpr-launcher-modal-z-index: 999999;
                    --trpr-launcher-btn-background-color: red;
                    --trpr-launcher-btn-text-color: cyan;
                    --trpr-launcher-btn-hover-background-color: green;
                    --trpr-launcher-cards-background-gradient-start: black;
                    --trpr-launcher-cards-background-gradient-end: yellow;
                    --trpr-launcher-cards-background-gradient-horizon:pink;
                  `}
              ></CodeBlock>
              <LegacyTypography>
                Then wrap your <strong>Tripper Launcher Code</strong> (from
                above) in that CSS class.
              </LegacyTypography>
              <CodeBlock
                allowCopy
                text={`<div class="style-overrides">
                  <ttc-tripper-launcher variant="${guideType}" id="${trprID}" lang="${lang}"></ttc-tripper-launcher>
                </div>`}
              ></CodeBlock>
              <hr className="page-divider" />
              <LegacyTypography as="h2" variant="H2">
                👋 Need a hand?
              </LegacyTypography>
              <LegacyTypography>
                We're always looking for ways to improve our services. Please
                don't hesitate to{" "}
                <button
                  className="tw-underline tw-text-[#066DCC] tw-bg-transparent tw-border-0"
                  onClick={() => handleBeaconClick()}
                >
                  contact us
                </button>{" "}
                if you have any questions or requests.
              </LegacyTypography>
            </div>
          </div>
        </div>
        <Footer />
      </motion.div>
    </AnimatePresence>
  );
}
