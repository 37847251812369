import { z } from "zod";

export const multilingualSchema = (maxChar: number) =>
  z.object({
    en: z.string(),
    fr: z.string(),
  });

export type MultilingualSchema = z.infer<ReturnType<typeof multilingualSchema>>;

export const multilingualSchemaInitialValues: MultilingualSchema = {
  en: "",
  fr: "",
};
