import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useAuth0 } from "@auth0/auth0-react";
import Logout from "./LogoutButton";

const variants = {
  open: {
    height: "auto",
    opacity: 1,
    overflow: "hidden",
  },
  closed: {
    height: 0,
    opacity: 0,
  },
};

export const UserMenu = () => {
  const { user } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={"tw-relative"}>
      <button
        className={"tw-border-none tw-rounded-full"}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {/*/>*/}
        <div
          className={
            "tw-overflow-hidden tw-w-[32px] tw-h-[32px] tw-bg-[#86765F] tw-rounded-full"
          }
        >
          <img src={user.picture} alt={""} />
        </div>
      </button>
      <AnimatePresence mode="wait">
        {isOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={variants}
            transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div
              className={
                "tw-absolute tw-mt-2 tw-right-0 tw-w-[210px] tw-flex tw-bg-white tw-rounded-lg tw-shadow-[0_2px_8px_0_rgba(0,0,0,0.08)] tw-border tw-border-borderWbts tw-px-6 tw-py-5"
              }
            >
              <Logout />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
