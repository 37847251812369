import { useAppContext } from "core/components/apps/PreferredLayout.tsx";
import { useEffect } from "react";
import { Flex } from "styled-system/jsx";
import { Spinner, Text } from "@ttc3k/trekker";
import { useOrderManyQuery } from "gql/generated";
import { useParams } from "react-router-dom";
import { RedirectToOperator } from "../components";
import {
  CalendarMenu,
  CalendarSection,
  ReservationsPerDayDetails,
} from "./components/";
import { useCalendarData } from "./hooks";

export function CalendarBundleView() {
  const { spaceId } = useParams();
  const { setTitle, setDescription, setActionSection } = useAppContext();
  const {
    selectedDate,
    onUpdateSelectedDate,
    hoveredReservationDateRangeISO,
    onReservationHover,
    closures,
  } = useCalendarData(spaceId ?? "");

  useEffect(() => {
    setTitle("Booking Calendar");
    setDescription(
      "All of your upcoming reservations in one place. View more details about your orders, or create and edit closure events to mark date ranges as closed.",
    );
    setActionSection(<CalendarMenu />);
  }, [setActionSection, setDescription, setTitle]);

  const { data, loading, error } = useOrderManyQuery({
    variables: {
      filter: { source: { operator: spaceId ?? "" } },
      perPage: 10000000,
    },
  });

  if (loading) {
    return <Spinner width={"50px"} height={"50px"} margin={"60px auto"} />;
  }
  if (!spaceId || spaceId === "undefined") {
    return <RedirectToOperator />;
  }
  if (error)
    return (
      <Text
        margin={"60px auto"}
        width={"full"}
        textAlign={"center"}
        color={"text.error.mid"}
      >
        Error : {error.message}
      </Text>
    );

  return (
    <Flex
      px={{ base: "400", md: "80px" }}
      pt={"3.75rem"}
      pb={"7.5rem"}
      justifyContent={"space-between"}
      gap={"600"}
      flexDir={{ base: "column-reverse", lg: "row" }}
    >
      <ReservationsPerDayDetails
        selectedDate={selectedDate}
        orders={data?.orderMany?.items ?? []}
        onReservationHover={onReservationHover}
        closures={closures}
        operatorID={spaceId ?? ""}
      />
      <CalendarSection
        selectedDate={selectedDate}
        onUpdateSelectedDate={onUpdateSelectedDate}
        hoveredReservationDateRangeISO={hoveredReservationDateRangeISO}
        closures={closures}
        orders={data?.orderMany?.items ?? []}
      />
    </Flex>
  );
}
