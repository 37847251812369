import { Location } from "react-router-dom";
import { motion } from "framer-motion";
import { Button, Card } from "@ttc3k/trekker";
import { Menu } from "../../components/Menu";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { MaintenanceData } from "../../../apps/library/directory/api/typeDefs";
import Notifications from "./Notifications";

const config = {
  tenant: "ttc",
};

interface HomeProps {
  registeredApps: any;
  previousLocation: Location;
  banner: MaintenanceData | undefined;
  organization: any | undefined;
}

export function Home({
  registeredApps,
  previousLocation,
  banner,
  organization,
}: HomeProps) {
  //@ToDO Lift this to a higher component? A navlink wrapper?
  const { scrollY } = useScrollPosition();
  const isScrolling = scrollY > 0 && !previousLocation;

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Menu
        registeredApps={registeredApps}
        organization={organization}
        isScrolling={isScrolling}
        banner={banner}
      />
      <Notifications />
    </motion.main>
  );
}
