import { Icon, Menu, MultiActionButton, Text } from "@ttc3k/trekker";
import { Code, NavArrowDown, Trash } from "iconoir-react";
import { useState } from "react";
import { DeleteProductModal } from "./DeleteProductModal";
import { EmbedDetailsModal } from "./EmbedDetailsModal";

enum ProudctMenuOption {
  Embed = "EMBED",
  Delete = "DELETE",
}

type ProductMenuButtonProps = {
  productID: string;
};

export const ProductMenuButton = ({ productID }: ProductMenuButtonProps) => {
  const [isEmebedModalOpen, setIsEmbedModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleMenuSelect = (value: ProudctMenuOption) => {
    switch (value) {
      case ProudctMenuOption.Embed:
        setIsEmbedModalOpen(true);
        break;
      case ProudctMenuOption.Delete:
        setIsDeleteModalOpen(true);
        break;
    }
  };
  return (
    <>
      <Menu.Root
        trigger={
          <MultiActionButton
            visual={"outline"}
            type={"button"}
            Icon={NavArrowDown}
            size={"lg"}
          >
            Product actions
          </MultiActionButton>
        }
        onSelect={d => handleMenuSelect(d.value as ProudctMenuOption)}
      >
        <Menu.Item
          value={ProudctMenuOption.Embed}
          gap={"100"}
          justifyContent={"flex-start"}
        >
          <Icon Element={Code} strokeWidth={1.75} />
          <Text visual={"smallMedium"} color={"text.dark"}>
            Embed
          </Text>
        </Menu.Item>
        <Menu.Item
          value={ProudctMenuOption.Delete}
          gap={"100"}
          justifyContent={"flex-start"}
        >
          <Icon Element={Trash} strokeWidth={1.75} />
          <Text visual={"smallMedium"} color={"text.dark"}>
            Delete product
          </Text>
        </Menu.Item>
      </Menu.Root>
      <DeleteProductModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        productID={productID}
      />
      <EmbedDetailsModal
        isOpen={isEmebedModalOpen}
        onClose={() => setIsEmbedModalOpen(false)}
        productID={productID}
      />
    </>
  );
};
