import { Button, Icon, Input, Select, Text } from "@ttc3k/trekker";
import { Search } from "iconoir-react";
import React, { useState } from "react";
import { Box, Divider, Flex } from "styled-system/jsx";
import { isBefore, isSameDay } from "date-fns";
import { OrderFilterValue, PaymentStatus } from "../hooks";

type FilterMenuProps = {
  filter: OrderFilterValue;
  updateFilter: <K extends keyof OrderFilterValue>(
    key: K,
    value: OrderFilterValue[K],
  ) => void;
  resetFilter: () => void;
};

const paymentStatusOptions = [
  { value: PaymentStatus.All, label: "All orders" },
  { value: PaymentStatus.FullRefund, label: "Full refund" },
  {
    value: PaymentStatus.PartialRefund,
    label: "Partial refund",
  },
  { value: PaymentStatus.NoRefund, label: "No refund" },
];

export const FilterMenu = ({
  resetFilter,
  updateFilter,
  filter,
}: FilterMenuProps) => {
  const [filterErrorMsg, setFilterErrorMsg] = useState("");
  const handleSearchParamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFilter("searchParams", e.currentTarget.value);
  };
  const handlePaymentStatusChange = (
    value: OrderFilterValue["paymentStatus"],
  ) => {
    updateFilter("paymentStatus", value);
  };
  const handleDateChange = (date: string, key: "start" | "end") => {
    const newDates = { ...filter.transactionDates, [key]: date };

    const isStartDateBeforeEndDate =
      newDates.start.length > 0 && newDates.end.length > 0
        ? isBefore(new Date(newDates.start), new Date(newDates.end)) ||
          isSameDay(new Date(newDates.start), new Date(newDates.end))
        : true;

    if (isStartDateBeforeEndDate) {
      updateFilter("transactionDates", newDates);
      setFilterErrorMsg("");
    } else {
      newDates.end = "";
      updateFilter("transactionDates", newDates);
      setFilterErrorMsg("Please select an end date after the before date");
    }
  };

  return (
    <Flex flexDir={"column"}>
      <Flex
        gap={{ base: "200", xl: "300" }}
        overflowX={"auto"}
        alignItems={"center"}
        h={"full"}
        flex={1}
      >
        <Box position={"relative"} width={"full"}>
          <Input
            name={"search"}
            placeholder={"Search..."}
            bg={"white"}
            onChange={handleSearchParamChange}
            value={filter.searchParams}
            minW={"200px"}
          />
          <Box position={"absolute"} top={"30%"} right={"100"}>
            <Icon Element={Search} color={"icon.mid"} />
          </Box>
        </Box>
        <FilterDivider />
        <Select.Root
          items={paymentStatusOptions}
          value={[filter.paymentStatus]}
          maxW={"160px"}
          gap={0}
          css={{
            "& [data-scope='select'][data-part='trigger']": {
              bg: "white",
            },
          }}
          onValueChange={({
            value,
          }: {
            value: OrderFilterValue["paymentStatus"][];
          }) => handlePaymentStatusChange(value[0])}
        >
          {paymentStatusOptions.map(v => (
            <Select.Item key={v.value} item={v} />
          ))}
        </Select.Root>
        <FilterDivider />
        <Flex gap={{ base: "100", xl: "150" }} alignItems={"center"} h={"full"}>
          <Text
            color={"text.mid"}
            visual={"smallMedium"}
            w={"140px"}
            minW={"140px"}
          >
            Show orders between
          </Text>
          <Input
            type={"date"}
            name={"start"}
            bg={"white"}
            value={filter.transactionDates.start}
            onChange={e => handleDateChange(e.currentTarget.value, "start")}
          />
          <Text color={"text.mid"} visual={"smallMedium"}>
            and
          </Text>
          <Input
            type={"date"}
            name={"end"}
            bg={"white"}
            value={filter.transactionDates.end}
            onChange={e => handleDateChange(e.currentTarget.value, "end")}
          />
          <Button visual={"outline"} py={"9px"} onClick={resetFilter}>
            Clear
          </Button>
        </Flex>
      </Flex>
      {filterErrorMsg.length > 0 && (
        <Text visual={"smallSemiBold"} color={"text.error.mid"} mt={"50"}>
          {filterErrorMsg}
        </Text>
      )}
    </Flex>
  );
};

function FilterDivider() {
  return (
    <Divider
      orientation={"vertical"}
      thickness={"2px"}
      borderColor={"border.light"}
      h={"24px"}
    />
  );
}
