//@ts-nocheck
import { gql, useMutation } from "@apollo/client";
import { LegacyButton, LegacyTypography } from "@ttc3k/ttc-design-system";

const SEND_VERIFICATION_EMAIL = gql`
  mutation sendEmailVerification($user_id: String!) {
    sendEmailVerification(user_id: $user_id) {
      user_id
    }
  }
`;

type User = {
  sub: string;
  email: string;
};

export const VerifyEmail = ({ user }) => {
  const [sendEmailVerification, { data, loading, error }] = useMutation(
    SEND_VERIFICATION_EMAIL,
  );

  const handleClick = () => {
    sendEmailVerification({ variables: { user_id: user.sub } }).then(r =>
      console.log(r),
    );
  };

  return (
    <div className="tw-relative tw-max-w-[420px]">
      <div className="tw-flex tw-flex-col tw-gap-5">
        <LegacyTypography as="h2" variant="H2">
          You must verify your email
        </LegacyTypography>
        <LegacyTypography>
          Please log into <b>{user.email}</b> and look for an email from
          Whereabouts.
        </LegacyTypography>
        {/*<Link to="/onboarding">*/}
        {/*  <LegacyButton fullWidth size={Sizes.Large}>*/}
        {/*    Next*/}
        {/*  </LegacyButton>*/}
        {/*</Link>*/}
        <LegacyButton onClick={handleClick}>
          Resend verification email
        </LegacyButton>
        {loading && <p>Sending...</p>}
        {error && <p>Something went wrong : {error.message}</p>}
        {data && <p>Sent! Please check your email</p>}
      </div>
    </div>
  );
};
