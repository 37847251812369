import { tokens } from "@ttc3k/ttc-design-system";
import { Widget as CloudinaryUploader } from "react-cloudinary-upload-widget";
import { handleAsyncError } from "../../../utils/alert.ts";

interface OnSubmitUpload {
  event: "success" | "failed";
  info?: {
    secure_url: string;
    thumbnail_url: string;
  };
}

export type UploadImageButtonProps = {
  label?: string;
  onSubmit?: (result: OnSubmitUpload) => void;
};

export const UploadImageButton = (props: UploadImageButtonProps) => {
  const { onSubmit, label = "Upload image(s)" } = props;
  return (
    <CloudinaryUploader
      sources={["local"]}
      resourceType={"image"}
      cloudName={import.meta.env.VITE_CLOUDINARY_CLOUD_NAME}
      uploadPreset={"whereabouts"} // check that an upload preset exists and check mode is signed or unisgned
      buttonText={label} // default "Upload Files"
      style={{
        color: "white",
        border: "none",
        padding: "6px 16px",
        backgroundColor: tokens.semantic.bgPrimaryBold,
        borderRadius: "8px",
        fontSize: "14px",
      }}
      folder={"whereabouts"}
      cropping={false}
      multiple={true}
      onSuccess={(result: OnSubmitUpload) => {
        onSubmit?.(result);
      }}
      onFailure={() => handleAsyncError()}
      logging={false}
      widgetStyles={{
        palette: {
          window: "#ffffff",
          windowBorder: "#FFFFFF",
          tabIcon: "#FF9600",
          menuIcons: "#D7D7D8",
          textDark: "#DEDEDE",
          textLight: "#666666",
          link: "#0078FF",
          action: "#FF620C",
          inactiveTabIcon: "#B3B3B3",
          error: "#F44235",
          inProgress: "#0078FF",
          complete: "#20B832",
          sourceBg: "#cecece",
        },
      }}
    />
  );
};
