import { Icon, Tag, Text } from "@ttc3k/trekker";
import { Flex, styled } from "styled-system/jsx";
import { EnumProductType } from "gql/generated";
import { SelectableCardSurface } from "core/components/shared/SelectableCardSurface";
import { ProductTypeData } from "./CreateProductStartModal";

type ProductTypeCardsProps = {
  productTypeData: ProductTypeData;
  selectedProductType: EnumProductType | "";
  onSelectProductType: (type: EnumProductType) => void;
};

export const ProductTypeCard = ({
  productTypeData,
  selectedProductType,
  onSelectProductType,
}: ProductTypeCardsProps) => {
  return (
    <styled.button
      width={"full"}
      position={"relative"}
      disabled={!productTypeData.available}
      onClick={() => onSelectProductType(productTypeData.type)}
    >
      {!productTypeData.available && (
        <Flex
          position={"absolute"}
          top={"-100"}
          width={"100%"}
          justifyContent={"center"}
        >
          <Tag visual={"accent"}>Coming soon!</Tag>
        </Flex>
      )}
      <SelectableCardSurface
        disabled={!productTypeData.available}
        visual={
          productTypeData.type === selectedProductType ? "selected" : "neutral"
        }
      >
        <Flex
          flexDir={"column"}
          gap={"150"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <Icon Element={productTypeData.icon} />
          <Text visual={"bodySemiBold"}>{productTypeData.title}</Text>
          <Text visual={"smallRegular"} color={"text.mid"} lineClamp={2}>
            {productTypeData.description}
          </Text>
        </Flex>
      </SelectableCardSurface>
    </styled.button>
  );
};
