import { MatomoAnalyticsContextProvider } from "@ttc3k/ttc-bkr-analytics";
import { BookerPosition } from "./App";
import { SingleProductModal } from "./features/SingleProductModal";
import { BookerContextProvider, CartContextProvider } from "./contexts";

type BookerModalProps = {
  bookerID: string;
  open: boolean;
  onClose: () => void;
  bookerPosition: BookerPosition;
};

export function BookerModal({
  bookerID,
  open,
  onClose,
  bookerPosition,
}: BookerModalProps) {
  const analyticsEnabled = import.meta.env.VITE_ENVIRONMENT == "production";

  return (
    <MatomoAnalyticsContextProvider
      containerId="Xu6ohsle"
      enabled={analyticsEnabled}
    >
      <BookerContextProvider
        isModalOpen={open}
        onCloseBookerModal={onClose}
        bookerID={bookerID}
      >
        <CartContextProvider>
          <SingleProductModal open={open} bookerPosition={bookerPosition} />
        </CartContextProvider>
      </BookerContextProvider>
    </MatomoAnalyticsContextProvider>
  );
}
