import {
  ButtonTagVariant,
  LegacyButton,
  LegacyLoadingSpinner,
  LegacyTypography,
  tokens,
  TypographyVariant,
  useLegacyModal,
} from "@ttc3k/ttc-design-system";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { InfoCircle } from "iconoir-react";
import { Sortable } from "core/components/shared/Sortable";
import { useAppContext } from "core/components/apps/PreferredLayout.tsx";
import { PerfectThumbnailModal } from "core/components/shared/PerfectThumbnailModal";
import { Leaf } from "core/components/shared/Leaf";
import { ImageMedia } from "core/components/shared/ImageMedia";
import { DocumentNode } from "@apollo/client";
import { Entity, NewImage } from "gql/generated";
import { useMedia } from "core/leaves/Media/useMedia";
import { Flex } from "styled-system/jsx";
import { UploadImageButton } from "core/components/shared/UploadImageButton";

export interface MediaConfig {
  query: DocumentNode;
  entityType: Entity;
}

export interface MediaProps {
  data: Partial<NewImage>[];
  loading?: boolean;
  config: MediaConfig;
}

export default function Media({ data, loading, config }: MediaProps) {
  const { id } = useParams();
  const { setDescription } = useAppContext();
  const { closeModal, modalOverlayRef, openModal } = useLegacyModal();
  const {
    handleDeleteImage,
    handleOnSaveImageInfo,
    handleSortableChange,
    handleUploadImages,
    createImageLoading,
    deleteImageId,
  } = useMedia({ id: id ?? "", config: config });
  const isProduct = config.entityType === Entity.Product;

  useEffect(() => {
    setDescription(
      "Add images to improve your profile. Images save automatically when uploaded.",
    );
  }, [setDescription]);

  return (
    <Leaf>
      <div className="tw-flex tw-flex-col tw-gap-y-3 tw-max-w-[600px]">
        <Flex justify={"space-between"} align={"center"} pb={150}>
          <LegacyTypography variant={TypographyVariant.h5}>
            Media Gallery
          </LegacyTypography>
          {isProduct && <UploadImageButton onSubmit={handleUploadImages} />}
        </Flex>
        <LegacyTypography color={tokens.semantic.textSubtle}>
          Upload photos to showcase and we’ll display them in an interactive
          gallery. You can drag and drop images in whatever order you’d like —
          the first image will serve as the default.
        </LegacyTypography>
        <LegacyButton
          iconStart
          icon={<InfoCircle />}
          variant={ButtonTagVariant.Unstyled}
          color={tokens.semantic.textInfo}
          onClick={openModal}
        >
          How can I get the "Perfect Thumbnail"?
        </LegacyButton>
        <PerfectThumbnailModal
          modalOverlayRef={modalOverlayRef}
          onModalClose={closeModal}
        />
      </div>
      {data && data.length > 0 ? (
        <Sortable
          value={data}
          sortableComponent={(image: Partial<NewImage>, i: number) => (
            <ImageMedia
              image={image}
              onDelete={handleDeleteImage}
              onSave={handleOnSaveImageInfo}
              loading={deleteImageId === image._id}
              showTag={i === 0}
            />
          )}
          onChange={handleSortableChange}
          selectorID={"_id"}
        />
      ) : (
        <h3>Please upload some images</h3>
      )}
      {(loading || createImageLoading) && (
        <div className={"tw-flex tw-gap-4 tw-self-start tw-items-center"}>
          <LegacyLoadingSpinner size={25} />
          <LegacyTypography>Loading...</LegacyTypography>
        </div>
      )}
    </Leaf>
  );
}
