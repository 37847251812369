function lockScrollbar() {
  const actualOverflow = document.body.style.overflow;
  const actualScrollGutter = document.body.style.scrollbarGutter;
  const actualDocOverflow = document.documentElement.style.overflow;
  document.body.setAttribute("data-bs-overflow", actualOverflow);
  document.body.setAttribute("data-bs-scrollbarGutter", actualScrollGutter);
  document.documentElement.setAttribute("data-doc-overflow", actualDocOverflow);

  document.documentElement.style.overflow = "hidden";
  document.body.style.overflow = "hidden";
  const isScroll =
    document.body.scrollHeight > document.documentElement.clientHeight;

  if (isScroll) document.body.style.scrollbarGutter = "stable";
}

export { lockScrollbar };
