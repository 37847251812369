import { Menu, Button, Icon, Text } from "@ttc3k/trekker";
import { OrderRefundModal } from "core/components/shared/OrderRefundModal";
import { OrderManyItems } from "core/types";
import { RefundManyQuery } from "gql/generated";
import { MoreHoriz, Undo } from "iconoir-react";
import { useState } from "react";

enum OrderMenuOption {
  Refund = "REFUND",
}

type OrderMenuProps = {
  order: OrderManyItems[0];
  orderRefunds: RefundManyQuery | undefined;
};

export const OrderMenu = ({ order, orderRefunds }: OrderMenuProps) => {
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  const handleMenuSelect = (value: OrderMenuOption) => {
    switch (value) {
      case OrderMenuOption.Refund:
        setIsRefundModalOpen(true);
        break;
    }
  };
  return (
    <>
      <Menu.Root
        trigger={
          <Button
            Icon={MoreHoriz}
            visual={"outline"}
            p={"8px"}
            css={{
              "& > svg": { width: "icons.sm", height: "icons.sm" },
            }}
          />
        }
        positioning={{ placement: "bottom-end" }}
        onSelect={d => handleMenuSelect(d.value as OrderMenuOption)}
      >
        <Menu.Item
          value={OrderMenuOption.Refund}
          gap={"100"}
          justifyContent={"flex-start"}
        >
          <Icon Element={Undo} />
          <Text visual={"smallMedium"} color={"text.dark"}>
            Refund
          </Text>
        </Menu.Item>
      </Menu.Root>
      <OrderRefundModal
        isOpen={isRefundModalOpen}
        onClose={() => setIsRefundModalOpen(false)}
        order={order}
        orderRefunds={orderRefunds}
      />
    </>
  );
};
