import { useFormHandler } from "core/hooks/useFormHandler";
import { ProductByIdDocument, useProductByIdQuery, useProductUpdateMutation } from "gql/generated";
import { useCallback } from "react";
import { z } from "zod";

const updateFeeFormSchema = z.object({
  name: z.string().min(1, "Please enter a name"),
  description: z.string(),
  price: z
    .string()
    .min(1, "Please enter a price")
    .transform(val => parseFloat(val))
    .refine(val => !isNaN(val) && val >= 0, {
      message: "Please enter a positive number",
    })
    .transform(val => val.toString()),
});

export type UpdateFeeFormValues = z.infer<typeof updateFeeFormSchema>;

interface UpdateFeeFormOptions {
  onSuccess?: (v: UpdateFeeFormValues | undefined) => void;
  onError?: (message: string) => void;
  productID: string;
}

export const useUpdateFeeForm = ({
  onSuccess,
  onError,
  productID,
}: UpdateFeeFormOptions) => {
  const { data: productData } = useProductByIdQuery({
    variables: { id: productID },
  });

  const defaultValues = {
    description: productData?.productById?.description?.en ?? "",
    name: productData?.productById?.name.en ?? "",
    price: productData?.productById?.pricing.tiers[0]?.price
      ? (productData?.productById?.pricing.tiers[0]?.price / 100).toString()
      : "0",
  };

  const [updateProduct] = useProductUpdateMutation();
  return useFormHandler<UpdateFeeFormValues>(
    useCallback(
      async data => {
        const payload = { ...data };
        const { price, name, description } = payload;

        const formErrorMessage =
          "Could not update this product, please try again later";
        if (productID && productData && productData?.productById) {
          updateProduct({
            variables: {
              id: productID,
              record: {
                name: {
                  en: name,
                  es: productData.productById.name.es,
                  fr: productData.productById.name.fr,
                },
                description: {
                  en: description,
                  es: productData.productById.description?.es,
                  fr: productData.productById.description?.fr,
                },
                pricing: {
                  description: {
                    en: productData.productById.pricing.description?.en,
                    es: productData.productById.pricing.description?.es,
                    fr: productData.productById.pricing.description?.fr,
                  },
                  tiers: productData.productById.pricing.tiers.map(v => {
                    return {
                      _id: v?._id,
                      label: {
                        en: v?.label?.en,
                        es: v?.label?.es,
                        fr: v?.label?.fr,
                      },
                      type: v?.type,
                      price: Number(price) * 100,
                    };
                  }),
                },
              },
            },
            refetchQueries: [ProductByIdDocument]
          })
            .then(() => onSuccess?.(data as UpdateFeeFormValues))
            .catch(() => onError?.(formErrorMessage));
        } else {
          onError?.(formErrorMessage);
        }
      },
      [onError, onSuccess, productData, productID, updateProduct],
    ),
    defaultValues,
    { schema: updateFeeFormSchema },
  );
};
