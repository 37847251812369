import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactNode, useEffect, useState } from "react";
import { NewImage } from "gql/generated";

type SortableItemProps = {
  children: ReactNode;
  id: string;
};

const SortableItem = ({ children, id }: SortableItemProps) => {
  const { setNodeRef, attributes, listeners, transform, transition } =
    useSortable({ id: id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div
      key={id}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="tw-flex tw-flex-col tw-items-center tw-justify-end tw-gap-[12px]"
    >
      {children}
    </div>
  );
};

export type SortableProps = {
  value: Partial<NewImage>[];
  onChange?: (value: Partial<NewImage>[]) => void;
  sortableComponent?: (item: Partial<NewImage>, index: number) => ReactNode;
  selectorID: keyof Partial<NewImage>;
};

export const Sortable = props => {
  const { value = [], onChange, sortableComponent, selectorID } = props;
  const [localValues, setLocalValues] = useState<{ id: string }[]>([]);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const onDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;
    if (active.id === over?.id) return;
    const oldIndex = localValues.findIndex(item => item.id === active.id);
    const newIndex = localValues.findIndex(item => item.id === over?.id);
    const newArray = arrayMove(localValues, oldIndex, newIndex);
    setLocalValues(newArray);
    onChange?.(newArray);
  };

  useEffect(() => {
    const newArray = value.map(item => ({
      ...item,
      id: String(item[selectorID]),
    }));
    setLocalValues(newArray);
  }, [value, selectorID]);

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      sensors={sensors}
    >
      <SortableContext items={localValues} strategy={rectSortingStrategy}>
        <div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-[24px] tw-w-full">
          {localValues.map((item, i) => {
            const { id, ...rest } = item;
            return (
              <SortableItem key={id} id={id}>
                {sortableComponent?.(rest, i)}
              </SortableItem>
            );
          })}
        </div>
      </SortableContext>
    </DndContext>
  );
};
