import { z } from "zod";

// Profile schema
export const userSchema = z
  .object({
    given_name: z.string().min(1, "Required"),
    family_name: z.string().min(1, "Required"),
    email: z
      .string()
      .email("Please enter a valid email")
      //.regex(/^[a-z0-9+_.-]+@[a-z0-9.-]+$/, "Email must be lowercase")
      .min(1, "Required"),
    password: z.string().min(8, "Passwords must be a minimum of 8 characters"),
    confirmPassword: z.string().min(1, "Required"),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: "custom",
        message: "The passwords did not match",
        path: ["confirmPassword"],
      });
    }
  });

export type userSchema = z.infer<typeof userSchema>;

export const userDefaultValues: userSchema = {
  given_name: "",
  family_name: "",
  email: "",
  password: "",
  confirmPassword: "",
};
