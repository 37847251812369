export const getAutocompleteAddress = (arg: google.maps.GeocoderResult) => {
    const addressObject = {
        province: '',
        city: '',
        line1: '',
        postal_code: '',
        country: ''
    };

    arg.address_components.forEach(item => {
        item.types.forEach(type => {
            switch (type) {
                case 'administrative_area_level_1':
                    addressObject.province = item.long_name;
                    break;
                case 'country':
                    addressObject.country = item.long_name;
                    break;
                case 'locality':
                    addressObject.city = item.long_name;
                    break;
                case 'postal_code':
                    addressObject.postal_code = item.long_name;
                    break;
                case 'street_number':
                    addressObject.line1 = item.long_name;
                    break;
                case 'route':
                    addressObject.line1 = addressObject.line1 ? `${addressObject.line1} ${item.long_name}` : item.long_name;
                    break;
                default:
                    break;
            }
        });
    });

    return addressObject
}