import {
  ClipboardButton as TrekkerClipboardButton,
  Heading,
  SectionHeader,
  Select,
  Spinner,
  Text,
} from "@ttc3k/trekker";
import { Booker, BookerPosition } from "apps/custom/ttc/booker/App";
import { useBookerByIdQuery } from "gql/generated";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Box, Center, Divider, Flex, styled } from "styled-system/jsx";
import { Code } from "iconoir-react";
import { generateEmbedStrings } from "./utils";

const embedOptions = [{ label: "My own custom website (html)", value: "html" }];

const ClipboardButton = styled(TrekkerClipboardButton);
const CodeText = styled(Text, {
  base: {
    fontFamily: "mono",
    fontSize: "2xs",
    lineHeight: "2xs",
  },
});

export function BookerEmbedInstructions() {
  const { bookerId } = useParams();
  const [searchParams] = useSearchParams();
  const [selectedEmbedOption, setSelectedEmbedOption] = useState("html");
  const buttonText = searchParams?.has("text")
    ? searchParams?.get("text") ?? undefined
    : undefined;
  const buttonColor = searchParams?.has("color")
    ? searchParams?.get("color") ?? undefined
    : undefined;
  const buttonBgColor = searchParams?.has("bgColor")
    ? searchParams?.get("bgColor") ?? undefined
    : undefined;
  const buttonRadius = searchParams?.has("radius")
    ? searchParams?.get("radius") ?? undefined
    : undefined;
  const bookerPosition = searchParams?.has("position")
    ? searchParams?.get("position") ?? undefined
    : undefined;

  const isDefaultBorderRadius = !buttonRadius || buttonRadius === "8";
  const isDefaultColor =
    !buttonColor || ["white", "#ffffff", "#FFFFFF"].includes(buttonColor);
  const isDefaultBg = !buttonBgColor || buttonBgColor === "#27272A";

  const { data, loading, error } = useBookerByIdQuery({
    variables: { id: bookerId },
  });

  const mainProduct = data?.bookerById?.productBundles[0]?.product;

  if (!bookerId) {
    return (
      <Center bg={"bg.wa.lightest"} minH={"100vh"} width={"full"}>
        <Text>Missing booker ID</Text>
      </Center>
    );
  }

  if (!data && !loading) {
    return (
      <Center bg={"bg.wa.lightest"} minH={"100vh"} width={"full"}>
        <Text>Could not find booker from id</Text>
      </Center>
    );
  }
  if (error && !loading) {
    return (
      <Center bg={"bg.wa.lightest"} minH={"100vh"} width={"full"}>
        <Text>Error: {error.message}</Text>
      </Center>
    );
  }
  if (loading) {
    <Center bg={"bg.wa.lightest"} minH={"100vh"} width={"full"}>
      <Spinner width={"50px"} height={"50px"} />
    </Center>;
  }

  const embedCodeString = generateEmbedStrings({
    bookerId,
    bookerPosition,
    bgColor: buttonBgColor,
    color: buttonColor,
    text: buttonText,
    borderRadius: buttonRadius,
  });

  return (
    <Flex
      bg={"bg.wa.lightest"}
      height={"full"}
      flexDir={{ base: "column", lg: "row" }}
    >
      <Flex
        px={"500"}
        py={"120px"}
        width={"full"}
        height={"full"}
        flexDir={"column"}
        gap={"400"}
      >
        <Flex flexDir={"column"} gap={"100"}>
          <Text visual={"smallLabel"} color={"text.mid"}>
            Booker Preview
          </Text>
          <Heading size={"h4"} color={"text.dark"}>
            {mainProduct?.name.en ??
              mainProduct?.name.fr ??
              mainProduct?.name.es}
          </Heading>
        </Flex>
        <Text color={"text.mid"}>
          On this page, you can see exactly how your Booker launcher button and
          your Booker itself will appear to consumers once embedded. Its embed
          code is simple and should be easy to add on any webpage; select from
          the guides below for more information.
        </Text>
        <Divider borderColor={"border.light"} />
        <Select.Root
          items={embedOptions}
          value={[selectedEmbedOption]}
          onValueChange={({ value }: { value: string[] }) =>
            setSelectedEmbedOption(value[0])
          }
          label={"Show instructions for embedding on..."}
          css={{
            "& [data-scope='select'][data-part='trigger']": {
              bg: "white",
            },
          }}
        >
          {embedOptions.map(v => (
            <Select.Item key={v.value} item={v} />
          ))}
        </Select.Root>
        {selectedEmbedOption === "html" && (
          <Flex flexDir={"column"} gap={"200"}>
            <SectionHeader text="Embed Code" Icon={Code} />
            <Text color={"text.mid"} visual={"smallRegular"}>
              Copy and paste the following embed code into the&nbsp;
              <CodeText as={"span"}>&#60;body&#47;&#62;</CodeText>
              &nbsp;of your html site. Paste the&nbsp;
              <CodeText as={"span"}>&#60;div&#47;&#62;</CodeText>
              &nbsp;component anywhere you'd like your button to display on your
              page. You can put more than one&nbsp;
              <CodeText as={"span"}>&#60;div&#47;&#62;</CodeText>
              &nbsp;on your page. <br /> Place the&nbsp;
              <CodeText as={"span"}>&#60;script&#47;&#62;</CodeText>
              &nbsp;tag anywhere in the body of your code, you do not need to
              repeat it no matter how many divs you have you on your page.
            </Text>
            <Box
              bg={"bg.wa.light"}
              borderRadius={"100"}
              pl={"200"}
              py={"200"}
              pr={"800"}
              position={"relative"}
            >
              <Text
                color={"text.mid"}
                fontSize={"sm"}
                lineHeight={"sm"}
                fontFamily={"mono"}
                width={"full"}
              >
                {embedCodeString.div}
                <br />
                {embedCodeString.script}
              </Text>

              <ClipboardButton
                value={embedCodeString.div + embedCodeString.script}
                position={"absolute"}
                top={"200"}
                right={"200"}
              />
            </Box>
          </Flex>
        )}
      </Flex>

      <Flex
        px={"500"}
        py={"120px"}
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"100"}
        bgColor={"bg.wa.light"}
        maxW={{ base: "full", lg: "400px" }}
        height={"full"}
      >
        <Booker
          bookerID={bookerId ?? ""}
          buttonStyle={{
            ...(buttonRadius && !isDefaultBorderRadius
              ? { borderRadius: buttonRadius + "px" }
              : {}),
            ...(buttonColor && !isDefaultColor ? { color: buttonColor } : {}),
          }}
          buttonBgColor={!isDefaultBg ? buttonBgColor : undefined}
          buttonCopy={buttonText}
          bookerPosition={bookerPosition as BookerPosition | undefined}
        />
        <Text color={"text.mid"} textAlign={"center"} visual={"smallMedium"}>
          Click this button to see how your Booker will launch and appear on
          your webpage.
        </Text>
      </Flex>
    </Flex>
  );
}
