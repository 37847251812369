//@ts-nocheck
import { motion } from "framer-motion";
import {
  LegacyButton,
  LegacyLoadingSpinner,
  LegacyProductTile,
  Sizes,
  tokens,
  LegacyTypography,
  TypographyVariant,
  ButtonTagVariant,
} from "@ttc3k/ttc-design-system";
import { useLocation, useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useCreateNotification } from "../../hooks/useCreateNotification.tsx";
import { insertAndAfterLastComma } from "../../utils/insertAndAfterLastComma.ts";
import SlideInPage from "../animation/SlideInPage.tsx";
import { useParams } from "react-router";

const GET_MEMBERS_BY_ID = gql`
  query GetMembersById($filter: FilterFindManyPersonInput) {
    personMany(filter: $filter) {
      _id
      firstName
      lastName
      accountStatus
      email
      tenantsOf {
        tenantId
        role
      }
    }
  }
`;

const CREATE_TENANT = gql`
  mutation createTenant($user_id: String, $operators: String) {
    createTenant(user_id: $user_id, operators: $operators) {
      _id
    }
  }
`;

const UPDATE_USER_METADATA = gql`
  mutation updateUserMetadata(
    $id: String
    $body: String
    $tenantId: String
    $userObject: String
  ) {
    updateUserMetadata(user_id: $id, body: $body) {
      user_id
    }
    addUserToTenant(tenantId: $tenantId, userObject: $userObject) {
      name
    }
  }
`;

const GET_OPERATORS_ONBOARDING = gql`
  query operators($tenants: String, $limit: Int, $offset: Int, $email: String) {
    getOperators(
      tenants: $tenants
      limit: $limit
      offset: $offset
      email: $email
    ) {
      data {
        operator_name
        address
        _id
      }
    }
  }
`;

const GET_OPERATORS_BY_ID = gql`
  query GetOperatorsByID($input: GetOperatorsByIDInput) {
    getOperatorsByID(input: $input) {
      email
      operator_name
      _id
      address
    }
  }
`;

const SAVE_ONBOARDING_STEP = gql`
  mutation saveOnboardingStep($id: String, $page: String, $type: String) {
    saveOnboardingStep(user_id: $id, page: $page, type: $type) {
      user_id
    }
  }
`;

const SET_ONBOARDING_STATUS = gql`
  mutation setOnboardingStatus($id: String, $status: Boolean) {
    setOnboardingStatus(user_id: $id, status: $status) {
      user_id
    }
  }
`;

const GET_ORG = gql`
  query Org($slug: String) {
    org(slug: $slug) {
      slug
      name
      customPortals
      logo {
        full_url
      }
      tripperSettings {
        teamId
      }
    }
  }
`;

export const PostBoardingAcceptInvitation = ({ urlAfterClaimFlow }) => {
  const { user } = useAuth0();
  const { state } = useLocation();

  // const {
  //   data: personData,
  //   loading: personLoading,
  //   error: personError,
  // } = useQuery(GET_MEMBERS_BY_ID, {
  //   fetchPolicy: "no-cache",
  //   variables: {
  //     filter: {
  //       email: user?.email,
  //     },
  //   },
  // });

  const inviteIds = state?.tenantsOf.map(t => {
    if (t.role == "INVITED") {
      return t.tenantId;
    }
  });

  if (inviteIds == undefined) {
    return (
      <SlideInPage>
        <div className={"tw-w-full md:tw-w-96 tw-flex tw-flex-col tw-gap-5"}>
          <LegacyTypography as="h2" variant="H2">
            We can't find any invitations waiting for you.
          </LegacyTypography>
        </div>
      </SlideInPage>
    );
  }

  const {
    loading: operatorLoading,
    error: operatorError,
    data: operatorData,
  } = useQuery(GET_OPERATORS_BY_ID, {
    variables: { input: { operatorIds: inviteIds } },
  });

  const navigate = useNavigate();

  const [
    updateUserMetadata,
    { data: dataUser, loading: loadingUser, error: errorUser },
  ] = useMutation(UPDATE_USER_METADATA);
  const { data, loading, error } = useQuery(GET_OPERATORS_ONBOARDING, {
    variables: {
      limit: 30,
      offset: 0,
      email: user?.email,
      tenants: JSON.stringify([user?.sub, "ohto|660dfd13dd810ca68f35231f"]),
    },
  });
  const baseURL = "/";
  const location = useLocation();
  const { hash, pathname, search } = location;
  const [
    saveOnboardingStep,
    {
      data: successSaveOnboardingStep,
      loading: loadingSaveOnboardingStep,
      error: errorSaveOnboardingStep,
    },
  ] = useMutation(SAVE_ONBOARDING_STEP);
  const { slug, program } = useParams();
  const [
    setOnboardingStatus,
    {
      data: successSetOnboardingStatus,
      loading: loadingSetOnboardingStatus,
      error: errorSetOnboardingStatus,
    },
  ] = useMutation(SET_ONBOARDING_STATUS);

  //State for claim flow
  const { multipleTourismBusinesses, setMultipleTourismBusinesses } =
    useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [claimSuccessNextURL, setClaimSuccessNextURL] = useState("");

  const [createNotification, { loading: notificationLoading }] =
    useCreateNotification();
  const [createTenant, { data: saved }] = useMutation(CREATE_TENANT);

  useEffect(() => {
    if (data?.getOperators.data) {
      setBusinesses([
        ...data.getOperators.data,
        ...operatorData?.getOperatorsByID,
      ]);
    }
  }, [data]);

  if (loading || operatorLoading) {
    return <h1>Loading .... </h1>;
  }

  if (error || operatorError) {
    return <h1>Error .... </h1>;
  }

  const handleApprove = () => {
    createTenant({
      variables: {
        user_id: user?.sub,
        operators: JSON.stringify(businesses.map(b => b._id)),
      },
    })
      .then(r =>
        updateUserMetadata({
          variables: {
            id: user.sub,
            body: JSON.stringify({
              app_metadata: {
                tenants: [user.sub],
                onboarding: {
                  complete: urlAfterClaimFlow == "SUCCESS",
                  operatorId: businesses[0]._id,
                  businesses: businesses,
                },
              },
            }),
            tenantId: JSON.stringify([user.sub]),
            userObject: JSON.stringify(user),
          },
        }),
      )
      .then(r =>
        createNotification({
          variables: {
            notification: JSON.stringify({
              tenant: user?.sub,
              title: `Profile${businesses.length > 1 ? "s" : ""} joined`,
              button: { label: "Review", path: "/app/directory" },
              message: insertAndAfterLastComma(
                businesses
                  .map(business => `${business.operator_name}`)
                  .join(", "),
              ),
            }),
          },
        }),
      )
      .then(result =>
        navigate(`${baseURL}/${urlAfterClaimFlow}`, {
          state: { operatorId: businesses[0]._id, businesses: businesses },
        }),
      );
  };

  const handleOops = () => {
    navigate(`${baseURL}/help`);
  };

  return (
    <SlideInPage>
      {businesses?.length == 0 ? (
        <LegacyTypography>Something went wrong.</LegacyTypography>
      ) : (
        <div className={"tw-w-full md:tw-w-96 tw-flex tw-flex-col tw-gap-5"}>
          <LegacyTypography as="h2" variant="H2">
            Welcome!
          </LegacyTypography>
          <LegacyTypography className="tw-mt-10 tw-mb-6">
            You've been invited to join
          </LegacyTypography>
          {businesses?.map(business => {
            return (
              <LegacyProductTile
                outlined
                key={business._id}
                style={{
                  padding: `${tokens.spacing.space250} ${tokens.spacing.space300}`,
                  cursor: "default",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className={"tw-flex tw-flex-col tw-gap-1 tw-text-left"}>
                  <LegacyTypography variant={TypographyVariant.primaryBold}>
                    {business.operator_name}
                  </LegacyTypography>
                  <LegacyTypography color={tokens.semantic.textSubtle}>
                    {business.address}
                  </LegacyTypography>
                </div>
              </LegacyProductTile>
            );
          })}
        </div>
      )}
      <div className="tw-w-full tw-flex tw-justify-between tw-gap-3 tw-mt-5">
        <LegacyButton
          onClick={handleApprove}
          type="button"
          fullWidth
          disabled={businesses == 0}
        >
          {loadingUser || loadingSetOnboardingStatus ? (
            <LegacyLoadingSpinner size={20} />
          ) : (
            "Continue"
          )}
        </LegacyButton>
      </div>
    </SlideInPage>
  );
};
