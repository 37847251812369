import _ from "lodash";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

export type ProductContextValues = {
  isUnsavedChanges: boolean;
  isFormError: boolean;
  useWatchProductFormState: <T extends FieldValues>(
    form: UseFormReturn<T>,
  ) => void;
  setIsUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFormError: React.Dispatch<React.SetStateAction<boolean>>;
};

type ProductContextProviderProps = PropsWithChildren;

const context = React.createContext<ProductContextValues | null>(null);

export const ProductContextProvider: React.FC<ProductContextProviderProps> = ({
  children,
}) => {
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [isFormError, setIsFormError] = useState(false);

  const useWatchProductFormState = <T extends FieldValues>(
    form: UseFormReturn<T>,
  ) => {
    const currentValues = form.getValues();
    const defaultValues = form.formState.defaultValues;
    const areFormFieldsEqual = useMemo(
      () => _.isEqual(currentValues, defaultValues),
      [currentValues, defaultValues],
    );
    const isFormDirty = form.formState.isDirty;
    const errors = Object.values(form.formState.errors);

    useEffect(() => {
      if (!areFormFieldsEqual || isFormDirty) {
        setIsUnsavedChanges(true);
      } else {
        setIsUnsavedChanges(false);
      }
    }, [form.formState.isSubmitted, areFormFieldsEqual, isFormDirty]);

    useEffect(() => {
      if (errors.length > 0) {
        setIsFormError(true);
      } else {
        setIsFormError(false);
      }
    }, [errors]);
  };

  const contextValue: ProductContextValues = useMemo(
    () => ({
      isUnsavedChanges,
      isFormError,
      useWatchProductFormState,
      setIsFormError,
      setIsUnsavedChanges,
    }),
    [isUnsavedChanges, isFormError],
  );

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export function useProductContext() {
  const bookerContext = React.useContext(context);

  if (!bookerContext) {
    throw new Error(
      "useProductContext must be used within a ProductContextProvider",
    );
  }

  return bookerContext;
}
