import _ from "lodash";

type CalendarLinks =
  | (
      | {
          _id?: string | undefined;
          link?: string | undefined;
        }
      | undefined
    )[]
  | undefined;

export const transformCalendarLinksByProduct = (value: CalendarLinks) => {
  return _.map(
    _.groupBy(value ? value.filter(v => !!v) : [], "_id"),
    (linksGroup, id) => ({
      productId: id,
      links: _.map(linksGroup, "link"),
    }),
  )
    .map(v => ({ ...v, links: _.compact(v.links) }))
    .filter(
      v =>
        !!v &&
        !!v.productId &&
        v.productId.length > 0 &&
        !!v.links &&
        (v.links.length > 0
          ? v.links.every(link => link && link.length > 0)
          : true),
    );
};
