import { SectionHeader } from "@ttc3k/trekker";
import { ProductManyQuery } from "gql/generated";
import { NavLink } from "react-router-dom";
import { Grid, styled } from "styled-system/jsx";
import { CreateNewBox } from "./CreateNewBox";
import { ProductCard } from "./ProductCard";

type ProductSectionProps = {
  products: ProductManyQuery["productMany"];
  title: string;
  sectionValue: string;
  onCreateNewUpsell?: () => void;
};

export const ProductSection = ({
  products,
  title,
  sectionValue,
  onCreateNewUpsell,
}: ProductSectionProps) => {
  const isDraft = sectionValue === "draft";
  return (
    <>
      <SectionHeader text={title} divider />
      <Grid
        gap={"300"}
        gridTemplateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
        }}
      >
        {products.map(p => (
          <ProductCard key={p._id} product={p} />
        ))}
        {!isDraft && (
          <>
            {sectionValue !== "UPSELL" ? (
              <NavLink
                to={`create-product/${sectionValue}`}
                style={{ gridColumnStart: 1 }}
              >
                <CreateNewBox itemText={sectionValue.toLowerCase()} />
              </NavLink>
            ) : (
              <styled.button
                textAlign={"left"}
                gridColumnStart={1}
                onClick={onCreateNewUpsell}
              >
                <CreateNewBox itemText={sectionValue.toLowerCase()} />
              </styled.button>
            )}
          </>
        )}
      </Grid>
    </>
  );
};
