import { CheckCircleSolid, MediaImage, Xmark } from "iconoir-react";
import {
  LegacyTypography,
  ModalVariant,
  tokens,
  TypographyVariant,
  LegacyModal,
  LegacyButton,
  ButtonTagVariant,
} from "@ttc3k/ttc-design-system";
import React from "react";
import { createPortal } from "react-dom";
import "./modal-overrides.scss";
import { useModalRoot } from "core/hooks/useModalRoot";

interface PerfectThumbnailModalProps {
  modalOverlayRef: React.RefObject<HTMLDivElement>;
  onModalClose: () => void;
}

export const PerfectThumbnailModal = ({
  modalOverlayRef,
  onModalClose,
}: PerfectThumbnailModalProps) => {
  const { modalRoot } = useModalRoot();
  return createPortal(
    <div className={"ttc-modal-override"}>
      <LegacyModal
        variant={ModalVariant.wbts}
        modalOverlayRef={modalOverlayRef}
        onModalClose={onModalClose}
      >
        <div className={"tw-flex tw-flex-col tw-gap-6"}>
          <div
            className={"tw-w-full tw-flex tw-justify-between tw-items-center"}
          >
            <div className={"tw-flex tw-gap-3 tw-items-center"}>
              <MediaImage color={tokens.semantic.icon} />
              <LegacyTypography variant={TypographyVariant.h5}>
                The Perfect Thumbnail
              </LegacyTypography>
            </div>
            <LegacyButton
              icon={<Xmark />}
              variant={ButtonTagVariant.Unstyled}
              onClick={onModalClose}
            />
          </div>
          <LegacyTypography color={tokens.semantic.textSubtle}>
            Here are a few tips for picking a great thumbnail image — one that
            will look good in all contexts.
          </LegacyTypography>
          <div className={"tw-flex tw-gap-5 max-sm:tw-flex-col"}>
            <div
              className={
                "tw-flex tw-flex-col tw-gap-2 tw-py-5 tw-px-6 tw-bg-[#F1EDE7] tw-rounded-lg"
              }
            >
              <div className={"tw-flex tw-gap-3 tw-items-center"}>
                <CheckCircleSolid color={tokens.semantic.iconSuccess} />
                <LegacyTypography variant={TypographyVariant.primaryMedium}>
                  Avoid text-heavy images
                </LegacyTypography>
              </div>
              <LegacyTypography
                variant={TypographyVariant.secondaryRegular}
                color={tokens.semantic.textSubtle}
              >
                Your image will often be featured on pages with a ton of text
                already. Avoid text to make sure your image really pops
              </LegacyTypography>
            </div>
            <div
              className={
                "tw-flex tw-flex-col tw-gap-2 tw-py-5 tw-px-6 tw-bg-[#F1EDE7] tw-rounded-lg"
              }
            >
              <div className={"tw-flex tw-gap-3 tw-items-center"}>
                <CheckCircleSolid color={tokens.semantic.iconSuccess} />
                <LegacyTypography variant={TypographyVariant.primaryMedium}>
                  Consider different layouts
                </LegacyTypography>
              </div>
              <LegacyTypography
                variant={TypographyVariant.secondaryRegular}
                color={tokens.semantic.textSubtle}
              >
                Browsers and devices come in different sizes. Try to pick an
                image that can work well in both landscape and portrait layouts
              </LegacyTypography>
            </div>
          </div>
          <LegacyTypography color={tokens.semantic.textSubtle}>
            Here’s a great example of a flexible, punchy thumbnail image. The
            main focus point is in the centre, making it easier for the image to
            scale and work well in different layouts.
          </LegacyTypography>
          <img
            src={
              "https://res.cloudinary.com/dsy97ue6s/image/upload/v1717539012/whereabouts/dpldlpmnwsbaz1wwfng5.png"
            }
            alt={"Image cropping mask"}
          />
        </div>
      </LegacyModal>
    </div>,
    modalRoot,
  );
};
