import { ComponentProps, forwardRef } from "react";
import { LegacyTypography, TypographyVariant } from "@ttc3k/ttc-design-system";
import { cn } from "../../../../utils/cn";

export type ButtonProps = ComponentProps<"button"> & {
  variant?: "default" | "info" | "warning" | "error";
};

export type Ref = HTMLButtonElement;

export const Button = forwardRef<Ref, ButtonProps>((props, ref) => {
  const { variant = "default", className, children, ...rest } = props;

  /* TODO: Yellows/warning should be a token, not hex */
  return (
    <button
      className={cn(
        "tw-py-[6px] tw-px-[12px] tw-flex tw-justify-center tw-items-center tw-rounded-[8px]",
        {
          "tw-bg-bgWbtsSubtlest tw-text-textSubtle ": variant === "default",
          "tw-bg-bgInfoBold tw-text-textInverse ": variant === "info",
          "tw-bg-[#FFCE84] tw-text-[#8A5402] ": variant === "warning",
          "tw-bg-bgErrorBold tw-text-textInverse ": variant === "error",
        },
        className,
      )}
      ref={ref}
      {...rest}
    >
      <LegacyTypography variant={TypographyVariant.tertiaryBold}>
        {children}
      </LegacyTypography>
    </button>
  );
});
