import { Card, Tag } from "@ttc3k/trekker";
import { DateRangeIso, useProductByIdQuery } from "gql/generated";
import { getDateRangeString } from "core/utils/getDateRangeString";
import _ from "lodash";
import { getTotalGuestCount } from "booker/shared/utils";
import pluralize from "pluralize";
import { isSameDay } from "date-fns";
import { parseToUTCDate } from "core/utils/parseToUTCDate";
import { NavLink } from "react-router-dom";
import { OrderManyItems } from "core/types";

type ReservationCardProps = {
  order: OrderManyItems[0];
  selectedDate: string;
  onReservationHover: (dateRangeISO: DateRangeIso | undefined) => void;
};

export const ReservationCard = ({
  order,
  selectedDate,
  onReservationHover,
}: ReservationCardProps) => {
  const mainProduct = order.purchasedProducts[0];
  const { data } = useProductByIdQuery({
    variables: { id: mainProduct?.product._id ?? "" },
  });
  const mainProductClaimedDateRange =
    mainProduct?.inventoryClaims[0]?.claimedDateRangeISO;
  const dateRangeString = mainProductClaimedDateRange
    ? getDateRangeString(mainProductClaimedDateRange)
    : null;

  const totalGuestCount = getTotalGuestCount(mainProduct?.guestsPerTier ?? []);

  const productName =
    data?.productById?.name.en ??
    data?.productById?.name.es ??
    data?.productById?.name.fr ??
    "Unknown product name";

  const subtextString = _.compact([
    productName,
    dateRangeString,
    totalGuestCount > 0 ? pluralize("guests", totalGuestCount, true) : null,
  ]).join(" • ");

  const isActiveDateSameAsStartDate = mainProductClaimedDateRange
    ? isSameDay(
        parseToUTCDate(selectedDate),
        parseToUTCDate(mainProductClaimedDateRange.start),
      )
    : false;
  const isActiveDateSameAsEndDate = mainProductClaimedDateRange
    ? isSameDay(
        parseToUTCDate(selectedDate),
        parseToUTCDate(mainProductClaimedDateRange.end),
      )
    : false;

  return (
    <NavLink to={`order-details/${order._id}`}>
      <Card.Surface
        bg={"white"}
        boxShadow={"25"}
        transitionProperty={"border, outline"}
        transitionDuration={"normal"}
        transitionTimingFunction={"default"}
        outline={"1px solid transparent"}
        _hover={{
          borderColor: "border.dark",
          outline: "1px solid token(colors.border.dark)",
        }}
        onMouseEnter={() =>
          onReservationHover(mainProductClaimedDateRange ?? undefined)
        }
        onMouseLeave={() => onReservationHover(undefined)}
      >
        <Card.Body py={"250"}>
          <Card.BodyContent alignItems={"center"}>
            <Card.Title
              mainText={order.customerContact.name}
              subText={subtextString}
            />
            {(isActiveDateSameAsStartDate || isActiveDateSameAsEndDate) && (
              <Tag
                visual={isActiveDateSameAsStartDate ? "accent" : "highlight"}
                mode={isActiveDateSameAsStartDate ? "dark" : "light"}
              >
                {isActiveDateSameAsStartDate ? "Start" : "End"}
              </Tag>
            )}
          </Card.BodyContent>
        </Card.Body>
      </Card.Surface>
    </NavLink>
  );
};
