import { Card, Icon } from "@ttc3k/trekker";
import { getDateRangeString } from "core/utils/getDateRangeString";
import { Edit } from "iconoir-react";
import { useState } from "react";
import { Box, styled } from "styled-system/jsx";
import { NewClosure } from "../../hooks";
import { EditClosureModal } from "./EditClosureModal";

type ClosureCardProps = {
  closure: NewClosure;
  operatorID: string;
};

export const ClosureCard = ({ closure, operatorID }: ClosureCardProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const dateRangeString = getDateRangeString(closure.dateRangeISO);

  const subText =
    (closure.isFullClosure ? "Full closure" : "Partial closure") +
    " • " +
    dateRangeString;

  const conditionalProps = {
    ...(hovered ? { "data-open": true } : {}),
    ...(!hovered ? { "data-closed": true } : {}),
  };

  return (
    <>
      <styled.button
        textAlign={"left"}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => setIsEditModalOpen(true)}
      >
        <Card.Surface
          bg={"bg.highlight.lightest"}
          boxShadow={"25"}
          transitionProperty={"border, outline"}
          transitionDuration={"normal"}
          transitionTimingFunction={"default"}
          outline={"1px solid transparent"}
          borderColor={"border.highlight"}
          _hover={{
            borderColor: "border.highlight",
            outline: "1px solid token(colors.border.highlight)",
          }}
        >
          <Card.Body py={"250"}>
            <Card.BodyContent alignItems={"center"}>
              <Box
                css={{ "& *[data-subtext]": { color: "text.highlight.dark" } }}
              >
                <Card.Title mainText={closure.name} subText={subText} />
              </Box>
              {hovered && (
                <Box
                  color={"icon.highlight.dark"}
                  _open={{ animation: "fadeIn 200ms" }}
                  _closed={{ animation: "fadeOut 200ms" }}
                  {...conditionalProps}
                >
                  <Icon Element={Edit} />
                </Box>
              )}
            </Card.BodyContent>
          </Card.Body>
        </Card.Surface>
      </styled.button>
      <EditClosureModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        closureID={closure._id}
        operatorID={operatorID}
      />
    </>
  );
};
