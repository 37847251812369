import { RowProps } from "./components/row";
import { MonthsValues, initialMonthsValues } from "./components/utils";

export const seasons = ["high", "low", "closed"] as const;

export type Season = (typeof seasons)[number];

export type SeasonMetadata = {
  shortName: string;
  name: string;
  variant: RowProps["variant"];
};

export type SeasonsMetadata = Record<Season, SeasonMetadata>;
export type SeasonsValues = Record<Season, MonthsValues>;
export type SeasonsErrors = Partial<Record<Season, string>>;

export const initialSeasonsValues: SeasonsValues = {
  high: initialMonthsValues,
  low: initialMonthsValues,
  closed: initialMonthsValues,
};

export const seasonsMetadata: SeasonsMetadata = {
  high: {
    shortName: "H",
    name: "High",
    variant: "info",
  },
  low: {
    shortName: "L",
    name: "Low",
    variant: "warning",
  },
  closed: {
    shortName: "C",
    name: "Closed",
    variant: "error",
  },
};
