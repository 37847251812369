import {
  LegacyTypography,
  TypographyVariant,
  tokens,
} from "@ttc3k/ttc-design-system";

type LabelProps = {
  id: string;
  label: string;
  labelRight?: string;
  error?: boolean;
};

export const Label = ({ id, label, labelRight, error = false }: LabelProps) => {
  return (
    <label htmlFor={id} className="tw-flex tw-justify-between tw-items-center">
      <LegacyTypography
        variant={TypographyVariant.secondaryMedium}
        color={tokens.semantic.textSubtle}
      >
        {label}
      </LegacyTypography>
      {labelRight && (
        <LegacyTypography
          variant={TypographyVariant.secondaryMedium}
          color={error ? tokens.semantic.textError : tokens.semantic.textSubtle}
          className="tw-self-end"
        >
          {labelRight}
        </LegacyTypography>
      )}
    </label>
  );
};
