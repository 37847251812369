//@ts-nocheck
import { motion } from "framer-motion";
import {
  LegacyButton,
  ButtonTagVariant,
  ProductTile,
  Sizes,
  tokens,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { variants } from "../onboarding/shared.ts";
import { VerifyEmail } from "../onboarding/0-VerifyEmail.tsx";
import { useOnboardingContext } from "../onboarding/Layout.tsx";

export const SelectOrg = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    isCustomOnboardingFlow,
    program,
    customPortalData,
    baseURL,
    saveOnboardingStep,
  } = useOnboardingContext();

  return (
    <motion.div
      className={
        "tw-mt-auto tw-w-full md:tw-w-96 tw-flex tw-flex-col tw-justify-center tw-gap-2 tw-text-center"
      }
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
    >
      <LegacyTypography>
        We've tried to match you with a DMO or Tourism Program. Please choose
        one from below or click the Get support button.
      </LegacyTypography>
      <Link
        className={"tw-pt-6"}
        state={{ email: state?.email }}
        to={`/onboarding/visit-the-county/members/sign-up`}
      >
        <LegacyButton fullWidth size={Sizes.Large}>
          Prince Edward County
        </LegacyButton>
      </Link>
      <Link
        className={"tw-pt-6"}
        state={{ email: state?.email }}
        to={`/onboarding/ontarios-highlands/members/sign-up`}
      >
        <LegacyButton fullWidth size={Sizes.Large}>
          Ontario's Highlands Tourism Organization
        </LegacyButton>
      </Link>
      <Link
        className={"tw-pt-6"}
        state={{ email: state?.email }}
        to={`/onboarding/northeastern-ontario/members/sign-up`}
      >
        <LegacyButton fullWidth size={Sizes.Large}>
          Northeastern Ontario Tourism
        </LegacyButton>
      </Link>
    </motion.div>
  );
};
