import { Button, Card, Spinner } from "@ttc3k/trekker";
import { useAmenityByIdsQuery } from "gql/generated";
import pluralize from "pluralize";
import _ from "lodash";
import { EditPencil } from "iconoir-react";
import { UpdateAccomFeaturesFormValues } from "./useUpdateAccomFeaturesForm";

type BedroomCardProps = {
  room: UpdateAccomFeaturesFormValues["bedrooms"][0];
  index: number;
  onEditClick: () => void;
};

export const BedroomCard = ({ room, index, onEditClick }: BedroomCardProps) => {
  const { data, loading } = useAmenityByIdsQuery({
    variables: { ids: room.amenities },
  });

  const amenityCounts = _.countBy(room.amenities);

  const amenityLabelAndCounts = data?.amenityByIds
    ? data.amenityByIds.map((a, i) => ({
        label: a.name.en ?? `No amenity name #${i + 1}`,
        count: amenityCounts[a._id],
      }))
    : [];

  const amenitiesString = amenityLabelAndCounts
    .map(a => pluralize(a.label, a.count, true))
    .join(", ");
  return (
    <Card.Surface bg={"white"} boxShadow={"sm"}>
      <Card.Body>
        <Card.BodyContent alignItems={"center"}>
          {loading ? (
            <Spinner height={"50px"} width={"50px"} />
          ) : (
            <Card.Title
              mainText={`Bedroom ${index + 1}`}
              subText={amenitiesString}
            />
          )}
          <Button
            Icon={EditPencil}
            visual={"outline"}
            onClick={onEditClick}
            type={"button"}
          />
        </Card.BodyContent>
      </Card.Body>
    </Card.Surface>
  );
};
