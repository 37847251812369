import {
  Select,
  toastFactory,
  Text,
  Input,
  Spinner,
  ClipboardButton,
} from "@ttc3k/trekker";
import { useProductCalendarLinkByIdLazyQuery } from "gql/generated";
import React, { useState } from "react";
import { Box, Flex } from "styled-system/jsx";

type ExportBookerCalendarProps = {
  productOptions: {
    value: string;
    label: string;
  }[];
};

export const ExportBookerCalendar = ({
  productOptions,
}: ExportBookerCalendarProps) => {
  const [selectedProductID, setSelectedProductID] = useState("");
  const [getCalendarLink, { data, loading }] =
    useProductCalendarLinkByIdLazyQuery();

  const handleProductSelect = async (id: string) => {
    setSelectedProductID(id);
    try {
      await getCalendarLink({ variables: { id } });
    } catch {
      toastFactory.create({
        title: "Error",
        description:
          "There has been an error loading the calendar for this product, please contact support",
      });
    }
  };
  return (
    <>
      <Box>
        <Text visual={"bodySemiBold"} color={"text.dark"}>
          Export your Booker calendars with an external calendar
        </Text>
        <Text color={"text.mid"} mt={"100"}>
          For any product you create that takes reservations, we’ll create an
          .ics link for its calendar. Below, select a product and copy/paste the
          its .ics URL into any third-party calendar service (Google Calendar,
          Apple Calendar, Airbnb...) to subscribe to the bookings for that
          product.
        </Text>
      </Box>
      <Flex gap={"150"} justifyContent={"space-between"} alignItems={"center"}>
        <Select.Root
          items={productOptions}
          value={[selectedProductID]}
          placeholder={"Select product"}
          maxW={"240px"}
          css={{
            "& [data-scope='select'][data-part='trigger']": {
              bg: "white",
            },
          }}
          onValueChange={({ value }: { value: string[] }) =>
            handleProductSelect(value[0])
          }
        >
          {productOptions.map(v => (
            <Select.Item key={v.value} item={v} />
          ))}
        </Select.Root>
        {loading ? (
          <Box minW={"408px"}>
            <Spinner h={"48px"} w={"48px"} />
          </Box>
        ) : (
          <Input
            name={`calendar-link-${selectedProductID}`}
            value={data?.productCalendarLinkById ?? ""}
            bg={"white"}
            readOnly
          />
        )}
        <ClipboardButton value={data?.productCalendarLinkById ?? ""} />
      </Flex>
    </>
  );
};
