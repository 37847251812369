export const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"] as const;

export type Day = (typeof days)[number];

export type DayProps = {
  from: string;
  to: string;
  isActive: boolean;
};

export type DayMetadata = {
  shortName: string;
  name: string;
};

export type DaysValues = Record<Day, DayProps>;
export type DaysMetadata = Record<Day, DayMetadata>;
export type DaysErrors = Partial<Record<Day, string>>;

//The values are the numbers of minutes from midnight (i.e. 9am and 5pm)
export const initialDaysValues: DaysValues = {
  sun: { from: "540", to: "1020", isActive: false },
  mon: { from: "540", to: "1020", isActive: false },
  tue: { from: "540", to: "1020", isActive: false },
  wed: { from: "540", to: "1020", isActive: false },
  thu: { from: "540", to: "1020", isActive: false },
  fri: { from: "540", to: "1020", isActive: false },
  sat: { from: "540", to: "1020", isActive: false },
};

export const daysMetadata: DaysMetadata = {
  sun: {
    shortName: "Sun",
    name: "Sunday",
  },
  mon: {
    shortName: "Mon",
    name: "Monday",
  },
  tue: {
    shortName: "Tue",
    name: "Tuesday",
  },
  wed: {
    shortName: "Wed",
    name: "Wednesday",
  },
  thu: {
    shortName: "Thu",
    name: "Thursday",
  },
  fri: {
    shortName: "Fri",
    name: "Friday",
  },
  sat: {
    shortName: "Sat",
    name: "Saturday",
  },
};
