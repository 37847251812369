import {
  OrderManyQuery,
  OrderPricesByIdsQuery,
  RefundsByOrderIdsQuery,
} from "gql/generated";

export enum OrderRefundStatus {
  Full = "FULL",
  Partial = "PARTIAL",
  None = "NONE",
}

export const getOrderRefundStatusMap = (
  orders: OrderManyQuery | undefined,
  orderPrices: OrderPricesByIdsQuery | undefined,
  refunds: RefundsByOrderIdsQuery | undefined,
): Record<string, OrderRefundStatus> => {
  const orderTotals = new Map(
    (orders?.orderMany?.items ?? []).map(o => {
      const total =
        orderPrices?.orderPricesByIds.find(v => v.orderID === o._id)?.prices
          ?.grandTotal ?? 0;
      return [o._id, total] as [string, number];
    }),
  );

  const refundTotals =
    refunds?.refundsByOrderIds?.reduce<Record<string, number>>(
      (acc, { orderID, amount }) => ({
        ...acc,
        [orderID]: (acc[orderID] ?? 0) + amount,
      }),
      {},
    ) ?? {};

  return Array.from(orderTotals.entries()).reduce<
    Record<string, OrderRefundStatus>
  >((acc, [orderID, total]) => {
    const refundTotal = refundTotals[orderID] ?? 0;
    let status: OrderRefundStatus;

    if (refundTotal === 0) {
      status = OrderRefundStatus.None;
    } else if (total > refundTotal) {
      status = OrderRefundStatus.Partial;
    } else {
      status = OrderRefundStatus.Full;
    }

    return { ...acc, [orderID]: status };
  }, {});
};
