import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";

type StripeElementsWrapperProps = React.PropsWithChildren & {
  connectedAccountId: string;
};

export const StripeElementsWrapper = ({
  connectedAccountId,
  children,
}: StripeElementsWrapperProps) => {
  if (
    !import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ||
    !connectedAccountId ||
    connectedAccountId.length < 1
  ) {
    throw new Error("Missing stripe publishable key or connected account id");
  }

  return (
    <Elements
      options={{
        fonts: [
          {
            cssSrc:
              "https://fonts.googleapis.com/css2?family=Figtree:wght@400;500;600&display=swap",
          },
        ],
      }}
      stripe={loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: connectedAccountId,
      })}
    >
      {children}
    </Elements>
  );
};
