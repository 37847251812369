import { DateRangeIso } from "gql/generated";
import { parseToUTCDate } from "./parseToUTCDate";
import { DATE_PATTERN_FULL_DAY, formatDate } from "./formatDate";

export const getDateRangeString = (dateRange: DateRangeIso): string => {
  const startDateString = formatDate(
    parseToUTCDate(dateRange.start),
    DATE_PATTERN_FULL_DAY,
  );
  const endDateString = formatDate(
    parseToUTCDate(dateRange.end),
    DATE_PATTERN_FULL_DAY,
  );
  return startDateString + " - " + endDateString;
};
