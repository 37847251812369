import { Combobox } from "@headlessui/react";
import { Fragment, ReactNode, useId, useState } from "react";
import { Xmark } from "iconoir-react";
import { LegacyLoadingSpinner } from "@ttc3k/ttc-design-system";
import { HelperText } from "../HelperText";
import { Label } from "../Label";
import { cn } from "../../../utils/cn";

export type OptionComponentProps = {
  active: boolean;
  selected: boolean;
};

export type Autocomplete2Props<T extends Record<string, any> | null> = {
  options: T[] | null;
  displayField: Extract<keyof T, string>;
  onChange?: (value: T | null) => void;
  onInputChange?: (value: string) => void;
  inputValue: string;
  label?: string;
  helperText?: ReactNode;
  error?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  optionComponent?: (item: T, optionProps: OptionComponentProps) => ReactNode;
};

export const Autocomplete2 = <T extends Record<string, any>>({
  displayField,
  onInputChange,
  inputValue,
  onChange,
  options = [],
  label,
  helperText,
  error,
  isLoading,
  optionComponent,
  disabled,
}: Autocomplete2Props<T>) => {
  const id = useId();
  const [selectedItem, setSelectedItem] = useState<T | null>(null);

  const handleClear = () => {
    onChange?.(null);
    onInputChange?.("");
    setSelectedItem(null);
  };

  const handleChange = (val: T | null) => {
    onChange?.(val);
    onInputChange?.(val?.[displayField] ?? "");
    setSelectedItem(val);
  };

  return (
    <Combobox
      immediate
      disabled={disabled}
      value={selectedItem}
      onChange={handleChange}
    >
      <div className="tw-relative tw-w-full tw-flex tw-flex-col tw-gap-[4px]">
        {label && <Label id={id} label={label} />}
        <div
          className={cn(
            "tw-w-full tw-gap-[8px] tw-font-figtree tw-border-solid tw-flex tw-items-center tw-bg-white tw-py-[12px] tw-px-[16px] tw-rounded-[8px] focus-within:tw-border-[#2E92EF] tw-border-border tw-border-[1px] tw-flex-wrap",
          )}
        >
          {/* Input */}
          <Combobox.Input
            className="tw-grow tw-bg-transparent tw-border-none tw-text-[#27272A] tw-outline-none tw-font-[400] tw-text-[16px] placeholder:tw-text-[#A1A1A9] placeholder:tw-font-thin"
            placeholder="Search"
            onChange={e => onInputChange?.(e.target.value)}
            displayValue={(val: T) => val?.[displayField] || inputValue}
          />

          {/* Clear */}
          {inputValue && !isLoading && (
            <button
              onClick={() => handleClear()}
              className="tw-bg-transparent hover:tw-bg-gray-200 tw-rounded-full tw-p-[3px]"
              type="button"
            >
              <Xmark
                fontSize={12}
                className="tw-cursor-pointer tw-opacity-50"
              />
            </button>
          )}

          {/* {Loading} */}
          {isLoading && <LegacyLoadingSpinner size={16} />}
        </div>

        {/* Options List */}
        {options && options.length > 0 && (
          <Combobox.Options className="tw-absolute tw-top-[70px] tw-max-h-48 tw-overflow-auto tw-bg-white tw-w-full tw-rounded-[8px] tw-mt-2 tw-flex tw-flex-col tw-border-border tw-border-[1px] tw-border-solid tw-z-10">
            {options.map((option, i) => (
              <Combobox.Option key={id + "_" + i} value={option} as={Fragment}>
                  {({ active, selected }) => {
                    if (optionComponent)
                      return optionComponent(option, { active, selected });
                    return (
                      <div
                        className={cn(
                          "tw-cursor-pointer tw-px-[14px] tw-py-[10px] tw-text-left",
                          {
                            "tw-bg-gray-200": selected,
                            "tw-bg-gray-100": active && !selected,
                          },
                        )}
                      >
                        {option[displayField]}
                      </div>
                    );
                  }}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}

        {helperText && typeof helperText === "string" ? (
          <HelperText text={helperText} error={error} />
        ) : (
          helperText
        )}
      </div>
    </Combobox>
  );
};
