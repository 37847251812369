/**
 * This function formats the price for
 * display in the FE. Amount is divided by
 * 100 because prices are stored in cents.
 * @param amount
 */
export function formatPrice(amount: number, removeDecimal?: boolean) {
  if (removeDecimal) {
    return (amount / 100).toLocaleString("en-US", {
      currency: "USD",
    });
  }
  return (amount / 100).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "USD",
  });
}
