import { DateRange } from "booker/features/Steps/components/DatesAndGuests/useDatesAndGuests";
import { formatDate } from "core/utils/formatDate";

const SHORT_DATE = "MMM d";

export const getDatesSummaryString = (dateRange: DateRange): string => {
  const startDate =
    dateRange.startDateISO.length > 0
      ? formatDate(dateRange.startDateISO, SHORT_DATE)
      : "";
  const endDate =
    dateRange.endDateISO.length > 0
      ? formatDate(dateRange.endDateISO, SHORT_DATE)
      : undefined;

  if (dateRange.startDateISO.length < 1) {
    return "Select dates";
  }

  const endDateString = endDate ? ` - ${endDate}` : "";

  return startDate + endDateString;
};
