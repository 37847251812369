import { SectionHeader, Text } from "@ttc3k/trekker";
import { useAmenityManyQuery, EnumAmenityType } from "gql/generated";
import { Bonfire } from "iconoir-react";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Autocomplete } from "core/components/shared/Autocomplete";
import { UpdateAccomFeaturesFormValues } from "./useUpdateAccomFeaturesForm";

export const AmenitiesSelection = () => {
  const { control, setValue } = useFormContext<UpdateAccomFeaturesFormValues>();
  const { data } = useAmenityManyQuery({
    variables: { filter: { type: EnumAmenityType.Accommodation } },
  });

  const amenities = useWatch({
    name: "amenities",
    control,
  });

  const amenitiesDefaultOptions = useMemo(
    () =>
      data?.amenityMany.map(a => ({
        label: a.name.en ?? "Unknown name",
        value: a._id as string,
      })) ?? [],
    [data?.amenityMany],
  );

  return (
    <>
      <SectionHeader text="Amenities" Icon={Bonfire} divider />
      <Text color={"text.mid"}>
        Select amenities for this product from our handy global list.
      </Text>
      <Autocomplete
        options={amenitiesDefaultOptions}
        value={amenities}
        onChange={v => setValue("amenities", v)}
        placeholder="Select amenities"
      />
    </>
  );
};
