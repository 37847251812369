import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import {ApolloClient, InMemoryCache, ApolloProvider, from, HttpLink} from "@apollo/client";
import Plausible from "plausible-tracker";
import * as Sentry from "@sentry/react";
import { StyleRoot } from "@ttc3k/trekker";
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import AuthFlow from "./AuthFlow";
import App from "./App.tsx";
import { Auth0ProviderWithNavigate } from "./Auth0ProviderWithNavigate";
import { GoogleMaps } from "./core/components/shared/GoogleMaps";
import { SiteSettingsProvider } from "./SiteSettingsContext.tsx";
import "../styled-system/styles.css";
import "@fontsource/ibm-plex-mono";
import "@fontsource-variable/figtree";

Sentry.init({
  dsn: "https://051fc59e6b20e0b12fdace8a91f0890b@o4506431460343808.ingest.sentry.io/4506431463030784",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/whereabouts\.tech/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: import.meta.env.VITE_ENVIRONMENT == "production",
});

if (import.meta.env.VITE_ENVIRONMENT == "production") {
  const { enableAutoPageviews } = Plausible({
    // Track localhost by default
    trackLocalhost: false,
    domain: "whereabouts.tech",
  });

  enableAutoPageviews();
}

const cache = new InMemoryCache({
  typePolicies: {
    Tag: {
      keyFields: ["_id", "bundle"],
    },
    Query: {
      keyFields: ["uuid"],
      fields: {
        getOperators: {
          keyArgs: ["status", "operator_name", "tenants", "sort", "tags"],
          merge(existing, incoming) {
            return {
              ...incoming,
              data: [...(existing?.data || []), ...incoming.data],
            };
          },
        },
        getEventsGroupByPeriod: {
          keyArgs: ["input", ["groupBy", "filterBy"]],
          merge(existing, incoming) {
            return incoming ? incoming : existing;
          },
        },
        getCoursesBy: {
          keyArgs: ["input", ["filterBy"]],
          merge(existing, incoming) {
            return incoming ? incoming : existing;
          },
        },
        membersGroupByOwner: {
          keyFields: ["groups", ["uuid"]],
          keyArgs: ["filter", "limit"],
          merge(existing, incoming) {
            return {
              ...incoming,
              groups: [...(existing?.groups || []), ...incoming.groups],
            };
          },
        },
        memberMany: {
          keyArgs: ["filter", "limit"],
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
      },
    },
    Product: {
      keyFields: ["name", "_id"],
    },
  },
});

/**
 * By instantiating our `uri` using `HttpLink` and the
 * `removeTypenamesFromVariables` link, this will automatically
 * clean our queries from the `__typename` variable, which makes
 * it easier for us to pass queried data into mutations.
 *
 * Sources:
 * https://github.com/apollographql/apollo-client/pull/10724
 * https://www.apollographql.com/docs/react/api/link/apollo-link-remove-typename/
 */
const httpLink = new HttpLink({
  uri: import.meta.env.VITE_API_SERVER_URL
});
const removeTypenameLink = removeTypenameFromVariables();
const link = from([removeTypenameLink, httpLink]);

const client = new ApolloClient({
  link,
  cache: cache,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <GoogleMaps>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Auth0ProviderWithNavigate>
            <SiteSettingsProvider>
              <AuthFlow>
                <StyleRoot>
                  <App />
                </StyleRoot>
              </AuthFlow>
            </SiteSettingsProvider>
          </Auth0ProviderWithNavigate>
        </BrowserRouter>
      </ApolloProvider>
    </GoogleMaps>
  </React.StrictMode>,
);
