import { ReactNode } from "react";
import { motion } from "framer-motion";
import { cn } from "../../../utils/cn";
import { Section } from "./Section";

export type BranchProps = {
  children: ReactNode;
  sideBarComponent?: ReactNode;
  sideBarPosition?: "left" | "right";
  className?: string;
};

const Branch = (props: BranchProps) => {
  const {
    children,
    className,
    sideBarComponent = null,
    sideBarPosition = "right",
  } = props;
  return (
    <motion.main
      className={cn(
        "tw-flex tw-gap-space-450 tw-flex-col lg:tw-flex-row tw-mt-10 tw-mx-6 lg:tw-mx-14",
        {
          "lg:tw-flex-row-reverse": sideBarPosition === "right",
        },
        className,
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {sideBarComponent && (
        <div className="tw-relative lg:tw-w-[200px]">
          <div className="lg:tw-sticky lg:tw-top-[10px]">
            {sideBarComponent}
          </div>
        </div>
      )}
      <div className="tw-flex-1 tw-flex tw-flex-col tw-gap-y-8">{children}</div>
    </motion.main>
  );
};

Branch.Section = Section;

export { Branch };
