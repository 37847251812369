import { useBookerContext } from "booker/contexts";
import { Product, useProductsByIdsQuery } from "gql/generated";

export const useAddOns = (productID: string | null) => {
  const { booker } = useBookerContext();

  const mainProductBundle = booker?.productBundles.find(
    bundle => bundle?.product?._id === productID,
  );
  const { data, loading, error } = useProductsByIdsQuery({
    variables: { ids: mainProductBundle?.addOns ?? [] },
  });

  return {
    loading,
    error,
    addOns: (data?.productsByIds as (Product | undefined)[]) ?? [],
  };
};
