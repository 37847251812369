//@ts-nocheck
import { motion } from "framer-motion";
import {
  LegacyButton,
  ButtonTagVariant,
  ProductTile,
  Sizes,
  tokens,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { variants } from "../onboarding/shared.ts";
import { VerifyEmail } from "../onboarding/0-VerifyEmail.tsx";
import { useOnboardingContext } from "../onboarding/Layout.tsx";

export const AlreadyOnboarded = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const {
    isCustomOnboardingFlow,
    program,
    customPortalData,
    baseURL,
    saveOnboardingStep,
  } = useOnboardingContext();

  // const next = `${baseURL}/${customPortalData.join.next}`;

  const handleSubmit = () => {
    saveOnboardingStep({
      variables: {
        id: user.sub,
        page: next,
        type: "ALREADY_ONBOARDED",
      },
    }).then(navigate(next || window.location.pathname));
  };

  return (
    <motion.div
      className={
        "tw-mt-auto tw-w-96 tw-flex tw-flex-col tw-justify-center tw-gap-2 tw-text-center"
      }
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
    >
      <LegacyTypography as="h2" variant="H2">
        {customPortalData.join.title}?
      </LegacyTypography>
      <LegacyTypography>
        You are already an active Whereabouts member. If you're interested in
        joining <strong>{customPortalData.name}</strong>, please go to your{" "}
        <strong>My Businesses</strong> app in Whereabouts and visit the
        Connections tab.
      </LegacyTypography>

      {/*<LegacyButton onClick={() => handleSubmit()} fullWidth size={Sizes.Large}>*/}
      {/*  Continue*/}
      {/*</LegacyButton>*/}

      <Link className={"tw-pt-6"} to={`/`}>
        <LegacyButton fullWidth size={Sizes.Large}>
          Continue to Whereabouts
        </LegacyButton>
      </Link>
    </motion.div>
  );
};
