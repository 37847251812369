import { SelectableCardSurface } from "core/components/shared/SelectableCardSurface";
import { useProductByIdQuery } from "gql/generated";
import { Text } from "@ttc3k/trekker";
import { styled } from "styled-system/jsx";

type UpsellCardProps = {
  productID: string;
  onSelectProduct: (productID: string) => void;
  isSelected: boolean;
};

export const UpsellCard = ({
  productID,
  onSelectProduct,
  isSelected,
}: UpsellCardProps) => {
  const { data } = useProductByIdQuery({ variables: { id: productID } });
  return (
    <styled.button
      width={"full"}
      position={"relative"}
      onClick={() => onSelectProduct(productID)}
      type={"button"}
    >
      <SelectableCardSurface
        p={"token(spacing.250) token(spacing.300)"}
        visual={isSelected ? "selected" : "neutral"}
      >
        <Text visual={"bodySemiBold"} color={"text.dark"}>
          {data?.productById?.name.en ??
            data?.productById?.name.es ??
            data?.productById?.name.fr ??
            "Unknown name"}
        </Text>
      </SelectableCardSurface>
    </styled.button>
  );
};
