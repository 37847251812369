import { CollapsableStep, ControlledInput } from "booker/shared";
import { useCartContext } from "booker/contexts";
import { Flex } from "styled-system/jsx";
import { FormProvider } from "react-hook-form";
import { Text } from "@ttc3k/trekker";
import { useEffect } from "react";
import useCustomerDetailsForm from "./useCustomerDetailsForm";

export const CustomerDetails = () => {
  const { incompleteCustomer, customer } = useCartContext();
  const { form, onSubmit } = useCustomerDetailsForm();

  // Automatically submit the form when the formState isValid
  useEffect(() => {
    if (form.formState.isValidating) {
      setTimeout(() => {
        if (form.formState.isValid) onSubmit();
      }, 1);
    }
  }, [form, onSubmit]);

  // Automatically revert the step if the user completes the step & then
  // triggers an error
  useEffect(() => {
    if (form.formState.isValidating && customer.complete) {
      setTimeout(() => {
        if (!form.formState.isValid) {
          const email = form.watch("email");
          const phone = form.watch("phone");
          const name = form.watch("name");
          onSubmit();
          incompleteCustomer({ email, phone, name });
        }
      }, 1);
    }
  }, [form, incompleteCustomer, customer.complete, onSubmit]);

  return (
    <CollapsableStep title={"Customer Details"} complete={customer.complete}>
      <Text color="text.mid">
        Please enter your contact info, and we&apos;ll send you a confirmation
        email right after checkout.
      </Text>
      <FormProvider {...form}>
        <form>
          <Flex flexDir="column" gap="150">
            <ControlledInput name="name" label="Full Name*" />
            <ControlledInput name="email" label="Email*" />
            <ControlledInput name="phone" label="Phone*" />
          </Flex>
        </form>
      </FormProvider>
    </CollapsableStep>
  );
};
