import { Tools } from "iconoir-react";
import {
  tokens,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { handleBeaconClick } from "../../../utils/helpScout";
import Logout from "../../LogoutButton.tsx";
import { useAuth0 } from "@auth0/auth0-react";

export const Doorstop = ({ message }: { message: string }) => {
  const { user } = useAuth0();

  return (
    <>
      {user && (
        <div className="tw-absolute tw-right-10 tw-top-5 tw-border-0">
          <Logout />
        </div>
      )}
      <div
        className={
          "tw-max-w-[432px] tw-flex tw-flex-col tw-text-center tw-p-12 tw-gap-4 tw-shadow-[0_8px_20px_-3px_rgba(0,0,0,0.15)] tw-bg-bgWbtsCanvas tw-rounded-lg"
        }
      >
        <div
          className={
            "tw-flex tw-items-center tw-flex-col tw-gap-2 tw-text-[#E08D11]"
          }
        >
          <Tools strokeWidth={2} />
          <LegacyTypography variant={TypographyVariant.h5}>
            Maintenance Mode
          </LegacyTypography>
        </div>
        <LegacyTypography color={tokens.semantic.textSubtle}>
          {message}
        </LegacyTypography>
        <LegacyTypography color={tokens.semantic.textSubtle}>
          Please{" "}
          <button
            className={"tw-bg-transparent tw-font-bold tw-text-[#2e92ef]"}
            onClick={() => handleBeaconClick(undefined)}
          >
            contact us
          </button>{" "}
          if you need assistance.
        </LegacyTypography>
      </div>
    </>
  );
};
