import { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { TextField } from "../../components/shared/TextField";
import { userSchema, userDefaultValues } from "./schema.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  LegacyButton,
  LegacyLoadingSpinner,
  LegacyToast,
  LegacyTypography,
} from "@ttc3k/ttc-design-system";
import { EyeClosed, EyeSolid } from "iconoir-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LockIcon } from "../../../apps/library/directory/branches/leaves/Profile.tsx";
import { useOnboardingContext } from "../onboarding/Layout.tsx";
import SlideInPage from "../animation/SlideInPage.tsx";
import { useAuth0 } from "@auth0/auth0-react";
import { parseToUTCDate } from "../../utils/parseToUTCDate.ts";
import PasswordStrengthMeter from "../../components/shared/PasswordStrengthMeter";
import { useCreateUserMutation } from "../../../gql/generated.ts";
import { getCompleteURL } from "../../utils/getCompleteURL.ts";

export default function SignUp() {
  const { user } = useAuth0();
  const navigate = useNavigate();

  //if user is logged in, then redirect
  useEffect(() => {
    if (user) {
      if (user?.whereaboutsOnboarding.signUp.pages) {
        navigate(user?.whereaboutsOnboarding.signUp.pages[0]);
      } else {
        navigate("/");
      }
    }
  }, []);

  //Variables for determining if this is default or custom signup
  const { isCustomOnboardingFlow, program, customPortalData, baseURL } =
    useOnboardingContext();
  const form = useForm<userSchema>({
    resolver: zodResolver(userSchema),
    defaultValues: userDefaultValues,
    mode: "onChange",
  });
  const { state } = useLocation();

  const {
    control,
    handleSubmit,
    //getValues,
    // reset,
    formState: { isSubmitting, isDirty },
    watch,
    setValue,
  } = form;

  let passedEmail;

  useEffect(() => {
    if (passedEmail) setValue("email", passedEmail, { shouldValidate: true });
  }, []);

  const [createUser, { data: saved, loading: loadingUser }] =
    useCreateUserMutation();

  //Check if this is an invite to join
  const [queryParameters] = useSearchParams();
  const inviteDataEncoded = queryParameters.get("invite") ?? false;
  const inviteData = inviteDataEncoded ? atob(inviteDataEncoded) : false;
  let inviteDataObject;
  try {
    inviteDataObject = JSON.parse(inviteData);
  } catch (error) {
    return (
      <LegacyTypography variant="H3">
        Something is wrong with the invite you were sent.
        <br />
        Please contact the member who invited you.
      </LegacyTypography>
    );
  }
  passedEmail = state?.email ? state.email : inviteDataObject.inviteEmail;

  const onSubmit: SubmitHandler<userSchema> = async formData => {
    try {
      await createUser({
        variables: {
          input: {
            given_name: formData.given_name,
            family_name: formData.family_name,
            email: formData.email,
            password: formData.password,
            app_metadata: {
              onboarding: {
                signUp: {
                  pages: getCompleteURL(),
                  startedDate: parseToUTCDate(new Date()),
                },
              },
            },
          },
        },
      }).then(r => {
        return r;
      });
    } catch (err) {
      // TODO: we should add a toast error message here
      console.log(err);
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const inProcessPassword = watch("password");

  return (
    <div className="sm:tw-full md:tw-w-96 tw-mx-auto tw-flex tw-flex-col tw-gap-5">
      <SlideInPage>
        {isCustomOnboardingFlow ? (
          <>
            <LegacyTypography variant="H2">
              {customPortalData.signUp.title}
            </LegacyTypography>
            <div className="tw-my-5">
              <LegacyTypography>
                {customPortalData.signUp.description}
              </LegacyTypography>
            </div>
          </>
        ) : (
          <LegacyTypography>
            Please create a secure account. It's free for all tourism
            businesses.
          </LegacyTypography>
        )}
        <div className="tw-flex tw-flex-col tw-gap-12">
          <div>
            <form>
              <div className={"tw-flex tw-flex-col tw-gap-4 tw-text-left"}>
                <Controller
                  name="given_name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Your First Name"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      // endIcon={<LockIcon />}
                      // disabled
                    />
                  )}
                />
                <Controller
                  name="family_name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Your Last Name"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      // endIcon={<LockIcon />}
                      // disabled
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState }) =>
                    passedEmail ? (
                      <TextField
                        value={passedEmail}
                        label="Email"
                        autoComplete="username"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        endIcon={<LockIcon />}
                        disabled
                      />
                    ) : (
                      <TextField
                        {...field}
                        label="Email"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        autoComplete="username"
                        //endIcon={<LockIcon />}
                        //disabled
                      />
                    )
                  }
                />
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      type={passwordShown ? "text" : "password"}
                      {...field}
                      label="Password"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      autoComplete="new-password"
                      helperComponent={
                        <PasswordStrengthMeter password={inProcessPassword} />
                      }
                      endIcon={
                        passwordShown ? (
                          <EyeSolid
                            className="tw-cursor-pointer"
                            onClick={togglePasswordVisiblity}
                          />
                        ) : (
                          <EyeClosed
                            className="tw-cursor-pointer"
                            onClick={togglePasswordVisiblity}
                          />
                        )
                      }
                      // disabled
                    />
                  )}
                />
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type={passwordShown ? "text" : "password"}
                      label="Confirm password"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      autoComplete="new-password"
                      endIcon={
                        passwordShown ? (
                          <EyeSolid
                            className="tw-cursor-pointer"
                            onClick={togglePasswordVisiblity}
                          />
                        ) : (
                          <EyeClosed
                            className="tw-cursor-pointer"
                            onClick={togglePasswordVisiblity}
                          />
                        )
                      }
                      // disabled
                    />
                  )}
                />
                <LegacyButton
                  fullWidth
                  onClick={() => handleSubmit(onSubmit)()}
                  disabled={!!saved}
                >
                  {loadingUser ? (
                    <LegacyLoadingSpinner size={20} />
                  ) : saved ? (
                    "Complete"
                  ) : (
                    "Sign Up"
                  )}
                </LegacyButton>
                {saved && (
                  <>
                    <LegacyToast>
                      {saved?.createUser.code == 201 ? (
                        <p>
                          Account created. Please check your email for a message
                          from Whereabouts containing an email verification
                          link.
                        </p>
                      ) : (
                        <p>
                          Oops. We ran into a problem creating your account.
                          Please contact us. Error: {saved?.createUser.message}
                        </p>
                      )}
                    </LegacyToast>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </SlideInPage>
    </div>
  );
}
