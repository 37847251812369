import { Card } from '@ttc3k/trekker';
import { styled } from 'styled-system/jsx';

export const SelectableCardSurface = styled(Card.Surface, {
  base: {
    p: "500",
    boxShadow: "none",
    bg: "white",
    outline: "0 transparent solid",
    transitionDuration: "normal",
    transitionProperty: "border, outline",
    transitionTimingFunction: "default",
    minWidth: "200px",
    _hover: {
      borderColor: "border.dark",
      outlineWidth: "1px",
      outlineColor: "border.dark",
    },
    _disabled: {
      bg: "transparent",
      cursor: "not-allowed",
      _hover: {
        outline: "0 transparent solid",
        borderColor: "border.light",
      },
    },
  },
  variants: {
    visual: {
      neutral: {},
      selected: {
        borderColor: "border.dark",
        outlineWidth: "1px",
        outlineColor: "border.dark",
      },
    },
  },
  defaultVariants: {
    visual: "neutral",
  },
});
