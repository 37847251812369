import {
  ControlledInput,
  ControlledTextarea,
} from "apps/custom/ttc/booker/shared";
import { Flex } from "styled-system/jsx";

type AccomDetailsFormFieldsProps = {
  isUpdating?: boolean;
};

export const AccomDetailsFormFields = ({
  isUpdating = false,
}: AccomDetailsFormFieldsProps) => {
  return (
    <Flex flexDir={"column"} gap={"300"} width={"full"}>
      <ControlledInput name={"name"} label={"Name"} width={"full"} />
      {!isUpdating && (
        <ControlledInput
          name={"inventory"}
          label={"What is the inventory for this product?"}
          type={"number"}
          hint={
            "If you offer multiples of this product (e.g. 5 identical motel rooms with the same features and amenities), please specify the number above"
          }
        />
      )}
      <ControlledTextarea name="description" label={"Description"} rows={10} />
      <ControlledTextarea
        name="shortDescription"
        label={"Short Description"}
        rows={10}
      />
    </Flex>
  );
};
