import { useJsApiLoader } from "@react-google-maps/api";
import { ReactNode } from "react";
import { PlacesAutocomplete } from "./PlacesAutocomplete";

export type GoogleMapsProps = {
  children: ReactNode;
};

const GoogleMaps = (props: GoogleMapsProps) => {
  const { children } = props;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_KEY,
    libraries: ["places"],
  });

  if (!isLoaded) return null;

  return <>{children}</>;
};

GoogleMaps.PlacesAutocomplete = PlacesAutocomplete;

export { GoogleMaps };
