//@ts-nocheck
import { motion } from "framer-motion";
import {
  LegacyButton,
  ButtonTagVariant,
  ProductTile,
  Sizes,
  tokens,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { variants } from "../shared.ts";
import { VerifyEmail } from "../0-VerifyEmail.tsx";
import { useOnboardingContext } from "../Layout.tsx";
import { parseToUTCDate } from "../../../utils/parseToUTCDate.ts";
import SlideInPage from "../../animation/SlideInPage.tsx";
import { useEffect } from "react";

const CHECK_PEOPLE = gql`
  query CheckPeople($filter: FilterFindManyPersonInput) {
    personMany(filter: $filter) {
      tenantsOf {
        tenantId
        role
      }
      email
    }
  }
`;

export const GET_OPERATORS_ONBOARDING = gql`
  query operators($tenants: String, $limit: Int, $offset: Int, $email: String) {
    getOperators(
      tenants: $tenants
      limit: $limit
      offset: $offset
      email: $email
    ) {
      data {
        operator_name
        address
        _id
      }
    }
  }
`;
export const StartClaimTemplate = ({ next, baseURL, stepData }) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const { updateUserMetadata, setClaimSuccessNextURL } = useOnboardingContext();

  const { data, loading, error } = useQuery(GET_OPERATORS_ONBOARDING, {
    variables: {
      limit: 30,
      offset: 0,
      email: user?.email,
      tenants: JSON.stringify([
        user?.sub,
        "vtc",
        "ohto|660dfd13dd810ca68f35231f",
        "neont",
      ]),
    },
  });

  const {
    data: peopleData,
    loading: peopleLoading,
    error: peopleError,
  } = useQuery(CHECK_PEOPLE, {
    variables: {
      filter: {
        email: user?.email,
      },
    },
  });

  useEffect(() => {
    setClaimSuccessNextURL(`${baseURL}/next`);
  }, []);

  useEffect(() => {
    //@TODO If a person has multiple invites, this flow will authenticate all of them
    peopleData?.personMany[0]?.tenantsOf?.some(el => el.role === "INVITED")
      ? navigate("accept-invitation", {
          state: { tenantsOf: peopleData?.personMany[0].tenantsOf },
        })
      : false;
  }, [peopleData]);

  if (loading || peopleLoading) {
    return <h1>Loading .... </h1>;
  }

  if (error) {
    return <h1>Error .... </h1>;
  }

  const nextStep =
    data?.getOperators?.data.length > 0 ? "claim/found" : "claim/search";

  return (
    <SlideInPage>
      <div className="tw-w-full md:tw-w-96 tw-mx-auto tw-flex tw-flex-col tw-gap-1">
        <LegacyTypography as="h2" variant="H2">
          {stepData.title}
        </LegacyTypography>
        <LegacyTypography>{stepData.description}</LegacyTypography>
        {/*{nextStep == "search" && (*/}
        {/*  <LegacyTypography>*/}
        {/*    We couldn't match your email with a business in our database, but in*/}
        {/*    the next step you can search for one.*/}
        {/*  </LegacyTypography>*/}
        {/*)}*/}
        {nextStep && (
          <Link className={"tw-pt-6"} to={`${baseURL}/${nextStep}`}>
            <LegacyButton fullWidth size={Sizes.Large}>
              Next
            </LegacyButton>
          </Link>
        )}
        {nextStep == "claim/search" && (
          <div className={"tw-mt-5"}>
            <LegacyTypography
              variant={TypographyVariant.secondaryMedium}
              color={"#27704C"}
            >
              Are you interested in joining our Membership but don't currently
              own or work at a tourism business?{" "}
              <strong>
                <Link to={`${baseURL}/in-review`}>Please click here.</Link>
              </strong>
            </LegacyTypography>
          </div>
        )}
      </div>
    </SlideInPage>
  );
};
