import { SectionHeader, Text } from "@ttc3k/trekker";
import { ControlledTextarea } from "apps/custom/ttc/booker/shared";
import { Page, XmarkCircle } from "iconoir-react";
import { Flex } from "styled-system/jsx";

export const PolicySection = () => {
  return (
    <>
      <Flex flexDir={"column"} gap={"300"} width={"full"}>
        <SectionHeader text="Terms & Conditions" Icon={Page} divider />
        <Text color={"text.mid"}>
          Add your terms and conditions below. You can also set whether or not
          agreeing to the terms is required during checkout.
        </Text>
        <ControlledTextarea
          name={"termsConditions"}
          placeholder={"Enter your terms and conditions here"}
        />
      </Flex>
      <Flex flexDir={"column"} gap={"300"} width={"full"}>
        <SectionHeader text="Cancellation Policy" Icon={XmarkCircle} divider />
        <Text color={"text.mid"}>
          If you have a written cancellation policy, you can add it to the field
          below and we’ll include in all of your Booker embeds. You can also set
          up cancellation cutoff dates with associated refund amounts.
        </Text>
        <ControlledTextarea
          name={"cancellationPolicy"}
          placeholder={"Enter your cancellation policy here"}
        />
      </Flex>
    </>
  );
};
