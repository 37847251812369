import { WhereaboutsModal } from "core/components/shared/WhereaboutsModal";
import { PlusCircle } from "iconoir-react";
import { FormProvider } from "react-hook-form";
import { toastFactory } from "@ttc3k/trekker";
import { useState } from "react";
import { useCreateClosureForm } from "./useCreateClosureForm";
import { ClosureFormComponents } from "./ClosureFormComponents";

type CreateClosureModalProps = {
  isOpen: boolean;
  onClose: () => void;
  operatorID: string;
};

export const CreateClosureModal = ({
  isOpen,
  onClose,
  operatorID,
}: CreateClosureModalProps) => {
  const [formError, setFormError] = useState("");
  const { form, onSubmit, defaultValues } = useCreateClosureForm({
    onSuccess: data => {
      form.reset(defaultValues);
      setFormError("");
      onClose();
      toastFactory.create({
        title: "Success!",
        description: `${data?.name} closure was created successfully`,
      });
    },
    onError: m => setFormError(m),
  });

  const handleCancel = () => {
    onClose();
    form.reset(defaultValues);
  };

  const formID = "booker-create-closure-form";

  return (
    <WhereaboutsModal
      isOpen={isOpen}
      onClose={handleCancel}
      title={"Create Closure"}
      description={
        "Create a closure event to stop bookings during a specific period, either across your entire business or for specific products."
      }
      HeaderIcon={PlusCircle}
      cancelButtonProps={{ onClick: handleCancel }}
      saveButtonProps={{ form: formID, type: "submit" }}
    >
      <FormProvider {...form}>
        <form onSubmit={onSubmit} id={formID}>
          <ClosureFormComponents
            operatorID={operatorID}
            formError={formError}
          />
        </form>
      </FormProvider>
    </WhereaboutsModal>
  );
};
