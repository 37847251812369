import {
  LegacyButton,
  ButtonTagVariant,
  LegacyTypography,
  TypographyVariant,
  LegacyAnimatedFadeInOut,
} from "@ttc3k/ttc-design-system";
import { Xmark } from "iconoir-react";
import { ReactNode, useState } from "react";
import { cn } from "../../../utils/cn";

export const Banner = ({
  message,
  className,
  typographyClassName,
  onClose,
}: {
  message: ReactNode;
  className?: string;
  typographyClassName?: string;
  onClose?: () => void;
}) => {
  const [showBanner, setShowBanner] = useState(true);
  return (
    <LegacyAnimatedFadeInOut isShown={showBanner}>
      <div
        className={cn(
          "tw-bg-[#E08D11] tw-grid tw-grid-cols-3 tw-py-3 tw-px-8 tw-text-white tw-text-center tw-w-full",
          className,
        )}
      >
        <LegacyTypography
          className={cn(typographyClassName)}
          variant={TypographyVariant.primaryBold}
        >
          {message}
        </LegacyTypography>
        <LegacyButton
          className={"tw-ml-auto tw-col-start-3"}
          variant={ButtonTagVariant.Unstyled}
          icon={<Xmark strokeWidth={2} />}
          onClick={() => (onClose ? onClose() : setShowBanner(false))}
        />
      </div>
    </LegacyAnimatedFadeInOut>
  );
};
