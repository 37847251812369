import { Spinner, Table, Text, Pagination, Tag } from "@ttc3k/trekker";
import { useGetLearnerActivityLogManyQuery } from "gql/generated";
import { Box, Flex } from "styled-system/jsx";
import { formatDate } from "core/utils/formatDate";
import { Headers } from "../constants";
import { TableRow } from "./TableRow";
import { TableCell } from "./TableCell";
import { Link } from "react-router-dom";

type UseGetLearnerActivityLogManyQueryReturn = Partial<
  ReturnType<typeof useGetLearnerActivityLogManyQuery>
>;

type ListProps = UseGetLearnerActivityLogManyQueryReturn & {
  onPageChange?: (newPage: number) => void;
};

export const List = ({ loading, error, data, onPageChange }: ListProps) => {
  const styleTag = status => {
    //console.log(courseStatus);
    switch (status) {
      case "VIEWED":
        return "primary";
      case "CONTINUED":
        return "accent";
      case "COMPLETED":
        return "success";
      case "STARTED":
        return "warning";
      default:
        return "primary";
    }
  };

  if (loading) {
    return <Spinner width={"50px"} height={"50px"} margin={"60px auto"} />;
  }
  if (error) {
    return (
      <Text
        margin={"60px auto"}
        width={"full"}
        textAlign={"center"}
        color={"text.error.mid"}
      >
        Error : {error.message}
      </Text>
    );
  }

  if (!data?.learnerActivityLogMany?.items?.length) {
    return (
      <Text margin={"60px auto"} width={"full"} textAlign={"center"}>
        There has been no activity at this time
      </Text>
    );
  }

  return (
    <Flex flexDir={"column"} gap={"200"}>
      <Box overflowX={"auto"}>
        <Table.Root>
          <Table.Body>
            <TableRow py={0} gap={0}>
              {Headers.map(o => (
                <Table.Header
                  key={o.label}
                  width={o.largeWidth ? "200px" : undefined}
                  textAlign={o.alignRight ? "right!" : undefined}
                  py={0}
                  gap={0}
                >
                  <Text visual={"smallMedium"} color={"text.light"}>
                    {o.label}
                  </Text>
                </Table.Header>
              ))}
            </TableRow>
            {data.learnerActivityLogMany.items.map((item, i) => (
              <TableRow key={item._id}>
                <TableCell>
                  <Text>{formatDate(item.createdDateISO, "d MMM ''yy")}</Text>
                </TableCell>
                <TableCell>
                  <Text>{item.courseName}</Text>
                </TableCell>
                <TableCell>
                  <Text>{item.personName}</Text>
                </TableCell>
                <TableCell>
                  <Link to={`/app/directory/x/${item.operatorId}`}>
                    <Text>{item.operatorName}</Text>
                  </Link>
                </TableCell>

                <TableCell justifyContent={"center"}>
                  <Tag visual={styleTag(item.eventType)}>{item.eventType}</Tag>
                </TableCell>
              </TableRow>
            ))}
          </Table.Body>
        </Table.Root>
      </Box>
      <Flex w={"full"} justifyContent={"flex-end"}>
        <Pagination
          count={data.learnerActivityLogMany.pageInfo.pageCount ?? 0}
          page={data.learnerActivityLogMany.pageInfo.currentPage ?? 1}
          pageSize={1}
          siblingCount={1}
          onPageChange={({ page }: { page: number }) => onPageChange?.(page)}
        />
      </Flex>
    </Flex>
  );
};
