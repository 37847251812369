// @ts-nocheck
import {
  LegacyButton,
  Sizes,
  LegacyTypography,
  LegacyLoadingSpinner,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import SlideInPage from "../../animation/SlideInPage.tsx";
import { TypeForm } from "../typeform/TypeForm.tsx";
import { EditEntityCard } from "../typeform/TypeFormEditCard/EditEntityCard.tsx";
import { parseToUTCDate } from "../../../utils/parseToUTCDate.ts";
import { useGetFormResponsesQuery } from "../../../../gql/generated.ts";
import { useState } from "react";

export const AdditionalInfoTemplate = ({ baseURL, next, stepData }) => {
  const { user, isLoading } = useAuth0();
  const { state } = useLocation();
  const [fetchingForm, setFetchingForm] = useState(false);
  const linked_id =
    stepData.formFields.type == "Operator"
      ? user?.whereaboutsOnboarding?.operatorId || state?.operatorId
      : user?.sub;

  const { data, loading, error, refetch } = useGetFormResponsesQuery({
    variables: {
      linkedId: linked_id,
      type: stepData.type,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });

  if (loading || isLoading) {
    return <h1>Loading .... </h1>;
  }

  if (error) {
    return <h1>Error .... </h1>;
  }

  //const next = data?.getOperators?.data.length > 0 ? "found" : "help";
  const disableNext = data?.getFormResponses?.data?.length <= 0;
  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-5">
        <div className="tw-w-full md:tw-w-96 tw-mx-auto tw-flex tw-flex-col tw-gap-5">
          <LegacyTypography as="h2" variant="H2">
            {stepData.title}
          </LegacyTypography>
          <LegacyTypography>{stepData.preDescription}</LegacyTypography>
        </div>
        {disableNext && (
          <>
            <TypeForm
              id={stepData.formId}
              onClose={() => {
                setFetchingForm(true);
                new Promise(resolve => setTimeout(resolve, 1500)).then(() => {
                  setFetchingForm(false);
                  refetch();
                });
              }}
              label={stepData.buttonLabel}
              autoClose={500}
              hidden={{
                ...stepData.formFields,
                name: `${user?.given_name} ${user?.family_name}`,
                linked_id: linked_id,
              }}
            />
            {fetchingForm && <LegacyLoadingSpinner />}
          </>
        )}
        <div className="tw-w-full md:tw-w-96 tw-mx-auto tw-flex tw-flex-col tw-gap-1">
          {data?.getFormResponses?.data?.map(request => (
            <EditEntityCard
              entity={{
                isActive: true,
                startDateTime: parseToUTCDate(new Date()),
                endDateTime: "2024-03-29T13:48:53Z",
                title: request.requestBody.form_response.definition.title,
                request: request,
              }}
              onUpdateSuccess={() => refetch()}
              key={request._id}
            />
          ))}
        </div>
        <div className="tw-w-full md:tw-w-96 tw-mx-auto tw-flex tw-flex-col tw-gap-5">
          {!disableNext && (
            <LegacyTypography
              variant={TypographyVariant.secondaryMedium}
              color={"#27704C"}
            >
              If you wish to change the private info above, you must delete and
              re-submit the form.
            </LegacyTypography>
          )}
          <LegacyTypography>{stepData.postDescription}</LegacyTypography>

          {next && (
            <Link className={"tw-pt-6"} to={`${baseURL}/${next}`} state={state}>
              <LegacyButton disabled={disableNext} fullWidth size={Sizes.Large}>
                Next
              </LegacyButton>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};
