import { useId, useMemo } from "react";
import { Combobox } from "@headlessui/react";
import { NavArrowDown } from "iconoir-react";
import { LegacyTypography, tokens } from "@ttc3k/ttc-design-system";
import { cn } from "../../../utils/cn";
import { Label } from "../Label";
import { HelperText } from "../HelperText";
import { Tag, TagProps } from "../Tag";

export type Option = {
  value: string;
  label: string;
};

export type SelectProps = {
  label?: string;
  tagPropsVariants?: TagProps["variant"];
  onChange?: (value: string) => void;
  value?: string;
  options?: Option[];
  helperText?: string;
  placeholder?: string;
};

export const Select = ({
  onChange,
  value = "",
  options = [],
  label,
  tagPropsVariants = "info",
  helperText,
  placeholder = "",
}: SelectProps) => {
  const id = useId();

  const filteredOption = options;

  const arrToObjOptions = useMemo(
    () =>
      options.reduce<Record<string, string>>(
        (acc, item) => ({ ...acc, [item.value]: item.label }),
        {},
      ),
    [options],
  );

  return (
    <Combobox
      value={value}
      onChange={e => {
        onChange?.(e);
      }}
      immediate
    >
      <div className="tw-relative tw-w-full tw-flex tw-flex-col tw-gap-[4px]">
        {label && <Label id={id} label={label} />}
        <div
          className={cn(
            "tw-relative tw-w-full tw-gap-[8px] tw-border-solid tw-flex tw-items-center tw-bg-white tw-py-[12px] tw-px-[16px] tw-rounded-[8px]  focus-within:tw-border-[#2E92EF] tw-border-border tw-border-[1px] tw-min-h-[50px]",
          )}
        >
          {placeholder && !value && (
            <LegacyTypography color={tokens.semantic.textSubtlest}>
              {placeholder}
            </LegacyTypography>
          )}
          {/* Selected values */}
          {arrToObjOptions?.[value] && (
            <Tag label={arrToObjOptions?.[value]} variant={tagPropsVariants} />
          )}

          {/* Input */}

          {/* <Combobox.Input
            className="tw-bg-transparent tw-border-none tw-text-[#27272A] tw-outline-none tw-font-[400] tw-text-[16px] placeholder:tw-text-[#A1A1A9] placeholder:tw-font-thin"
            // placeholder="Search"
            onChange={e => setQuery(e.target.value)}
            value={query}
          /> */}
          <Combobox.Button className="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-2 tw-flex tw-items-center tw-bg-transparent">
            <NavArrowDown />
          </Combobox.Button>
        </div>
        {/* Options List */}
        {filteredOption.length > 0 && (
          <Combobox.Options className="tw-absolute tw-top-[70px] tw-max-h-48 tw-overflow-auto tw-bg-white tw-w-full tw-rounded-[8px] tw-mt-2 tw-flex tw-flex-col tw-gap-[14px] tw-border-border tw-border-[1px] tw-border-solid tw-p-[14px] tw-z-10">
            <Combobox.Option
              value={""}
              className="tw-cursor-pointer tw-text-textSubtlest"
            >
              None
            </Combobox.Option>
            {filteredOption.map(option => (
              <Combobox.Option
                key={option.value}
                value={option.value}
                className={({ active, selected }) =>
                  cn("tw-cursor-pointer tw-text-left", {
                    "tw-text-[#2E92EF95]":
                      active && tagPropsVariants === "info",
                    "tw-text-[#2E92EF]":
                      selected && tagPropsVariants === "info",
                    "tw-text-[#E08D1195]":
                      active && tagPropsVariants === "warning",
                    "tw-text-[#E08D11]":
                      selected && tagPropsVariants === "warning",
                  })
                }
              >
                {option.label}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
        {helperText && <HelperText text={helperText} />}
      </div>
    </Combobox>
  );
};
