import { useId } from "react";
import { cn } from "../../../utils/cn";
import { Row } from "./components/row";
import { MonthsValues } from "./components/utils";
import {
  Season,
  initialSeasonsValues,
  seasons,
  seasonsMetadata,
} from "./utils";

export type SeasonalityProps = {
  value?: Record<Season, MonthsValues>;
  onChange?: (value: Record<Season, MonthsValues>) => void;
};

export const Seasonality = ({
  value = initialSeasonsValues,
  onChange,
}: SeasonalityProps) => {
  const id = useId();

  const handleChange = (val: MonthsValues, season: Season) => {
    onChange?.({ ...value, [season]: val });
  };

  return (
    <div className={cn("tw-flex tw-flex-col tw-gap-y-[6px]")}>
      {seasons.map(season => {
        const selectSeasonProps = value[season];
        const selectSeasonMeta = seasonsMetadata[season];
        return (
          <Row
            key={id + season}
            label={selectSeasonMeta.name}
            value={selectSeasonProps}
            onChange={val => handleChange(val, season)}
            variant={selectSeasonMeta.variant}
          />
        );
      })}
    </div>
  );
};
