import { Link, Outlet, useLocation, useOutletContext } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import Logout from "../../components/LogoutButton.tsx";
import HelpButton from "../../components/shared/HelpButton";
import PoweredByWhereabouts from "../../components/shared/PoweredByWhereabouts";
import { Breadcrumbs } from "../../components/apps/Breadcrumbs/Breadcrumbs.tsx";
import { useParams } from "react-router";
import { Loader } from "../../components/Loader.tsx";
import {
  LegacyButton,
  LegacyTypography,
  Sizes,
} from "@ttc3k/ttc-design-system";
import WhereaboutsLogo from "../../components/shared/WhereaboutsLogo";

export type OutletContext = {
  user: object;
  successUpdateUserMetadata: object;
  loadingUpdateUserMetadata: boolean;
  errorUpdateUserMetadata: object;
};

const UPDATE_USER_METADATA = gql`
  mutation updateUserMetadata(
    $id: String
    $body: String #    $tenantId: String
  ) #    $userObject: String
  {
    updateUserMetadata(user_id: $id, body: $body) {
      user_id
    }
    #    addUserToTenant(tenantId: $tenantId, userObject: $userObject) {
    #      name
    #    }
  }
`;

const SAVE_ONBOARDING_STEP = gql`
  mutation saveOnboardingStep($id: String, $page: String) {
    saveOnboardingStep(user_id: $id, page: $page) {
      user_id
    }
  }
`;

const SET_ONBOARDING_STATUS = gql`
  mutation setOnboardingStatus($id: String, $status: Boolean) {
    setOnboardingStatus(user_id: $id, status: $status) {
      user_id
    }
  }
`;

const GET_ORG = gql`
  query Org($slug: String) {
    org(slug: $slug) {
      slug
      name
      customPortals
      logo {
        full_url
      }
      tripperSettings {
        teamId
      }
    }
  }
`;

export default function PostboardingLayout({
  isCustomOnboardingFlow,
  customPortalData,
  customPortalLoading,
  customPortalError,
  user,
  baseURL,
}) {
  const location = useLocation();
  const { hash, pathname, search } = location;
  const [
    updateUserMetadata,
    {
      data: successUpdateUserMetadata,
      loading: loadingUpdateUserMetadata,
      error: errorUpdateUserMetadata,
    },
  ] = useMutation(UPDATE_USER_METADATA);
  const [
    saveOnboardingStep,
    {
      data: successSaveOnboardingStep,
      loading: loadingSaveOnboardingStep,
      error: errorSaveOnboardingStep,
    },
  ] = useMutation(SAVE_ONBOARDING_STEP);
  const { slug, program } = useParams();
  const [
    setOnboardingStatus,
    {
      data: successSetOnboardingStatus,
      loading: loadingSetOnboardingStatus,
      error: errorSetOnboardingStatus,
    },
  ] = useMutation(SET_ONBOARDING_STATUS);

  //State for claim flow
  const { multipleTourismBusinesses, setMultipleTourismBusinesses } =
    useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [claimSuccessNextURL, setClaimSuccessNextURL] = useState("");

  return (
    <>
      {user && (
        <div className="tw-absolute tw-right-2 tw-top-2 md:tw-right-10 md:tw-top-5 tw-border-0">
          <Logout />
        </div>
      )}
      <div
        className={
          "tw-bg-bgWbts tw-min-h-[100dvh] md:tw-min-h-screen tw-p-5 tw-flex tw-flex-col tw-justify-center tw-items-center"
        }
      >
        {user && customPortalData?.settings?.breadcrumbs && (
          <div className="tw-mt-5">
            <Breadcrumbs
              crumbs={customPortalData.onboarding.reduce((results, step) => {
                if (step.breadcrumb !== undefined)
                  results.push({
                    name: step.breadcrumb,
                    href: `${baseURL}/${step.slug}`,
                  });
                return results;
              }, [])}
            />
          </div>
        )}
        {isCustomOnboardingFlow ? (
          <a href={`${baseURL}/`}>
            <img
              className="tw-w-[150px] tw-mt-5 tw-mb-10"
              src={customPortalData.settings.logo?.full_url}
              alt="Logo"
            />
          </a>
        ) : (
          <WhereaboutsLogo />
        )}
        <div
          className={`tw-gap-5 tw-flex tw-flex-col tw-text-center ${
            isCustomOnboardingFlow ? "tw-mt-auto" : ""
          }`}
        >
          <Outlet
            context={{
              user,
              updateUserMetadata,
              saveOnboardingStep,
              loadingUpdateUserMetadata,
              errorUpdateUserMetadata,
              successUpdateUserMetadata,
              businesses,
              setBusinesses,
              isCustomOnboardingFlow,
              program,
              customPortalData,
              customPortalLoading,
              customPortalError,
              baseURL,
              multipleTourismBusinesses,
              setMultipleTourismBusinesses,
              claimSuccessNextURL,
              setClaimSuccessNextURL,
              setOnboardingStatus,
              successSetOnboardingStatus,
              loadingSetOnboardingStatus,
              errorSetOnboardingStatus,
            }}
          />
        </div>
        {isCustomOnboardingFlow && (
          <div className="tw-mt-auto tw-mb-5 tw-pt-5">
            <PoweredByWhereabouts />
          </div>
        )}
        <div className="tw-flex tw-mt-5 md:tw-mt-0 md:tw-fixed md:tw-right-5 md:tw-bottom-5">
          <HelpButton />
        </div>
      </div>
    </>
  );
}

export function useOnboardingContext() {
  return useOutletContext<OutletContext>();
}
