import { Table } from "@ttc3k/trekker";
import { styled } from "styled-system/jsx";

export const TableRow = styled(Table.Row, {
  base: {
    display: "grid",
    gridTemplateColumns: "98px 1fr 1fr 1fr 110px",
    width: "full",
    columnGap: "400",
    py: "150",
  },
});
