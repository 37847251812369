import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { getCompleteURL } from "./core/utils/getCompleteURL.ts";

const UPDATE_USER_METADATA = gql`
  mutation updateUserMetadata(
    $id: String
    $body: String #    $tenantId: String
    #    $userObject: String
  ) {
    updateUserMetadata(user_id: $id, body: $body) {
      user_id
    }
    #    addUserToTenant(tenantId: $tenantId, userObject: $userObject) {
    #      name
    #    }
  }
`;

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [
    updateUserMetadata,
    {
      data: successUpdateUserMetadata,
      loading: loadingUpdateUserMetadata,
      error: errorUpdateUserMetadata,
    },
  ] = useMutation(UPDATE_USER_METADATA);

  // const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  // const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  // const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

  const domain = "account.whereabouts.tech";
  const clientId = "SJSHdmqZ0NttG09Utf2StPFrJqNUBcoq";
  const redirectUri = window.location.origin;

  const onRedirectCallback = (appState, user) => {
    setSearchParams({});
    return <Navigate to={"/"} />;
    console.log("hello");
    navigate("/onboarding/ontarios-highlands/members/join");
    if (user?.whereaboutsOnboarding?.onboarding.complete == true) {
      navigate(`/join`);
    }

    //   updateUserMetadata({
    //     variables: {
    //       id: user.sub,
    //       body: JSON.stringify({
    //         app_metadata: {
    //           tenants: appState.tenants,
    //           onboarding: {
    //             ...user?.whereaboutsOnboarding?.onboarding,
    //             signUp: {
    //               ...user?.whereaboutsOnboarding?.onboardingsignUp,
    //               pages: [appState?.returnTo || getCompleteURL()],
    //             },
    //           },
    //         },
    //       }),
    //     },
    //   }).then(navigate(appState?.returnTo || getCompleteURL()));
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
      //useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};
