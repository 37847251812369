import { Carousel } from "@ttc3k/trekker";
import { Maybe, NewImage } from "gql/generated";
import { Box } from "styled-system/jsx";
import { css } from "styled-system/css";

type HeroImageProps = {
  images: NewImage[] | Maybe<NewImage>[] | undefined;
};

export const HeroImage = ({ images }: HeroImageProps) => {
  if (!images || images.length < 1) {
    return null;
  }

  return (
    <>
      <Box mb="-20px" position="sticky" top={0}>
        <Carousel
          images={images}
          height={484}
          tagClassName={css({ pb: "150" })}
        />
      </Box>
    </>
  );
};
