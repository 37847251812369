import { useState } from "react";
import { Box, Flex } from "styled-system/jsx";
import { FormProvider } from "react-hook-form";
import { ControlledInput, ControlledTextarea } from "booker/shared";
import { Text } from "@ttc3k/trekker";
import { OverlayPage, ConsumingOverlayPageProps } from "../OverlayPage";
import { useContactUsForm } from "./useContactUsForm";

type ContactUsPageProps = ConsumingOverlayPageProps;

export const ContactUsPage = (args: ContactUsPageProps) => {
  const [formError, setFormError] = useState("");
  const { form, onSubmit, defaultValues, loading } = useContactUsForm({
    onSuccess: () => {
      form.reset(defaultValues);
      args.onClose();
      setFormError("");
    },
    onError: m => setFormError(m),
  });

  return (
    <OverlayPage
      title={"Contact Us"}
      subtitle={
        "Please write any questions you may have in the form below. We're looking forward to hearing from you!"
      }
      buttonLabel={"Send message"}
      onButtonClick={onSubmit}
      buttonDisabled={loading}
      {...args}
    >
      <Box mt={"400"}>
        <FormProvider {...form}>
          <form>
            <Flex flexDir={"column"} gap={"150"}>
              <ControlledInput name="name" label="Full Name" />
              <ControlledInput name="email" label="Email address" />
              <ControlledInput name="phone" label="Phone number" />
              <ControlledTextarea
                name="message"
                label="Message"
                _focus={{
                  boxShadow: "inset 0 0 0 1px token(colors.border.dark)",
                  outline: "0",
                }}
                _hover={{
                  _focus: {
                    boxShadow: "inset 0 0 0 1px token(colors.border.dark)",
                    outline: "0",
                  },
                  _enabled: {
                    boxShadow: "inset 0 0 0 1px token(colors.border.mid)",
                    outline: "0",
                  },
                }}
              />
            </Flex>
          </form>
        </FormProvider>
      </Box>
      {formError.length > 0 && (
        <Text color={"text.error.mid"} visual={"smallMedium"}>
          {formError}
        </Text>
      )}
    </OverlayPage>
  );
};
