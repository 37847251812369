import {
  ControlledInput,
  ControlledTextarea,
} from "apps/custom/ttc/booker/shared";
import { ControlledPriceInput } from "core/components/shared/ControlledPriceInput";

import { Flex } from "styled-system/jsx";

export const FeeDetailsFormFields = () => {
  return (
    <Flex flexDir={"column"} gap={"300"} width={"full"}>
      <ControlledInput name={"name"} label={"Name"} width={"full"} />
      <ControlledPriceInput name={"price"} label={"Price"} />
      <ControlledTextarea name="description" label={"Description"} rows={10} />
    </Flex>
  );
};
