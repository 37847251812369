import {
  ButtonTagVariant,
  LegacyButton,
  LegacyToast,
  LegacyTypography,
} from "@ttc3k/ttc-design-system";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "../TextField";
import { Dialog } from "../Dialog";
import { LockIcon } from "../../../../apps/library/directory/branches/leaves/Profile.tsx";
import {
  personalProfileSchema,
  personalProifleDefaultValues,
} from "./peresonalProfileSchema.ts";

const CREATE_PERSON = gql`
  mutation CreatePerson($record: CreateOnePersonInput!) {
    personCreate(record: $record) {
      recordId
      record {
        email
      }
    }
  }
`;

const UPDATE_AUTH0 = gql`
  mutation AddProfileIdToUser($auth0Id: String!, $id: String!) {
    addProfileIdToUser(auth0Id: $auth0Id, id: $id) {
      user_id
    }
  }
`;

export default function CreateUserProfile({ user, onComplete }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  //Mutation to Create Person Record in DB
  const [
    createPerson,
    {
      data: createPersonData,
      loading: createPersonLoading,
      error: createPersonError,
    },
  ] = useMutation(CREATE_PERSON);

  //Mutation to add Person record to Auth0
  const [
    updateAuth0,
    {
      data: updateAuth0Data,
      loading: updateAuth0Loading,
      error: updateAuth0Error,
    },
  ] = useMutation(UPDATE_AUTH0);

  //Prepare the Form
  const form = useForm<personalProfileSchema>({
    resolver: zodResolver(personalProfileSchema),
    defaultValues: personalProifleDefaultValues,
    mode: "onChange",
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isDirty, isValid },
  } = form;

  //Handle form submit
  const onSubmit: SubmitHandler<personalProfileSchema> = async formData => {
    try {
      createPerson({
        variables: {
          record: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email.toLowerCase(),
            auth0Id: user.sub,
            accountStatus: "ACTIVE",
            tenantsOf: [
              {
                tenantId: user.sub,
                role: "OWNER",
              },
            ],
          },
        },
      })
        .then(r => {
          updateAuth0({
            variables: { id: r.data.personCreate.recordId, auth0Id: user.sub },
          });
          onComplete(user.sub);
        })
        .then(r => {
          setIsDialogOpen(false);
        });
    } catch (e) {
      setErrorMessage(e);
      console.log(e);
    }
  };

  useEffect(() => {
    setValue("email", user.email, { shouldValidate: true });
  }, []);

  return (
    <>
      <LegacyButton
        onMouseDown={() => {
          setIsDialogOpen(true);
        }}
      >
        Create profile
      </LegacyButton>
      <Dialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        className="tw-flex tw-flex-col tw-gap-6 tw-shadow-none tw-rounded-xl"
      >
        <div className="tw-flex tw-flex-col tw-gap-5">
          <LegacyTypography variant={"H3"}>
            Create Personal Whereabouts Profile
          </LegacyTypography>
          <LegacyTypography>
            <b>What's this for?</b> Depending on how your were onboarded, you
            may not have a personal profile. A profile is necessary to use apps
            like Learner.
          </LegacyTypography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={"tw-flex tw-flex-col tw-gap-5"}>
              <Controller
                name="firstName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="First Name"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    autoComplete="first-name"
                    // endIcon={<LockIcon />}
                    // disabled
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Last Name"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    autoComplete="last-name"
                    // endIcon={<LockIcon />}
                    // disabled
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    value={user.email}
                    label="Email address"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    autoComplete="current-email"
                    endIcon={<LockIcon />}
                    disabled
                  />
                )}
              />
            </div>
            <div className="tw-flex tw-space-x-space-150 tw-mt-10">
              <LegacyButton
                fullWidth
                variant={ButtonTagVariant.Secondary}
                onClick={() => setIsDialogOpen(false)}
              >
                Cancel
              </LegacyButton>
              <LegacyButton
                fullWidth
                variant={ButtonTagVariant.InfoBold}
                disabled={createPersonLoading || !isValid}
                type="submit"
                isLoading={createPersonLoading}
                onClick={() => handleSubmit(onSubmit)}
              >
                Create User
              </LegacyButton>
            </div>
            <div className="tw-mt-5">
              {errorMessage && <LegacyToast>{errorMessage}</LegacyToast>}
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
}
