import { useAppContext } from "core/components/apps/PreferredLayout";
import { useEffect, useState } from "react";

export enum PaymentStatus {
  "All" = "ALL",
  "FullRefund" = "FULL_REFUND",
  "PartialRefund" = "PARTIAL_REFUND",
  "NoRefund" = "NO_REFUND",
}

export type OrderFilterValue = {
  searchParams: string;
  paymentStatus: PaymentStatus;
  transactionDates: { start: string; end: string };
};

export const useOrderSpace = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    refundModal: false,
  });
  const defaultFilterValue: OrderFilterValue = {
    searchParams: "",
    paymentStatus: PaymentStatus.All,
    transactionDates: { start: "", end: "" },
  };
  const [filterValue, setFilterValue] = useState(defaultFilterValue);

  const { setTitle, setDescription } = useAppContext();

  useEffect(() => {
    setTitle("Orders");
    setDescription(
      "See the full history of all your Booker orders and issue full or partial refunds.",
    );
  }, [setDescription, setTitle]);

  const handleOpenIssueRefundModal = () => {
    setIsModalOpen(prev => ({ ...prev, refundModal: true }));
  };
  const handleCloseIssueRefundModal = () => {
    setIsModalOpen(prev => ({ ...prev, refundModal: false }));
  };

  const handleUpdateFilter = <K extends keyof OrderFilterValue>(
    key: K,
    value: OrderFilterValue[K],
  ) => {
    setFilterValue(prev => ({ ...prev, [key]: value }));
  };

  const handleResetFilter = () => {
    setFilterValue(defaultFilterValue);
  };
  return {
    filter: filterValue,
    onUpdateFilter: handleUpdateFilter,
    onResetFilter: handleResetFilter,
    isIssueRefundModalOpen: isModalOpen,
    onOpenIssueRefundModal: handleOpenIssueRefundModal,
    onCloseIssueRefundModal: handleCloseIssueRefundModal,
  };
};
