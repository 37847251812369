import {
  LegacySectionHeader,
  LegacySectionHeaderProps,
} from "@ttc3k/ttc-design-system";
import { ReactNode } from "react";
import { cn } from "../../../utils/cn";

export interface SectionProps extends LegacySectionHeaderProps {
  children: ReactNode;
  className?: string;
}

export const Section = (props: SectionProps) => {
  const { title, icon, children, className, endComponent } = props;
  return (
    <div className={cn("tw-w-full tw-flex tw-flex-col tw-gap-4", className)}>
      <LegacySectionHeader
        title={title}
        icon={icon}
        endComponent={endComponent}
      />
      {children}
    </div>
  );
};
