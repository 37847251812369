import { useFormHandler } from "core/hooks/useFormHandler";
import {
  OrderManyDocument,
  RefundManyDocument,
  useOrderUpdateMutation,
  useRefundCreateMutation,
} from "gql/generated";
import { useCallback } from "react";
import { z } from "zod";

const orderRefundCancelFormSchema = z.object({
  refundAmount: z.number().min(0),
  cancelled: z.boolean(),
});

export type OrderRefundCancelFormValues = z.infer<
  typeof orderRefundCancelFormSchema
>;

interface CreateClosureFormOptions {
  onSuccess?: () => void;
  onError?: (message: string) => void;
  orderTotal?: number;
  orderID: string;
}

export const useOrderRefundCancelForm = ({
  onSuccess,
  onError,
  orderTotal,
  orderID,
}: CreateClosureFormOptions) => {
  const defaultValues: OrderRefundCancelFormValues = {
    refundAmount: orderTotal ? orderTotal : 0,
    cancelled: false,
  };

  const [createRefund] = useRefundCreateMutation();
  const [updateOrder] = useOrderUpdateMutation();

  return useFormHandler<OrderRefundCancelFormValues>(
    useCallback(
      async data => {
        const payload = { ...data };

        const { cancelled, refundAmount } = payload;

        if (refundAmount && refundAmount > 0) {
          try {
            const refundRes = await createRefund({
              variables: { amount: refundAmount * 100, orderId: orderID },
              refetchQueries: [RefundManyDocument],
            });

            if (
              refundRes &&
              refundRes.data &&
              refundRes.data.refundCreate?.record
            ) {
              if (cancelled) {
                try {
                  const orderRes = await updateOrder({
                    variables: { id: orderID, cancelled: true },
                    refetchQueries: [OrderManyDocument],
                  });

                  if (
                    orderRes &&
                    orderRes.data &&
                    orderRes.data.orderUpdate?.recordId
                  ) {
                    onSuccess?.();
                  }
                } catch {
                  onError?.(
                    "There has been an error cancelling this order. The refund has been initiated. Please try again later.",
                  );
                }
              } else {
                onSuccess?.();
              }
            }
          } catch {
            onError?.(
              "There has been an error refunding this order. Please try again later.",
            );
          }
        }

        if (cancelled && (!refundAmount || refundAmount === 0)) {
          try {
            const orderRes = await updateOrder({
              variables: { id: orderID, cancelled: true },
              refetchQueries: [OrderManyDocument],
            });

            if (
              orderRes &&
              orderRes.data &&
              orderRes.data.orderUpdate?.recordId
            ) {
              onSuccess?.();
            }
          } catch {
            onError?.(
              "There has been an error cancelling this order. Please try again later.",
            );
          }
        }
      },
      [createRefund, onError, onSuccess, orderID, updateOrder],
    ),
    defaultValues,
    { schema: orderRefundCancelFormSchema },
  );
};
